import React, { Component } from 'react';
import moment from 'moment-timezone';

export default class DateLabel extends Component {
	render() {
		if (!this.props.date) {
			return null;
		}

		try {
			const date = moment(new Date(this.props.date));
			return <span>{date.format('D') + ' ' + date.format('MMM') + ' ' + date.format('YYYY')}</span>;
		} catch (ex) {
			return null;
		}
	}
}
