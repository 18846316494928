import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';

import APIPut from '../../../API/APIPut';

import APIHelper from '../../../Helper/APIHelper';

import WholePageLoading from '../../../Components/WholePageLoading';
import TextArea from '../../../Components/TextArea';

import APPROVAL_STATUS from '../../../StaticData/ApprovalStatus';

export default class ExpenseModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			approvalStatus: {
				value: props.expense.approvalStatus,
				label: props.expense.approvalStatus,
			},
			approvalReason: props.expense.approvalReason,
		};
	}

	componentDidMount() {}

	handleInputChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	validateModal = () => {
		return true;
	};

	save = async () => {
		if (this.validateModal()) {
			this.setState({
				isLoading: true,
			});
			const { expense } = this.props;
			const data = {
				approvalReason: this.state.approvalReason,
				approvalStatus: this.state.approvalStatus.value,
			};

			const request = await APIPut(
				'incentive/programs/' +
					expense.incentiveProgramId +
					'/expenses/' +
					expense.id +
					'/approval',
				data
			);
			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success('แก้ไขเรียบร้อยแล้ว');
				const newExpense = request.data;
				this.props.updateApprovalStatus(newExpense);
			} else {
				toast.error('เกิดปัญหาในการแก้ไข');
			}

			this.setState({
				isLoading: false,
			});
		} else {
			toast.warning('โปรดตัวสอบข้อมูลให้ครบถ้วน');
		}
	};

	render() {
		return (
			<div className="modal is-active">
				<div className="modal-background" onClick={this.props.close} />
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title">Approve</p>
						<button className="delete" onClick={this.props.close} />
					</header>
					<section className="modal-card-body">
						<div className="row">
							<label className="label">สถานะ</label>
							<Select
								name="approvalStatus"
								value={this.state.approvalStatus}
								clearable={false}
								onChange={value =>
									this.handleInputChange(
										'approvalStatus',
										value
									)
								}
								options={APPROVAL_STATUS}
							/>
						</div>
						<div className="row">
							<TextArea
								title="เหตุผล"
								labelSize="s"
								row={10}
								keyProps="approvalReason"
								value={this.state.approvalReason}
								onInputChange={this.handleInputChange}
								isMandatory={false}
							/>
						</div>
					</section>
					<footer className="modal-card-foot level">
						<div className="level-item level-left" />
						<div className="level-item level-right">
							<button
								className="button is-success"
								onClick={this.save}
							>
								Save
							</button>
							<button
								className="button"
								onClick={this.props.close}
							>
								Cancel
							</button>
						</div>
					</footer>
				</div>
				{this.state.isLoading ? <WholePageLoading /> : null}
			</div>
		);
	}
}
