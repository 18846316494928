import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';

import AppConfig from '../../../AppConfig';

export default class Companies extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			companies: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('companies');
			if (APIHelper.handleAPIResponse(this, request)) {
				const companies = request.data;
				console.log('companies: ', companies);
				this.setState({
					isLoading: false,
					companies,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">ลูกค้า (Incentive)</h1>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/newcompany'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<thead>
							<tr>
								<td width="40%">ชื่อ</td>
								<td width="15%" style={{ textAlign: 'right' }}>
									ติดเงิน (บาท)
								</td>
								<td width="15%" style={{ textAlign: 'right' }}>
									ติดเงิน (วัน)
								</td>
								<td width="15%" style={{ textAlign: 'center' }}>
									ผู้ติดต่อ
								</td>
								<td width="15%" style={{ textAlign: 'center' }}>
									โปรแกรมทั้งหมด
								</td>
							</tr>
						</thead>
						<tbody>
							{this.state.companies.map(company => (
								<tr key={company.id}>
									<td>
										<Link to={AppConfig.appPath + '/data/companies/' + company.id}>{company.name}</Link>
									</td>
									<td className="number-cell">
										<NumberColor isExpense={true} number={company.debt} />
									</td>
									<td className="number-cell">
										<NumberColor isExpense={true} number={company.debtDays} />
									</td>
									<td style={{ textAlign: 'center' }}>
										<Link to={AppConfig.appPath + '/data/companies/' + company.id + '/contacts'}>แก้ไข</Link>
									</td>
									<td style={{ textAlign: 'center' }}>
										<Link to={AppConfig.appPath + '/data/companies/' + company.id + '/programs'}>เปิด</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
