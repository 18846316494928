import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthRender from '../AuthRender';

import AppConfig from '../../AppConfig';

class DesktopMiddleMenu extends Component {
	isPageOpened = partOfUrl => {
		const check = window.location.href.split(partOfUrl);
		return check.length > 1;
	};

	render() {
		if (!this.props.isLogin) {
			return null;
		}

		return (
			<div className="navbar-start">
				<NavLink to={AppConfig.appPath + '/programs/incentive'} className="navbar-item clickable" activeClassName="is-active">
					INCENTIVE
				</NavLink>
				<NavLink to={AppConfig.appPath + '/programs/planning-incentive'} className="navbar-item clickable" activeClassName="is-active">
					INCENTIVE Planning
				</NavLink>
				<hr className="navbar-divider" />
				<NavLink to={AppConfig.appPath + '/programs/jointour'} className="navbar-item clickable" activeClassName="is-active">
					JOIN TOUR
				</NavLink>
				<hr className="navbar-divider" />
				<NavLink to={AppConfig.appPath + '/programs/fit'} className="navbar-item clickable" activeClassName="is-active">
					FIT
				</NavLink>
				<hr className="navbar-divider" />
				<NavLink to={AppConfig.appPath + '/programs/collective'} className="navbar-item clickable" activeClassName="is-active">
					COLLECTIVE
				</NavLink>
				<AuthRender roles={['Admin', 'Finance']}>
					<div className="navbar-item has-dropdown is-hoverable">
						<a className="navbar-link">Finance</a>
						<div className="navbar-dropdown is-boxed">
							<NavLink to={AppConfig.appPath + '/finance/companyexpenses'} className="navbar-item clickable" activeClassName="is-active">
								รายจ่ายของบริษัท
							</NavLink>
							<NavLink to={AppConfig.appPath + '/finance/companyExpensesDaily'} className="navbar-item clickable" activeClassName="is-active">
								รายจ่ายของบริษัท (สรุปรายวัน)
							</NavLink>
							<hr className="navbar-divider" />
							<NavLink to={AppConfig.appPath + '/finance/companyexpensecategories'} className="navbar-item clickable" activeClassName="is-active">
								ประเภทรายจ่ายของบริษัท
							</NavLink>
							<AuthRender roles={['Admin']}>
								<NavLink to={AppConfig.appPath + '/finance/salary'} className="navbar-item clickable" activeClassName="is-active">
									เงินเดือน
								</NavLink>
								<NavLink to={AppConfig.appPath + '/finance/commission'} className="navbar-item clickable" activeClassName="is-active">
									Commission
								</NavLink>
								<NavLink to={AppConfig.appPath + '/finance/monthlysummary'} className="navbar-item clickable" activeClassName="is-active">
									สรุปรายเดือน
								</NavLink>
							</AuthRender>
						</div>
					</div>
				</AuthRender>
				<div className="navbar-item has-dropdown is-hoverable">
					<a className="navbar-link">Report</a>
					<div className="navbar-dropdown is-boxed">
						<NavLink to={AppConfig.appPath + '/reports/creditCard'} className="navbar-item clickable" activeClassName="is-active">
							Credit Card
						</NavLink>
						<NavLink to={AppConfig.appPath + '/reports/summary'} className="navbar-item clickable" activeClassName="is-active">
							Summary
						</NavLink>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DesktopMiddleMenu);
