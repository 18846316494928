import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import LoadingCenter from './LoadingCenter';
import InputRow from './InputRow';

import APIGet from '../API/APIGet';
import APIPost from '../API/APIPost';
import AuthHelper from '../Helper/AuthHelper';

export default class LoginForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			email: '',
			password: '',
		};
	}

	handleInputChange = (key, value) => {
		this.setState({
			[key]: value,
		});
	};

	handleKeyPress = e => {
		if (e.key === 'Enter') {
			if (this.state.email && this.state.password) {
				this.login();
			}
		}
	};

	login = async () => {
		var loginError = true;
		this.setState({
			isLoading: true,
		});

		const loginToken = await APIPost('auth', {
			email: this.state.email,
			password: this.state.password,
		});

		if (loginToken && loginToken.status === 200) {
			AuthHelper.loginSuccess(loginToken.data);
			const userDetail = await APIGet('auth');
			if (userDetail && userDetail.status === 200) {
				AuthHelper.saveUserDetail(userDetail.data);
				loginError = false;
				this.props.onSuccess();
			}
		}

		if (loginError) {
			this.setState({
				isLoading: false,
			});

			toast.error('เกิดปัญหาในการ Login');
		}
	};

	render() {
		return (
			<div className="card">
				<header className="card-header">
					<p className="card-header-title">ESKY / Login</p>
				</header>
				<div className="card-content">
					<div className="content">
						<div>
							<InputRow
								title="email"
								labelSize="s"
								placeholder="email"
								keyProps="email"
								// value={this.state.email}
								onInputChange={this.handleInputChange}
								disabled={false}
								onKeyPress={this.handleKeyPress}
							/>
						</div>
						<div style={{ marginTop: '15px' }}>
							<InputRow
								title="Password"
								labelSize="s"
								type="password"
								placeholder="Password"
								keyProps="password"
								// value={this.state.password}
								onInputChange={this.handleInputChange}
								disabled={false}
								onKeyPress={this.handleKeyPress}
							/>
						</div>
					</div>
				</div>
				<footer
					className="card-footer"
					style={{ backgroundColor: '#333333' }}
				>
					{this.state.isLoading ? (
						<div style={{ width: '100%', textAlign: 'center' }}>
							<LoadingCenter />
						</div>
					) : (
						<a className="card-footer-item" onClick={this.login}>
							Login
						</a>
					)}
				</footer>
			</div>
		);
	}
}
