import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import InputRow from '../../../Components/InputRow';
import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';

import AppConfig from '../../../AppConfig';

export default class FITCustomrs extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			fitCustomers: [],
			search: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('fit/customers');
			if (APIHelper.handleAPIResponse(this, request)) {
				const fitCustomers = request.data;
				console.log('fitCustomers: ', fitCustomers);
				this.setState({
					isLoading: false,
					fitCustomers,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	handleInputChange = (key, value) => {
		this.setState({
			[key]: value,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		let { search } = this.state;
		const filtered = this.state.fitCustomers.filter(
			fitCustomer =>
				(!fitCustomer.deleted_at && fitCustomer.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
				(fitCustomer.nickname && fitCustomer.nickname.toLowerCase().indexOf(search.toLowerCase()) !== -1)
		);

		console.log(search, filtered);
		return (
			<div className="container">
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">ลูกค้า (FIT)</h1>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/newfitcustomer'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<div className="row columns">
						<div className="column is-6">
							<InputRow
								title="ชื่อ"
								labelSize="s"
								placeholder="ชื่อ"
								keyProps="search"
								value={this.state.search}
								onInputChange={this.handleInputChange}
								disabled={false}
								onKeyPress={this.handleKeyPress}
							/>
						</div>
					</div>
					<table className="table is-hoverable is-fullwidth">
						<thead>
							<tr>
								<td width="60%">ชื่อ</td>
								<td width="20%" style={{ textAlign: 'center' }}>
									ผู้ติดต่อ
								</td>
								<td width="20%" style={{ textAlign: 'center' }}>
									โปรแกรมที่ผ่านมา
								</td>
							</tr>
						</thead>
						<tbody>
							{filtered.map(fitCustomer => (
								<tr key={fitCustomer.id}>
									<td>
										<Link to={AppConfig.appPath + '/data/fit/customers/' + fitCustomer.id}>
											{fitCustomer.name} {fitCustomer.nickname ? '(' + fitCustomer.nickname + ')' : ''}
										</Link>
									</td>
									<td style={{ textAlign: 'center' }}>
										<Link to={AppConfig.appPath + '/data/fit/customers/' + fitCustomer.id + '/contacts'}>ผู้ติดต่อ</Link>
									</td>
									<td style={{ textAlign: 'center' }}>
										<Link to={AppConfig.appPath + '/data/fit/customers/' + fitCustomer.id + '/programs'}>เปิด</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
