import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import DateLabel from '../../Components/DateLabel';
import DateRangeLabel from '../../Components/DateRangeLabel';

import AppConfig from '../../AppConfig';

import SummaryTableRow from './SummaryTableRow';

export default class SummaryTable extends PureComponent {
	getSummary = (jointour, incentive, fit) => {
		let summary = {
			customer: 0,
			price: 0,
			salePrice: 0,
			profit: 0,
		};

		if (jointour) {
			summary.customer += parseInt(jointour.customer);
			summary.price += jointour.price;
			summary.salePrice += jointour.salePrice;
			summary.profit += jointour.profit;
		}

		if (incentive) {
			summary.customer += parseInt(incentive.customer);
			summary.price += incentive.price;
			summary.salePrice += incentive.salePrice;
			summary.profit += incentive.profit;
		}

		if (fit) {
			summary.customer += parseInt(fit.customer);
			summary.price += fit.price;
			summary.salePrice += fit.salePrice;
			summary.profit += fit.profit;
		}

		return summary;
	};
	render() {
		const { date, jointour, incentive, fit, startDate, endDate } = this.props;
		const summary = this.getSummary(jointour, incentive, fit);

		const linkStartDate = startDate ? startDate : date;
		const linkEndDate = endDate ? endDate : date;

		return (
			<div className="row" style={{ borderWidth: 1, borderStyle: 'solid', borderColor: '#999999' }}>
				<table className="table is-hoverable is-fullwidth is-bordered">
					<thead>
						<tr className="table-header">
							<td>{date ? <DateLabel date={date} /> : <DateRangeLabel startDate={startDate} endDate={endDate} />}</td>
							<td width="15%">ลูกค้า</td>
							<td width="15%">ราคาขาย</td>
							<td width="15%">ต้นทุน</td>
							<td width="15%">กำไร</td>
						</tr>
					</thead>
					<tbody>
						<SummaryTableRow type="JOINTOUR" {...jointour} goto={`/programs/jointour?startDate=${linkStartDate}&&endDate=${linkEndDate}`} />
						<SummaryTableRow type="FIT" {...fit} goto={`/programs/fit?startDate=${linkStartDate}&&endDate=${linkEndDate}`} />
						<SummaryTableRow type="INCENTIVE" {...incentive} goto={`/programs/incentive?startDate=${linkStartDate}&&endDate=${linkEndDate}`} />
						<SummaryTableRow type="Summary" {...summary} />
					</tbody>
				</table>
			</div>
		);
	}
}
