import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import APIGet from '../../../API/APIGet';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import AppConfig from '../../../AppConfig';

export default class CompanyContacts extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			contacts: [],
			jointourCustomer: null,
			customer: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { customerId } = this.props.match.params;
			let requestJointourCustomerContacts = APIGet(`jointour/customers/${customerId}/contacts`);
			let requestJointourCustomer = APIGet('jointour/customers/' + customerId);
			requestJointourCustomerContacts = await requestJointourCustomerContacts;
			requestJointourCustomer = await requestJointourCustomer;
			if (APIHelper.handleAPIResponse(this, requestJointourCustomerContacts, requestJointourCustomer)) {
				const contacts = requestJointourCustomerContacts.data;
				const jointourCustomer = requestJointourCustomer.data;
				console.log('contacts: ', contacts);
				console.log('jointourCustomer: ', jointourCustomer);
				this.setState({
					isLoading: false,
					contacts,
					jointourCustomer,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { customerId } = this.props.match.params;
		const { jointourCustomer, contacts } = this.state;
		return (
			<div className="container">
				<BackButtonRouter link={`${AppConfig.appPath}/data/jointour/customers`} />
				<div className="columns">
					<div className="column is-8">
						<h1 className="title is-2">{jointourCustomer.name}</h1>
						<h3 className="subtitle is-4">ผู้ติดต่อ</h3>
					</div>
					<div className="column is-4" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/jointour/customers/' + customerId + '/newcontact'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{contacts.map(customer => (
								<tr key={customer.id}>
									<td width="70%">
										<Link to={AppConfig.appPath + '/data/jointour/customers/' + customerId + '/contacts/' + customer.id}>
											{customer.name} {customer.nickname ? '(' + customer.nickname + ')' : ''}
										</Link>
									</td>
									<td width="30%" style={{ textAlign: 'right' }} />
								</tr>
							))}
							{contacts.length === 0 ? 'ยังไม่มีผู้ติดต่อของบริษัทในระบบ' : null}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
