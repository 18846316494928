import React from 'react';
import { toast } from 'react-toastify';

import LoadingCenter from '../LoadingCenter';
import DateLabel from '../DateLabel';

import APIGet from '../../API/APIGet';
import APIHelper from '../../Helper/APIHelper';

export default class RestaurantHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			visitDetail: null,
		};
	}
	async componentDidMount() {
		const { programId, id } = this.props;
		if (programId) {
			const request = await APIGet(`incentive/programs/${programId}/restaurants/${id}`);
			if (APIHelper.handleAPIResponse(this, request)) {
				const visitDetail = request.data;
				console.log('visitDetail: ', visitDetail);
				this.setState({
					isLoading: false,
					visitDetail,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		}
	}

	render() {
		const { programId } = this.props;
		if (!programId) {
			return null;
		}

		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { visitDetail } = this.state;

		if (!visitDetail) {
			return null;
		}

		return (
			<div>
				{visitDetail.pricePerHead} | <DateLabel date={visitDetail.visitDate} /> | {visitDetail.visitTime}
			</div>
		);
	}
}
