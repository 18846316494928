import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import NumberColor from '../../Components/NumberColor';

import AppConfig from '../../AppConfig';

export default class SummaryTableRow extends PureComponent {
	render() {
		const { type, customer, salePrice, price, profit, goto } = this.props;

		return (
			<tr>
				<td>{goto ? <Link to={AppConfig.appPath + goto}>{type}</Link> : <span>{type}</span>}</td>
				<td className="number-cell">
					<NumberColor number={customer ? customer : 0} />
				</td>
				<td className="number-cell">
					<NumberColor number={salePrice ? salePrice : 0} />
				</td>
				<td className="number-cell">
					<NumberColor number={price ? price : 0} isExpense={true} />
				</td>
				<td className="number-cell">
					<NumberColor number={profit ? profit : 0} />
				</td>
			</tr>
		);
	}
}
