import React, { Component } from 'react';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';

import FITTypeHelper from '../../FIT/Helpers/FITTypeHelper';

import AppColor from '../../../AppColor';

export default class FitList extends Component {
	getRows = list => {
		let rows = [];

		list.forEach(row => {
			const price = row.price + row.expense + row.creditCharge;

			const salePrice = row.salePrice + row.creditCharge;
			const profit = salePrice - price;
			rows.push({
				...row,
				price,
				salePrice,
				profit,
			});
		});

		return rows;
	};

	getSummary = list => {
		let summary = {
			price: 0,
			salePrice: 0,
			profit: 0,
		};

		list.forEach(row => {
			summary.price += row.price;
			summary.salePrice += row.salePrice;
			summary.profit += row.salePrice - row.price;
		});

		return summary;
	};
	render() {
		const { list, isLoading } = this.props;
		if (isLoading) {
			return <LoadingCenter />;
		}

		if (!list || list.length === 0) {
			return null;
		}

		const rows = this.getRows(list);
		const summary = this.getSummary(rows);
		return (
			<div className="columns row">
				<div className="column is-12">
					<h4 className="title is-4">Fit</h4>
					<div className="row">
						<table className="table is-hoverable is-fullwidth is-bordered">
							<thead>
								<tr className="table-header">
									<td>Booking Date</td>
									<td>ลูกค้า</td>
									<td>Types</td>
									<td>Staffs</td>
									<td>ราคาขาย</td>
									<td>ต้นทุน</td>
									<td>กำไร</td>
								</tr>
							</thead>
							<tbody>
								{rows.map(row => (
									<tr key={'fitList' + row.id}>
										<td>
											<DateLabel date={row.created_at} />
										</td>
										<td>{row.customer ? row.customer.name : ''}</td>
										<td>
											{row.fitItems &&
												row.fitItems.map(item => (
													<span
														className="tag"
														key={'type-' + item.id}
														style={{ marginRight: 2, backgroundColor: AppColor.fitType[item.fitType] }}
													>
														{FITTypeHelper.mapLabel(item.fitType)}
													</span>
												))}
										</td>
										<td>{row.staffs.map(({ staff }) => (staff ? staff.name : '')).join(', ')}</td>
										<td className="number-cell">
											<NumberColor number={row.salePrice} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.price} isExpense={true} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.profit} />
										</td>
									</tr>
								))}
								<tr className="table-summary-row">
									<td colSpan={4}>Summary</td>
									<td className="number-cell">
										<NumberColor number={summary.salePrice} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.price} isExpense={true} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.profit} />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
