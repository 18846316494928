const PAYMENT_METHODS = [
	{
		value: 'NotPaid',
		label: 'ยังไม่จ่าย',
	},
	{
		value: 'Cash',
		label: 'Cash',
	},
	{
		value: 'Transfer',
		label: 'Transfer',
	},
	{
		value: 'Check',
		label: 'Check',
	},
	{
		value: 'Credit Card',
		label: 'Credit Card',
	},
];

export const PAYMENT_METHODS_REQUIRED = [
	{
		value: 'Cash',
		label: 'Cash',
	},
	{
		value: 'Transfer',
		label: 'Transfer',
	},
	{
		value: 'Check',
		label: 'Check',
	},
	{
		value: 'Credit Card',
		label: 'Credit Card',
	},
];

export default PAYMENT_METHODS;
