import React, { Component } from 'react';
import Loading from 'react-loading';

export default class LoadingCenter extends Component {
	render() {
		return (
			<div className="level">
				<div className="level-item has-text-centered">
					<Loading type="bubbles" color="#eeeeee" delay={0} />
				</div>
			</div>
		);
	}
}
