import React from 'react';

import FITItemTicket from './FITItemTicket';
import FITItemVisa from './FITItemVisa';

export default class FITItem extends React.Component {
	render() {
		const { items, fitItemHelper } = this.props;
		console.log(items);
		return (
			<div className="container">
				{items &&
					items.map(item => {
						if (item) {
							if (fitItemHelper.isTicket(item)) {
								return <FITItemTicket item={item} key={'item' + item.fitType + item.id} fitItemHelper={fitItemHelper} />;
							} else {
								return <FITItemVisa item={item} key={'item' + item.fitType + item.id} fitItemHelper={fitItemHelper} />;
							}
						} else {
							return null;
						}
					})}
			</div>
		);
	}
}
