import AppConfig from '../AppConfig';
import APIHelper from '../Helper/APIHelper';

export default async function APIPut(service, params = {}) {
	var data = null;
	var status = null;
	var statusText = null;
	try {
		const requestHeaders = APIHelper.getHttpHeader();
		var response = await fetch(AppConfig.apiUrl + service, {
			method: 'PUT',
			headers: requestHeaders,
			body: JSON.stringify(params),
		});

		status = response.status;
		statusText = response.statusText;
		try {
			data = await response.json();
		} catch (error) {}
	} catch (error) {
		console.log(error);
	}

	return { status, statusText, data };
}
