import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';

class BackButtonRouter extends Component {
	goBack = () => {
		// console.log(this.props.history);
		if (this.props.link) {
			this.props.history.replace(this.props.link);
		} else {
			this.props.history.goBack();
		}
	};

	render() {
		if (!this.props.link && this.props.history.length < 2) {
			return null;
		}

		return (
			<div className="clickable" style={{ marginBottom: 15, width: 200 }} onClick={this.goBack}>
				<FontAwesomeIcon icon="arrow-left" /> {this.props.title ? this.props.title : 'กลับหน้าที่แล้ว'}
			</div>
		);
	}
}

export default withRouter(BackButtonRouter);
