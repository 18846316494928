import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import EditableText from '../../../Components/EditableText';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';
import AuthRender from '../../../Components/AuthRender';

export default class IncomeEtcCategories extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			incomeEtcCategories: [],
			isEditLoading: '',
			newincomeEtcCategory: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('incentiveIncomeEtcCategories');
			if (APIHelper.handleAPIResponse(this, request)) {
				const incomeEtcCategories = request.data;
				console.log(incomeEtcCategories);
				this.setState({
					isLoading: false,
					incomeEtcCategories,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	saveChange = async (incomeEtcCategoryId, text) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPut('incentiveIncomeEtcCategories/' + incomeEtcCategoryId, {
			name: text,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const incomeEtcCategory = request.data;
			let incomeEtcCategories = JSON.parse(JSON.stringify(this.state.incomeEtcCategories));
			for (let i = 0; i < incomeEtcCategories.length; i++) {
				if (incomeEtcCategories[i].id === incomeEtcCategoryId) {
					incomeEtcCategories[i] = incomeEtcCategory;
				}
			}

			// console.log(incomeEtcCategories);
			toast.success('แก้ไขข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				incomeEtcCategories,
			});
		} else {
			toast.error('ไม่สามารถแก้ไขข้อมูลได้');
		}
	};

	addNew = async text => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPost('incentiveIncomeEtcCategories', {
			name: text,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const incomeEtcCategory = request.data;
			let incomeEtcCategories = JSON.parse(JSON.stringify(this.state.incomeEtcCategories));
			incomeEtcCategories.push(incomeEtcCategory);

			// console.log(incomeEtcCategories);
			toast.success('เพิ่มข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				incomeEtcCategories,
			});
		} else {
			toast.error('ไม่สามารถเพิ่มข้อมูลได้');
		}
	};

	delete = async incomeEtcCategoryId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete('incentiveIncomeEtcCategories/' + incomeEtcCategoryId);
		if (APIHelper.handleAPIResponse(this, request)) {
			const incomeEtcCategories = this.state.incomeEtcCategories.filter(incomeEtcCategory => incomeEtcCategory.id !== incomeEtcCategoryId);

			// console.log(incomeEtcCategories);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				incomeEtcCategories,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">ประเภทรายรับอื่นๆ</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.incomeEtcCategories.map(incomeEtcCategory => (
								<tr key={incomeEtcCategory.id}>
									<td width="100%">
										<EditableText
											id={incomeEtcCategory.id}
											text={incomeEtcCategory.name}
											confirmChange={text => this.saveChange(incomeEtcCategory.id, text)}
											confirmDelete={this.delete}
										>
											{incomeEtcCategory.name}
										</EditableText>
									</td>
								</tr>
							))}
							<AuthRender roles={['Admin', 'Finance']}>
								<tr>
									<td width="100%">
										<EditableText text={this.state.newincomeEtcCategory} confirmChange={text => this.addNew(text)} confirmText="เพิ่ม">
											เพิ่มประเภทรายรับอื่นๆ
										</EditableText>
									</td>
								</tr>
							</AuthRender>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
