import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingCenter from './LoadingCenter';

import APIPost from '../API/APIPost';
import APIDelete from '../API/APIDelete';
import APIHelper from '../Helper/APIHelper';

export default class FileManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	fileChangedHandler = event => {
		event.persist();
		let reader = new FileReader();
		let file = event.target.files[0];

		reader.onloadend = () => {
			var base64 = reader.result.substr(reader.result.indexOf(',') + 1);
			var newFile = {
				base64: base64,
				name: file.name,
				size: file.size,
			};

			this.uploadFile(newFile);
		};

		reader.onerror = error => {
			console.log('Error: ', error);
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	uploadFile = async file => {
		this.setState({
			isLoading: true,
		});

		const request = await APIPost(this.props.api, {
			uploadFile: file,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const response = request.data;
			this.setState({
				isLoading: false,
			});

			if (this.props.onUploadSuccess) {
				this.props.onUploadSuccess(response);
			}
		} else {
			this.setState({
				isLoading: false,
			});

			if (this.props.onUploadFail) {
				this.props.onUploadFail();
			}
		}
	};

	delete = async fileId => {
		this.setState({
			isLoading: true,
		});

		const request = await APIDelete(this.props.api + '/' + fileId);

		if (APIHelper.handleAPIResponse(this, request)) {
			const response = request.data;
			this.setState({
				isLoading: false,
			});

			if (this.props.onDeleteSuccess) {
				this.props.onDeleteSuccess(response);
			}
		} else {
			this.setState({
				isLoading: false,
			});

			if (this.props.onDeleteFail) {
				this.props.onDeleteFail();
			}
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { disableDelete, disableUpload, hideTitle, title, files } = this.props;

		return (
			<div className="content">
				{!hideTitle ? <strong>{title ? title : 'Files'}</strong> : null}
				<ul>
					{files &&
						files.map(file => (
							<li key={'file' + file.id}>
								<a target="_blank" href={file.url}>
									{file.filename}
								</a>{' '}
								{!disableDelete ? (
									<span className="clickable" style={{ color: 'red' }} onClick={() => this.delete(file.id)}>
										(ลบ)
									</span>
								) : null}
							</li>
						))}
				</ul>
				{!disableUpload ? (
					<div className="row">
						<label className="file-label">
							<input className="file-input" type="file" name="resume" onChange={this.fileChangedHandler} />
							<span className="file-cta">
								<span className="file-icon">
									<FontAwesomeIcon icon="upload" />
								</span>
								<span className="file-label">Choose a file…</span>
							</span>
						</label>
					</div>
				) : null}
			</div>
		);
	}
}

FileManager.propTypes = {
	title: PropTypes.string,
	files: PropTypes.array,
	api: PropTypes.string.isRequired,
	onUploadSuccess: PropTypes.func,
	onUploadFail: PropTypes.func,
	onDeleteSuccess: PropTypes.func,
	onDeleteFail: PropTypes.func,
};
