import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Select from 'react-select';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

export default class AddEditProgram extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			startDate: moment(new Date()),
			endDate: moment(new Date()).add(6, 'd'),
			countries: [],
			companies: [],
			selectCompanies: [],
			selectCompanyUsers: [],
			country: null,
			company: null,
			companyUser: null,
			numberOfCustomers: 0,
			title: '',
			code: '',
			total: 0,
			note: '',
			status: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	isUpdate = () => {
		const { programId } = this.props.match.params;
		if (programId) {
			return true;
		} else {
			return false;
		}
	};

	initCreate = async () => {
		let requestCountries = APIGet('countries');
		let requestCompanies = APIGet('companies');
		requestCountries = await requestCountries;
		requestCompanies = await requestCompanies;
		if (APIHelper.handleAPIResponse(this, requestCountries, requestCompanies)) {
			const countries = ThingsToSelect(requestCountries.data);
			const selectCompanies = ThingsToSelect(requestCompanies.data);
			const companies = requestCompanies.data;
			console.log('companies: ', companies);
			this.setState({
				countries,
				companies,
				selectCompanies,
			});

			return true;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { programId } = this.props.match.params;
		let requestProgram = await APIGet('incentive/programs/' + programId);
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const program = requestProgram.data;
			const company = program.company
				? {
						value: program.companyId,
						label: program.company.name,
				  }
				: null;
			const selectCompanyUsers = this.getCompanyUsersList(company);
			console.log('program: ', program);
			console.log('selectCompanyUsers: ', selectCompanyUsers);
			this.setState({
				note: program.note,
				title: program.title,
				total: program.total,
				code: program.code,
				country: program.country
					? {
							value: program.countryId,
							label: program.country.name,
					  }
					: null,
				companyUser: program.companyUser
					? {
							value: program.companyUserId,
							label: program.companyUser.name,
					  }
					: null,
				company,
				numberOfCustomers: program.numberOfCustomers,
				selectCompanyUsers,
				startDate: moment(new Date(program.startDate)),
				endDate: moment(new Date(program.endDate)),
				status: program.status,
			});

			return true;
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	onStateChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onCompanyChange = value => {
		const selectCompanyUsers = this.getCompanyUsersList(value);
		this.setState({
			company: value,
			selectCompanyUsers,
			companyUser: null,
		});
	};

	getCompanyUsersList = selectedCompany => {
		if (selectedCompany) {
			try {
				const companyId = selectedCompany.value;
				const company = this.state.companies.filter(company => company.id === companyId)[0];
				const companyUsers = ThingsToSelect(company.companyUsers);
				console.log('companyUsers: ', companyUsers);
				return companyUsers;
			} catch (ex) {
				console.error(ex);
				return [];
			}
		}

		return [];
	};

	validateSaveState = () => {
		const title = this.state.title.trim() !== '';
		if (!title) {
			toast.warn('กรุณาระบุชื่อโปรแกรม');
		}

		const country = this.state.country && this.state.country.value;
		if (!country) {
			toast.warn('กรุณาระบุชื่อประเทศ');
		}

		const datetime = this.state.startDate <= this.state.endDate;
		if (!datetime) {
			toast.warn('กรุณากรอกวันเดินทางให้ถูกต้อง (วันเริ่มเดินทางต้องมาก่อนวันสิ้นสุดการเดินทาง)');
		}

		const company = this.state.company && this.state.company.value;
		if (!company) {
			toast.warn('กรุณาระบุลูกค้า');
		}

		const companyUser = this.state.companyUser && this.state.companyUser.value;
		if (!companyUser) {
			toast.warn('กรุณาระบุผู้ติดต่อ');
		}

		const total = !isNaN(this.state.total);
		if (!total) {
			toast.warn('กรุณาระบุยอด โดยกรอกเป็นตัวเลขและจุดทศนิยมเท่านั้น (ไม่ต้องมี ,)');
		}

		const numberOfCustomers = !isNaN(this.state.numberOfCustomers);
		if (!numberOfCustomers) {
			toast.warn('กรุณาระบุจำนวนลูกค้า โดยกรอกเป็นตัวเลขและจุดทศนิยมเท่านั้น (ไม่ต้องมี ,)');
		}

		return title && country && datetime && company && companyUser && total && numberOfCustomers;
	};

	save = async () => {
		if (this.validateSaveState()) {
			let data = {
				title: this.state.title,
				code: this.state.code,
				total: this.state.total,
				note: this.state.note,
				countryId: this.state.country.value,
				startDate: this.state.startDate.format('YYYY-MM-DD'),
				endDate: this.state.endDate.format('YYYY-MM-DD'),
				companyUserId: this.state.companyUser.value,
				companyId: this.state.company.value,
				numberOfCustomers: this.state.numberOfCustomers,
			};

			let request = null;
			if (this.isUpdate()) {
				const { programId } = this.props.match.params;
				request = await APIPut('incentive/programs/' + programId, data);
			} else {
				request = await APIPost('incentive/programs', data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขโปรแกรมเรียบร้อยแล้ว' : 'เพิ่มโปรแกรมร้อยแล้ว');
				const result = request.data;
				this.props.history.push(AppConfig.appPath + '/programs/incentive/' + result.id);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มโปรแกรม');
			}
		} else {
			toast.warning('โปรดตรวจสอบความถูกต้องของข้อมูล');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { programId } = this.props.match.params;
		return (
			<div className="container">
				<BackButtonRouter link={this.isUpdate() ? AppConfig.appPath + '/programs/incentive/' + programId : AppConfig.appPath + '/programs/incentive'} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">{this.isUpdate() ? 'แก้ไข' : 'เพิ่ม'} INCENTIVE PROGRAM</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<div className="columns row">
								<div className="column is-3">
									<InputRow
										title="Code"
										labelSize="s"
										placeholder="code"
										keyProps="code"
										value={this.state.code}
										onInputChange={this.onStateChange}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-9">
									<InputRow
										title="ชื่อโปรแกรม"
										labelSize="s"
										placeholder="ชื่อโปรแกรม"
										keyProps="title"
										value={this.state.title}
										onInputChange={this.onStateChange}
										disabled={false}
										isMandatory={false}
									/>
								</div>
							</div>
							<div className="columns row">
								<div className="column is-4">
									<label className="label">ประเทศ</label>
									<Select
										name="country"
										value={this.state.country}
										clearable={false}
										onChange={value => this.onStateChange('country', value)}
										options={this.state.countries}
									/>
								</div>
								<div className="column is-8">
									<div className="is-pulled-left" style={{ marginLeft: 10 }}>
										<label className="label">จาก</label>
										<DatePicker
											selected={this.state.startDate}
											onChange={newDate => this.onStateChange('startDate', newDate)}
											dateFormat="DD MMM YYYY"
											className="input float-left"
										/>
									</div>
									<div className="is-pulled-left" style={{ marginLeft: 10 }}>
										<label className="label">ถึง</label>
										<DatePicker
											selected={this.state.endDate}
											onChange={newDate => this.onStateChange('endDate', newDate)}
											dateFormat="DD MMM YYYY"
											className="input float-left"
										/>
									</div>
								</div>
							</div>
							<div className="columns row">
								<div className="column is-3">
									<label className="label">ลูกค้า</label>
									<Select
										name="company"
										value={this.state.company}
										clearable={false}
										onChange={this.onCompanyChange}
										options={this.state.selectCompanies}
									/>
								</div>
								{this.state.selectCompanyUsers && this.state.selectCompanyUsers.length > 0 ? (
									<div className="column is-3">
										<label className="label">ติดต่อกับ</label>
										<Select
											name="companyUser"
											value={this.state.companyUser}
											clearable={false}
											onChange={value => this.onStateChange('companyUser', value)}
											options={this.state.selectCompanyUsers}
										/>
									</div>
								) : null}
								<div className="column is-3">
									<InputRow
										title="ลูกค้ากี่ท่าน"
										labelSize="s"
										placeholder="ลูกค้ากี่ท่าน"
										keyProps="numberOfCustomers"
										value={this.state.numberOfCustomers}
										onInputChange={this.onStateChange}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-3">
									<InputRow
										title="ยอดเรียกเก็บทั้งหมด"
										labelSize="s"
										placeholder="ยอดเรียกเก็บทั้งหมด"
										keyProps="total"
										value={this.state.total}
										onInputChange={this.onStateChange}
										disabled={false}
										isMandatory={false}
									/>
								</div>
							</div>
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onStateChange}
									isMandatory={false}
								/>
							</div>
							<div style={{ textAlign: 'right', marginTop: 20 }}>
								{AdminLockRender(
									this.state.status,
									<button className="button is-success" onClick={this.save}>
										บันทึก
									</button>,
									<button className="button is-success" disabled>
										บันทึก
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
