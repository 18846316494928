import React, { Component } from 'react';

import NumberColor from '../../../Components/NumberColor';

class Summary extends Component {
	render() {
		const { summary, total } = this.props;
		return (
			<div className="table-container">
				<table className="table is-hoverable is-fullwidth is-bordered">
					<thead>
						<tr className="table-header-green">
							<td width="70%">Summary</td>
							<td>Total (THB)</td>
						</tr>
					</thead>
					<tbody>
						{summary.map(expense => (
							<tr
								key={
									'summary' + expense.companyExpenseCategoryId
								}
							>
								<td>{expense.companyExpenseCategory.name}</td>
								<td className="number-cell">
									<NumberColor
										number={expense.total}
										isExpense={true}
									/>
								</td>
							</tr>
						))}
						<tr className="table-summary-row">
							<td>Total</td>
							<td className="number-cell">
								<NumberColor number={total} isExpense={true} />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}

export default Summary;
