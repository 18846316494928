import React, { Component } from 'react';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import { EditableDateSwitch } from '../../../../Components/EditableDateSwitch';
import moment from 'moment';
import { EditableSelectSwitch } from '../../../../Components/EditableSelectSwitch';

export const DetailInput = ({ state, onDepartureChange, onArrivalChange, onLandOperatorChange, onStaffChange }) => {
	const { isEditing, departureDate, returnDate, staffs, staff, landOperator } = state;
	return (
		<div className="columns">
			<div className="column is-3 control">
				<EditableDateSwitch isEditing={isEditing} title="ไป" value={moment(departureDate)} onChange={onDepartureChange} showIcon={true}>
					<p>{departureDate ? moment(departureDate).format('DD MMM YYYY') : 'None'}</p>
				</EditableDateSwitch>
			</div>
			<div className="column is-3 control">
				<EditableDateSwitch isEditing={isEditing} title="กลับ" value={moment(returnDate)} onChange={onArrivalChange} showIcon={true}>
					<p>{returnDate ? moment(returnDate).format('DD MMM YYYY') : 'None'}</p>
				</EditableDateSwitch>
			</div>
			<div className="column is-3 control">
				<EditableTextSwitch
					title="Land operator"
					titleLabelSize="s"
					keyProps="title"
					isEditing={isEditing}
					value={landOperator}
					onChange={text => onLandOperatorChange(text)}
				>
					<div>{landOperator ? landOperator : 'ไม่ระบุ'}</div>
				</EditableTextSwitch>
			</div>
			<div className="column is-3 control">
				<EditableSelectSwitch
					title="พนักงาน"
					name="creator"
					value={staff ? { value: staff.id, label: staff.name } : ''}
					clearable={false}
					onChange={staff => {
						const selectedStaff = staffs.filter(s => s.id === staff.value)[0];
						// console.log(selectedStaff);
						onStaffChange(selectedStaff);
					}}
					options={staffs.map(staff => ({ value: staff.id, label: staff.name }))}
					isEditing={isEditing}
				>
					<p>{staff ? staff.name : 'ไม่ระบุ'}</p>
				</EditableSelectSwitch>
			</div>
		</div>
	);
};
