const SEARCH_BY = [
	{
		value: 'TravelingDate',
		label: 'วันเดินทาง',
	},
	{
		value: 'BookingDate',
		label: 'วันทำจอง',
	},
];

export default SEARCH_BY;
