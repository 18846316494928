import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthRender from '../../../Components/AuthRender';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import FileManager from '../../../Components/FileManager';

import APIPost from '../../../API/APIPost';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

import Store from '../../../Helper/Store';
import StoreKey from '../../../StoreKey';

export default class IncomeInfo extends PureComponent {
	gotoAddTransaction = totalPrice => {
		const { program, type } = this.props;
		const url = AppConfig.appPath + '/programs/jointour/' + program.id + '/incomes/' + type + '/newtransaction';
		Store.set(StoreKey.incomeTransaction.title, totalPrice.title);
		Store.set(StoreKey.incomeTransaction.total, totalPrice.total - totalPrice.paid);
		this.props.goto(url);
	};

	getTotalPrice = () => {
		const { program, type } = this.props;
		let totalPrice = {
			title: '',
			total: 0,
			paid: 0,
			expectedPaymentDate: '',
		};
		if (type === 'deposit') {
			totalPrice.title = 'มัดจำ';
			totalPrice.total = program.deposit;
			totalPrice.paid = program.paidDeposit;
			totalPrice.expectedPaymentDate = program.dueDateDeposit;
		} else if (type === 'deposit2') {
			totalPrice.title = 'มัดจำ (งวดที่ 2)';
			totalPrice.total = program.deposit2;
			totalPrice.paid = program.paidDeposit2;
			totalPrice.expectedPaymentDate = program.dueDateDeposit2;
		} else {
			totalPrice.title = 'ยอดที่เหลือ';
			totalPrice.total = program.full;
			totalPrice.paid = program.paidFull;
			totalPrice.expectedPaymentDate = program.duedateFull;
		}

		return totalPrice;
	};

	onUploadSuccess = program => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.props.onProgramUpdated(program);
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = program => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.props.onProgramUpdated(program);
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	notifyPayInAttached = async () => {
		const request = await APIPost(`jointour/programs/${this.props.program.id}/incomes/${this.props.type}/transactions/notifyPayInAttached`);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('แจ้งข้อมูลไปยังบัญชีแล้ว');
		} else {
			toast.error('เกิดปัญหาในการแจ้งเตือน');
		}
	};

	render() {
		const { program, type } = this.props;
		const totalPrice = this.getTotalPrice();

		return (
			<div className="section">
				<div className="columns">
					<div className="column is-8">
						<h3 className="subtitle is-5">รายรับ</h3>
						<h3 className="title is-3">{totalPrice.title}</h3>
					</div>
					<div className="column is-4" style={{ textAlign: 'right' }} />
				</div>
				<div className="columns">
					<div className="column is-4">
						<div>
							ยอดเรียกเก็บทั้งหมด:{' '}
							<strong>
								<NumberColor number={totalPrice.total} />
							</strong>
						</div>
						<div>
							รับชําระมาเเล้ว:{' '}
							<strong>
								<NumberColor number={totalPrice.paid} />
							</strong>
						</div>
						<div>
							ค้างจ่าย:{' '}
							<strong>
								<NumberColor number={totalPrice.paid - totalPrice.total} />
							</strong>
						</div>
						<div>
							สถานะ:{' '}
							<strong>
								{totalPrice.total > totalPrice.paid ? (
									<label className="tag is-warning">ยังเก็บไม่ครบ</label>
								) : (
									<label className="tag is-success">เก็บครบแล้ว</label>
								)}
							</strong>
						</div>
						<div>
							ต้องชำระภายใน:{' '}
							<strong>
								<DateLabel date={totalPrice.expectedPaymentDate} />
							</strong>
						</div>
						<div>
							<strong>Note:</strong>
							<p>{program.note}</p>
						</div>
					</div>
					<div className="column is-4">
						<FileManager
							title="Files"
							files={program.incomeFiles}
							api={'jointour/programs/' + program.id + '/incomes/files/' + type}
							onUploadSuccess={this.onUploadSuccess}
							onUploadFail={this.onUploadFail}
							onDeleteSuccess={this.onDeleteSuccess}
							onDeleteFail={this.onDeleteFail}
							disableUpload={AuthHelper.hasRight('Finance')}
						/>
						<AuthRender roles={['Staff', 'Admin']}>
							<div className="row">
								<button className="button is-info" onClick={this.notifyPayInAttached}>
									แจ้งการแนบ Pay In
								</button>
							</div>
						</AuthRender>
					</div>
					<div className="column is-4" />
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="50%">รายการ</td>
								<td width="30%">ราคา</td>
								<td width="20%">เพิ่มรายการเก็บเงิน</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{totalPrice.title}</td>
								<td className="number-cell">
									<NumberColor number={totalPrice.total} />
								</td>
								<td style={{ textAlign: 'center' }}>
									{AuthHelper.hasRight('Finance', 'Admin') ? (
										<button className="button is-info" onClick={() => this.gotoAddTransaction(totalPrice)}>
											เพิ่ม
										</button>
									) : (
										<span className="tag is-warning">เฉพาะ Finance</span>
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
