import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import ProgramHeader from '../../../Components/ProgramHeader';
import PaymentDetail from '../../../Components/PaymentDetail';
import DeleteButton from '../../../Components/DeleteButton';

import { PAYMENT_METHODS_REQUIRED } from '../../../StaticData/PaymentMethods';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import ValidatePaymentDetail from '../../../Helper/ValidatePaymentDetail';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

import Store from '../../../Helper/Store';
import StoreKey from '../../../StoreKey';

import InputValidation from './InputValidation';

export default class AddEditIncomeTransaction extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			paymentDate: moment(new Date()),
			paymentTime: '',
			note: '',
			paymentMethod: PAYMENT_METHODS_REQUIRED[0],
			title: Store.get(StoreKey.incomeTransaction.title) ? Store.get(StoreKey.incomeTransaction.title) : '',
			total: Store.get(StoreKey.incomeTransaction.total) ? Store.get(StoreKey.incomeTransaction.total) : 0,
			bank: null,
			checkNumber: '',
			bankAccount: null,
			creditCard: null,
		};

		Store.invalidate(StoreKey.incomeTransaction.title);
		Store.invalidate(StoreKey.incomeTransaction.total);
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	isUpdate = () => {
		const { transactionId } = this.props.match.params;
		if (transactionId) {
			return true;
		} else {
			return false;
		}
	};

	initCreate = async () => {
		const { programId } = this.props.match.params;
		let requestProgram = await APIGet('jointour/programs/' + programId);
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const program = requestProgram.data;
			console.log('program: ', program);
			this.setState({
				isLoading: false,
				program,
			});
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
			return true;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { programId, type, transactionId } = this.props.match.params;
		let requestTransaction = await APIGet('jointour/programs/' + programId + '/incomes/' + type + '/transactions/' + transactionId);
		if (APIHelper.handleAPIResponse(this, requestTransaction)) {
			const transaction = requestTransaction.data;
			console.log('transaction: ', transaction);
			this.setState({
				...transaction,
				paymentDate: moment(new Date(transaction.paymentDate)),
				paymentMethod: PAYMENT_METHODS_REQUIRED.filter(p => p.value === transaction.paymentMethod)[0],
				bank: transaction.bank
					? {
							value: transaction.bankId,
							label: transaction.bank.name,
					  }
					: null,
				bankAccount: transaction.bankAccount
					? {
							value: transaction.bankAccountId,
							label: transaction.bankAccount.name,
					  }
					: null,
				creditCard: transaction.creditCard
					? {
							value: transaction.creditCardId,
							label: transaction.creditCard.name,
					  }
					: null,
			});

			return true;
		} else if (requestTransaction.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	save = async () => {
		const { programId, type, transactionId } = this.props.match.params;
		if (InputValidation(this.state, toast) && ValidatePaymentDetail(this.state, toast)) {
			let request;
			const data = {
				...this.state,
				paymentDate: this.state.paymentDate.format('YYYY-MM-DD'),
				paymentMethod: this.state.paymentMethod.value,
				bankId: this.state.bank ? this.state.bank.value : '',
				bankAccountId: this.state.bankAccount ? this.state.bankAccount.value : '',
				creditCardId: this.state.creditCard ? this.state.creditCard.value : '',
			};

			if (!this.isUpdate()) {
				request = await APIPost(`jointour/programs/${programId}/incomes/${type}/transactions`, data);
			} else {
				const { programId } = this.props.match.params;
				request = await APIPut(`jointour/programs/${programId}/incomes/${type}/transactions/${transactionId}`, data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายการเรียบร้อยแล้ว' : 'เพิ่มรายการร้อยแล้ว');
				this.props.history.push(`${AppConfig.appPath}/programs/jointour/${programId}/incomes/${type}`);
			} else {
				toast.error('เกิดปัญหาในการเพิ่ม/แก้ไขรายการ');
			}
		}
	};

	delete = async () => {
		const { programId, type, transactionId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = await APIDelete('jointour/programs/' + programId + '/incomes/' + type + '/transactions/' + transactionId);
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/programs/jointour/' + programId + '/incomes/' + type);
		} else if (requestDelete.status === 400) {
			toast.warn('คุณไม่มีสิทธิ์ลบรายการนี้');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onPaymentDateChange = newDate => {
		this.setState({
			paymentDate: newDate,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program } = this.state;
		const { programId, type } = this.props.match.params;
		let title = 'มัดจำ';
		if (type === 'deposit2') {
			title = 'มัดจำ (งวดที่ 2)';
		} else if (type === 'full') {
			title = 'ยอดที่เหลือ';
		}

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/jointour/' + programId + '/incomes/' + type} />
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">
								{this.isUpdate() ? 'แก้ไขรายการเก็บเงิน' : 'เพิ่มรายการเก็บเงิน'} {title}
							</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<PaymentDetail
								paymentMethod={this.state.paymentMethod}
								paymentTime={this.state.paymentTime}
								bank={this.state.bank}
								bankAccount={this.state.bankAccount}
								creditCard={this.state.creditCard}
								checkNumber={this.state.checkNumber}
								paymentDate={this.state.paymentDate}
								handleSelectChange={this.handleSelectChange}
								onPaymentDateChange={this.onPaymentDateChange}
								onTextChange={this.onTextChange}
								disableCurrency={true}
							/>
							<div className="columns">
								<div className="column is-8">
									<InputRow
										title="รายการ"
										labelSize="s"
										placeholder="รายการ"
										keyProps="title"
										onInputChange={this.onTextChange}
										value={this.state.title}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-4" style={{ textAlign: 'right' }}>
									<InputRow
										title={'รายรับ'}
										labelSize="s"
										placeholder="รายรับ"
										keyProps="total"
										onInputChange={this.onTextChange}
										value={this.state.total}
										disabled={false}
										isMandatory={false}
									/>
								</div>
							</div>
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onTextChange}
									isMandatory={false}
								/>
							</div>
							{AuthHelper.hasRight('Finance', 'Admin') ? (
								<div className="columns rows" style={{ marginTop: 20 }}>
									<div className="column is-8">
										{this.isUpdate() ? <DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} /> : null}
									</div>
									<div className="column is-4" style={{ textAlign: 'right' }}>
										<button className="button is-success" onClick={this.save}>
											บันทึก
										</button>
									</div>
								</div>
							) : (
								<span className="tag is-danger">คุณไม่สามารถเพิ่ม/แก้ไขรายการเก็บเงินได้</span>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
