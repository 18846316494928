import React from 'react';

const ApprovalStatus = ({ status }) => {
	if (status === 'Staff Review') {
		return <label className="tag is-info">พนักงานตรวจสอบ</label>;
	} else if (status === 'Waiting') {
		return <label className="tag is-warning">รอการอนุมัติ</label>;
	} else if (status === 'Approved') {
		return <label className="tag is-success">อนุมัติแล้ว</label>;
	} else if (status === 'Not Approved') {
		return <label className="tag is-danger">ไม่อนุมัติ</label>;
	} else {
		return <label className="tag">ไม่ระบุ</label>;
	}
};

export default ApprovalStatus;
