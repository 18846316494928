import React, { PureComponent } from 'react';
import Select from 'react-select';

import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';

import CUSTOMER_CHANNELS from '../../../StaticData/CustomerChannels';

export default class CustomerDetail extends PureComponent {
	render() {
		// console.log(this.props.note);
		return (
			<div>
				<div className="row">
					<h3 className="subtitle is-4">
						<strong>
							<u>ลูกค้า</u>
						</strong>
					</h3>
				</div>
				<div className="row columns">
					<div className="column is-6">
						<label className="label">ลูกค้า</label>
						<Select
							name="customer"
							value={this.props.customer}
							clearable={false}
							onChange={value => this.props.onStateChange('customer', value)}
							options={this.props.selectCustomers}
						/>
					</div>
					<div className="column is-6">
						<label className="label">ติดต่อ</label>
						<Select
							name="customerContact"
							value={this.props.customerContact}
							clearable={false}
							onChange={value => this.props.onStateChange('customerContact', value)}
							options={this.props.selectCustomerContacts}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<InputRow
							title="เบอร์โทรศัพท์"
							labelSize="s"
							placeholder="เบอร์โทรศัพท์"
							keyProps="telephone"
							value={this.props.telephone}
							onInputChange={this.props.onStateChange}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="column is-6">
						<InputRow
							title="Email"
							labelSize="s"
							placeholder="Email"
							keyProps="email"
							value={this.props.email}
							onInputChange={this.props.onStateChange}
							disabled={false}
							isMandatory={false}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<InputRow
							title="Line ID"
							labelSize="s"
							placeholder="Line ID"
							keyProps="lineId"
							value={this.props.lineId}
							onInputChange={this.props.onStateChange}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="column is-6">
						<label className="label">ช่องทางติดต่อ</label>
						<Select
							name="channel"
							value={this.props.channel}
							clearable={false}
							onChange={value => this.props.onStateChange('channel', value)}
							options={CUSTOMER_CHANNELS}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12">
						<TextArea title="Note" labelSize="s" keyProps="note" value={this.props.note} onInputChange={this.props.onStateChange} />
					</div>
				</div>
			</div>
		);
	}
}
