/* eslint-disable radix */
import React, { Component } from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import Select from 'react-select';

import LoadingCenter from '../../Components/LoadingCenter';

import APIGet from '../../API/APIGet';
import APIHelper from '../../Helper/APIHelper';
import AuthHelper from '../../Helper/AuthHelper';
import InitSelect from '../../Helper/InitSelect';

import SummaryTable from './SummaryTable';
import SEARCH_BY from '../../StaticData/SearchBy';
import SearchFilterContext from '../../Context/SearchFilterContext';

export default class Programs extends Component {
	static contextType = SearchFilterContext;
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			startDate: null,
			endDate: null,
			summary: null,
		};
	}

	componentDidMount = async () => {
		this.setState(
			{
				...this.context,
			},
			async () => {
				await this.getSummary();
			}
		);
	};

	handleStartDateChange = newDate => {
		if (newDate <= this.state.endDate) {
			this.setState({ startDate: newDate });
		}
	};

	handleEndDateChange = newDate => {
		if (newDate >= this.state.startDate) {
			this.setState({ endDate: newDate });
		}
	};

	handleSearchByChange = searchBy => {
		this.setState({ searchBy });
	};

	getSummary = async () => {
		let { startDate, endDate } = this.state;
		startDate = startDate.format('YYYY-MM-DD');
		endDate = endDate.format('YYYY-MM-DD');
		this.setState({
			isLoading: true,
		});

		this.context.updateSearchFilter({
			startDate: this.state.startDate,
			endDate: this.state.endDate,
		});

		const filter = {
			startDate: startDate,
			endDate: endDate,
			incentiveStatus: 'Done',
		};

		const result = await APIGet('dashboard', filter);
		if (APIHelper.handleAPIResponse(this, result)) {
			console.log('result: ', result.data);
			this.setState({
				isLoading: false,
				summary: result.data,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูล Incentive ได้ กรุณาลองใหม่อีกครั้ง');
		}

		if (window.history.pushState) {
			const newQueryString = queryString.stringify({
				startDate,
				endDate,
			});
			var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + newQueryString;
			window.history.pushState({ path: newurl }, '', newurl);
		}
	};

	getDayDiffRange = (startDate, endDate, summary) => {
		let result = [];
		for (let i = 0; i < endDate.diff(startDate, 'days') + 1; i++) {
			const date = moment(new Date(startDate))
				.add(i, 'd')
				.format('YYYY-MM-DD');

			result[i] = {
				date,
				jointour: summary.jointour.filter(row => row.date === date)[0],
				incentive: summary.incentive.filter(row => row.date === date)[0],
				fit: summary.fit.filter(row => row.date === date)[0],
			};
		}
		console.log(result);
		return result;
	};

	getTotalByType = typeSummary => {
		let result = {
			customer: 0,
			price: 0,
			salePrice: 0,
			profit: 0,
		};

		typeSummary &&
			typeSummary.forEach(record => {
				if (record) {
					result.customer += parseInt(record.customer);
					result.price += parseInt(record.price);
					result.salePrice += parseInt(record.salePrice);
					result.profit += parseInt(record.profit);
				}
			});

		return result;
	};

	getTotal = summary => {
		let jointour = this.getTotalByType(summary.jointour);
		let incentive = this.getTotalByType(summary.incentive);
		let fit = this.getTotalByType(summary.fit);
		let result = {
			startDate: this.state.startDate.format('YYYY-MM-DD'),
			endDate: this.state.endDate.format('YYYY-MM-DD'),
			jointour,
			incentive,
			fit,
		};

		return result;
	};

	render() {
		if (!AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
			return null;
		}

		const { isLoading } = this.state;
		if (isLoading) {
			return <LoadingCenter />;
		}

		const dayDiffRange = this.getDayDiffRange(this.state.startDate, this.state.endDate, this.state.summary);
		const total = this.getTotal(this.state.summary);
		return (
			<div className="container">
				<div className="columns">
					<div className="column is-8">
						<h1 className="title is-2">ESKY Summary</h1>
					</div>
				</div>
				<div className="row columns">
					<div className="column is-2">
						<label className="label">Start Date</label>
						<DatePicker
							selected={this.state.startDate}
							onChange={this.handleStartDateChange}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
					<div className="column is-2">
						<label className="label">End Date</label>
						<DatePicker selected={this.state.endDate} onChange={this.handleEndDateChange} dateFormat="DD MMM YYYY" className="input float-left" />
					</div>
					<div className="column is-2 button-padding-label">
						<button className="button is-info" onClick={this.getSummary}>
							Submit
						</button>
					</div>
				</div>
				<SummaryTable {...total} />
				<br />
				<h3 className="title is-3">สรุปรายวัน</h3>
				{dayDiffRange.map(row => (
					<SummaryTable key={'summary' + row.date} {...row} />
				))}
			</div>
		);
	}
}
