import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DeleteButton from '../../../Components/DeleteButton';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import ProgramHeader from '../../../Components/ProgramHeader';
import FileManager from '../../../Components/FileManager';
import AuthRender from '../../../Components/AuthRender';

import APIGet from '../../../API/APIGet';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

export default class IncentiveExpenseDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			expense: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { programId, expenseId } = this.props.match.params;
			let requestProgram = APIGet('jointour/programs/' + programId);
			let requestExpense = APIGet('jointour/programs/' + programId + '/expenseEtcs/' + expenseId);
			requestProgram = await requestProgram;
			requestExpense = await requestExpense;
			if (APIHelper.handleAPIResponse(this, requestProgram, requestExpense)) {
				const program = requestProgram.data;
				const expense = requestExpense.data;
				console.log('program: ', program);
				console.log('expense: ', expense);
				this.setState({
					isLoading: false,
					program,
					expense,
				});
			} else if (requestProgram.status === 404) {
				toast.warning('ไม่พบโปรแกรมดังกล่าว');
			} else {
				toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	onUploadSuccess = expense => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.setState({
			expense,
		});
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = expense => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.setState({
			expense,
		});
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program, expense } = this.state;
		const { programId, expenseId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/jointour/' + programId} />
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-8">
							<h3 className="subtitle is-5">
								รายจ่าย {expense.title}
								<span> </span>
								<Link
									to={AppConfig.appPath + '/programs/jointour/' + programId + '/expenseEtcs/' + expenseId + '/edit'}
									className="button is-info"
								>
									แก้ไข
								</Link>
							</h3>
						</div>
						<div className="column is-4" style={{ textAlign: 'right' }} />
					</div>
					<div className="columns">
						<div className="column is-6">
							<div>
								ราคาต่อหน่วย:{' '}
								<strong>
									<NumberColor isExpense={true} number={expense.pricePerPiece} /> {expense.currency}
								</strong>
							</div>
							<div>
								จำนวน:{' '}
								<strong>
									<NumberColor isExpense={true} number={expense.quantity} />
								</strong>
							</div>
							<div>
								ราคารวม:{' '}
								<strong>
									<NumberColor isExpense={true} number={expense.pricePerPiece * expense.quantity} /> {expense.currency}
								</strong>
							</div>
							<div>
								กำหนดชำระ:{' '}
								<strong>
									<DateLabel date={expense.dueDatePayment} />
								</strong>
							</div>
							{expense.companyExpenseSupplier ? (
								<div>
									Supplier: <strong>{expense.companyExpenseSupplier.name}</strong>
								</div>
							) : null}
							<div>
								สถานะ:
								{!expense.financeStaff ? (
									<span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span>
								) : (
									<span className="tag is-success">บัญชีตรวจสอบแล้ว ({expense.financeStaff.name})</span>
								)}
							</div>
							<div>
								พนักงานบัญชี: <strong>{expense.financeStaff && expense.financeStaff.name}</strong>
							</div>
							<hr />
							<div>
								วันที่:{' '}
								<strong>
									<DateLabel date={expense.paymentDate} />
								</strong>
							</div>
							<div>
								วิธีการ: <strong>{expense.paymentMethod}</strong>
							</div>
							{expense.paymentMethod === 'Transfer' ? (
								<div>
									บัญชี: <strong>{expense.bankAccount.name}</strong>
								</div>
							) : null}
							{expense.paymentMethod === 'Check' ? (
								<div>
									ธนาคาร: <strong>{expense.bank.name}</strong>
									<br />
									เช็คเลขที่: <strong>{expense.checkNumber}</strong>
								</div>
							) : null}
							{expense.paymentMethod === 'Credit Card' ? (
								<div>
									บัตร: <strong>{expense.creditCard.name}</strong>
								</div>
							) : null}
						</div>
						<div className="column is-6">
							<div className="content">
								<strong>เอกสาร:</strong>
								<ul>
									{expense.paymentVouchers &&
										expense.paymentVouchers.map(doc => (
											<li key={'paymentVouchers' + doc.id}>
												<a onClick={() => OpenLink.jointourPaymentVoucherEtc(doc.id)} target="_blank">
													{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
												</a>
												{doc.deleted_at && (
													<label className="tag is-warning">
														Inactive - <DateLabel date={doc.deleted_at} />
														{}
													</label>
												)}
											</li>
										))}
								</ul>
							</div>
							<hr />
							<div className="content">
								<FileManager
									title="Files"
									files={expense.files}
									api={'jointour/programs/' + programId + '/expenseEtcs/' + expenseId + '/files'}
									onUploadSuccess={this.onUploadSuccess}
									onUploadFail={this.onUploadFail}
									onDeleteSuccess={this.onDeleteSuccess}
									onDeleteFail={this.onDeleteFail}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
