import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import APIGet from '../../../API/APIGet';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import AppConfig from '../../../AppConfig';

export default class CompanyContacts extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			customers: [],
			company: null,
			customer: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { companyId } = this.props.match.params;
			let requestCompanyUsers = APIGet(`companies/${companyId}/users`);
			let requestCompany = APIGet('companies/' + companyId);
			requestCompanyUsers = await requestCompanyUsers;
			requestCompany = await requestCompany;
			if (APIHelper.handleAPIResponse(this, requestCompanyUsers, requestCompany)) {
				const customers = requestCompanyUsers.data;
				const company = requestCompany.data;
				console.log('customers: ', customers);
				console.log('company: ', company);
				this.setState({
					isLoading: false,
					customers,
					company,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { companyId } = this.props.match.params;
		const { company, customers } = this.state;
		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-8">
						<h1 className="title is-2">{company.name}</h1>
						<h3 className="subtitle is-4">ลูกค้า/ผู้ติดต่อ</h3>
					</div>
					<div className="column is-4" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/companies/' + companyId + '/newuser'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{customers.map(customer => (
								<tr key={customer.id}>
									<td width="70%">
										<Link to={AppConfig.appPath + '/data/companies/' + companyId + '/users/' + customer.id}>{customer.name}</Link>
									</td>
									<td width="30%" style={{ textAlign: 'right' }} />
								</tr>
							))}
							{customers.length === 0 ? 'ยังไม่มีผู้ติดต่อของบริษัทในระบบ' : null}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
