import React from 'react';

import NumberString from '../../../../Components/NumberString';

export const SumTable = ({ state }) => {
	const { selectedTab, exchangeRates, incentiveSubPlans, summary, isEditing, displaySubPlanIndex } = state;

	if (selectedTab === 'summary') {
		return <></>;
	}

	const getRecords = index => {
		if (selectedTab === 'ticket') {
			return summary.tickets[index];
		} else if (selectedTab === 'landAssessment') {
			return summary.landAssessments[index];
		} else if (selectedTab === 'visa') {
			return summary.visas[index];
		} else if (selectedTab === 'tourLeader') {
			return summary.tourLeaders[index];
		} else if (selectedTab === 'etc') {
			return summary.others[index];
		} else if (selectedTab === 'commission') {
			return summary.commissions[index];
		}

		return [];
	};

	return (
		<div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 50, display: selectedTab !== 'summary' ? 'block' : 'none' }}>
			<table className="table" style={{ width: '100%' }}>
				<thead style={{ backgroundColor: '#F5F5F5' }}>
					<tr>
						<td width="60%" style={{}}>
							รวม (บาท)
						</td>
						<td width="20%" style={{ textAlign: 'center' }}>
							ผู้ใหญ่
						</td>
						<td width="20%" style={{ textAlign: 'center' }}>
							เด็ก
						</td>
					</tr>
				</thead>
				<tbody>
					{incentiveSubPlans && incentiveSubPlans.length > 0 ? (
						incentiveSubPlans.map((subPlan, index) => {
							let isSelected = false;
							if (isEditing) {
								isSelected = subPlan.isSelected;
							} else {
								isSelected = index === displaySubPlanIndex;
							}
							return (
								<SumRow
									key={`sub-plan-sum-${index}`}
									name={subPlan.name}
									record={getRecords(index)}
									exchangeRates={exchangeRates}
									isSelected={isSelected}
								/>
							);
						})
					) : (
						<></>
					)}
				</tbody>
			</table>
		</div>
	);
};

const SumRow = ({ name, isSelected, record }) => {
	return (
		<tr style={{ backgroundColor: isSelected ? '#ff9900' : '#F5F5F5' }}>
			<td>{name}</td>
			<td style={styles.tableRowDataStyle}>
				<NumberString number={record.adult} />
			</td>
			<td style={styles.tableRowDataStyle}>
				<NumberString number={record.child} />
			</td>
		</tr>
	);
};

// const SummaryPrice = ({ records, customerType, exchangeRates = [] }) => {
// 	if (!records) {
// 		return <NumberString number={0} />;
// 	}

// 	const currencyRate = currency => {
// 		if (!currency) {
// 			return 1;
// 		}

// 		for (const exchange of exchangeRates) {
// 			if (exchange.id === currency.id) {
// 				return exchange.calculateRate;
// 			}
// 		}

// 		return 1;
// 	};

// 	const calculateTotal = () => {
// 		const filteredRecords = records.filter(record => record.customerType && record.customerType === customerType);
// 		let total = 0;
// 		for (const record of filteredRecords) {
// 			if (record.expenses) {
// 				for (const expense of record.expenses) {
// 					const sum = expense.price * expense.quantity * currencyRate(record.currency);
// 					if (!isNaN(sum)) {
// 						total += sum;
// 					}
// 				}
// 			}
// 		}

// 		return total;
// 	};

// 	return <NumberString number={calculateTotal()} />;
// };

const styles = {
	tableHeaderStyle: {
		color: '#ffffff',
		textAlign: 'center',
	},
	tableRowDataStyle: {
		textAlign: 'center',
	},
};
