import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import PaymentDetail from '../../../Components/PaymentDetail';
import DeleteButton from '../../../Components/DeleteButton';
import FileManager from '../../../Components/FileManager';
import ApprovalStatus from '../../../Components/ApprovalStatus';
import AuthRender from '../../../Components/AuthRender';

import CURRENCIES from '../../../StaticData/Currencies';
import { PAYMENT_METHODS_REQUIRED } from '../../../StaticData/PaymentMethods';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import ValidatePaymentDetail from '../../../Helper/ValidatePaymentDetail';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

import ApproveModal from './ApproveModal';

export default class AddEditCompanyExpense extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			companyExpenseCategories: [],
			suppliers: [],
			paymentDate: moment(new Date()),
			paymentTime: '',
			note: '',
			paymentMethod: PAYMENT_METHODS_REQUIRED[0],
			currency: CURRENCIES[0],
			currencyRate: 1,
			title: '',
			bank: null,
			checkNumber: '',
			bankAccount: null,
			creditCard: null,
			companyExpenseList: [
				{
					title: '',
					pricePerPiece: 0,
					quantity: 0,
				},
			],
			companyExpenseCategory: null,
			supplier: null,
			categories: [],
			files: [],
			isShowApproveModal: false,
			notIncludeInMonthlySummary: false,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initCreate = async () => {
		const { expenseCategoryId } = this.props.match.params;

		let requestCategories = APIGet('finance/companyExpenseCategories');
		requestCategories = await requestCategories;
		if (APIHelper.handleAPIResponse(this, requestCategories)) {
			const categories = requestCategories.data;
			const companyExpenseCategories = ThingsToSelect(requestCategories.data);
			let suppliers = [];
			let companyExpenseCategory = null;
			if (expenseCategoryId) {
				companyExpenseCategory = companyExpenseCategories.filter(e => e.value == expenseCategoryId)[0];
				suppliers = ThingsToSelect(categories.filter(category => category.id == expenseCategoryId)[0].companyExpenseSuppliers);
			}
			this.setState({
				companyExpenseCategories,
				companyExpenseCategory,
				categories,
				suppliers,
			});

			return true;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { expenseId } = this.props.match.params;
		let requestExpense = APIGet('finance/companyExpenses/' + expenseId);
		requestExpense = await requestExpense;
		if (APIHelper.handleAPIResponse(this, requestExpense)) {
			const expense = requestExpense.data;
			console.log('expense: ', expense);
			this.setState({
				note: expense.note,
				currency: CURRENCIES.filter(c => c.value === expense.currency)[0],
				currencyRate: expense.currencyRate,
				title: expense.title,
				companyExpenseList: expense.companyExpenseList,
				companyExpenseCategory: {
					value: expense.companyExpenseCategory.id,
					label: expense.companyExpenseCategory.name,
				},
				supplier: {
					value: expense.companyExpenseSupplier.id,
					label: expense.companyExpenseSupplier.name,
				},
				paymentMethod: PAYMENT_METHODS_REQUIRED.filter(p => p.value === expense.paymentMethod)[0],
				bank: expense.bank
					? {
							value: expense.bankId,
							label: expense.bank.name,
					  }
					: null,
				checkNumber: expense.checkNumber,
				bankAccount: expense.bankAccount
					? {
							value: expense.bankAccountId,
							label: expense.bankAccount.name,
					  }
					: null,
				paymentTime: expense.paymentTime,
				paymentDate: moment(new Date(expense.paymentDate)),
				creditCard: expense.creditCard
					? {
							value: expense.creditCardId,
							label: expense.creditCard.name,
					  }
					: null,
				files: expense.files ? expense.files : [],
				approvalStatus: expense.approvalStatus,
				approvalReason: expense.approvalReason,
				approver: expense.approver,
				notIncludeInMonthlySummary: expense.notIncludeInMonthlySummary,
			});

			return true;
		} else if (requestExpense.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { expenseId } = this.props.match.params;
		if (expenseId) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		const { expenseId } = this.props.match.params;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				title: this.state.title,
				total: this.calculateTotalPrice(),
				currencyRate: this.state.currencyRate,
				currency: this.state.currency.value,
				note: this.state.note,
				companyExpenseList: this.state.companyExpenseList,
				companyExpenseCategoryId: this.state.companyExpenseCategory.value,
				companyExpenseSupplierId: this.state.supplier.value,
				paymentMethod: this.state.paymentMethod.value,
				paymentDate: this.state.paymentDate.format('YYYY-MM-DD'),
				notIncludeInMonthlySummary: this.state.notIncludeInMonthlySummary,
			};

			if (this.state.paymentMethod.value === 'Transfer') {
				data.bankAccountId = this.state.bankAccount.value;
				data.paymentTime = this.state.paymentTime;
			} else if (this.state.paymentMethod.value === 'Check') {
				data.bankId = this.state.bank.value;
				data.checkNumber = this.state.checkNumber;
			} else if (this.state.paymentMethod.value === 'Credit Card') {
				data.creditCardId = this.state.creditCard.value;
			}

			let request = null;
			if (this.isUpdate()) {
				request = await APIPut('finance/companyExpenses/' + expenseId, data);
			} else {
				request = await APIPost('finance/companyExpenses', data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายรับเรียบร้อยแล้ว' : 'เพิ่มรายรับเรียบร้อยแล้ว');
				const result = request.data;
				this.setState({
					isLoading: false,
				});
				this.props.history.push(AppConfig.appPath + '/finance/companyexpenses/' + result.id);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มรายรับ');
				this.setState({
					isLoading: false,
				});
			}
		}
	};

	delete = async () => {
		const { expenseId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete(`finance/companyExpenses/${expenseId}`);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/finance/companyExpenses');
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	validateSaveState = () => {
		const companyExpenseCategory = this.state.companyExpenseCategory && this.state.companyExpenseCategory.value;
		if (!companyExpenseCategory) {
			toast.warn('กรุณาระบุประเภท');
		}

		const supplier = this.state.supplier && this.state.supplier.value;
		if (!supplier) {
			toast.warn('กรุณาระบุ Supplier');
		}

		const title = this.state.title.trim() !== '';
		if (!title) {
			toast.warn('กรุณาระบุรายการหลัก');
		}

		const currencyRate = !isNaN(this.state.currencyRate);
		if (!currencyRate) {
			toast.warn('กรุณาระบุอัตราแลกเปลี่ยนเงินบาท โดยกรอกเป็นตัวเลขและจุดทศนิยมเท่านั้น (ไม่ต้องมี ,)');
		}

		const lists = this.state.companyExpenseList.length > 0;
		if (!lists) {
			toast.warn('กรุณาระบุรายการ');
		}

		let listContent = true;
		this.state.companyExpenseList.forEach(row => {
			listContent = listContent && (row.title.trim() !== '' && !isNaN(row.pricePerPiece) && !isNaN(row.quantity));
		});

		if (!listContent) {
			toast.warn('กรุณาระบุข้อมูลในรายการให้ครบและถูกต้อง');
		}

		const paymentDetail = ValidatePaymentDetail(this.state, toast);

		return companyExpenseCategory && supplier && title && currencyRate && lists && listContent && paymentDetail;
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleCategoryChange = value => {
		const expenseCategoryId = value.value;
		const suppliers = ThingsToSelect(this.state.categories.filter(category => category.id == expenseCategoryId)[0].companyExpenseSuppliers);
		this.setState({
			companyExpenseCategory: value,
			supplier: null,
			suppliers,
		});
	};

	onPaymentDateChange = newDate => {
		this.setState({
			paymentDate: newDate,
		});
	};

	onExpenseListChange = (key, value, index) => {
		// console.log(key, value, index);
		let companyExpenseList = JSON.parse(JSON.stringify(this.state.companyExpenseList));
		companyExpenseList[index][key] = value;
		this.setState({
			companyExpenseList,
		});
	};

	calculateTotalPrice = () => {
		let totalPrice = 0;
		this.state.companyExpenseList.forEach(expense => {
			totalPrice += expense.pricePerPiece * expense.quantity;
		});

		return totalPrice;
	};

	addExpenseRow = () => {
		let companyExpenseList = JSON.parse(JSON.stringify(this.state.companyExpenseList));
		companyExpenseList.push({
			title: '',
			pricePerPiece: 0,
			quantity: 0,
		});

		this.setState({
			companyExpenseList,
		});
	};

	deleteExpenseRow = index => {
		let companyExpenseList = JSON.parse(JSON.stringify(this.state.companyExpenseList));
		companyExpenseList.splice(index, 1);
		this.setState({
			companyExpenseList,
		});
	};

	onUploadSuccess = expense => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.setState({
			files: expense.files,
		});
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = expense => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.setState({
			files: expense.files,
		});
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	toggleApproveModal = () => {
		this.setState({
			isShowApproveModal: !this.state.isShowApproveModal,
		});
	};

	updateApprovalStatus = expense => {
		this.setState({
			approvalStatus: expense.approvalStatus,
			approvalReason: expense.approvalReason,
			approver: expense.approver,
			isShowApproveModal: false,
		});
	};

	requestApproval = async () => {
		this.setState({
			isLoading: true,
		});
		const { expenseId } = this.props.match.params;
		const request = await APIPut('finance/companyExpenses/' + expenseId + '/approval', {
			approvalReason: '',
			approvalStatus: 'Waiting',
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const expense = request.data;
			toast.success('แก้ไขเรียบร้อยแล้ว');
			this.setState({
				isLoading: false,
				approvalReason: expense.approvalReason,
				approvalStatus: expense.approvalStatus,
				approver: null,
			});
		} else {
			toast.error('เกิดปัญหาในการแก้ไข');
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { expenseId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/finance/companyexpenses'} />
				<div className="section">
					<div className="columns">
						<div className="column is-4">
							<h3 className="title is-3">{this.isUpdate() ? 'แก้ไขรายจ่าย' : 'เพิ่มรายจ่าย'}</h3>
						</div>
						<div
							className="column is-8"
							style={{ borderWidth: 0, borderLeftWidth: 1, borderLeftColor: '#cccccc', borderStyle: 'solid', paddingLeft: 15 }}
						>
							{this.isUpdate() ? (
								<div>
									<div>
										สถานะ: <ApprovalStatus status={this.state.approvalStatus} />
										{this.state.approvalStatus === 'Approved' ? <div>โดย: {this.state.approver.name}</div> : null}
										<div className="row">{this.state.approvalReason}</div>
									</div>
									<AuthRender roles={['Admin']}>
										<button className="button is-primary" onClick={this.toggleApproveModal}>
											แก้ไขการส่งเรื่องขอทำจ่าย
										</button>
									</AuthRender>
									<span> </span>
									{this.state.approvalStatus === 'Staff Review' || this.state.approvalStatus === '' ? (
										<AuthRender roles={['Staff', 'Finance']}>
											<button className="button is-warning" onClick={this.requestApproval}>
												ส่งเรื่องขอทำจ่าย
											</button>
										</AuthRender>
									) : null}
								</div>
							) : null}
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<PaymentDetail
								paymentMethod={this.state.paymentMethod}
								paymentTime={this.state.paymentTime}
								bank={this.state.bank}
								bankAccount={this.state.bankAccount}
								creditCard={this.state.creditCard}
								checkNumber={this.state.checkNumber}
								currency={this.state.currency}
								currencyRate={this.state.currencyRate}
								paymentDate={this.state.paymentDate}
								handleSelectChange={this.handleSelectChange}
								onPaymentDateChange={this.onPaymentDateChange}
								onTextChange={this.onTextChange}
							/>
							<div className="columns row">
								<div className="column is-3">
									<label className="label">ประเภท</label>
									<Select
										name="companyExpenseCategory"
										value={this.state.companyExpenseCategory}
										clearable={false}
										onChange={this.handleCategoryChange}
										options={this.state.companyExpenseCategories}
									/>
								</div>
								<div className="column is-3">
									<label className="label">Supplier</label>
									<Select
										name="supplier"
										value={this.state.supplier}
										clearable={false}
										onChange={value => this.handleSelectChange('supplier', value)}
										options={this.state.suppliers}
									/>
								</div>
							</div>
							<div className="columns row">
								<div className="column is-8">
									<InputRow
										title="รายการหลัก"
										labelSize="s"
										placeholder="รายการหลัก"
										keyProps="title"
										onInputChange={this.onTextChange}
										value={this.state.title}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-4" style={{ textAlign: 'right' }}>
									<h5 className="subtitle is-5">ยอดรวม</h5>
									<h3 className="title is-3">
										<NumberColor number={this.calculateTotalPrice()} isExpense={true} /> {this.state.currency.value}
									</h3>
								</div>
							</div>
							<div className="row table-container">
								<table className="table is-hoverable is-fullwidth is-striped">
									<thead>
										<tr>
											<td width="55%">รายการ</td>
											<td width="20%">ราคาต่อหน่วย ({this.state.currency.value})</td>
											<td width="20%">จำนวน</td>
											<td width="5%" />
										</tr>
									</thead>
									<tbody>
										{this.state.companyExpenseList.map((row, index) => (
											<tr key={'addCompanyExpenseTable' + index}>
												<td>
													<InputRow
														placeholder="รายการ"
														keyProps="title"
														onInputChange={(key, value) => this.onExpenseListChange(key, value, index)}
														value={row.title}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<InputRow
														placeholder="ราคาต่อหน่วย"
														keyProps="pricePerPiece"
														onInputChange={(key, value) => this.onExpenseListChange(key, value, index)}
														value={row.pricePerPiece}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<InputRow
														placeholder="จำนวน"
														keyProps="quantity"
														onInputChange={(key, value) => this.onExpenseListChange(key, value, index)}
														value={row.quantity}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<div
														style={{
															marginTop: 9,
															color: 'red',
															cursor: 'pointer',
														}}
														onClick={() => this.deleteExpenseRow(index)}
													>
														<FontAwesomeIcon icon="times" />
													</div>
												</td>
											</tr>
										))}
										<tr className="clickable" onClick={this.addExpenseRow}>
											<td colSpan="4" style={{ textAlign: 'center' }}>
												เพิ่มแถว
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="row columns">
								<div className="column is-6">
									<TextArea
										title="Note"
										labelSize="s"
										keyProps="note"
										value={this.state.note}
										onInputChange={this.onTextChange}
										isMandatory={false}
									/>
									<div className="columns">
										<div className="column is-6">
											{this.isUpdate() ? <DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} /> : null}
										</div>
										<div
											className="column is-6"
											style={{
												textAlign: 'right',
												marginTop: 20,
											}}
										>
											<div className="row">
												<label className="checkbox">
													<input
														type="checkbox"
														checked={this.state.notIncludeInMonthlySummary}
														onClick={event => this.setState({ notIncludeInMonthlySummary: event.target.checked })}
													/>
													ไม่นำไปรวมกับยอดรายเดือน
												</label>
											</div>
											<div className="row">
												<button className="button is-success" onClick={this.save}>
													บันทึก
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="column is-6">
									<div className="content">
										{this.isUpdate() ? (
											<FileManager
												title="Files"
												files={this.state.files}
												api={`finance/companyExpenses/${expenseId}/files`}
												onUploadSuccess={this.onUploadSuccess}
												onUploadFail={this.onUploadFail}
												onDeleteSuccess={this.onDeleteSuccess}
												onDeleteFail={this.onDeleteFail}
											/>
										) : (
											'กรุณาบันทึกรายการก่อน Upload file'
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.isShowApproveModal ? (
					<ApproveModal
						id={expenseId}
						approvalReason={this.state.approvalReason}
						approvalStatus={this.state.approvalStatus}
						close={this.toggleApproveModal}
						updateApprovalStatus={this.updateApprovalStatus}
					/>
				) : null}
			</div>
		);
	}
}
