import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';

import APIGet from '../../../API/APIGet';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

export default class EditProfile extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			name: '',
			email: '',
			nickname: '',
			telephone: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initUpdate = await this.initUpdate();
			if (initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initUpdate = async () => {
		const staffId = AuthHelper.getUserDetail().id;
		let requestStaff = APIGet('staffs/' + staffId);
		requestStaff = await requestStaff;
		if (APIHelper.handleAPIResponse(this, requestStaff)) {
			const staff = requestStaff.data;
			console.log('staff: ', staff);
			if (staff) {
				this.setState({
					name: staff.name,
					email: staff.email,
					telephone: staff.telephone,
					nickname: staff.nickname,
				});

				return true;
			}
		} else if (requestStaff.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}

		return false;
	};

	save = async () => {
		const staffId = AuthHelper.getUserDetail().id;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				name: this.state.name,
				email: this.state.email,
				telephone: this.state.telephone,
				nickname: this.state.nickname,
			};

			const request = await APIPut('profile/' + staffId, data);

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success('แก้ไขเรียบร้อยแล้ว');
				const result = request.data;
			} else {
				toast.error('เกิดปัญหาในการแก้ไข');
			}

			this.setState({
				isLoading: false,
			});
		}
	};

	validateSaveState = () => {
		const name = this.state.name.trim() !== '';
		if (!name) {
			toast.warn('กรุณาระบุชื่อ');
		}

		const telephone = this.state.telephone.trim() !== '';
		if (!telephone) {
			toast.warn('กรุณาระบุเบอร์โทรศัพท์');
		}

		const email = this.state.email.trim() !== '';
		if (!email) {
			toast.warn('กรุณาระบุ email');
		}

		return name && telephone && email;
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">แก้ Profile</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<InputRow
								title="email"
								labelSize="s"
								placeholder="email"
								keyProps="email"
								onInputChange={this.onTextChange}
								value={this.state.email}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="ชื่อ"
								labelSize="s"
								placeholder="ชื่อ"
								keyProps="name"
								onInputChange={this.onTextChange}
								value={this.state.name}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="ชื่อเล่น"
								labelSize="s"
								placeholder="ชื่อเล่น"
								keyProps="nickname"
								onInputChange={this.onTextChange}
								value={this.state.nickname}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-4">
							<InputRow
								title="telephone"
								labelSize="s"
								placeholder="telephone"
								keyProps="telephone"
								onInputChange={this.onTextChange}
								value={this.state.telephone}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4" />
					</div>
					<div className="columns row">
						<div className="column is-6" />
						<div className="column is-6" style={{ textAlign: 'right', marginTop: 20 }}>
							<button className="button is-success" onClick={this.save}>
								บันทึก
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
