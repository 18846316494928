import React, { Component } from 'react';

import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';

export default class SummaryCustomerDetail extends Component {
	render() {
		const { program } = this.props;
		return (
			<div className="row">
				ราคา: <NumberColor number={program.price} /> บาท <br />
				ลูกค้า: <strong>{program.customer ? program.customer.name : ''}</strong> <br />
				เบอร์โทร: <strong>{program.customer ? program.customer.telephone : ''}</strong> <br />
				email: <strong>{program.customer ? program.customer.email : ''}</strong> <br />
				จำนวนลูกค้า:{' '}
				<strong>
					<NumberColor number={program.seat} /> ท่าน
				</strong>
				<br />
				วันทำจอง:{' '}
				<strong>
					<DateLabel date={program.created_at} />
				</strong>
				<hr />
				Whole Sale: <strong>{program.wholeSale ? program.wholeSale.name : ''}</strong>
				<br />
				Whole Sale Contact: <strong>{program.wholeSaleContact ? program.wholeSaleContact.name : ''}</strong>
				<hr />
				<b>ติดต่อ</b>
				<br />
				ชื่อ: <strong>{program.customerContact.name}</strong> <br />
				เบอร์โทร: <strong>{program.telephone}</strong> <br />
				email: <strong>{program.email}</strong> <br />
				Line: <strong>{program.lineId}</strong> <br />
				<hr />
			</div>
		);
	}
}
