import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthHelper from '../../Helper/AuthHelper';
import IsMobile from '../../Helper/IsMobile';

import AppConfig from '../../AppConfig';

import DesktopMiddleMenu from './DesktopMiddleMenu';
import DesktopRightMenu from './DesktopRightMenu';
import MobileNotification from './MobileNotification';

class AppNavbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMenu: '',
		};
	}

	toggleMenu = () => {
		this.setState({
			showMenu: !this.state.showMenu ? 'is-active' : '',
		});
	};

	getNavBarColorStyle = () => {
		const pathName = window.location.pathname.toLowerCase();
		if (pathName.indexOf('programs/planning-incentive') !== -1) {
			return 'is-primary';
		} else if (pathName.indexOf('programs/incentive') !== -1 || pathName.indexOf('programs/newincentive') !== -1) {
			return 'is-link';
		} else if (pathName.indexOf('programs/jointour') !== -1 || pathName.indexOf('programs/newjointour') !== -1) {
			return 'is-success';
		} else if (pathName.indexOf('programs/fit') !== -1 || pathName.indexOf('programs/newfit') !== -1) {
			return 'is-danger';
		} else if (pathName.indexOf('programs/collective') !== -1) {
			return 'is-warning';
		} else {
			return 'is-info';
		}
	};

	render() {
		const navBarColorStyle = this.getNavBarColorStyle();
		console.log('nav bar color style: ', navBarColorStyle);
		return (
			<div className={'navbar has-shadow ' + navBarColorStyle} role="navigation" style={{ marginBottom: 20 }}>
				<div className="navbar-brand">
					<NavLink exact to={AppConfig.appPath + '/'} className="navbar-item clickable" activeClassName="is-active">
						<strong>ESKY</strong>
					</NavLink>

					<a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" style={{ color: 'white' }} onClick={this.toggleMenu}>
						<span aria-hidden="true" />
						<span aria-hidden="true" />
						<span aria-hidden="true" />
					</a>

					{AuthHelper.isLogin() && IsMobile() ? <MobileNotification /> : null}
				</div>
				<div className={`navbar-menu ${this.state.showMenu}`} onClick={this.toggleMenu}>
					<DesktopMiddleMenu isLogin={AuthHelper.isLogin()} />
					<DesktopRightMenu isLogin={AuthHelper.isLogin()} userDetail={AuthHelper.getUserDetail()} />
				</div>
			</div>
		);
	}
}

export default withRouter(AppNavbar);
