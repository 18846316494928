import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import StatusIcon from '../../../Components/StatusIcon';
import DeleteTag from '../../../Components/DeleteTag';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';
import InitSelect from '../../../Helper/InitSelect';

import Store from '../../../Helper/Store';
import StoreKey from '../../../StoreKey';

import AppConfig from '../../../AppConfig';

import SEARCH_BY from '../../../StaticData/SearchBy';

import Search from './Search';
import SearchFilterContext from '../../../Context/SearchFilterContext';

export default class JoinTourList extends Component {
	static contextType = SearchFilterContext;
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		const searchBy = query.searchBy && InitSelect(SEARCH_BY, query.searchBy) ? InitSelect(SEARCH_BY, query.searchBy) : SEARCH_BY[1];
		const status = Store.get(StoreKey.filter.status);
		const country = Store.get(StoreKey.filter.country);
		const wholeSale = Store.get(StoreKey.filter.wholeSale);
		const invoice = Store.get(StoreKey.filter.invoice);
		const customerName = Store.get(StoreKey.filter.customerName);
		const customerTelephone = Store.get(StoreKey.filter.customerTelephone);
		this.state = {
			isLoading: true,
			filter: {
				status: status
					? status
					: {
							value: '',
							label: 'All - ทั้งหมด',
					  },
				country: country
					? country
					: {
							value: 0,
							label: 'All - ทั้งหมด',
					  },
				wholeSale: wholeSale
					? wholeSale
					: {
							value: 0,
							label: 'All - ทั้งหมด',
					  },
				startDate: null,
				endDate: null,
				invoice: invoice ? invoice : '',
				customerName: customerName ? customerName : '',
				customerTelephone: customerTelephone ? customerTelephone : '',
				searchBy,
				staffName: '',
			},
			code: '',
			programs: [],
			summary: {},
		};
	}

	async componentDidMount() {
		this.setState(
			state => ({
				filter: {
					...state.filter,
					...this.context,
				},
			}),
			async () => {
				await this.getPrograms();
			}
		);
	}

	getPrograms = async () => {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.context.updateSearchFilter(this.state.filter);
			this.setState({
				isLoading: true,
			});

			Store.set(StoreKey.filter.status, this.state.filter.status);
			Store.set(StoreKey.filter.country, this.state.filter.country);
			Store.set(StoreKey.filter.wholeSale, this.state.filter.wholeSale);
			Store.set(StoreKey.filter.invoice, this.state.filter.invoice);
			Store.set(StoreKey.filter.customerName, this.state.filter.customerName);
			Store.set(StoreKey.filter.customerTelephone, this.state.filter.customerTelephone);
			Store.set(StoreKey.filter.staffName, this.state.filter.staffName);

			const startDate = this.state.filter.startDate.format('YYYY-MM-DD');
			const endDate = this.state.filter.endDate.format('YYYY-MM-DD');

			let filter = {
				startDate: startDate,
				endDate: endDate,
				jointourSupplierId: this.state.filter.wholeSale.value,
				countryId: this.state.filter.country.value,
				status: this.state.filter.status.value,
				invoice: this.state.filter.invoice,
				customerName: this.state.filter.customerName,
				customerTelephone: this.state.filter.customerTelephone,
				searchBy: this.state.filter.searchBy.value,
				staffName: this.state.filter.staffName,
			};

			const { companyId } = this.props.match.params;
			if (companyId) {
				filter.companyId = companyId;
			}

			let requestPrograms = APIGet('jointour/programs', filter);
			let requestSummary = APIGet('jointour', filter);

			requestPrograms = await requestPrograms;
			requestSummary = await requestSummary;
			if (APIHelper.handleAPIResponse(this, requestPrograms, requestSummary)) {
				const programs = requestPrograms.data;
				const summary = requestSummary.data;
				console.log('programs: ', programs);
				console.log('summary: ', summary);
				this.setState({
					isLoading: false,
					programs,
					summary,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	clearSearch = () => {
		this.setState(
			{
				filter: {
					status: {
						value: '',
						label: 'All - ทั้งหมด',
					},
					country: {
						value: 0,
						label: 'All - ทั้งหมด',
					},
					wholeSale: {
						value: 0,
						label: 'All - ทั้งหมด',
					},
					startDate: this.state.filter.startDate,
					endDate: this.state.filter.endDate,
					invoice: '',
					customerName: '',
					customerTelephone: '',
					staffName: '',
				},
			},
			() => {
				this.getPrograms();
			}
		);
	};

	onFilterChange = (name, value) => {
		let filter = { ...this.state.filter };
		filter[name] = value;
		console.log(filter);
		this.setState({
			filter,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { companyId } = this.props.match.params;
		const { summary, programs } = this.state;
		return (
			<div className="container">
				{!companyId ? (
					<BackButtonRouter link={AppConfig.appPath + '/'} title="ดูทั้งหมด" />
				) : (
					<BackButtonRouter link={`${AppConfig.appPath}/data/companies`} title="ลูกค้า" />
				)}
				<div className="columns">
					<div className="column is-6">
						<h2 className="title is-2">JOIN TOUR</h2>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-success" to={`${AppConfig.appPath}/programs/newjointour`}>
							เพิ่มรายการ
						</Link>
					</div>
				</div>
				<Search {...this.state.filter} onFilterChange={this.onFilterChange} search={this.getPrograms} clearSearch={this.clearSearch} />
				<div className="table-container row">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="12%">วันเดินทาง</td>
								<td>Invoice</td>
								<td>ประเทศ</td>
								<td width="20%">โปรแกรม</td>
								<td width="20%">ลูกค้า / พนักงาน</td>
								<td>Whole Sale</td>
								<td>Seat</td>
								<td width="5%">Commission (หักส่วนลด)</td>
								<td width="5%">ราคา</td>
								<td width="10%">รับชำระมาแล้ว</td>
							</tr>
						</thead>
						<tbody>
							{programs.map(
								program =>
									program.wholeSale &&
									program.country &&
									program.airline &&
									program.customer && (
										<tr key={'program' + program.id}>
											<td>
												<DateRangeLabel startDate={program.startDate} endDate={program.endDate} />
											</td>
											<td>
												{program.invoice ? (
													<a onClick={() => OpenLink.jointourInvoice(program.invoice.id)}>{program.invoice.documentNumber}</a>
												) : (
													''
												)}
											</td>
											<td style={{ textAlign: 'center' }}>{program.country.name}</td>
											<td>
												<Link to={AppConfig.appPath + '/programs/jointour/' + program.id}>
													{program.title} <StatusIcon status={program.status} deletedAt={program.deleted_at} />{' '}
													<span style={{ color: 'red', fontSize: 10 }}>
														{program.deleted_by_user_name ? program.deleted_by_user_name : null}
													</span>
												</Link>
											</td>
											<td>
												{program.customer && program.customer.name}
												{program.staffs && program.staffs.length > 0 ? (
													<div>
														<b>Staffs</b>
														{program.staffs.map(staff => {
															if (staff && staff.staff) {
																return <div key={'staff-' + staff.staff.id}>- {staff.staff.nickname}</div>;
															} else {
																return null;
															}
														})}
													</div>
												) : null}
											</td>
											<td>{program.wholeSale.shortname ? program.wholeSale.shortname : program.wholeSale.name}</td>
											<td className="number-cell">
												<NumberColor
													number={
														program.quantityNormal +
														program.quantityChildWithBed +
														program.quantityChildNoBed +
														program.quantityInfant
													}
												/>
											</td>
											<td className="number-cell">
												<NumberColor
													number={
														program.commission * program.quantityCommission +
														program.totalIncomeEtc -
														program.totalDiscount -
														program.totalExpenseEtc
													}
												/>
											</td>
											<td className="number-cell">
												<NumberColor number={program.deposit + program.deposit2 + program.full} />
											</td>
											<td className="number-cell">
												<NumberColor number={program.paidDeposit + program.paidDeposit2 + program.paidFull} />
											</td>
										</tr>
									)
							)}
							<tr className="table-summary-row">
								<td colSpan="6" style={{ textAlign: 'center' }}>
									Summary
								</td>
								<td className="number-cell">
									<NumberColor number={summary.seat} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.commission + summary.incomeEtc - summary.totalDiscount - summary.expenseEtc} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.price} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.paid} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
			</div>
		);
	}
}
