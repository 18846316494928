import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import FileManager from '../../../Components/FileManager';
import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import AdditionalCustomer from '../../../Components/JointourProgramAdditionalCustomer';

import OpenLink from '../../../Helper/OpenLink';

import SummaryCustomerDetail from './SummaryCustomerDetail';
import SummaryStaffs from './SummaryStaffs';
import SummaryPricing from './SummaryPricing';
import SummaryStatus from './SummaryStatus';
import SummaryTraveller from './SummaryTraveller';
import SummaryInvoice from './SummaryInvoice';

export default class Summary extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isLoadingStaff: false,
			status: {
				value: props.program.status,
				label: props.program.status,
			},
			staff: null,
		};
	}

	handleInputChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	handleStatusChange = async value => {
		this.setState({
			status: value,
			isLoading: true,
		});
	};

	onUploadSuccess = program => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.props.onProgramUpdated(program);
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = program => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.props.onProgramUpdated(program);
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	render() {
		const { program, staffs } = this.props;
		const { staff } = this.state;
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="columns">
				<div className="column is-5">
					<SummaryCustomerDetail program={program} />
					<SummaryStaffs program={program} staff={staff} staffs={staffs} onProgramUpdated={this.props.onProgramUpdated} />
					<br />
					<AdditionalCustomer programId={program.id} />
					<br />
					<FileManager
						title="Files"
						files={program.files}
						api={'jointour/programs/' + program.id + '/files'}
						onUploadSuccess={this.onUploadSuccess}
						onUploadFail={this.onUploadFail}
						onDeleteSuccess={this.onDeleteSuccess}
						onDeleteFail={this.onDeleteFail}
					/>
				</div>
				<div className="column is-7">
					<SummaryPricing program={program} />
					<SummaryStatus program={program} onProgramUpdated={this.props.onProgramUpdated} />
					<br />
					<SummaryTraveller program={program} />
					<br />
					<hr />
					<div className="row">
						<strong>Note:</strong>
						<p>{program.note}</p>
					</div>
					<SummaryInvoice program={program} />
				</div>
			</div>
		);
	}
}
