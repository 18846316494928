import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import JoinTourList from '../Pages/JoinTour/List';
import JoinTourListByCustomer from '../Pages/JoinTour/ListByCustomer';
import JoinTourProgramDetail from '../Pages/JoinTour/ProgramDetail';
import JoinTourAddEditProgram from '../Pages/JoinTour/AddEditProgram';
import JoinTourIncome from '../Pages/JoinTour/Income';
import JoinTourAddEditIncomeTransaction from '../Pages/JoinTour/AddEditIncomeTransaction';
import JoinTourExpense from '../Pages/JoinTour/ExpenseDetail';
import JoinTourAddEditExpense from '../Pages/JoinTour/AddEditExpense';
import JoinTourExpenseEtc from '../Pages/JoinTour/ExpenseEtcDetail';
import AddEditExpenseEtc from '../Pages/JoinTour/AddEditExpenseEtc';

import AppConfig from '../AppConfig';

export default class JoinTourRoutes extends Component {
	render() {
		return (
			<span>
				<Route exact path={AppConfig.appPath + '/programs/jointour'} component={JoinTourList} />
				<Route exact path={AppConfig.appPath + '/data/jointour/customers/:customerId/programs'} component={JoinTourListByCustomer} />
				<Route exact path={AppConfig.appPath + '/programs/newjointour'} component={JoinTourAddEditProgram} />
				<Route exact path={AppConfig.appPath + '/programs/jointour/:programId'} component={JoinTourProgramDetail} />
				<Route exact path={AppConfig.appPath + '/programs/jointour/:programId/edit'} component={JoinTourAddEditProgram} />
				<Route exact path={AppConfig.appPath + '/programs/jointour/:programId/incomes/:type'} component={JoinTourIncome} />
				<Route
					exact
					path={AppConfig.appPath + '/programs/jointour/:programId/incomes/:type/newtransaction'}
					component={JoinTourAddEditIncomeTransaction}
				/>
				<Route
					exact
					path={AppConfig.appPath + '/programs/jointour/:programId/incomes/:type/transactions/:transactionId'}
					component={JoinTourAddEditIncomeTransaction}
				/>
				<Route exact path={AppConfig.appPath + '/programs/jointour/:programId/expenses/:expenseId'} component={JoinTourExpense} />
				<Route exact path={AppConfig.appPath + '/programs/jointour/:programId/expenses/:expenseId/edit'} component={JoinTourAddEditExpense} />
				<Route exact path={AppConfig.appPath + '/programs/jointour/:programId/expenseEtcs/:expenseId'} component={JoinTourExpenseEtc} />
				<Route exact path={AppConfig.appPath + '/programs/jointour/:programId/expenseEtcs/:expenseId/edit'} component={AddEditExpenseEtc} />
			</span>
		);
	}
}
