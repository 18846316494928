import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import ProgramHeader from '../../../Components/ProgramHeader';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

import IncomeInfo from './IncomeInfo';
import IncomeTransaction from './IncomeTransaction';

export default class IncomeCustomer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
		};
	}

	async componentDidMount() {
		const { programId } = this.props.match.params;

		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let requestProgram = APIGet('incentive/programs/' + programId);
			requestProgram = await requestProgram;
			if (APIHelper.handleAPIResponse(this, requestProgram)) {
				const program = requestProgram.data;
				// console.log('program: ', program);
				this.setState({
					isLoading: false,
					program,
				});
			} else if (requestProgram.status === 404) {
				toast.warning('ไม่พบโปรแกรมดังกล่าว');
			} else {
				toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	goBack = () => {
		const { programId } = this.props.match.params;
		this.props.history.replace(AppConfig.appPath + '/programs/incentive/' + programId);
	};

	goto = url => {
		this.props.history.replace(url);
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program } = this.state;
		const { programId, incomeId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/incentive/' + programId} />
				<ProgramHeader program={program} />

				<IncomeInfo programId={programId} program={program} incomeId={incomeId} goBack={this.goBack} goto={this.goto} />

				<IncomeTransaction programId={programId} program={program} incomeId={incomeId} />
			</div>
		);
	}
}
