import AppConfig from '../AppConfig';
import AuthHelper from '../Helper/AuthHelper';

const OpenLink = (function() {
	const incentivePaymentVoucher = id => {
		window.open(AppConfig.serverUrl + 'incentive/pdf/expense/' + id);
	};

	const incentiveInvoiceReceipt = id => {
		window.open(AppConfig.serverUrl + 'incentive/pdf/invoice/' + id);
	};

	const incentiveIncomeEtcInvoiceReceipt = id => {
		window.open(AppConfig.serverUrl + 'incentive/pdf/invoiceEtc/' + id);
	};

	const incentiveQuotation = id => {
		window.open(AppConfig.serverUrl + 'incentive/pdf/quotation/' + id);
	};

	const companyPaymentVoucher = id => {
		window.open(AppConfig.serverUrl + 'finance/pdf/companyexpense/' + id);
	};

	const jointourInvoice = id => {
		const userDetail = AuthHelper.getUserDetail();
		let creatorName = '';
		if (userDetail) {
			creatorName = userDetail.name;
		}

		if (creatorName) {
			window.open(`${AppConfig.serverUrl}jointour/pdf/invoice/${id}/${creatorName}`);
		} else {
			window.open(`${AppConfig.serverUrl}jointour/pdf/invoice/${id}`);
		}
	};

	const jointourReceipt = id => {
		const userDetail = AuthHelper.getUserDetail();
		let creatorName = '';
		if (userDetail) {
			creatorName = userDetail.name;
		}

		if (creatorName) {
			window.open(`${AppConfig.serverUrl}jointour/pdf/receipt/${id}/${creatorName}`);
		} else {
			window.open(`${AppConfig.serverUrl}jointour/pdf/receipt/${id}`);
		}
	};

	const jointourPaymentVoucher = id => {
		window.open(AppConfig.serverUrl + 'jointour/pdf/paymentvoucher/' + id);
	};

	const jointourPaymentVoucherEtc = id => {
		window.open(AppConfig.serverUrl + 'jointour/pdf/paymentvoucheretc/' + id);
	};

	const fitInvoice = id => {
		window.open(`${AppConfig.serverUrl}fit/pdf/invoice/${id}`);
	};

	const fitReceipt = id => {
		window.open(`${AppConfig.serverUrl}fit/pdf/receipt/${id}`);
	};

	const fitPaymentVoucher = id => {
		window.open(`${AppConfig.serverUrl}fit/pdf/paymentvoucher/${id}`);
	};

	const fitExpenseToSupplierPaymentVoucher = id => {
		window.open(`${AppConfig.serverUrl}fit/pdf/tosupplierpaymentvoucher/${id}`);
	};

	const printCommission = (year, month, staffId) => {
		window.open(`${AppConfig.serverUrl}finance/commissions/${year}/${month}/${staffId}/pdf`);
	};

	const printSalary = (year, month) => {
		window.open(`${AppConfig.serverUrl}finance/salaries/${year}/${month}/pdf`);
	};

	const getIncentiveRestaurantExcel = programId => {
		window.open(`${AppConfig.serverUrl}incentive/programs/${programId}/restaurants/excel`);
	};

	const buildQueryString = params => {
		let paramString = '';
		if (params) {
			paramString = '?';
			for (var key in params) {
				if (params.hasOwnProperty(key)) {
					paramString = paramString + key + '=' + params[key] + '&';
				}
			}

			paramString = paramString.slice(0, -1);
		}

		return paramString;
	};

	return {
		incentivePaymentVoucher,
		incentiveInvoiceReceipt,
		incentiveIncomeEtcInvoiceReceipt,
		incentiveQuotation,
		companyPaymentVoucher,
		jointourInvoice,
		jointourReceipt,
		jointourPaymentVoucher,
		jointourPaymentVoucherEtc,
		fitInvoice,
		fitReceipt,
		fitPaymentVoucher,
		fitExpenseToSupplierPaymentVoucher,
		printCommission,
		printSalary,
		getIncentiveRestaurantExcel,
	};
})();

export default OpenLink;
