import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import ProgramHeader from '../../../Components/ProgramHeader';

import CURRENCIES from '../../../StaticData/Currencies';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

export default class AddEditIncomeCustomer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			isBilledDateRequired: false,
			billedDate: moment(new Date()),
			expectedPaymentDate: moment(new Date()),
			note: '',
			currency: CURRENCIES[0],
			currencyRate: 1,
			title: '',
			incentiveIncomeList: [
				{
					title: '',
					pricePerPiece: 0,
					quantity: 0,
				},
			],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initCreate = async () => {
		const { programId } = this.props.match.params;

		let requestProgram = APIGet('incentive/programs/' + programId);
		requestProgram = await requestProgram;
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const program = requestProgram.data;
			console.log('program: ', program);
			this.setState({
				program,
			});

			return true;
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { programId, incomeId } = this.props.match.params;
		let requestIncome = APIGet('incentive/programs/' + programId + '/incomes/' + incomeId);
		requestIncome = await requestIncome;
		if (APIHelper.handleAPIResponse(this, requestIncome)) {
			const income = requestIncome.data;
			console.log('income: ', income);
			this.setState({
				isBilledDateRequired: income.billedDate ? true : false,
				billedDate: moment(new Date(income.billedDate)),
				expectedPaymentDate: moment(new Date(income.expectedPaymentDate)),
				note: income.note,
				currency: CURRENCIES.filter(c => c.value === income.currency)[0],
				currencyRate: income.currencyRate,
				title: income.title,
				incentiveIncomeList: income.incentiveIncomeList,
			});

			return true;
		} else if (requestIncome.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { incomeId } = this.props.match.params;
		if (incomeId) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		const { programId, incomeId } = this.props.match.params;
		if (this.validateSaveState()) {
			let data = {
				incentiveProgramId: programId,
				title: this.state.title,
				expectedPaymentDate: this.state.expectedPaymentDate.format('YYYY-MM-DD'),
				total: this.calculateTotalPrice(),
				currencyRate: this.state.currencyRate,
				currency: this.state.currency.value,
				note: this.state.note,
				incentiveIncomeList: this.state.incentiveIncomeList,
			};

			if (this.state.isBilledDateRequired) {
				data.billedDate = this.state.billedDate.format('YYYY-MM-DD');
			}

			let request;
			if (this.isUpdate()) {
				request = await APIPut('incentive/programs/' + programId + '/incomes/' + incomeId, data);
			} else {
				request = await APIPost('incentive/programs/' + programId + '/incomes', data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายรับเรียบร้อยแล้ว' : 'เพิ่มรายรับเรียบร้อยแล้ว');
				const result = request.data;
				this.props.history.push(AppConfig.appPath + '/programs/incentive/' + programId + '/incomes/' + result.id);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มรายรับ');
			}
		} else {
			toast.warn('โปรดตรวจสอบความถูกต้องครบถ้วนของข้อมูล');
		}
	};

	validateSaveState = () => {
		const title = this.state.title.trim() !== '';
		if (!title) {
			toast.warn('กรุณาระบุรายการหลัก');
		}

		const currencyRate = !isNaN(this.state.currencyRate);
		if (!currencyRate) {
			toast.warn('กรุณาระบุอัตราแลกเปลี่ยนเงินบาท โดยกรอกเป็นตัวเลขและจุดทศนิยมเท่านั้น (ไม่ต้องมี ,)');
		}

		const lists = this.state.incentiveIncomeList.length > 0;
		if (!lists) {
			toast.warn('กรุณาระบุรายการ');
		}

		let listContent = true;
		this.state.incentiveIncomeList.forEach(row => {
			listContent = listContent && (row.title.trim() !== '' && !isNaN(row.pricePerPiece) && !isNaN(row.quantity));
		});

		if (!listContent) {
			toast.warn('กรุณาระบุข้อมูลในรายการให้ครบและถูกต้อง');
		}

		return title && currencyRate && lists && listContent;
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	toggleBilledDate = () => {
		this.setState({
			isBilledDateRequired: !this.state.isBilledDateRequired,
		});
	};

	onBilledDateChange = newDate => {
		this.setState({
			billedDate: newDate,
		});
	};

	onExpectedPaymentDateChange = newDate => {
		this.setState({
			expectedPaymentDate: newDate,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onIncomeListChange = (key, value, index) => {
		// console.log(key, value, index);
		let incentiveIncomeList = JSON.parse(JSON.stringify(this.state.incentiveIncomeList));
		incentiveIncomeList[index][key] = value;
		this.setState({
			incentiveIncomeList,
		});
	};

	calculateTotalPrice = () => {
		let totalPrice = 0;
		this.state.incentiveIncomeList.forEach(income => {
			totalPrice += income.pricePerPiece * income.quantity;
		});

		return totalPrice;
	};

	addIncomeRow = () => {
		let incentiveIncomeList = JSON.parse(JSON.stringify(this.state.incentiveIncomeList));
		incentiveIncomeList.push({
			title: '',
			pricePerPiece: 0,
			quantity: 0,
		});

		this.setState({
			incentiveIncomeList,
		});
	};

	deleteIncomeRow = index => {
		let incentiveIncomeList = JSON.parse(JSON.stringify(this.state.incentiveIncomeList));
		incentiveIncomeList.splice(index, 1);
		this.setState({
			incentiveIncomeList,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program } = this.state;
		const { programId, incomeId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter
					link={
						this.isUpdate()
							? AppConfig.appPath + '/programs/incentive/' + programId + '/incomes/' + incomeId
							: AppConfig.appPath + '/programs/incentive/' + programId
					}
				/>
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">เพิ่มการเก็บเงินจากลูกค้า</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<div className="columns row">
								<div className="column is-4">
									<label className="label">ชำระภายใน</label>
									<DatePicker
										selected={this.state.expectedPaymentDate}
										onChange={this.onExpectedPaymentDateChange}
										dateFormat="DD MMM YYYY"
										className="input float-left"
									/>
								</div>
								<div className="column is-4">
									<label className="checkbox label">
										<input
											type="checkbox"
											value={this.state.isBilledDateRequired}
											onClick={this.toggleBilledDate}
											checked={this.state.isBilledDateRequired ? 'checked' : ''}
											onChange={() => {}}
										/>
										&nbsp;ต้องการระบุวันวางบิล
									</label>
									<br />
									{this.state.isBilledDateRequired ? (
										<div>
											<DatePicker
												selected={this.state.billedDate}
												onChange={this.onBilledDateChange}
												dateFormat="DD MMM YYYY"
												className="input float-left"
											/>
										</div>
									) : null}
								</div>
							</div>
							<div className="columns">
								<div className="column is-8">
									<InputRow
										title="รายการหลัก"
										labelSize="s"
										placeholder="รายการหลัก"
										keyProps="title"
										onInputChange={this.onTextChange}
										value={this.state.title}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-4" style={{ textAlign: 'right' }}>
									<h5 className="subtitle is-5">ยอดรวม</h5>
									<h3 className="title is-3">
										<NumberColor number={this.calculateTotalPrice()} /> {this.state.currency.value}
									</h3>
								</div>
							</div>
							<div className="columns row">
								<div className="column is-3">
									<label className="label">สกุลเงิน</label>
									<Select
										name="currency"
										value={this.state.currency}
										clearable={false}
										onChange={value => this.handleSelectChange('currency', value)}
										options={CURRENCIES}
									/>
								</div>
								<div className="column is-4">
									<InputRow
										title={'อัตราแลกเปลี่ยนเงินบาทต่อ 1 ' + this.state.currency.value}
										labelSize="s"
										placeholder="อัตราแลกเปลี่ยนกับเงินบาท"
										keyProps="currencyRate"
										onInputChange={this.onTextChange}
										value={this.state.currencyRate}
										disabled={false}
										isMandatory={false}
									/>
								</div>
							</div>
							<div className="row table-container">
								<table className="table is-hoverable is-fullwidth is-striped">
									<thead>
										<tr>
											<td width="55%">รายการ</td>
											<td width="20%">ราคาต่อหน่วย ({this.state.currency.value})</td>
											<td width="20%">จำนวน</td>
											<td width="5%" />
										</tr>
									</thead>
									<tbody>
										{this.state.incentiveIncomeList.map((row, index) => (
											<tr key={'addIncentiveIncomeTable' + index}>
												<td>
													<InputRow
														placeholder="รายการ"
														keyProps="title"
														onInputChange={(key, value) => this.onIncomeListChange(key, value, index)}
														value={row.title}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<InputRow
														placeholder="ราคาต่อหน่วย"
														keyProps="pricePerPiece"
														onInputChange={(key, value) => this.onIncomeListChange(key, value, index)}
														value={row.pricePerPiece}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<InputRow
														placeholder="จำนวน"
														keyProps="quantity"
														onInputChange={(key, value) => this.onIncomeListChange(key, value, index)}
														value={row.quantity}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<div
														style={{
															marginTop: 9,
															color: 'red',
															cursor: 'pointer',
														}}
														onClick={() => this.deleteIncomeRow(index)}
													>
														<FontAwesomeIcon icon="times" />
													</div>
												</td>
											</tr>
										))}
										<tr className="clickable" onClick={this.addIncomeRow}>
											<td colSpan="4" style={{ textAlign: 'center' }}>
												เพิ่มแถว
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onTextChange}
									isMandatory={false}
								/>
							</div>
							<div className="columns rows" style={{ marginTop: 20 }}>
								<div className="column is-8" />
								<div className="column is-4" style={{ textAlign: 'right' }}>
									{AdminLockRender(
										program.status,
										<button className="button is-success" onClick={this.save}>
											บันทึก
										</button>,
										<button className="button is-success" disabled>
											บันทึก
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
