import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import InputRow from '../../../Components/InputRow';
import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';

import AppConfig from '../../../AppConfig';

export default class JointourWholesales extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			jointourCustomers: [],
			search: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('jointour/customers');
			if (APIHelper.handleAPIResponse(this, request)) {
				const jointourCustomers = request.data;
				console.log('jointourCustomers: ', jointourCustomers);
				this.setState({
					isLoading: false,
					jointourCustomers,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	handleInputChange = (key, value) => {
		this.setState({
			[key]: value,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		let { search } = this.state;
		const filtered = this.state.jointourCustomers.filter(
			jointourCustomer =>
				jointourCustomer.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
				(jointourCustomer.nickname && jointourCustomer.nickname.toLowerCase().indexOf(search.toLowerCase()) !== -1)
		);

		console.log(search, filtered);
		return (
			<div className="container">
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">ลูกค้า (Jointour)</h1>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/newjointourCustomer'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<div className="row columns">
						<div className="column is-6">
							<InputRow
								title="ชื่อ"
								labelSize="s"
								placeholder="ชื่อ"
								keyProps="search"
								value={this.state.search}
								onInputChange={this.handleInputChange}
								disabled={false}
								onKeyPress={this.handleKeyPress}
							/>
						</div>
					</div>
					<table className="table is-hoverable is-fullwidth">
						<thead>
							<tr>
								<td width="60%">ชื่อ</td>
								<td width="20%" style={{ textAlign: 'center' }}>
									ผู้ติดต่อ
								</td>
								<td width="20%" style={{ textAlign: 'center' }}>
									โปรแกรมที่ผ่านมา
								</td>
							</tr>
						</thead>
						<tbody>
							{filtered.map(jointourCustomer => (
								<tr key={jointourCustomer.id}>
									<td>
										<Link to={AppConfig.appPath + '/data/jointour/customers/' + jointourCustomer.id}>
											{jointourCustomer.name} {jointourCustomer.nickname ? '(' + jointourCustomer.nickname + ')' : ''}
										</Link>
									</td>
									<td style={{ textAlign: 'center' }}>
										<Link to={AppConfig.appPath + '/data/jointour/customers/' + jointourCustomer.id + '/contacts'}>ผู้ติดต่อ</Link>
									</td>
									<td style={{ textAlign: 'center' }}>
										<Link to={AppConfig.appPath + '/data/jointour/customers/' + jointourCustomer.id + '/programs'}>เปิด</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
