import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import StatusIcon from '../../../Components/StatusIcon';
import DeleteTag from '../../../Components/DeleteTag';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

export default class JoinTourListByCustomer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			programs: [],
			summary: {},
			customer: null,
		};
	}

	async componentDidMount() {
		await this.getPrograms();
	}

	getPrograms = async () => {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.setState({
				isLoading: true,
			});

			const { customerId } = this.props.match.params;
			let requestPrograms = APIGet(`jointour/customers/${customerId}/programs`);
			let requestSummary = APIGet(`jointour/customers/${customerId}/programs/summary`);
			let requestCustomer = APIGet('jointour/customers/' + customerId);

			requestPrograms = await requestPrograms;
			requestSummary = await requestSummary;
			requestCustomer = await requestCustomer;
			if (APIHelper.handleAPIResponse(this, requestPrograms, requestSummary, requestCustomer)) {
				const programs = requestPrograms.data;
				const summary = requestSummary.data;
				const customer = requestCustomer.data;

				console.log('programs: ', programs);
				console.log('summary: ', summary);
				console.log('customer: ', customer);
				this.setState({
					isLoading: false,
					programs,
					summary,
					customer,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { summary, programs, customer } = this.state;
		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h2 className="title is-2">JOIN TOUR ({customer.name})</h2>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }} />
				</div>
				<div className="table-container row">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="12%">วันเดินทาง</td>
								<td>Invoice</td>
								<td>ประเทศ</td>
								<td width="20%">โปรแกรม</td>
								<td width="20%">ลูกค้า / พนักงาน</td>
								<td>Whole Sale</td>
								<td>Seat</td>
								<td width="5%">Commission (หักส่วนลด)</td>
								<td width="5%">ราคา</td>
								<td width="10%">รับชำระมาแล้ว</td>
							</tr>
						</thead>
						<tbody>
							{programs.map(
								program =>
									program.wholeSale &&
									program.country &&
									program.airline &&
									program.customer && (
										<tr key={'program' + program.id}>
											<td>
												<DateRangeLabel startDate={program.startDate} endDate={program.endDate} />
											</td>
											<td>
												{program.invoice ? (
													<a onClick={() => OpenLink.jointourInvoice(program.invoice.id)}>{program.invoice.documentNumber}</a>
												) : (
													''
												)}
											</td>
											<td style={{ textAlign: 'center' }}>{program.country.name}</td>
											<td>
												<Link to={AppConfig.appPath + '/programs/jointour/' + program.id}>
													{program.title}{' '}
													{program.deleted_at ? <DeleteTag deletedAt={program.deleted_at} /> : <StatusIcon status={program.status} />}
												</Link>
											</td>
											<td>
												{program.customer && program.customer.name}
												{program.staffs && program.staffs.length > 0 ? (
													<div>
														<b>Staffs</b>
														{program.staffs.map(staff => {
															if (staff && staff.staff) {
																return <div key={'staff-' + staff.staff.id}>- {staff.staff.nickname}</div>;
															} else {
																return null;
															}
														})}
													</div>
												) : null}
											</td>
											<td>{program.wholeSale.shortname ? program.wholeSale.shortname : program.wholeSale.name}</td>
											<td className="number-cell">
												<NumberColor
													number={
														program.quantityNormal +
														program.quantityChildWithBed +
														program.quantityChildNoBed +
														program.quantityInfant
													}
												/>
											</td>
											<td className="number-cell">
												<NumberColor
													number={
														program.commission * program.quantityCommission +
														program.totalIncomeEtc -
														program.totalDiscount -
														program.totalExpenseEtc
													}
												/>
											</td>
											<td className="number-cell">
												<NumberColor number={program.deposit + program.deposit2 + program.full} />
											</td>
											<td className="number-cell">
												<NumberColor number={program.paidDeposit + program.paidDeposit2 + program.paidFull} />
											</td>
										</tr>
									)
							)}
							<tr className="table-summary-row">
								<td colSpan="6" style={{ textAlign: 'center' }}>
									Summary
								</td>
								<td className="number-cell">
									<NumberColor number={summary.seat} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.commission + summary.incomeEtc - summary.totalDiscount - summary.expenseEtc} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.price} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.paid} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
			</div>
		);
	}
}
