import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import StatusIcon from '../../../Components/StatusIcon';
import DeleteTag from '../../../Components/DeleteTag';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';
import InitSelect from '../../../Helper/InitSelect';

import Store from '../../../Helper/Store';
import StoreKey from '../../../StoreKey';

import AppConfig from '../../../AppConfig';
import AppColor from '../../../AppColor';

import FITTypeHelper from '../Helpers/FITTypeHelper';

import SEARCH_BY from '../../../StaticData/SearchBy';

import Search from './Search';
import SearchFilterContext from '../../../Context/SearchFilterContext';

export default class JoinTourList extends Component {
	static contextType = SearchFilterContext;
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		const searchBy = query.searchBy && InitSelect(SEARCH_BY, query.searchBy) ? InitSelect(SEARCH_BY, query.searchBy) : SEARCH_BY[1];
		const status = Store.get(StoreKey.filter.status);
		const fitCustomer = Store.get(StoreKey.filter.fitCustomer);
		// console.log('fitCustomer: ', fitCustomer);
		const fitType = Store.get(StoreKey.filter.fitType);
		const invoice = Store.get(StoreKey.filter.invoice);
		const customerName = Store.get(StoreKey.filter.customerName);
		const customerTelephone = Store.get(StoreKey.filter.customerTelephone);
		this.state = {
			isLoading: true,
			filter: {
				status: status
					? status
					: {
							value: '',
							label: 'All - ทั้งหมด',
					  },
				fitCustomer: fitCustomer
					? fitCustomer
					: {
							value: 0,
							label: 'All - ทั้งหมด',
					  },
				fitType: fitType
					? fitType
					: {
							value: '',
							label: 'All - ทั้งหมด',
					  },
				startDate: null,
				endDate: null,
				invoice: invoice ? invoice : '',
				searchBy,
				customerName: customerName ? customerName : '',
				customerTelephone: customerTelephone ? customerTelephone : '',
				staffName: '',
			},
			code: '',
			programs: [],
			summary: {},
		};
	}

	async componentDidMount() {
		this.setState(
			state => ({
				filter: {
					...state.filter,
					...this.context,
				},
			}),
			async () => {
				await this.getPrograms();
			}
		);
	}

	getPrograms = async () => {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.context.updateSearchFilter(this.state.filter);
			this.setState({
				isLoading: true,
			});

			Store.set(StoreKey.filter.status, this.state.filter.status);
			Store.set(StoreKey.filter.fitType, this.state.filter.fitType);
			Store.set(StoreKey.filter.fitCustomer, this.state.filter.fitCustomer);
			Store.set(StoreKey.filter.invoice, this.state.filter.invoice);
			Store.set(StoreKey.filter.customerName, this.state.filter.customerName);
			Store.set(StoreKey.filter.customerTelephone, this.state.filter.customerTelephone);
			Store.set(StoreKey.filter.staffName, this.state.filter.staffName);

			const startDate = this.state.filter.startDate.format('YYYY-MM-DD');
			const endDate = this.state.filter.endDate.format('YYYY-MM-DD');

			let filter = {
				startDate: startDate,
				endDate: endDate,
				fitType: this.state.filter.fitType.value,
				fitCustomerId: this.state.filter.fitCustomer.value,
				status: this.state.filter.status.value,
				invoice: this.state.filter.invoice,
				customerName: this.state.filter.customerName,
				customerTelephone: this.state.filter.customerTelephone,
				searchBy: this.state.filter.searchBy.value,
				staffName: this.state.filter.staffName,
			};

			let requestPrograms = APIGet('fit/programs', filter);
			let requestSummary = APIGet('fit', filter);
			requestPrograms = await requestPrograms;
			requestSummary = await requestSummary;
			if (APIHelper.handleAPIResponse(this, requestPrograms, requestSummary)) {
				const programs = requestPrograms.data;
				const summary = requestSummary.data;
				console.log('programs: ', programs);
				console.log('summary: ', summary);
				this.setState({
					isLoading: false,
					programs,
					summary,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	clearSearch = () => {
		this.setState(
			{
				filter: {
					fitCustomer: {
						value: 0,
						label: 'All - ทั้งหมด',
					},
					fitType: {
						value: '',
						label: 'All - ทั้งหมด',
					},
					date: this.state.filter.date,
					invoice: '',
					customerName: '',
					customerTelephone: '',
					status: {
						value: '',
						label: 'All - ทั้งหมด',
					},
				},
			},
			() => {
				this.getPrograms();
			}
		);
	};

	onFilterChange = (name, value) => {
		let filter = { ...this.state.filter };
		filter[name] = value;
		console.log(filter);
		this.setState({
			filter,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { summary, programs } = this.state;
		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/'} title="ดูทั้งหมด" />
				<div className="columns">
					<div className="column is-6">
						<h2 className="title is-2">FIT</h2>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-success" to={`${AppConfig.appPath}/programs/newfit`}>
							เพิ่มรายการ
						</Link>
					</div>
				</div>
				<Search {...this.state.filter} onFilterChange={this.onFilterChange} search={this.getPrograms} clearSearch={this.clearSearch} />
				<br />
				<div className="table-container row">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="12%">วันจอง</td>
								<td>Invoice</td>
								<td>Types</td>
								<td width="20%">รายการ</td>
								<td width="20%">พนักงาน</td>
								<td width="5%">ราคา</td>
								<td width="5%">รายจ่าย</td>
								<td width="5%">กำไร</td>
								<td width="10%">รับชำระมาแล้ว</td>
							</tr>
						</thead>
						<tbody>
							{programs.map(
								program =>
									program.customer && (
										<tr key={'program' + program.id}>
											<td>
												<DateLabel date={program.created_at} />
											</td>
											<td>
												{program.invoice ? (
													<a onClick={() => OpenLink.fitInvoice(program.invoice.id)}>{program.invoice.documentNumber}</a>
												) : (
													''
												)}
											</td>
											<td>
												{program.fitItems &&
													program.fitItems.map(item => (
														<span
															className="tag"
															key={'type-' + item.id}
															style={{ marginRight: 2, backgroundColor: AppColor.fitType[item.fitType] }}
														>
															{FITTypeHelper.mapLabel(item.fitType)}
														</span>
													))}
											</td>
											<td>
												<Link to={AppConfig.appPath + '/programs/fit/' + program.id}>
													{program.title} ({program.customer && program.customer.name}){' '}
													<StatusIcon status={program.status} deletedAt={program.deleted_at} />{' '}
													<span style={{ color: 'red', fontSize: 10 }}>
														{program.deleted_by_user_name ? program.deleted_by_user_name : null}
													</span>
												</Link>
											</td>
											<td>
												{program.staffs && program.staffs.length > 0 ? (
													<div>
														{program.staffs.map(staff => {
															if (staff && staff.staff) {
																return <div key={'staff-' + staff.staff.id}>- {staff.staff.nickname}</div>;
															} else {
																return null;
															}
														})}
													</div>
												) : null}
											</td>
											<td className="number-cell">
												<NumberColor number={program.salePrice + program.creditCharge} />
											</td>
											<td className="number-cell">
												<NumberColor number={program.expense + program.price + program.creditCharge} isExpense={true} />
											</td>
											<td className="number-cell">
												<NumberColor number={program.salePrice - program.expense - program.price} />
											</td>
											<td className="number-cell">
												<NumberColor number={program.paid} />
											</td>
										</tr>
									)
							)}
							<tr className="table-summary-row">
								<td colSpan="5" style={{ textAlign: 'center' }}>
									Summary
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalSalePrice + summary.totalCreditCharge} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalExpense + summary.totalPrice + summary.totalCreditCharge} isExpense={true} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalSalePrice - summary.totalExpense - summary.totalPrice} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalPaid} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
			</div>
		);
	}
}
