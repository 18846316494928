import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DeleteButton from '../../../Components/DeleteButton';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import ProgramHeader from '../../../Components/ProgramHeader';
import FileManager from '../../../Components/FileManager';

import APIGet from '../../../API/APIGet';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';
import OwnerLockRender from '../../../Helper/OwnerLockRender';

export default class IncomeEtc extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			income: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { programId, incomeId } = this.props.match.params;
			let requestProgram = APIGet('incentive/programs/' + programId);
			let requestIncome = APIGet('incentive/programs/' + programId + '/incomeEtcs/' + incomeId);
			requestProgram = await requestProgram;
			requestIncome = await requestIncome;
			if (APIHelper.handleAPIResponse(this, requestProgram, requestIncome)) {
				const program = requestProgram.data;
				const income = requestIncome.data;
				console.log('program: ', program);
				console.log('income: ', income);
				this.setState({
					isLoading: false,
					program,
					income,
				});
			} else if (requestProgram.status === 404) {
				toast.warn('ไม่พบโปรแกรมดังกล่าว');
			} else {
				toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	delete = async () => {
		const { programId, incomeId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete('incentive/programs/' + programId + '/incomeEtcs/' + incomeId);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			const { programId } = this.props.match.params;
			this.props.history.replace(AppConfig.appPath + '/programs/incentive/' + programId);
		} else if (requestDelete.status === 400) {
			toast.warn('คุณไม่มีสิทธิ์ลบรายการนี้');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	onUploadSuccess = income => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.setState({
			income,
		});
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = income => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.setState({
			income,
		});
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program, income } = this.state;
		const { programId, incomeId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/incentive/' + programId} />
				<ProgramHeader program={program} />

				<div className="section">
					<div className="columns">
						<div className="column is-8">
							<h3 className="subtitle is-5">รายรับอื่นๆ</h3>
							<h3 className="title is-3">
								{income.title}
								<span> </span>
								{AdminLockRender(
									program.status,
									AuthHelper.hasRight('Finance', 'Admin') ? (
										<Link
											className="button is-info"
											to={AppConfig.appPath + '/programs/incentive/' + programId + '/incomeEtcs/' + incomeId + '/edit'}
										>
											แก้ไข
										</Link>
									) : null,
									AuthHelper.hasRight('Finance', 'Admin') ? (
										<button className="button is-info" disabled>
											แก้ไข
										</button>
									) : null
								)}
							</h3>
						</div>
						<div className="column is-4" style={{ textAlign: 'right' }}>
							{OwnerLockRender(<DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />, null)}
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<div>
								ราคารวม:{' '}
								<strong>
									<NumberColor number={income.total} /> {income.currency}{' '}
									{!income.financeStaff ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}
								</strong>
							</div>
							<div>
								วันที่:{' '}
								<strong>
									<DateLabel date={income.paymentDate} />
								</strong>
							</div>
							<div>
								ประเภท: <strong>{income.incentiveIncomeEtcCategory.name}</strong>
							</div>
							<div>
								Supplier: <strong>{income.supplier.name}</strong>
							</div>
							<div>
								<strong>Note:</strong>
								<p>{income.note}</p>
							</div>
						</div>
						<div className="column is-4">
							{AdminLockRender(
								program.status,
								<FileManager
									title="Files"
									files={income.incentiveIncomeEtcFiles}
									api={'incentive/programs/' + programId + '/incomeEtcs/' + incomeId + '/files'}
									onUploadSuccess={this.onUploadSuccess}
									onUploadFail={this.onUploadFail}
									onDeleteSuccess={this.onDeleteSuccess}
									onDeleteFail={this.onDeleteFail}
								/>,
								<FileManager
									title="Files"
									files={income.incentiveIncomeEtcFiles}
									disableUpload={true}
									disableDelete={true}
									api={'incentive/programs/' + programId + '/incomeEtcs/' + incomeId + '/files'}
									onUploadSuccess={this.onUploadSuccess}
									onUploadFail={this.onUploadFail}
									onDeleteSuccess={this.onDeleteSuccess}
									onDeleteFail={this.onDeleteFail}
								/>
							)}
						</div>
						<div className="column is-4">
							<div className="content">
								<strong>Invoices</strong>
								<ul>
									{income.pdfIncentiveIncomeEtcInvoiceReceiptsWithDeleted &&
										income.pdfIncentiveIncomeEtcInvoiceReceiptsWithDeleted.map(doc => (
											<li key={'paymentVouchers' + doc.id}>
												<a onClick={() => OpenLink.incentiveIncomeEtcInvoiceReceipt(doc.id)} target="_blank">
													{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
												</a>
												{doc.deleted_at && (
													<label className="tag is-warning">
														Inactive - <DateLabel date={doc.deleted_at} />
														{}
													</label>
												)}
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12 table-container">
							<table className="table is-hoverable is-fullwidth is-striped is-bordered">
								<thead>
									<tr className="table-header">
										<td width="40%">รายการ</td>
										<td width="20%">ราคาต่อหน่วย (บาท)</td>
										<td width="20%">จำนวน</td>
										<td width="20%">รวม (บาท)</td>
									</tr>
								</thead>
								<tbody>
									{income.incentiveIncomeEtcList.map(row => (
										<tr key={'incomeEtcTable' + row.id}>
											<td>{row.title}</td>
											<td className="number-cell">
												<NumberColor number={row.pricePerPiece * income.currencyRate} />
											</td>
											<td className="number-cell">{row.quantity}</td>
											<td className="number-cell">
												<NumberColor number={row.pricePerPiece * income.currencyRate * row.quantity} />
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
