import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Select from 'react-select';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';
import FileManager from '../../../Components/FileManager';
import ApprovalStatus from '../../../Components/ApprovalStatus';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import MonthFilter from '../../../Helper/MonthFilter';
import YearFilter from '../../../Helper/YearFilter';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

import Summary from './Summary';

export default class CompanyExpenses extends Component {
	constructor(props) {
		const today = moment(new Date());
		super(props);
		this.state = {
			isLoading: true,
			category: {
				label: 'All - ทั้งหมด',
				value: 0,
			},
			month: {
				label: today.format('MMMM'),
				value: today.format('MMMM'),
			},
			year: {
				label: today.format('YYYY'),
				value: today.format('YYYY'),
			},
			categories: [],
			expenses: [],
			summary: [],
		};

		console.log(this.state);
	}

	componentDidMount = async () => {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			let requestCategories = await APIGet(`finance/companyExpenseCategories`);

			requestCategories = await requestCategories;
			if (APIHelper.handleAPIResponse(this, requestCategories)) {
				const categories = ThingsToSelect(requestCategories.data, true);
				console.log('category: ', categories);
				this.setState({
					categories,
				});

				await this.getExpenses();
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	getExpenses = async () => {
		this.setState({
			isLoading: true,
		});

		let requestExpenses = await APIGet(`finance/companyExpenses/${this.state.year.value}/${this.state.month.value}/${this.state.category.value}`);

		let requestSummary = await APIGet(`finance/companyExpenses/${this.state.year.value}/${this.state.month.value}/${this.state.category.value}/summary`);

		requestExpenses = await requestExpenses;
		requestSummary = await requestSummary;
		if (APIHelper.handleAPIResponse(this, requestExpenses, requestSummary)) {
			const expenses = requestExpenses.data;
			const summary = requestSummary.data;
			console.log('expenses: ', expenses);
			console.log('summary: ', summary);
			this.setState({
				isLoading: false,
				expenses,
				summary,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	getTotalExpense = () => {
		let total = 0;
		this.state.expenses.forEach(expense => {
			total += expense.total * expense.currencyRate;
		});

		return total;
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const total = this.getTotalExpense();

		return (
			<div className="container">
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h1 className="title is-2">รายจ่ายของบริษัท</h1>
						</div>
						<div className="column is-6" />
					</div>
					<div className="columns row">
						<div className="column is-2">
							<label className="label">Month</label>
							<Select
								name="month"
								value={this.state.month}
								clearable={false}
								onChange={value => this.handleSelectChange('month', value)}
								options={MonthFilter()}
							/>
						</div>
						<div className="column is-2">
							<label className="label">Year</label>
							<Select
								name="year"
								value={this.state.year}
								clearable={false}
								onChange={value => this.handleSelectChange('year', value)}
								options={YearFilter()}
							/>
						</div>
						<div className="column is-4">
							<label className="label">Category</label>
							<Select
								name="category"
								value={this.state.category}
								clearable={false}
								onChange={value => this.handleSelectChange('category', value)}
								options={this.state.categories}
							/>
						</div>
						<div className="column is-2" style={{ paddingTop: 44 }}>
							<button className="button is-info" onClick={this.getExpenses}>
								Filter
							</button>
						</div>
						<div
							className="column is-2"
							style={{
								paddingTop: 44,
								textAlign: 'right',
							}}
						>
							<Link className="button is-success" to={AppConfig.appPath + '/finance/newcompanyexpense'}>
								เพิ่มรายการ
							</Link>
						</div>
					</div>

					<Summary summary={this.state.summary} total={total} />
					<div className="table-container">
						<table className="table is-hoverable is-fullwidth is-bordered">
							<thead>
								<tr className="table-header">
									<td width="15%">Date</td>
									<td width="20%">Category</td>
									<td width="30%">รายการ</td>
									<td>เอกสาร</td>
									<td>Files</td>
									<td>Currency</td>
									<td>Total (THB)</td>
								</tr>
							</thead>
							<tbody>
								{this.state.expenses.map(expense => (
									<tr key={expense.id}>
										<td>
											<DateLabel date={expense.paymentDate} />
										</td>
										<td>{expense.companyExpenseCategory.name}</td>
										<td>
											<Link to={AppConfig.appPath + '/finance/companyexpenses/' + expense.id}>{expense.title}</Link>
											<span> </span>
											<ApprovalStatus status={expense.approvalStatus} />
										</td>
										<td>
											{expense.pdfCompanyExpensePaymentVouchers[0] ? (
												<a onClick={() => OpenLink.companyPaymentVoucher(expense.pdfCompanyExpensePaymentVouchers[0].id)}>
													{expense.pdfCompanyExpensePaymentVouchers[0].documentNumber}
												</a>
											) : null}
										</td>
										<td>
											<FileManager files={expense.files} disableUpload={true} disableDelete={true} hideTitle={true} />
										</td>
										<td>
											{expense.currency} ({expense.currencyRate})
										</td>
										<td className="number-cell">
											<NumberColor number={expense.total * expense.currencyRate} isExpense={true} />
										</td>
									</tr>
								))}
								<tr className="table-summary-row">
									<td colSpan={6}>Summary</td>
									<td className="number-cell">
										<NumberColor number={total} isExpense={true} />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
