import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import WholePageLoading from '../../../Components/WholePageLoading';
import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';
import InputRow from '../../../Components/InputRow';
import InDevelopmentTag from '../../../Components/InDevelopmentTag';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';

import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import AuthHelper from '../../../Helper/AuthHelper';
import MonthFilter from '../../../Helper/MonthFilter';
import YearFilter from '../../../Helper/YearFilter';

import AppConfig from '../../../AppConfig';

const MONTHS = MonthFilter();
const YEARS = YearFilter();
const DEFAULT_NEWEXPENSE = {
	title: '',
	total: 0,
};
export default class MonthlySummary extends Component {
	constructor(props) {
		super(props);
		const today = moment(new Date());
		this.state = {
			isLoading: true,
			month: {
				label: today.format('MMMM'),
				value: today.format('MMMM'),
			},
			year: {
				label: today.format('YYYY'),
				value: today.format('YYYY'),
			},
			expensePrograms: {},
			companyExpenses: [],
			newExpenses: [
				{
					...DEFAULT_NEWEXPENSE,
				},
			],
			expenses: [],
			salaries: [],
			commissions: [],
		};
	}

	componentDidMount = async () => {
		if (AuthHelper.hasRight('Admin')) {
			await this.getData();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	getData = async () => {
		let { month, year } = this.state;
		const startDate = this.getBeginningOfTheMonth(month.value, year.value);
		const endDate = this.getEndOfTheMonth(month.value, year.value);
		this.setState({
			isLoading: true,
		});

		const filter = {
			startDate: startDate,
			endDate: endDate,
			incentiveStatus: 'Done',
		};

		let dashboardRequest = APIGet('dashboard', filter);
		let monthlyExpenseRequest = APIGet(`finance/monthlyExpenses/${year.value}/${month.value}`);
		let salaryRequest = APIGet(`finance/salaries/${year.value}/${month.value}`);
		let commissionRequest = APIGet(`finance/commissions/${year.value}/${month.value}`);
		let companyExpenseRequest = APIGet(`finance/companyExpenses/${year.value}/${month.value}/0/summary`, {
			approvalStatus: 'Approved',
			filterOutNotIncludeInMonthlySummary: true,
		});
		dashboardRequest = await dashboardRequest;
		monthlyExpenseRequest = await monthlyExpenseRequest;
		salaryRequest = await salaryRequest;
		commissionRequest = await commissionRequest;
		companyExpenseRequest = await companyExpenseRequest;
		if (APIHelper.handleAPIResponse(this, dashboardRequest, monthlyExpenseRequest, commissionRequest, commissionRequest, companyExpenseRequest)) {
			const expensePrograms = this.getProgramTotal(dashboardRequest.data);
			const companyExpenses = companyExpenseRequest.data;
			const expenses = monthlyExpenseRequest.data;
			const salaries = salaryRequest.data;
			const commissions = commissionRequest.data;
			console.log('dashboardRequest: ', expensePrograms);
			console.log('companyExpenses: ', companyExpenses);
			console.log('monthlyExpenses: ', expenses);
			console.log('salaries: ', salaries);
			console.log('commissions: ', commissions);

			this.setState({
				expensePrograms,
				companyExpenses,
				expenses,
				salaries,
				commissions,
				isLoading: false,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	getProgramTotalByType = typeSummary => {
		let result = {
			customer: 0,
			price: 0,
			salePrice: 0,
			profit: 0,
		};

		typeSummary &&
			typeSummary.forEach(record => {
				if (record) {
					result.customer += parseInt(record.customer);
					result.price += parseInt(record.price);
					result.salePrice += parseInt(record.salePrice);
					result.profit += parseInt(record.profit);
				}
			});

		return result;
	};

	getProgramTotal = summary => {
		let jointour = this.getProgramTotalByType(summary.jointour);
		let incentive = this.getProgramTotalByType(summary.incentive);
		let fit = this.getProgramTotalByType(summary.fit);
		let result = {
			jointour,
			incentive,
			fit,
		};

		return result;
	};

	getBeginningOfTheMonth(month, year) {
		const day = moment('1 ' + month + ' ' + year);
		const startDate = day.startOf('month').format('YYYY-MM-DD');
		return startDate;
	}

	getEndOfTheMonth(month, year) {
		const day = moment('1 ' + month + ' ' + year);
		const endDate = day.endOf('month').format('YYYY-MM-DD');
		return endDate;
	}

	updateMonthlyExpense = (index, key, value) => {
		let expenses = [...this.state.expenses];
		expenses[index][key] = value;
		this.setState({
			expenses,
		});
	};

	updateNewExpense = (index, key, value) => {
		let newExpenses = [...this.state.newExpenses];
		newExpenses[index][key] = value;
		this.setState({
			newExpenses,
		});
	};

	addNewExpense = () => {
		let newExpenses = [
			...this.state.newExpenses,
			{
				...DEFAULT_NEWEXPENSE,
			},
		];
		this.setState({
			newExpenses,
		});
	};

	getTotalSummary = () => {
		let result = {
			programTotal: 0,
			etcTotal: 0,
			totalBeforePatCommission: 0,
			patCommission: 0,
			total: 0,
		};

		if (this.state.isLoading) {
			return result;
		}

		const { expensePrograms, newExpenses, companyExpenses, expenses, salaries, commissions } = this.state;
		result.programTotal = expensePrograms.jointour.profit + expensePrograms.fit.profit + expensePrograms.incentive.profit;
		newExpenses.forEach(expense => {
			result.etcTotal += !isNaN(parseFloat(expense.total)) ? parseFloat(expense.total) : 0;
		});

		companyExpenses.forEach(expense => {
			result.etcTotal += !isNaN(parseFloat(expense.total)) ? parseFloat(expense.total) : 0;
		});

		expenses.forEach(expense => {
			result.etcTotal += !isNaN(parseFloat(expense.total)) ? parseFloat(expense.total) : 0;
		});

		salaries.forEach(expense => {
			result.etcTotal += !isNaN(parseFloat(expense.total)) ? parseFloat(expense.total) : 0;
		});

		commissions.forEach(expense => {
			result.etcTotal += !isNaN(parseFloat(expense.total)) ? parseFloat(expense.total) : 0;
		});

		result.totalBeforePatCommission = result.programTotal - result.etcTotal;
		result.patCommission = parseInt((result.totalBeforePatCommission * 5) / 100);
		if (result.patCommission < 0) {
			result.patCommission = 0;
		}
		result.total = result.totalBeforePatCommission - result.patCommission;

		return result;
	};

	save = async () => {
		this.setState({
			isLoading: true,
		});
		const { month, year, newExpenses, expenses } = this.state;
		let data = {
			expenses: [
				...expenses.map(expense => ({
					title: expense.title,
					total: expense.total,
				})),
				...newExpenses.map(expense => ({
					title: expense.title,
					total: expense.total,
				})),
			],
		};

		const request = await APIPost(`finance/monthlyExpenses/${year.value}/${month.value}`, data);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('แก้ไขรายการเรียบร้อยแล้ว');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('เกิดปัญหาในการเพิ่มรายรับ');
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		let { month, year } = this.state;
		const endOfTheMonth = this.getEndOfTheMonth(month.value, year.value);
		const summary = this.getTotalSummary();
		return (
			<div className="container">
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">สรุปผลกำไรและขาดทุนประจำเดือน</h3>
						</div>
						<div className="column is-2" style={{}}>
							<Select name="year" value={this.state.year} clearable={false} onChange={value => this.setState({ year: value })} options={YEARS} />
						</div>
						<div className="column is-2" style={{}}>
							<Select
								name="month"
								value={this.state.month}
								clearable={false}
								onChange={value => this.setState({ month: value })}
								options={MONTHS}
							/>
						</div>
						<div className="column is-2">
							<button className="button is-info" onClick={this.getData}>
								Go
							</button>
						</div>
					</div>
				</div>
				<div className="section">
					<table className="table is-hoverable is-fullwidth is-bordered">
						<thead>
							<tr className="table-header">
								<td>วัน/เดือน/ปี</td>
								<td width="40%">รายการ</td>
								<td width="15%">กำไรจากการขาย</td>
								<td width="15%">ยอดจ่ายทั่วไป</td>
								<td width="15%">ยอดสุทธิ</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="date-cell">
									<DateLabel date={endOfTheMonth} />
								</td>
								<td>JOIN TOUR</td>
								<td className="number-cell">
									<NumberColor
										number={
											this.state.expensePrograms && this.state.expensePrograms.jointour ? this.state.expensePrograms.jointour.profit : 0
										}
									/>
								</td>
								<td />
								<td />
							</tr>
							<tr>
								<td className="date-cell">
									<DateLabel date={endOfTheMonth} />
								</td>
								<td>FIT</td>
								<td className="number-cell">
									<NumberColor
										number={this.state.expensePrograms && this.state.expensePrograms.fit ? this.state.expensePrograms.fit.profit : 0}
									/>
								</td>
								<td />
								<td />
							</tr>
							<tr>
								<td className="date-cell">
									<DateLabel date={endOfTheMonth} />
								</td>
								<td>INCENTIVE</td>
								<td className="number-cell">
									<NumberColor
										number={
											this.state.expensePrograms && this.state.expensePrograms.incentive ? this.state.expensePrograms.incentive.profit : 0
										}
									/>
								</td>
								<td />
								<td />
							</tr>
							{this.state.companyExpenses.map(expense => (
								<tr key={'companyexpense' + expense.id}>
									<td className="date-cell">
										<DateLabel date={endOfTheMonth} />
									</td>
									<td>{expense.companyExpenseCategory ? expense.companyExpenseCategory.name : '- Deleted category --'}</td>
									<td />
									<td className="number-cell">
										<NumberColor number={expense.total} isExpense={true} />
									</td>
									<td />
								</tr>
							))}
							{this.state.expenses.map((expense, index) => (
								<tr key={'monthlyexpense' + expense.id}>
									<td className="date-cell">
										<DateLabel date={endOfTheMonth} />
									</td>
									<td>
										<InputRow
											hideLabel={true}
											title="title"
											placeholder="รายการ"
											keyProps="title"
											value={expense.title}
											onInputChange={(key, value) => this.updateMonthlyExpense(index, key, value)}
											disabled={false}
										/>
									</td>
									<td />
									<td>
										<InputRow
											hideLabel={true}
											title="expense"
											placeholder="ยอดจ่ายทั่วไป"
											keyProps="total"
											value={expense.total}
											onInputChange={(key, value) => this.updateMonthlyExpense(index, key, value)}
											disabled={false}
										/>
									</td>
									<td />
								</tr>
							))}

							{this.state.salaries.map(salary => (
								<tr key={'salary' + salary.staffId}>
									<td className="date-cell">
										<DateLabel date={endOfTheMonth} />
									</td>
									<td>เงินเดือน {salary.staff.name}</td>
									<td />
									<td>
										<NumberColor number={salary.total} isExpense={true} />
									</td>
									<td />
								</tr>
							))}
							{this.state.commissions.map(com => (
								<tr key={'commission' + com.staffId}>
									<td className="date-cell">
										<DateLabel date={endOfTheMonth} />
									</td>
									<td>Commission {com.staff.name}</td>
									<td />
									<td>
										<NumberColor number={com.total} isExpense={true} />
									</td>
									<td />
								</tr>
							))}

							{this.state.newExpenses.map((expense, index) => (
								<tr key={'newexpense' + index}>
									<td className="date-cell">
										<DateLabel date={endOfTheMonth} />
									</td>
									<td>
										<InputRow
											hideLabel={true}
											title="title"
											placeholder="รายการ"
											keyProps="title"
											value={expense.title}
											onInputChange={(key, value) => this.updateNewExpense(index, key, value)}
											disabled={false}
										/>
									</td>
									<td />
									<td>
										<InputRow
											hideLabel={true}
											title="expense"
											placeholder="ยอดจ่ายทั่วไป"
											keyProps="total"
											value={expense.total}
											onInputChange={(key, value) => this.updateNewExpense(index, key, value)}
											disabled={false}
										/>
									</td>
									<td />
								</tr>
							))}
							<tr>
								<td colSpan={5} style={{ textAlign: 'center' }} className="clickable" onClick={this.addNewExpense}>
									Add
								</td>
							</tr>
							<tr>
								<td colSpan={2}>สรุปยอดรวม</td>
								<td className="number-cell">
									<NumberColor number={summary.programTotal} />
								</td>
								<td className="number-cell">
									<NumberColor isExpense={true} number={summary.etcTotal} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalBeforePatCommission} />
								</td>
							</tr>
							<tr>
								<td colSpan={4}>หักค่าคอมคุณพัชรินทร์</td>
								<td className="number-cell">
									<NumberColor isExpense={true} number={summary.patCommission} />
								</td>
							</tr>
							<tr className="table-summary-row">
								<td colSpan={4}>สรุป</td>
								<td className="number-cell">
									<NumberColor number={summary.total} />
								</td>
							</tr>
						</tbody>
					</table>
					<div>
						<button className="button is-success" onClick={this.save}>
							Save
						</button>
					</div>
				</div>
				{this.state.isLoading ? <WholePageLoading /> : null}
			</div>
		);
	}
}
