import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import ProgramHeader from '../../../Components/ProgramHeader';
import PaymentDetail from '../../../Components/PaymentDetail';

import CURRENCIES from '../../../StaticData/Currencies';
import { PAYMENT_METHODS_REQUIRED } from '../../../StaticData/PaymentMethods';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import ValidatePaymentDetail from '../../../Helper/ValidatePaymentDetail';
import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

export default class AddEditIncomeEtc extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			incentiveIncomeEtcCategories: [],
			paymentDate: moment(new Date()),
			paymentTime: '',
			note: '',
			paymentMethod: PAYMENT_METHODS_REQUIRED[0],
			currency: CURRENCIES[0],
			currencyRate: 1,
			suppliers: [],
			title: '',
			bank: null,
			checkNumber: '',
			bankAccount: null,
			creditCard: null,
			incentiveIncomeEtcList: [
				{
					title: '',
					pricePerPiece: 0,
					quantity: 0,
				},
			],
			incentiveIncomeEtcCategory: null,
			supplier: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initCreate = async () => {
		const { programId } = this.props.match.params;

		let requestProgram = APIGet('incentive/programs/' + programId);
		let requestCategories = APIGet('incentiveIncomeEtcCategories');
		let requestSuppliers = APIGet('suppliers');
		requestProgram = await requestProgram;
		requestCategories = await requestCategories;
		requestSuppliers = await requestSuppliers;
		if (APIHelper.handleAPIResponse(this, requestProgram, requestCategories, requestSuppliers)) {
			const program = requestProgram.data;
			const incentiveIncomeEtcCategories = ThingsToSelect(requestCategories.data);
			const suppliers = ThingsToSelect(requestSuppliers.data);
			console.log('program: ', program);
			this.setState({
				program,
				incentiveIncomeEtcCategories,
				suppliers,
			});

			return true;
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { programId, incomeId } = this.props.match.params;
		let requestIncome = APIGet('incentive/programs/' + programId + '/incomeEtcs/' + incomeId);
		requestIncome = await requestIncome;
		if (APIHelper.handleAPIResponse(this, requestIncome)) {
			const income = requestIncome.data;
			console.log('income: ', income);
			this.setState({
				note: income.note,
				currency: CURRENCIES.filter(c => c.value === income.currency)[0],
				currencyRate: income.currencyRate,
				title: income.title,
				incentiveIncomeEtcList: income.incentiveIncomeEtcList,
				incentiveIncomeEtcCategory: {
					value: income.incentiveIncomeEtcCategory.id,
					label: income.incentiveIncomeEtcCategory.name,
				},
				supplier: {
					value: income.supplier.id,
					label: income.supplier.name,
				},
				paymentDate: moment(income.paymentDate),
				paymentTime: income.paymentTime,
				paymentMethod: PAYMENT_METHODS_REQUIRED.filter(p => p.value === income.paymentMethod)[0],
				bank: income.bank
					? {
							value: income.bankId,
							label: income.bank.name,
					  }
					: null,
				checkNumber: income.checkNumber,
				bankAccount: income.bankAccount
					? {
							value: income.bankAccountId,
							label: income.bankAccount.name,
					  }
					: null,
				creditCard: income.creditCard
					? {
							value: income.creditCardId,
							label: income.creditCard.name,
					  }
					: null,
			});

			return true;
		} else if (requestIncome.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { incomeId } = this.props.match.params;
		if (incomeId) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		const { programId, incomeId } = this.props.match.params;
		if (this.validateSaveState()) {
			let data = {
				incentiveProgramId: programId,
				title: this.state.title,
				total: this.calculateTotalPrice(),
				currencyRate: this.state.currencyRate,
				currency: this.state.currency.value,
				note: this.state.note,
				supplierId: this.state.supplier.value,
				incentiveIncomeEtcList: this.state.incentiveIncomeEtcList,
				incentiveIncomeEtcCategoryId: this.state.incentiveIncomeEtcCategory.value,
				paymentMethod: this.state.paymentMethod.value,
				paymentDate: this.state.paymentDate.format('YYYY-MM-DD'),
			};

			if (this.state.paymentMethod.value === 'Transfer') {
				data.bankAccountId = this.state.bankAccount.value;
				data.paymentTime = this.state.paymentTime;
			} else if (this.state.paymentMethod.value === 'Check') {
				data.bankId = this.state.bank.value;
				data.checkNumber = this.state.checkNumber;
			} else if (this.state.paymentMethod.value === 'Credit Card') {
				data.creditCardId = this.state.creditCard.value;
			}

			let request = null;
			if (this.isUpdate()) {
				request = await APIPut('incentive/programs/' + programId + '/incomeEtcs/' + incomeId, data);
			} else {
				request = await APIPost('incentive/programs/' + programId + '/incomeEtcs', data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายรับเรียบร้อยแล้ว' : 'เพิ่มรายรับเรียบร้อยแล้ว');
				const result = request.data;
				this.props.history.push(AppConfig.appPath + '/programs/incentive/' + programId + '/incomeEtcs/' + result.id);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มรายรับ');
			}
		} else {
			toast.warn('โปรดตรวจสอบความถูกต้องของข้อมูล');
		}
	};

	validateSaveState = () => {
		const incentiveIncomeEtcCategory = this.state.incentiveIncomeEtcCategory && this.state.incentiveIncomeEtcCategory.value;
		const title = this.state.title.trim() !== '';
		if (!title) {
			toast.warn('กรุณาระบุรายการหลัก');
		}

		const currencyRate = !isNaN(this.state.currencyRate);
		if (!currencyRate) {
			toast.warn('กรุณาระบุอัตราแลกเปลี่ยนเงินบาท');
		}

		const lists = this.state.incentiveIncomeEtcList.length > 0;
		if (!lists) {
			toast.warn('กรุณาระบุรายการ');
		}

		const supplier = this.state.supplier && this.state.supplier.value;
		if (!supplier) {
			toast.warn('กรุณาระบุ Supplier');
		}

		let listContent = true;
		this.state.incentiveIncomeEtcList.forEach(row => {
			listContent = listContent && (row.title.trim() !== '' && !isNaN(row.pricePerPiece) && !isNaN(row.quantity));
		});

		if (!listContent) {
			toast.warn('กรุณาระบุข้อมูลในรายการให้ครบและถูกต้อง');
		}

		const paymentDetail = ValidatePaymentDetail(this.state, toast);

		return incentiveIncomeEtcCategory && title && currencyRate && lists && supplier && listContent && paymentDetail;
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onPaymentDateChange = newDate => {
		this.setState({
			paymentDate: newDate,
		});
	};

	onIncomeListChange = (key, value, index) => {
		// console.log(key, value, index);
		let incentiveIncomeEtcList = JSON.parse(JSON.stringify(this.state.incentiveIncomeEtcList));
		incentiveIncomeEtcList[index][key] = value;
		this.setState({
			incentiveIncomeEtcList,
		});
	};

	calculateTotalPrice = () => {
		let totalPrice = 0;
		this.state.incentiveIncomeEtcList.forEach(income => {
			totalPrice += income.pricePerPiece * income.quantity;
		});

		return totalPrice;
	};

	addIncomeRow = () => {
		let incentiveIncomeEtcList = JSON.parse(JSON.stringify(this.state.incentiveIncomeEtcList));
		incentiveIncomeEtcList.push({
			title: '',
			pricePerPiece: 0,
			quantity: 0,
		});

		this.setState({
			incentiveIncomeEtcList,
		});
	};

	deleteIncomeRow = index => {
		let incentiveIncomeEtcList = JSON.parse(JSON.stringify(this.state.incentiveIncomeEtcList));
		incentiveIncomeEtcList.splice(index, 1);
		this.setState({
			incentiveIncomeEtcList,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program } = this.state;
		const { programId, incomeId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter
					link={
						this.isUpdate()
							? AppConfig.appPath + '/programs/incentive/' + programId + '/incomeEtcs/' + incomeId
							: AppConfig.appPath + '/programs/incentive/' + programId
					}
				/>
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">เพิ่มรายรับอื่นๆ</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<PaymentDetail
								paymentMethod={this.state.paymentMethod}
								paymentTime={this.state.paymentTime}
								bank={this.state.bank}
								bankAccount={this.state.bankAccount}
								creditCard={this.state.creditCard}
								checkNumber={this.state.checkNumber}
								currency={this.state.currency}
								currencyRate={this.state.currencyRate}
								paymentDate={this.state.paymentDate}
								handleSelectChange={this.handleSelectChange}
								onPaymentDateChange={this.onPaymentDateChange}
								onTextChange={this.onTextChange}
							/>
							<div className="columns row">
								<div className="column is-2">
									<label className="label">ประเภท</label>
									<Select
										name="incentiveIncomeEtcCategory"
										value={this.state.incentiveIncomeEtcCategory}
										clearable={false}
										onChange={value => this.handleSelectChange('incentiveIncomeEtcCategory', value)}
										options={this.state.incentiveIncomeEtcCategories}
									/>
								</div>
								<div className="column is-3">
									<label className="label">Supplier</label>
									<Select
										name="supplier"
										value={this.state.supplier}
										clearable={false}
										onChange={value => this.handleSelectChange('supplier', value)}
										options={this.state.suppliers}
									/>
								</div>
								<div className="column is-4">
									<InputRow
										title="รายการหลัก"
										labelSize="s"
										placeholder="รายการหลัก"
										keyProps="title"
										onInputChange={this.onTextChange}
										value={this.state.title}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-3" style={{ textAlign: 'right' }}>
									<h5 className="subtitle is-5">ยอดรวม</h5>
									<h3 className="title is-3">
										<NumberColor number={this.calculateTotalPrice()} /> {this.state.currency.value}
									</h3>
								</div>
							</div>
							<div className="row table-container">
								<table className="table is-hoverable is-fullwidth is-striped">
									<thead>
										<tr>
											<td width="55%">รายการ</td>
											<td width="20%">ราคาต่อหน่วย ({this.state.currency.value})</td>
											<td width="20%">จำนวน</td>
											<td width="5%" />
										</tr>
									</thead>
									<tbody>
										{this.state.incentiveIncomeEtcList.map((row, index) => (
											<tr key={'addIncentiveIncomeTable' + index}>
												<td>
													<InputRow
														placeholder="รายการ"
														keyProps="title"
														onInputChange={(key, value) => this.onIncomeListChange(key, value, index)}
														value={row.title}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<InputRow
														placeholder="ราคาต่อหน่วย"
														keyProps="pricePerPiece"
														onInputChange={(key, value) => this.onIncomeListChange(key, value, index)}
														value={row.pricePerPiece}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<InputRow
														placeholder="จำนวน"
														keyProps="quantity"
														onInputChange={(key, value) => this.onIncomeListChange(key, value, index)}
														value={row.quantity}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<div
														style={{
															marginTop: 9,
															color: 'red',
															cursor: 'pointer',
														}}
														onClick={() => this.deleteIncomeRow(index)}
													>
														<FontAwesomeIcon icon="times" />
													</div>
												</td>
											</tr>
										))}
										<tr className="clickable" onClick={this.addIncomeRow}>
											<td colSpan="4" style={{ textAlign: 'center' }}>
												เพิ่มแถว
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onTextChange}
									isMandatory={false}
								/>
							</div>
							<div style={{ textAlign: 'right', marginTop: 20 }}>
								{AdminLockRender(
									program.status,
									<button className="button is-success" onClick={this.save}>
										บันทึก
									</button>,
									<button className="button is-success" disabled>
										บันทึก
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
