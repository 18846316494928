import React, { Component } from 'react';
import DeleteTag from './DeleteTag';

export default class StatusIcon extends Component {
	render() {
		const { status, deletedAt } = this.props;
		console.log(this.props);
		if (deletedAt) {
			return <DeleteTag deletedAt={deletedAt} />;
		}

		if (status === 'In Progress') {
			return <span className="tag is-warning">In Progress</span>;
		} else if (status === 'Done') {
			return <span className="tag is-success">Done</span>;
		} else if (status === 'Cancelled') {
			return <span className="tag is-danger">Cancelled</span>;
		} else {
			return null;
		}
	}
}
