import React, { Component } from 'react';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import NumberColor from '../../../Components/NumberColor';

export default class IncentiveList extends Component {
	getSummary = list => {
		let summary = {
			customer: 0,
			price: 0,
			salePrice: 0,
			profit: 0,
		};

		list.forEach(row => {
			summary.customer += parseInt(row.numberOfCustomers);
			summary.price += row.expense * row.currencyRate;
			summary.salePrice += row.total * row.currencyRate;
			summary.profit += row.total * row.currencyRate - row.expense * row.currencyRate;
		});

		return summary;
	};
	render() {
		const { list, isLoading } = this.props;
		if (isLoading) {
			return <LoadingCenter />;
		}

		if (!list || list.length === 0) {
			return null;
		}
		const summary = this.getSummary(list);
		return (
			<div className="columns">
				<div className="column is-12">
					<h4 className="title is-4">Incentive</h4>
					<div className="row">
						<table className="table is-hoverable is-fullwidth is-bordered">
							<thead>
								<tr className="table-header">
									<td>Booking Date</td>
									<td>วันเดินทาง</td>
									<td>ลูกค้า</td>
									<td>Staffs</td>
									<td>ลูกค้าเดินทาง</td>
									<td>ราคาขาย</td>
									<td>ต้นทุน</td>
									<td>กำไร</td>
								</tr>
							</thead>
							<tbody>
								{list.map(row => (
									<tr key={'incentiveList' + row.id}>
										<td>
											<DateLabel date={row.created_at} />
										</td>
										<td>
											<DateRangeLabel startDate={row.startDate} endDate={row.endDate} />
										</td>
										<td>{row.company ? row.company.name : ''}</td>
										<td>{row.staffs.map(({ staff }) => (staff ? staff.name : '')).join(', ')}</td>
										<td className="number-cell">
											<NumberColor number={row.numberOfCustomers} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.total * row.currencyRate} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.expense * row.currencyRate} isExpense={true} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.total * row.currencyRate - row.expense * row.currencyRate} />
										</td>
									</tr>
								))}
								<tr className="table-summary-row">
									<td colSpan={4}>Summary</td>
									<td className="number-cell">
										<NumberColor number={summary.customer} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.salePrice} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.price} isExpense={true} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.profit} />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
