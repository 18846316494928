import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import ProgramHeader from '../../../Components/ProgramHeader';
import PaymentDetail from '../../../Components/PaymentDetail';
import AuthRender from '../../../Components/AuthRender';

import CURRENCIES from '../../../StaticData/Currencies';
import { PAYMENT_METHODS_REQUIRED } from '../../../StaticData/PaymentMethods';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import ValidatePaymentDetail from '../../../Helper/ValidatePaymentDetail';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

export default class AddEditExpense extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			jointourExpenseCategories: [],
			suppliers: [],
			paymentDate: moment(new Date()),
			paymentTime: '',
			note: '',
			wholeSaleId: 0,
			total: 0,
			paymentMethod: PAYMENT_METHODS_REQUIRED[0],
			title: '',
			type: '',
			bank: null,
			checkNumber: '',
			bankAccount: null,
			creditCard: null,
			jointourExpenseList: [
				{
					title: '',
					pricePerPiece: 0,
					quantity: 0,
				},
			],
			jointourExpenseCategory: null,
			supplier: null,
			isFinanceReviewed: null,
			categories: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initCreate = async () => {
		const { programId } = this.props.match.params;

		let requestProgram = APIGet('jointour/programs/' + programId);
		requestProgram = await requestProgram;
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const program = requestProgram.data;
			this.setState({
				program,
			});

			return true;
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { programId, expenseId } = this.props.match.params;
		let requestExpense = APIGet('jointour/programs/' + programId + '/expenses/' + expenseId);
		requestExpense = await requestExpense;
		if (APIHelper.handleAPIResponse(this, requestExpense)) {
			const expense = requestExpense.data;
			let isFinanceReviewed = null;
			if (AuthHelper.getUserDetail().authRole === 'Finance') {
				if (expense.financeStaff) {
					isFinanceReviewed = true;
				} else {
					isFinanceReviewed = false;
				}
			}

			console.log('expense: ', expense);
			this.setState({
				...expense,
				paymentMethod: PAYMENT_METHODS_REQUIRED.filter(p => p.value === expense.paymentMethod)[0],
				bank: expense.bank
					? {
							value: expense.bankId,
							label: expense.bank.name,
					  }
					: null,
				bankAccount: expense.bankAccount
					? {
							value: expense.bankAccountId,
							label: expense.bankAccount.name,
					  }
					: null,
				paymentDate: moment(new Date(expense.paymentDate)),
				creditCard: expense.creditCard
					? {
							value: expense.creditCardId,
							label: expense.creditCard.name,
					  }
					: null,
				isFinanceReviewed,
			});

			return true;
		} else if (requestExpense.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { expenseId } = this.props.match.params;
		if (expenseId) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		const { programId, expenseId } = this.props.match.params;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				...this.state,
				jointourProgramId: programId,
				paymentMethod: this.state.paymentMethod.value,
				paymentDate: this.state.paymentDate.format('YYYY-MM-DD'),
			};

			if (this.state.paymentMethod.value === 'Transfer') {
				data.bankAccountId = this.state.bankAccount.value;
				data.paymentTime = this.state.paymentTime;
			} else if (this.state.paymentMethod.value === 'Check') {
				data.bankId = this.state.bank.value;
				data.checkNumber = this.state.checkNumber;
			} else if (this.state.paymentMethod.value === 'Credit Card') {
				data.creditCardId = this.state.creditCard.value;
			}

			let request = null;
			if (this.isUpdate()) {
				request = await APIPut('jointour/programs/' + programId + '/expenses/' + expenseId, data);
			} else {
				request = await APIPost('jointour/programs/' + programId + '/expenses', data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายจ่ายเรียบร้อยแล้ว' : 'เพิ่มรายจ่ายเรียบร้อยแล้ว');
				const result = request.data;
				this.props.history.push(AppConfig.appPath + '/programs/jointour/' + programId + '/expenses/' + result.id);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มรายจ่าย');
				this.setState({
					isLoading: false,
				});
			}
		}
	};

	validateSaveState = () => {
		const title = this.state.title.trim() !== '';
		if (!title) {
			toast.warn('กรุณาระบุรายการหลัก');
		}

		const paymentDetail = ValidatePaymentDetail(this.state, toast);

		return title && paymentDetail;
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onPaymentDateChange = newDate => {
		this.setState({
			paymentDate: newDate,
		});
	};

	onExpenseListChange = (key, value, index) => {
		// console.log(key, value, index);
		let jointourExpenseList = JSON.parse(JSON.stringify(this.state.jointourExpenseList));
		jointourExpenseList[index][key] = value;
		this.setState({
			jointourExpenseList,
		});
	};

	calculateTotalPrice = () => {
		let totalPrice = 0;
		this.state.jointourExpenseList.forEach(expense => {
			totalPrice += expense.pricePerPiece * expense.quantity;
		});

		return totalPrice;
	};

	addExpenseRow = () => {
		let jointourExpenseList = JSON.parse(JSON.stringify(this.state.jointourExpenseList));
		jointourExpenseList.push({
			title: '',
			pricePerPiece: 0,
			quantity: 0,
		});

		this.setState({
			jointourExpenseList,
		});
	};

	deleteExpenseRow = index => {
		let jointourExpenseList = JSON.parse(JSON.stringify(this.state.jointourExpenseList));
		jointourExpenseList.splice(index, 1);
		this.setState({
			jointourExpenseList,
		});
	};

	toggleFinanceReviewed = () => {
		this.setState({
			isFinanceReviewed: !this.state.isFinanceReviewed,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program } = this.state;
		const { programId, expenseId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/jointour/' + programId + '/expenses/' + expenseId} />
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">เพิ่ม/แก้ไขรายจ่าย</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<PaymentDetail
								paymentMethod={this.state.paymentMethod}
								paymentTime={this.state.paymentTime}
								bank={this.state.bank}
								bankAccount={this.state.bankAccount}
								creditCard={this.state.creditCard}
								checkNumber={this.state.checkNumber}
								currency={this.state.currency}
								currencyRate={this.state.currencyRate}
								paymentDate={this.state.paymentDate}
								handleSelectChange={this.handleSelectChange}
								onPaymentDateChange={this.onPaymentDateChange}
								onTextChange={this.onTextChange}
								disableCurrency={true}
							/>
							<div className="columns row">
								<div className="column is-8">
									<InputRow
										title="รายการ"
										labelSize="s"
										placeholder="รายการหลัก"
										keyProps="title"
										onInputChange={this.onTextChange}
										value={this.state.title}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-4" style={{ textAlign: 'right' }}>
									<InputRow
										title="ยอดรวม"
										labelSize="s"
										placeholder="ยอดรวม"
										keyProps="total"
										onInputChange={this.onTextChange}
										value={this.state.total}
										disabled={false}
										isMandatory={false}
									/>
								</div>
							</div>
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onTextChange}
									isMandatory={false}
								/>
							</div>
							<div style={{ textAlign: 'right', marginTop: 20 }}>
								<AuthRender roles={['Finance']}>
									<label className="checkbox">
										<input
											type="checkbox"
											value={this.state.isFinanceReviewed}
											onClick={this.toggleFinanceReviewed}
											checked={this.state.isFinanceReviewed ? 'checked' : ''}
											onChange={() => {}}
										/>
										&nbsp;ตรวจสอบโดยพนักงานบัญชีแล้ว
									</label>
								</AuthRender>
								<span> </span>
								<button className="button is-success" onClick={this.save}>
									บันทึก
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
