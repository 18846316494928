const APPROVAL_STATUS = [
	{
		value: 'Staff Review',
		label: 'Staff Review',
	},
	{
		value: 'Waiting',
		label: 'Waiting',
	},
	{
		value: 'Approved',
		label: 'Approved',
	},
	{
		value: 'Not Approved',
		label: 'Not Approved',
	},
];

export default APPROVAL_STATUS;
