import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import Row from './Row';

export default class CreditCards extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			banks: [],
			creditCards: [],
			isEditLoading: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let requestcreditCards = APIGet('creditCards');
			let requestBanks = APIGet('banks');
			requestcreditCards = await requestcreditCards;
			requestBanks = await requestBanks;
			if (APIHelper.handleAPIResponse(this, requestcreditCards, requestBanks)) {
				const creditCards = requestcreditCards.data;
				const banks = ThingsToSelect(requestBanks.data);
				console.log('creditCards: ', creditCards);
				console.log('banks: ', banks);
				this.setState({
					isLoading: false,
					creditCards,
					banks,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	saveChange = async (creditCardId, name, number, selectedBank) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPut('creditCards/' + creditCardId, {
			name: name,
			cardNumber: number,
			bankId: selectedBank.value,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const creditCard = request.data;
			let creditCards = JSON.parse(JSON.stringify(this.state.creditCards));
			for (let i = 0; i < creditCards.length; i++) {
				if (creditCards[i].id === creditCardId) {
					creditCards[i] = creditCard;
				}
			}

			// console.log(creditCards);
			toast.success('แก้ไขข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				creditCards,
			});
		} else {
			toast.error('ไม่สามารถแก้ไขข้อมูลได้');
		}
	};

	addNew = async (name, number, selectedBank) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPost('creditCards', {
			name: name,
			cardNumber: number,
			bankId: selectedBank.value,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const creditCard = request.data;
			let creditCards = JSON.parse(JSON.stringify(this.state.creditCards));
			creditCards.push(creditCard);

			// console.log(creditCards);
			toast.success('เพิ่มข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				creditCards,
			});
		} else {
			toast.error('ไม่สามารถเพิ่มข้อมูลได้');
		}
	};

	delete = async creditCardId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete('creditCards/' + creditCardId);
		if (APIHelper.handleAPIResponse(this, request)) {
			const creditCards = this.state.creditCards.filter(creditCard => creditCard.id !== creditCardId);

			// console.log(creditCards);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				creditCards,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Credit Cards</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.creditCards.map(creditCard => (
								<tr key={creditCard.id}>
									<td width="100%">
										<Row
											id={creditCard.id}
											banks={this.state.banks}
											name={creditCard.name}
											number={creditCard.cardNumber}
											selectedBank={{
												value: creditCard.bank.id,
												label: creditCard.bank.name,
											}}
											confirmChange={(name, number, selectedBank) => this.saveChange(creditCard.id, name, number, selectedBank)}
											confirmDelete={this.delete}
										>
											{creditCard.name}
										</Row>
									</td>
								</tr>
							))}
							<tr>
								<td width="100%">
									<Row name="" number="" selectedBank={null} banks={this.state.banks} confirmChange={this.addNew} confirmText="เพิ่ม">
										เพิ่มบัญชี
									</Row>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
