const CUSTOMER_CHANNELS = [
	{
		value: 'LINE',
		label: 'Line',
	},
	{
		value: 'FACEBOOK',
		label: 'Facebook',
	},
	{
		value: 'GOOGLE_ADS',
		label: 'Google Ads',
	},
	{
		value: 'RETURN_CUSTOMER',
		label: 'ลูกค้าเก่า',
	},
	{
		value: 'FROM_BOOTH',
		label: 'ลูกค้าจากบูธ',
	},
	{
		value: 'RECOMMENDATION',
		label: 'เพือนแนะนํา',
	},
	{
		value: 'RECOMMENDATION_JLENG',
		label: 'แนะนําจากเจ๊เล้ง',
	},
	{
		value: 'RECOMMENDATION_PPOP',
		label: 'แนะนําจกพีป๊อบ',
	},
	{
		value: 'ETC',
		label: 'อื่นๆ',
	},
];

export default CUSTOMER_CHANNELS;
