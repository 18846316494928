import React, { Component } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import PROGRAM_STATUS from '../../../StaticData/ProgramStatus';

import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import LoadingCenter from '../../../Components/LoadingCenter';

import Status from './Status';

export default class SummaryStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			status: {
				value: props.program.status,
				label: props.program.status,
			},
		};
	}

	handleStatusChange = async value => {
		const previousStatus = this.state.status;
		this.setState({
			status: value,
		});

		let updatedProgram = await this.updateStatus('STATUS', value.value);
		if (!updatedProgram) {
			this.setState({
				status: previousStatus,
			});
		} else {
			this.props.onProgramUpdated(updatedProgram);
		}
	};

	onPassportClick = async () => {
		const previousStatus = this.state.statusPassport;
		this.setState({
			statusPassport: !previousStatus,
		});

		let updatedProgram = await this.updateStatus('PASSPORT', !previousStatus);
		if (!updatedProgram) {
			this.setState({
				status: previousStatus,
			});
		} else {
			this.props.onProgramUpdated(updatedProgram);
		}
	};

	onDepositClick = async () => {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			const previousStatus = this.state.statusDeposit;
			this.setState({
				statusDeposit: !previousStatus,
			});

			let updatedProgram = await this.updateStatus('DEPOSIT', !previousStatus);
			if (!updatedProgram) {
				this.setState({
					status: previousStatus,
				});
			} else {
				this.props.onProgramUpdated(updatedProgram);
			}
		} else {
			toast.warn('ไม่อนุญาตให้ Sale แก้ไขสถานะทางการเงิน');
		}
	};

	onFullClick = async () => {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			const previousStatus = this.state.statusFull;
			this.setState({
				statusFull: !previousStatus,
			});

			let updatedProgram = await this.updateStatus('FULL', !previousStatus);
			if (!updatedProgram) {
				this.setState({
					status: previousStatus,
				});
			} else {
				this.props.onProgramUpdated(updatedProgram);
			}
		} else {
			toast.warn('ไม่อนุญาตให้ Sale แก้ไขสถานะทางการเงิน');
		}
	};

	updateStatus = async (type, status) => {
		const { program } = this.props;
		this.setState({
			isLoading: true,
		});

		const request = await APIPut('jointour/programs/' + program.id + '/status', {
			type,
			status,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const updatedProgram = request.data;
			this.setState({
				isLoading: false,
			});

			toast.success('แก้ไขสถานะเรียบร้อยแล้ว');
			return updatedProgram;
		} else {
			this.setState({
				isLoading: false,
			});

			toast.error('เกิดปัญหาในการแก้ไขสถานะ');
			return null;
		}
	};

	render() {
		const { program } = this.props;
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}
		return (
			<div className="row">
				<div className="row" style={{ textAlign: 'right' }}>
					<label className="label">สถานะ</label>
					<Select
						name="status"
						// isDisabled={!AuthHelper.hasRight('Finance', 'Admin')}
						value={this.state.status}
						clearable={false}
						onChange={this.handleStatusChange}
						options={PROGRAM_STATUS}
					/>
				</div>
				<div className="columns row">
					<div className="column is-4">
						<Status title="Passport" status={program.statusPassport} onClick={this.onPassportClick} />
					</div>
					<div className="column is-4">
						<Status title="มัดจำ" status={program.statusDeposit} onClick={this.onDepositClick} />
					</div>
					<div className="column is-4">
						<Status title="ชำระครบ" status={program.statusFull} onClick={this.onFullClick} />
					</div>
				</div>
			</div>
		);
	}
}
