export default (state, toast) => {
	let result = true;

	if (state.paymentMethod.value === 'Transfer') {
		const bankAccount = state.bankAccount && state.bankAccount.value;
		if (!bankAccount && toast) {
			toast.warn('กรุณาระบุเลขที่บัญชี');
		}

		const paymentTime = state.paymentTime.trim() !== '';
		if (!paymentTime && toast) {
			toast.warn('กรุณาระบุเวลาโอน');
		}

		result = bankAccount && paymentTime;
	} else if (state.paymentMethod.value === 'Check') {
		const bank = state.bank && state.bank.value;
		if (!bank && toast) {
			toast.warn('กรุณาระบุธนาคาร');
		}

		const checkNumber = state.checkNumber.trim() !== '';
		if (!checkNumber && toast) {
			toast.warn('กรุณาระบุหมายเลขเช็ค');
		}

		result = bank && checkNumber;
	} else if (state.paymentMethod.value === 'Credit Card') {
		const creditCard = state.creditCard && state.creditCard.value;
		if (!creditCard && toast) {
			toast.warn('กรุณาระบุบัตรเครดิต');
		}

		result = creditCard;
	}

	return result;
};
