import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import IncentiveList from '../Pages/Incentive/List';
import AddEditIncentiveProgram from '../Pages/Incentive/AddEditProgram';
import IncentiveProgramDetail from '../Pages/Incentive/ProgramDetail';
import IncentiveIncome from '../Pages/Incentive/Income';
import AddEditIncentiveIncome from '../Pages/Incentive/AddEditIncome';
import AddEditIncentiveIncomeTransaction from '../Pages/Incentive/AddEditIncomeTransaction';
import IncentiveIncomeEtc from '../Pages/Incentive/IncomeEtc';
import AddEditIncentiveIncomeEtc from '../Pages/Incentive/AddEditIncomeEtc';
import IncentiveExpense from '../Pages/Incentive/Expense';
import IncentiveExpenseDetail from '../Pages/Incentive/ExpenseDetail';
import AddEditExpense from '../Pages/Incentive/AddEditExpense';
import ExchangeBudget from '../Pages/Incentive/ExchangeBudget';
import IncentiveProgramRestaurant from '../Pages/Incentive/ProgramRestaurant';
import ManageRestaurants from '../Pages/Incentive/ManageRestaurants';

import AppConfig from '../AppConfig';

export default class IncentiveRoutes extends Component {
	render() {
		return (
			<span>
				<Route exact path={AppConfig.appPath + '/programs/incentive'} component={IncentiveList} />
				<Route exact path={AppConfig.appPath + '/programs/newincentive'} component={AddEditIncentiveProgram} />
				<Route exact path={AppConfig.appPath + '/data/companies/:companyId/programs'} component={IncentiveList} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId'} component={IncentiveProgramDetail} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/edit'} component={AddEditIncentiveProgram} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/newincome'} component={AddEditIncentiveIncome} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/newincomeetc'} component={AddEditIncentiveIncomeEtc} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/incomes/:incomeId'} component={IncentiveIncome} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/incomes/:incomeId/edit'} component={AddEditIncentiveIncome} />
				<Route
					exact
					path={AppConfig.appPath + '/programs/incentive/:programId/incomes/:incomeId/newtransaction'}
					component={AddEditIncentiveIncomeTransaction}
				/>
				<Route
					exact
					path={AppConfig.appPath + '/programs/incentive/:programId/incomes/:incomeId/transactions/:transactionId'}
					component={AddEditIncentiveIncomeTransaction}
				/>
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/incomeEtcs/:incomeId'} component={IncentiveIncomeEtc} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/incomeEtcs/:incomeId/edit'} component={AddEditIncentiveIncomeEtc} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/expenses/:expenseCategoryId'} component={IncentiveExpense} />
				<Route
					exact
					path={AppConfig.appPath + '/programs/incentive/:programId/expenses/:expenseCategoryId/:expenseId'}
					component={IncentiveExpenseDetail}
				/>
				<Route
					exact
					path={AppConfig.appPath + '/programs/incentive/:programId/expenses/:expenseCategoryId/:expenseId/edit'}
					component={AddEditExpense}
				/>
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/newexpense'} component={AddEditExpense} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/newexpense/:expenseCategoryId'} component={AddEditExpense} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/exchangeBudget/:budgetId'} component={ExchangeBudget} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/restaurants/:id/:bookingId'} component={IncentiveProgramRestaurant} />
				<Route exact path={AppConfig.appPath + '/programs/incentive/:programId/managerestaurants'} component={ManageRestaurants} />
			</span>
		);
	}
}
