import React, { Component } from 'react';

import NumberString from './NumberString';

export default class NumberColor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	componentDidCatch() {
		this.setState({
			hasError: true,
		});
	}

	render() {
		if (this.state.hasError) {
			return null;
		}

		if (this.props.number !== 0 && !this.props.number) {
			return null;
		}

		let valueStyle = {};
		if (this.props.style) {
			valueStyle = {
				...this.props.style,
			};
		}

		if (this.props.number >= 0) {
			valueStyle.color = '#21C65B';
		} else {
			valueStyle.color = '#ff3860';
		}

		if (this.props.isExpense) {
			if (this.props.number > 0) {
				valueStyle.color = '#ff3860';
			} else {
				valueStyle.color = '#21C65B';
			}
		}

		return (
			<span style={valueStyle} className={this.props.className}>
				<NumberString number={this.props.number} />
			</span>
		);
	}
}
