import React, { PureComponent } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import DeleteButton from '../../../Components/DeleteButton';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

export default class JointourWholesaleContactDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			name: '',
			email: '',
			lineId: '',
			telephone: '',
			note: '',
			wholesaleId: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initCreate = async () => {
		const { wholesaleId } = this.props.match.params;
		let requestWholesale = APIGet('jointour/wholesales/' + wholesaleId);
		requestWholesale = await requestWholesale;
		if (APIHelper.handleAPIResponse(this, requestWholesale)) {
			const wholesale = requestWholesale.data;
			console.log('wholesale: ', wholesale);
			this.setState({
				wholesale,
			});

			return true;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
			return false;
		}
	};

	initUpdate = async () => {
		const { wholesaleId, contactId } = this.props.match.params;
		let requestContact = APIGet(`jointour/wholesales/${wholesaleId}/contacts/${contactId}`);
		requestContact = await requestContact;
		if (APIHelper.handleAPIResponse(this, requestContact)) {
			const contact = requestContact.data;
			console.log('contact: ', contact);
			this.setState({
				...contact,
			});

			return true;
		} else if (requestContact.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { wholesaleId, contactId } = this.props.match.params;
		if (wholesaleId && contactId) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		const { wholesaleId, contactId } = this.props.match.params;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				...this.state,
			};

			let request = null;
			if (this.isUpdate()) {
				request = await APIPut(`jointour/wholesales/${wholesaleId}/contacts/${contactId}`, data);
			} else {
				request = await APIPost(`jointour/wholesales/${wholesaleId}/contacts`, data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขเรียบร้อยแล้ว' : 'เพิ่มเรียบร้อยแล้ว');
				const result = request.data;

				this.props.history.push(`${AppConfig.appPath}/data/jointour/wholesales/${wholesaleId}/contacts`);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มผู้ติดต่อ');
			}

			this.setState({
				isLoading: false,
			});
		}
	};

	validateSaveState = () => {
		const name = this.state.name.trim() !== '';
		if (!name) {
			toast.warn('กรุณาระบุชื่อ');
		}

		const telephone = this.state.telephone.trim() !== '';
		if (!telephone) {
			toast.warn('กรุณาระบุเบอร์โทรศัพท์');
		}

		const email = this.state.email.trim() !== '';
		if (!email) {
			toast.warn('กรุณาระบุ email');
		}

		return name && telephone && email;
	};

	delete = async () => {
		const { wholesaleId, contactId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete(`jointour/wholesales/${wholesaleId}/contacts/${contactId}`);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.push(`${AppConfig.appPath}/data/jointour/wholesales/${wholesaleId}/contacts`);
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { wholesaleId, contactId } = this.props.match.params;
		const { wholesale } = this.state;

		return (
			<div className="container">
				<BackButtonRouter link={`${AppConfig.appPath}/data/jointour/wholesales/${wholesaleId}/contacts`} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h1 className="title is-2">{wholesale.name}</h1>
							<h3 className="title is-3">{this.isUpdate() ? 'แก้ไขผู้ติดต่อ' : 'เพิ่มผู้ติดต่อ'}</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<InputRow
								title="ชื่อ"
								labelSize="s"
								placeholder="ชื่อ"
								keyProps="name"
								onInputChange={this.onTextChange}
								value={this.state.name}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="email"
								labelSize="s"
								placeholder="email"
								keyProps="email"
								onInputChange={this.onTextChange}
								value={this.state.email}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="lineId"
								labelSize="s"
								placeholder="lineId"
								keyProps="lineId"
								onInputChange={this.onTextChange}
								value={this.state.lineId}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-4">
							<InputRow
								title="telephone"
								labelSize="s"
								placeholder="telephone"
								keyProps="telephone"
								onInputChange={this.onTextChange}
								value={this.state.telephone}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-12">
							<TextArea
								title="Address"
								labelSize="s"
								keyProps="note"
								value={this.state.note}
								onInputChange={this.onTextChange}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-6">
							{this.isUpdate() ? <DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} /> : null}
						</div>
						<div className="column is-6" style={{ textAlign: 'right', marginTop: 20 }}>
							<button className="button is-success" onClick={this.save}>
								บันทึก
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
