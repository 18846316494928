import React from 'react';

import NumberColor from '../../../Components/NumberColor';
export default class SummaryPricingBox extends React.Component {
	render() {
		const { fit } = this.props;
		const cost = fit.price + fit.expense;
		return (
			<div className="columns">
				<div className="column is-4">
					<div className="box" style={{ textAlign: 'right' }}>
						<span style={{ fontSize: 14 }}>ราคาขาย</span>
						<p className="title is-4">
							<NumberColor number={fit.salePrice + fit.creditCharge} isExpense={false} />
						</p>
						บาท
					</div>
					{fit.creditCharge > 0 ? (
						<div style={{ fontSize: 12, marginTop: -20 }}>
							(รวม credit charge: <NumberColor number={fit.creditCharge} /> THB)
						</div>
					) : null}
				</div>

				<div className="column is-4">
					<div className="box" style={{ textAlign: 'right' }}>
						ต้นทุน + ส่วนลด
						<p className="title is-4">
							<NumberColor number={cost} isExpense={true} />
						</p>
						บาท
					</div>
				</div>

				<div className="column is-4">
					<div className="box" style={{ textAlign: 'right' }}>
						กำไร
						<p className="title is-4">
							<NumberColor number={fit.salePrice - cost} isExpense={false} />
						</p>
						บาท
					</div>
				</div>
			</div>
		);
	}
}
