import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../API/APIGet';
import APIHelper from '../../Helper/APIHelper';

import LoadingCenter from '../LoadingCenter';

import AppConfig from '../../AppConfig';

export default class RestaurantInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			restaurant: null,
		};
	}
	async componentDidMount() {
		const request = await APIGet('restaurants/' + this.props.id);
		if (APIHelper.handleAPIResponse(this, request)) {
			const restaurant = request.data;
			// console.log('restaurant: ', restaurant);
			this.setState({
				isLoading: false,
				restaurant,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { restaurant } = this.state;
		if (!restaurant) {
			return <div>No Data</div>;
		}

		return (
			<>
				<h3 className="title is-4">ข้อมูลร้าน</h3>
				<Link className="button is-info" to={AppConfig.appPath + '/data/incentive/restaurants/' + restaurant.id + '/edit'}>
					แก้ไขข้อมูล
				</Link>
				<table className="table is-hoverable is-fullwidth">
					<tbody>
						<tr>
							<td width="30%">ประเทศ</td>
							<td>{restaurant.country ? restaurant.country.name : ''}</td>
						</tr>
						<tr>
							<td>เมือง</td>
							<td>{restaurant.city}</td>
						</tr>
						<tr>
							<td>ที่อยู่</td>
							<td>{restaurant.address}</td>
						</tr>
						<tr>
							<td>ประเภท</td>
							<td>{restaurant.type}</td>
						</tr>
						<tr>
							<td>Contact Person</td>
							<td>{restaurant.contact}</td>
						</tr>
						<tr>
							<td>Course</td>
							<td>
								<div>{restaurant.course}</div>
							</td>
						</tr>
						<tr>
							<td>Website</td>
							<td>
								<a href={restaurant.website}>{restaurant.website}</a>
							</td>
						</tr>
						<tr>
							<td>Price (ต่อคน)</td>
							<td>{restaurant.pricePerHead}</td>
						</tr>
						<tr>
							<td>Remark</td>
							<td>{restaurant.remark}</td>
						</tr>
					</tbody>
				</table>
			</>
		);
	}
}
