import React from 'react';

import Stars from './Stars';
import TextArea from '../TextArea';

export default class RestaurantNewComment extends React.Component {
	render() {
		const { comment, course, star } = this.props;

		return (
			<>
				<div>
					<TextArea
						title="เพิ่มข้อมูลการรีวิวที่นี่"
						labelSize="s"
						keyProps="comment"
						value={comment}
						onInputChange={this.props.onTextChange}
						isMandatory={false}
					/>
				</div>
				<div className="row">
					<TextArea
						title="Course ของทริปนี้"
						labelSize="s"
						keyProps="course"
						value={course}
						onInputChange={this.props.onTextChange}
						isMandatory={false}
					/>
				</div>
				<div className="columns row">
					<div className="column is-9">
						<Stars active={star} onClick={this.props.onCommentStarClick} />
					</div>
					<div className="column is-3" style={{ textAlign: 'right' }}>
						<button className="button is-success" onClick={this.props.onAddComment}>
							Add
						</button>
					</div>
				</div>
			</>
		);
	}
}
