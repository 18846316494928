import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';

import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

export default class EditProfile extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			newPassword: '',
			newPasswordAgain: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.setState({
				isLoading: false,
			});
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	save = async () => {
		const staffId = AuthHelper.getUserDetail().id;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				newPassword: this.state.newPassword,
			};

			const request = await APIPut('profile/' + staffId + '/password', data);

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success('แก้ไขเรียบร้อยแล้ว');
				const result = request.data;
			} else {
				toast.error('โปรดตรวจสอบ Password ให้ถูกต้อง');
			}

			this.setState({
				isLoading: false,
			});
		}
	};

	validateSaveState = () => {
		const newPassword = this.state.newPassword.trim() !== '';
		if (!newPassword) {
			toast.warn('กรุณากรอก Password ใหม่');
		}

		const newPasswordAgain = this.state.newPasswordAgain.trim() !== '';
		if (!newPasswordAgain) {
			toast.warn('กรุณากรอก Password ใหม่อีกครั้ง');
		}

		const matchPassword = this.state.newPassword === this.state.newPasswordAgain;
		if (!matchPassword) {
			toast.warn('กรุณากรอก Password ใหม่ให้ตรงกัน');
		}

		return newPassword && newPasswordAgain && matchPassword;
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">เปลี่ยน Password</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-6">
							<InputRow
								type="password"
								title="Password ใหม่"
								labelSize="s"
								placeholder="Password ใหม่"
								keyProps="newPassword"
								onInputChange={this.onTextChange}
								value={this.state.newPassword}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns">
						<div className="column is-6">
							<InputRow
								type="password"
								title="Password ใหม่ (อีกครั้ง)"
								labelSize="s"
								placeholder="Password ใหม่ (อีกครั้ง)"
								keyProps="newPasswordAgain"
								onInputChange={this.onTextChange}
								value={this.state.newPasswordAgain}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-6" />
						<div className="column is-6" style={{ textAlign: 'right', marginTop: 20 }}>
							<button className="button is-success" onClick={this.save}>
								บันทึก
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
