import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

export default class IncomeTransaction extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			transactions: [],
		};
	}

	async componentDidMount() {
		const { programId, incomeId } = this.props;
		let requestIncomeTransaction = APIGet('incentive/programs/' + programId + '/incomes/' + incomeId + '/transactions');
		requestIncomeTransaction = await requestIncomeTransaction;
		if (APIHelper.handleAPIResponse(this, requestIncomeTransaction)) {
			const transactions = requestIncomeTransaction.data;
			console.log('transactions: ', transactions);
			this.setState({
				isLoading: false,
				transactions,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูล Transaction ได้ กรุณาลองใหม่อีกครั้ง');
		}
	}

	summaryTotal = () => {
		let total = 0;
		this.state.transactions.forEach(t => (total += t.total * t.currencyRate));
		return total;
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { transactions } = this.state;
		const { program } = this.props;

		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="subtitle is-4">
							รายการเก็บเงิน
							<span> </span>
							{AdminLockRender(
								program.status,
								AuthHelper.hasRight('Finance', 'Admin') ? (
									<Link
										className="button is-info"
										to={
											AppConfig.appPath +
											'/programs/incentive/' +
											this.props.programId +
											'/incomes/' +
											this.props.incomeId +
											'/newtransaction'
										}
									>
										เพิ่ม
									</Link>
								) : null,
								AuthHelper.hasRight('Finance', 'Admin') ? (
									<button className="button is-info" disabled>
										เพิ่ม
									</button>
								) : null
							)}
						</h3>
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="20%">วันที่</td>
								<td width="40%">รายการ</td>
								<td width="40">ยอดเงิน (THB)</td>
							</tr>
						</thead>
						<tbody>
							{transactions.map(transaction => (
								<tr key={'transaction' + transaction.id}>
									<td>
										<DateLabel date={transaction.paymentDate} />
									</td>
									<td>
										<Link
											to={
												AppConfig.appPath +
												'/programs/incentive/' +
												this.props.programId +
												'/incomes/' +
												this.props.incomeId +
												'/transactions/' +
												transaction.id
											}
										>
											{transaction.title} {!transaction.financeStaff ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}
										</Link>
									</td>
									<td className="number-cell">
										<NumberColor number={transaction.total * transaction.currencyRate} />
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan="2">Summary</td>
								<td className="number-cell">
									<NumberColor number={this.summaryTotal()} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
