import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import ThingsToSelect from '../../../Helper/ThingsToSelect';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import Row from './Row';

export default class BankAccounts extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			banks: [],
			bankAccounts: [],
			isEditLoading: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let requestBankAccounts = APIGet('bankAccounts');
			let requestBanks = APIGet('banks');
			requestBankAccounts = await requestBankAccounts;
			requestBanks = await requestBanks;
			if (APIHelper.handleAPIResponse(this, requestBankAccounts, requestBanks)) {
				const bankAccounts = requestBankAccounts.data;
				const banks = ThingsToSelect(requestBanks.data);
				console.log('bankAccounts: ', bankAccounts);
				console.log('banks: ', banks);
				this.setState({
					isLoading: false,
					bankAccounts,
					banks,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	saveChange = async (bankAccountId, name, number, selectedBank) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPut('bankAccounts/' + bankAccountId, {
			name: name,
			accountNumber: number,
			bankId: selectedBank.value,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const bankAccount = request.data;
			let bankAccounts = JSON.parse(JSON.stringify(this.state.bankAccounts));
			for (let i = 0; i < bankAccounts.length; i++) {
				if (bankAccounts[i].id === bankAccountId) {
					bankAccounts[i] = bankAccount;
				}
			}

			// console.log(bankAccounts);
			toast.success('แก้ไขข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				bankAccounts,
			});
		} else {
			toast.error('ไม่สามารถแก้ไขข้อมูลได้');
		}
	};

	addNew = async (name, number, selectedBank) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPost('bankAccounts', {
			name: name,
			accountNumber: number,
			bankId: selectedBank.value,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const bankAccount = request.data;
			let bankAccounts = JSON.parse(JSON.stringify(this.state.bankAccounts));
			bankAccounts.push(bankAccount);

			// console.log(bankAccounts);
			toast.success('เพิ่มข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				bankAccounts,
			});
		} else {
			toast.error('ไม่สามารถเพิ่มข้อมูลได้');
		}
	};

	delete = async bankAccountId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete('bankAccounts/' + bankAccountId);
		if (APIHelper.handleAPIResponse(this, request)) {
			const bankAccounts = this.state.bankAccounts.filter(bankAccount => bankAccount.id !== bankAccountId);

			// console.log(bankAccounts);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				bankAccounts,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Bank Accounts</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.bankAccounts.map(bankAccount => (
								<tr key={bankAccount.id}>
									<td width="100%">
										<Row
											id={bankAccount.id}
											banks={this.state.banks}
											name={bankAccount.name}
											number={bankAccount.accountNumber}
											selectedBank={{
												value: bankAccount.bank.id,
												label: bankAccount.bank.name,
											}}
											confirmChange={(name, number, selectedBank) => this.saveChange(bankAccount.id, name, number, selectedBank)}
											confirmDelete={this.delete}
										>
											{bankAccount.name}
										</Row>
									</td>
								</tr>
							))}
							<tr>
								<td width="100%">
									<Row name="" number="" selectedBank={null} banks={this.state.banks} confirmChange={this.addNew} confirmText="เพิ่ม">
										เพิ่มบัญชี
									</Row>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
