import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import CompanyExpenseCategories from '../Pages/Finance/CompanyExpenseCategories';
import CompanyExpenseSuppliers from '../Pages/Finance/CompanyExpenseSuppliers';
import CompanyExpenseSupplierDetail from '../Pages/Finance/CompanyExpenseSupplierDetail';
import CompanyExpenses from '../Pages/Finance/CompanyExpenses';
import CompanyExpenseDaily from '../Pages/Finance/CompanyExpenseDaily';
import AddEditCompanyExpense from '../Pages/Finance/AddEditCompanyExpense';
import MonthlySummary from '../Pages/Finance/MonthlySummary';
import CommissionUser from '../Pages/Finance/CommissionUser';
import CommissionDetail from '../Pages/Finance/CommissionDetail';
import Salary from '../Pages/Finance/Salary';

import AppConfig from '../AppConfig';

export default class FinanceRoutes extends Component {
	render() {
		return (
			<span>
				<Route exact path={AppConfig.appPath + '/finance/companyexpensecategories'} component={CompanyExpenseCategories} />
				<Route exact path={AppConfig.appPath + '/finance/companyexpensecategories/:categoryId/suppliers'} component={CompanyExpenseSuppliers} />
				<Route exact path={AppConfig.appPath + '/finance/companyexpensecategories/:categoryId/newsupplier'} component={CompanyExpenseSupplierDetail} />
				<Route
					exact
					path={AppConfig.appPath + '/finance/companyexpensecategories/:categoryId/suppliers/:supplierId'}
					component={CompanyExpenseSupplierDetail}
				/>
				<Route exact path={AppConfig.appPath + '/finance/companyexpenses'} component={CompanyExpenses} />
				<Route exact path={AppConfig.appPath + '/finance/newcompanyexpense'} component={AddEditCompanyExpense} />
				<Route exact path={AppConfig.appPath + '/finance/companyexpenses/:expenseId'} component={AddEditCompanyExpense} />
				<Route exact path={AppConfig.appPath + '/finance/companyExpensesDaily'} component={CompanyExpenseDaily} />
				<Route exact path={AppConfig.appPath + '/finance/monthlysummary'} component={MonthlySummary} />
				<Route exact path={AppConfig.appPath + '/finance/salary'} component={Salary} />
				<Route exact path={AppConfig.appPath + '/finance/commission'} component={CommissionUser} />
				<Route exact path={AppConfig.appPath + '/finance/commission/:staffId'} component={CommissionDetail} />
			</span>
		);
	}
}
