import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import AppConfig from '../../../AppConfig';

export default class Suppliers extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			suppliers: [],
			category: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { categoryId } = this.props.match.params;
			let requestCategory = await APIGet(`incentiveExpenseCategories/${categoryId}`);
			let requestSuppliers = await APIGet(`incentiveExpenseCategories/${categoryId}/suppliers`);
			requestCategory = await requestCategory;
			requestSuppliers = await requestSuppliers;
			if (APIHelper.handleAPIResponse(this, requestCategory, requestSuppliers)) {
				const suppliers = requestSuppliers.data;
				const category = requestCategory.data;
				console.log('suppliers: ', suppliers);
				console.log('category: ', category);
				this.setState({
					isLoading: false,
					suppliers,
					category,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { category, suppliers } = this.state;
		const { categoryId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={`${AppConfig.appPath}/data/expenseCategories`} />
				<div className="columns">
					<div className="column is-6">
						<h1 className="subtitle is-4">Expense Suppliers:</h1>
						<h1 className="title is-2">{category.name}</h1>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/expenseCategories/' + categoryId + '/newsupplier'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{suppliers.map(supplier => (
								<tr key={supplier.id}>
									<td width="70%">
										<Link to={AppConfig.appPath + '/data/expenseCategories/' + categoryId + '/suppliers/' + supplier.id}>
											{supplier.name}
										</Link>
									</td>
									<td width="30%" style={{ textAlign: 'right' }} />
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
