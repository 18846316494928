import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Stars extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isHalfClicked: false,
			latestClickNumber: 0,
		};
	}

	isToRenderHalf(index, active) {
		const result = index < active && index > active - 1;
		// console.log(index, active, result);
		return result;
	}

	getStar = (index, active) => {
		let star = <FontAwesomeIcon icon="star" style={{ color: index < active ? 'orange' : '#eeeeee' }} />;
		if (this.isToRenderHalf(index, active)) {
			star = <FontAwesomeIcon icon="star-half-alt" style={{ color: 'orange' }} />;
		}

		return star;
	};

	onClick = index => {
		let latestClickNumber = this.state.latestClickNumber;
		if (latestClickNumber === index - 0.5) {
			latestClickNumber = index;
		} else {
			latestClickNumber = index - 0.5;
		}

		// console.log(index, latestClickNumber);

		this.props.onClick(latestClickNumber);
		this.setState({
			latestClickNumber,
		});
	};

	render() {
		const { active, total } = this.props;
		let stars = [];
		let totalStars = total;
		if (!totalStars) {
			totalStars = 5;
		}
		for (let i = 0; i < totalStars; i++) {
			const star = this.getStar(i, active);
			if (this.props.onClick) {
				stars.push(
					<span className="clickable" onClick={() => this.onClick(i)} key={'star' + i}>
						{star}
					</span>
				);
			} else {
				stars.push(<span key={'star' + i}>{star}</span>);
			}
		}

		return stars;
	}
}
