import React, { useMemo } from 'react';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import ThingsToSelect from '../../../../Helper/ThingsToSelect';
import ADULT_CHILD from '../../../../StaticData/AdultChild';
import { SumTable } from './SumTable';
import { DEFAULT_TICKET } from '../../../../StaticData/SubPlanTemplate';
import { Remark } from './Remark';

export const TicketTabs = ({ selectedSubPlan, state, onChange, onRemarkChange }) => {
	const { isEditing, selectedTab, exchangeRates, airlines } = state;

	if (selectedTab !== 'ticket') {
		return <></>;
	}

	if (!selectedSubPlan) return <></>;

	const { tickets } = selectedSubPlan;

	if (!tickets) {
		return <></>;
	}

	return (
		<div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 50, display: selectedTab === 'ticket' ? 'block' : 'none' }}>
			<Remark state={state} onChange={onRemarkChange} />
			<p style={{ backgroundColor: '#209CEE', fontSize: 25, fontWeight: 'bold', color: 'white', padding: 5, paddingLeft: 10 }}>ตั๋ว</p>
			<div style={{ height: '30px' }} />
			{tickets && tickets.length > 0
				? tickets.map((ticket, index) => (
						<TicketRow
							key={`ticket-${index}`}
							airlines={airlines}
							exchangeRates={exchangeRates}
							onChange={(index, newTicket) => {
								onChange([...tickets.slice(0, index), newTicket, ...tickets.slice(index + 1, tickets.length)]);
							}}
							onRemove={index => {
								onChange([...tickets.slice(0, index), ...tickets.slice(index + 1, tickets.length)]);
							}}
							index={index}
							ticket={ticket}
							isEditing={isEditing}
						/>
				  ))
				: null}

			<div style={{ height: '50px' }} />
			{isEditing ? (
				<button
					className="button is-info"
					onClick={e => {
						e.preventDefault();
						onChange([...(tickets ? tickets : []), DEFAULT_TICKET]);
					}}
				>
					+เพิ่มตั๋ว
				</button>
			) : null}

			<div />
		</div>
	);
};

const TicketRow = ({ ticket, index, airlines, exchangeRates, isEditing, onChange, onRemove }) => {
	const _onChange = (key, value) => {
		const updatedData = JSON.parse(JSON.stringify(ticket));
		updatedData[key] = value;
		onChange(index, updatedData);
	};

	const TICKET_TYPES = [{ value: 'inter', label: 'International' }, { value: 'domestic', label: 'Domestic' }];

	const selectedTicketType = useMemo(() => {
		if (ticket.name === 'inter') {
			return TICKET_TYPES[0];
		} else if (ticket.name === 'domestic') {
			return TICKET_TYPES[1];
		}

		return ticket.name;
	}, [ticket.name]);

	const selectedAirline = useMemo(() => {
		if (ticket.airline && !ticket.airline.label) {
			return {
				value: ticket.airline.id,
				label: ticket.airline.name,
			};
		}

		return ticket.airline;
	}, [ticket.airline]);

	const selectedCurrency = useMemo(() => {
		if (ticket.currency && !ticket.currency.label) {
			return {
				value: ticket.currency.id,
				label: ticket.currency.name,
			};
		}

		return ticket.currency;
	}, [ticket.currency]);

	const selectedCustomerType = useMemo(() => {
		if (ticket.customerType && !ticket.customerType.label) {
			if (ticket.customerType === 'adult') {
				return ADULT_CHILD[0];
			} else if (ticket.customerType === 'child') {
				return ADULT_CHILD[1];
			}
		}

		return ticket.customerType;
	}, [ticket.customerType]);

	return (
		<div style={{ marginBottom: 30 }}>
			<div className="columns" style={{ justifyContent: 'center' }}>
				<div className="column is-4">
					<div className="level level-item">
						<p style={{ paddingRight: 20 }}>{index + 1}.</p>
						<div className="" style={{ width: '100%' }}>
							<Select
								placeholder="ชนิดตั๋ว"
								options={TICKET_TYPES}
								value={selectedTicketType}
								onChange={type => {
									_onChange('name', type.value);
								}}
								isDisabled={!isEditing}
							/>
						</div>
					</div>
				</div>
				<div className="column is-2">
					<Select
						placeholder="สายการบิน"
						value={selectedAirline}
						onChange={airline => {
							_onChange('airline', airline);
						}}
						options={ThingsToSelect(airlines)}
						isDisabled={!isEditing}
					/>
				</div>
				<div className="column is-2">
					<Select
						placeholder="สกุลเงิน"
						value={selectedCurrency}
						onChange={currency => {
							_onChange('currency', {
								id: currency.value,
								name: currency.label,
							});
						}}
						options={ThingsToSelect(exchangeRates)}
						isDisabled={!isEditing}
					/>
				</div>
				<div className="column is-2">
					<Select
						placeholder="ผู้ใหญ่/เด็ก"
						value={selectedCustomerType}
						onChange={customerType => {
							_onChange('customerType', customerType.value);
						}}
						options={ADULT_CHILD}
						isDisabled={!isEditing}
					/>
				</div>
				<div className="column is-1" style={{ display: 'flex', alignItems: 'center' }}>
					<FontAwesomeIcon
						icon={faTrashCan}
						style={{ color: isEditing ? 'red' : 'black', cursor: isEditing ? 'pointer' : 'auto' }}
						onClick={e => {
							e.preventDefault();
							if (isEditing) {
								onRemove(index);
							}
						}}
					/>
				</div>
			</div>
			<div className="" style={{ paddingLeft: 70, paddingRight: 50 }}>
				<table className="" style={{ width: '100%' }}>
					<tbody>
						{ticket.expenses && ticket.expenses.length > 0
							? ticket.expenses.map((detail, detailIndex) => (
									<tr key={`ticket-${index}-detail-${detailIndex}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
										<td style={{ width: '3%' }}>•</td>
										<td style={{ width: '35%' }}>{detail.name}</td>
										<td style={{ width: '20%' }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'number'}
												value={detail.price}
												onChange={text => {
													const updatedTicket = JSON.parse(JSON.stringify(ticket));
													updatedTicket.expenses[detailIndex] = {
														...detail,
														price: text,
													};

													onChange(index, updatedTicket);
												}}
											>
												<p style={{ textAlign: 'center' }}>{detail.price.toLocaleString()}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '1%', paddingLeft: 10, paddingRight: 15 }}>x</td>
										<td style={{ width: '12%' }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'number'}
												value={detail.quantity}
												onChange={text => {
													const updatedTicket = JSON.parse(JSON.stringify(ticket));
													updatedTicket.expenses[detailIndex] = {
														...detail,
														quantity: text,
													};

													onChange(index, updatedTicket);
												}}
											>
												<p style={{ textAlign: 'center' }}>{detail.quantity.toLocaleString()}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '15%', display: 'flex', paddingLeft: 10 }}>
											<p style={{ paddingRight: 10 }}>=</p>
											<p>{(detail.price * detail.quantity).toLocaleString()}</p>
										</td>
									</tr>
							  ))
							: null}
						<tr style={{ display: 'flex', alignItems: 'center', marginTop: isEditing ? 20 : 0, marginBottom: 15 }}>
							<td style={{ width: '3%' }}>•</td>
							<td style={{ width: '35%' }}>รวม</td>
							<td style={{ width: '20%' }} />
							<td style={{ width: '1%', paddingLeft: 10, paddingRight: 15 }} />
							<td style={{ width: '12%' }} />
							<td style={{ width: '15%', display: 'flex', paddingLeft: 10 }}>
								<p style={{ paddingRight: 10 }}>=</p>
								<p>
									{ticket.expenses && ticket.expenses.length > 0
										? ticket.expenses.reduce((acc, detail) => acc + detail.price * detail.quantity, 0).toLocaleString()
										: 0}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
