import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

export default class IncomeTransaction extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			transactions: [],
		};
	}

	async componentDidMount() {
		const { program, type } = this.props;
		let requestTransactions = await APIGet('jointour/programs/' + program.id + '/incomes/' + type + '/transactions');
		if (APIHelper.handleAPIResponse(this, requestTransactions)) {
			const transactions = requestTransactions.data;
			console.log('transactions: ', transactions);
			this.setState({
				isLoading: false,
				transactions,
			});

			return true;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	}

	summaryTotal = () => {
		let total = 0;
		this.state.transactions.forEach(t => (total += t.total));
		return total;
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { transactions } = this.state;
		const { program, type } = this.props;

		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="subtitle is-4">
							รายการเก็บเงิน
							<span> </span>
							{AuthHelper.hasRight('Finance', 'Admin') ? (
								<Link
									className="button is-info"
									to={AppConfig.appPath + '/programs/jointour/' + program.id + '/incomes/' + type + '/newtransaction'}
								>
									เพิ่ม
								</Link>
							) : null}
						</h3>
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="20%">วันที่</td>
								<td width="35%">รายการ</td>
								<td width="25%">ใบเสร็จ</td>
								<td width="20">ยอดเงิน (THB)</td>
							</tr>
						</thead>
						<tbody>
							{transactions.map(transaction => {
								let receipt = null;
								if (transaction.receipts) {
									receipt = transaction.receipts[transaction.receipts.length - 1];
								}

								return (
									<tr key={'transaction' + transaction.id}>
										<td>
											<DateLabel date={transaction.paymentDate} />
										</td>
										<td>
											<Link
												to={
													AppConfig.appPath +
													'/programs/jointour/' +
													program.id +
													'/incomes/' +
													type +
													'/transactions/' +
													transaction.id
												}
											>
												{transaction.title}{' '}
												{!transaction.financeStaff ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}
											</Link>
										</td>
										<td style={{ textAlign: 'center' }}>
											{receipt && (
												<a onClick={() => OpenLink.jointourReceipt(receipt.id)} target="_blank">
													{receipt.documentNumber} {receipt.version > 0 ? `revise ${receipt.version}` : null}{' '}
												</a>
											)}
										</td>
										<td className="number-cell">
											<NumberColor number={transaction.total} />
										</td>
									</tr>
								);
							})}
							<tr className="table-summary-row">
								<td colSpan="3">Summary</td>
								<td className="number-cell">
									<NumberColor number={this.summaryTotal()} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
