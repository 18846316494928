import React, { Component } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import PROGRAM_STATUS from '../../../StaticData/ProgramStatus';

import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';

import LoadingCenter from '../../../Components/LoadingCenter';

export default class SummaryStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			status: {
				value: props.fit.status,
				label: props.fit.status,
			},
		};
	}

	handleStatusChange = async value => {
		const previousStatus = this.state.status;
		this.setState({
			status: value,
		});

		let updatedProgram = await this.updateStatus('STATUS', value.value);
		if (!updatedProgram) {
			this.setState({
				status: previousStatus,
			});
		} else {
			this.props.onProgramUpdated(updatedProgram);
		}
	};

	updateStatus = async (type, status) => {
		const { fit } = this.props;
		this.setState({
			isLoading: true,
		});

		const request = await APIPut('fit/programs/' + fit.id + '/status', {
			type,
			status,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const updatedProgram = request.data;
			this.setState({
				isLoading: false,
			});

			toast.success('แก้ไขสถานะเรียบร้อยแล้ว');
			return updatedProgram;
		} else {
			this.setState({
				isLoading: false,
			});

			toast.error('เกิดปัญหาในการแก้ไขสถานะ');
			return null;
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}
		return (
			<div className="row">
				<div className="row" style={{ textAlign: 'right' }}>
					<label className="label">สถานะ</label>
					<Select
						name="status"
						// isDisabled={!AuthHelper.hasRight('Finance', 'Admin')}
						value={this.state.status}
						clearable={false}
						onChange={this.handleStatusChange}
						options={PROGRAM_STATUS}
					/>
				</div>
			</div>
		);
	}
}
