import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import AppConfig from '../../AppConfig';
import AuthHelper from '../../Helper/AuthHelper';

export default class Logout extends PureComponent {
	componentDidMount = () => {
		if (AuthHelper.isLogin()) {
			AuthHelper.logout();
		}

		this.props.history.replace(AppConfig.appPath);
	};

	render() {
		return (
			<div>
				<section className="hero">
					<div className="hero-body">
						<div className="container">
							<h1 className="title">
								<b>ออกจากระบบเรียบร้อยแล้ว</b>
							</h1>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
