import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DeleteButton from '../../../Components/DeleteButton';
import ProgramHeader from '../../../Components/ProgramHeader';
import BackButtonRouter from '../../../Components/BackButtonRouter';

import APIGet from '../../../API/APIGet';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

import Summary from './Summary';
import IncomeCustomer from './IncomeCustomer';
import IncomeEtc from './IncomeEtc';
import Expense from './Expense';
import Budget from './Budget';
import Restaurant from './Restaurant';

export default class IncentiveProgramDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingProgram: true,
			isLoadingIncome: true,
			isLoadingIncomeEtc: true,
			isLoadingExpense: true,
			isLoadingBudget: true,
			isLoadingRestaurant: true,
			program: {},
			incomeEtc: [],
			incomes: [],
			expenseGroup: [],
			staffs: [],
			budgets: [],
			restaurants: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.getProgram();
			this.getIncome();
			this.getIncomeEtc();
			this.getExpenseGroup();
			this.getBudget();
			this.getRestaurant();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	getProgram = async () => {
		const { programId } = this.props.match.params;

		let requestProgram = APIGet('incentive/programs/' + programId);
		let requestStaffs = APIGet('staffs');
		requestProgram = await requestProgram;
		requestStaffs = await requestStaffs;
		if (APIHelper.handleAPIResponse(this, requestProgram, requestStaffs)) {
			const program = requestProgram.data;
			const staffs = ThingsToSelect(requestStaffs.data);
			console.log('program: ', program);
			console.log('staffs: ', staffs);
			this.setState({
				isLoadingProgram: false,
				program,
				staffs,
			});
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	getIncome = async () => {
		const { programId } = this.props.match.params;

		const request = await APIGet('incentive/programs/' + programId + '/incomes');
		if (APIHelper.handleAPIResponse(this, request)) {
			const incomes = request.data;
			console.log('incomes: ', incomes);
			this.setState({
				isLoadingIncome: false,
				incomes,
			});
		} else if (request.status === 404) {
			toast.warning('ไม่พบข้อมูลรายรับ');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลรายรับได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	getIncomeEtc = async () => {
		const { programId } = this.props.match.params;

		const request = await APIGet('incentive/programs/' + programId + '/incomeEtcs');
		if (APIHelper.handleAPIResponse(this, request)) {
			const incomeEtcs = request.data;
			console.log('incomeEtcs: ', incomeEtcs);
			this.setState({
				isLoadingIncomeEtc: false,
				incomeEtcs,
			});
		} else if (request.status === 404) {
			toast.warning('ไม่พบข้อมูลรายรับ');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลรายรับได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	getExpenseGroup = async () => {
		const { programId } = this.props.match.params;

		const request = await APIGet('incentive/programs/' + programId + '/expenses');
		if (APIHelper.handleAPIResponse(this, request)) {
			const expenseGroup = request.data;
			console.log('expenseGroup: ', expenseGroup);
			this.setState({
				isLoadingExpense: false,
				expenseGroup,
			});
		} else if (request.status === 404) {
			toast.warning('ไม่พบข้อมูลรายรับ');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลรายรับได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	getBudget = async () => {
		const { programId } = this.props.match.params;

		const request = await APIGet('incentive/programs/' + programId + '/budgets');
		if (APIHelper.handleAPIResponse(this, request)) {
			const budgets = request.data;
			console.log('budgets: ', budgets);
			this.setState({
				isLoadingBudget: false,
				budgets,
			});
		} else if (request.status === 404) {
			toast.warning('ไม่พบข้อมูล Budget');
		} else {
			toast.error('ไม่สามารถดึงข้อมูล Budget ได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	getRestaurant = async () => {
		const { programId } = this.props.match.params;

		const request = await APIGet('incentive/programs/' + programId + '/restaurants');
		if (APIHelper.handleAPIResponse(this, request)) {
			const restaurants = request.data;
			console.log('restaurants: ', restaurants);
			this.setState({
				isLoadingRestaurant: false,
				restaurants,
			});
		} else if (request.status === 404) {
			toast.warning('ไม่พบข้อมูลร้านอาหาร');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลร้านอาหารได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	onProgramUpdated = program => {
		this.setState({
			program,
		});
	};

	delete = async () => {
		const { programId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete('incentive/programs/' + programId);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/programs/incentive');
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		if (this.state.isLoadingProgram) {
			return <LoadingCenter />;
		}

		const { program, incomes, expenseGroup, incomeEtcs, staffs, budgets, restaurants } = this.state;
		const { programId } = this.props.match.params;

		if (!program) {
			return <div>Error</div>;
		}

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/incentive'} title="INCENTIVE" />
				<ProgramHeader program={program} />

				<div className="columns row">
					<div className="column is-6">
						{AdminLockRender(
							program.status,
							<Link className="button is-info" to={AppConfig.appPath + '/programs/incentive/' + programId + '/edit'}>
								แก้ไข
							</Link>,
							<button className="button is-info" disabled>
								แก้ไข
							</button>
						)}
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						{AdminLockRender(program.status, <DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />, null)}
					</div>
				</div>

				<br />

				<Summary program={program} onProgramUpdated={this.onProgramUpdated} staffs={staffs} />

				{!this.state.isLoadingIncome ? <IncomeCustomer incomes={incomes} program={program} programId={programId} /> : <LoadingCenter />}

				{!this.state.isLoadingIncomeEtc ? <IncomeEtc program={program} programId={programId} incomeEtcs={incomeEtcs} /> : <LoadingCenter />}

				{!this.state.isLoadingExpense ? <Expense program={program} programId={programId} expenseGroup={expenseGroup} /> : <LoadingCenter />}

				{!this.state.isLoadingBudget ? <Budget program={program} programId={programId} budgets={budgets} /> : <LoadingCenter />}

				{!this.state.isLoadingBudget ? <Restaurant programId={programId} restaurants={restaurants} /> : <LoadingCenter />}
			</div>
		);
	}
}
