import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import ProgramHeader from '../../../Components/ProgramHeader';
import AuthRender from '../../../Components/AuthRender';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import AuthHelper from '../../../Helper/AuthHelper';

import CURRENCIES from '../../../StaticData/Currencies';

import AppConfig from '../../../AppConfig';

export default class ExchangeBudget extends PureComponent {
	constructor(props) {
		super(props);
		const { budgetId } = props.match.params;
		this.state = {
			isLoading: true,
			program: null,
			budgets: [],
			selectedBudgetId: parseInt(budgetId),
			total: 0,
			currency: CURRENCIES[0],
			currencyRate: 1,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			const { programId } = this.props.match.params;
			let requestProgram = APIGet('incentive/programs/' + programId);
			let requestBudget = APIGet(
				'incentive/programs/' + programId + '/budgets'
			);
			requestProgram = await requestProgram;
			requestBudget = await requestBudget;
			if (
				APIHelper.handleAPIResponse(this, requestProgram, requestBudget)
			) {
				const program = requestProgram.data;
				const budgets = requestBudget.data;
				console.log('budgets: ', budgets);
				this.setState({
					program,
					budgets,
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	isSelected = budget => {
		return budget.id === this.state.selectedBudgetId;
	};

	selectBudget = budget => {
		this.setState({
			selectedBudgetId: budget.id,
			total: budget.total,
		});
	};

	getBudget = budgetId => {
		return this.state.budgets.filter(budget => budget.id === budgetId)[0];
	};

	handleInputChange = (key, value) => {
		this.setState({
			[key]: value,
		});
	};

	handleTotalChange = (key, value) => {
		const budget = this.getBudget(this.state.selectedBudgetId);
		if (value > budget.total) {
			value = budget.total;
		}

		this.setState({
			total: value,
		});
	};

	exchange = async () => {
		this.setState({
			isLoading: true,
		});

		const { programId } = this.props.match.params;
		const budget = this.getBudget(this.state.selectedBudgetId);
		const data = {
			total: this.state.total,
			toCurrency: this.state.currency.value,
			toCurrencyRate: this.state.currencyRate,
			fromCurrency: budget.currency,
		};

		const request = await APIPost(
			'incentive/programs/' + programId + '/budgets',
			data
		);

		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('ทำรายการเรียบร้อยแล้ว');
			const budgets = request.data;
			this.setState({
				isLoading: false,
				budgets,
			});
		} else {
			toast.error('เกิดปัญหาในการทำรายการ');
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const {
			program,
			budgets,
			selectedBudgetId,
			currency,
			currencyRate,
			total,
		} = this.state;
		const { programId } = this.props.match.params;
		const budget = this.getBudget(selectedBudgetId);

		return (
			<div className="container">
				<BackButtonRouter
					link={
						AppConfig.appPath + '/programs/incentive/' + programId
					}
				/>
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">
								แลกเปลี่ยนสกุลเงิน Budget
							</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-3">
							<aside className="menu">
								<ul className="menu-list">
									{budgets.map(budget => (
										<li key={'budget' + budget.id}>
											<a
												className={
													this.isSelected(budget)
														? 'is-active'
														: ''
												}
												onClick={() =>
													this.selectBudget(budget)
												}
											>
												<NumberColor
													number={budget.total}
												/>{' '}
												{budget.currency}
											</a>
										</li>
									))}
								</ul>
							</aside>
						</div>
						<div className="column is-9">
							<div className="row" style={{ textAlign: 'right' }}>
								<h3 className="title is-3">
									<b>ยอดเงินที่มี: </b>
									<NumberColor number={budget.total} />{' '}
									{budget.currency}
								</h3>
							</div>
							<div className="columns row">
								<div className="column is-4">
									<InputRow
										title="ยอดเงินที่แลก"
										labelSize="s"
										placeholder="ยอดที่แลก"
										keyProps="total"
										value={this.state.total}
										onInputChange={this.handleTotalChange}
										disabled={false}
									/>
								</div>
								<div className="column is-4">
									<label className="label">
										แลกเป็นสกุลเงิน
									</label>
									<Select
										name="currency"
										value={this.state.currency}
										clearable={false}
										onChange={value =>
											this.handleInputChange(
												'currency',
												value
											)
										}
										options={CURRENCIES}
									/>
								</div>
								<div className="column is-4">
									<InputRow
										title={
											'อัตราแลกเปลี่ยนเงินบาทต่อ 1 ' +
											this.state.currency.value
										}
										labelSize="s"
										placeholder="อัตราแลกเปลี่ยนกับเงินบาท"
										keyProps="currencyRate"
										onInputChange={this.handleInputChange}
										value={this.state.currencyRate}
										disabled={false}
										isMandatory={false}
									/>
								</div>
							</div>
							<div className="row" style={{ textAlign: 'right' }}>
								<h3 className="title is-4">
									<b>แลกได้: </b>
									<NumberColor
										number={total / currencyRate}
									/>{' '}
									{currency.value}
								</h3>
								<h3 className="title is-4">
									<b>เหลือ: </b>
									<NumberColor
										number={budget.total - total}
									/>{' '}
									{budget.currency}
								</h3>
							</div>
							<div
								className="row"
								style={{ textAlign: 'right', marginTop: 20 }}
							>
								<button
									className="button is-success"
									onClick={this.exchange}
								>
									แลก
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
