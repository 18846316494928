import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppColor from '../../../AppColor';

export default class Status extends Component {
	render() {
		const { status, title } = this.props;
		return (
			<div
				className="box clickable"
				style={{
					backgroundColor: status ? AppColor.bulma.isSuccess : AppColor.bulma.isWarning,
				}}
				onClick={this.props.onClick}
			>
				<div className="columns">
					<div className="column is-8">
						<strong>{title}</strong>
					</div>
					<div className="column is-4" style={{ textAlign: 'right' }}>
						{status ? <FontAwesomeIcon icon="check-circle" color="white" /> : <FontAwesomeIcon icon="circle-notch" color="white" />}
					</div>
				</div>
			</div>
		);
	}
}
