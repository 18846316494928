import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../API/APIGet';
import APIHelper from '../../Helper/APIHelper';

import LoadingCenter from '../LoadingCenter';

import CustomerList from './CustomerList';
import AddExistingCustomer from './AddExistingCustomer';
import AddNewCustomerModal from './AddNewCustomerModal';

const JointourProgramAdditionalCustomer = ({ programId, onChange, allowUpdate, mainCustomerId }) => {
	const [isShowModal, setIsShowModal] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isInitial, setIsInitial] = useState(true);

	useEffect(() => {
		if (programId && isInitial) {
			fetchCustomers();
		}

		setIsInitial(false);
	}, []);

	useEffect(() => {
		if (!isInitial) {
			console.log('main customer id changed: ', mainCustomerId);
			fetchFriends();
		}
	}, [mainCustomerId]);

	useEffect(() => {
		console.log('on customer changed', customers);
		allowUpdate && onChange(customers);
	}, [customers]);

	const fetchCustomers = async () => {
		setIsLoading(true);
		const getCustomers = await APIGet(`jointour/programs/${programId}/customers`);
		if (APIHelper.handleAPIResponse(this, getCustomers)) {
			// console.log('fetchCustomers: ', getCustomers.data);
			let customers = getCustomers.data.map(customer => {
				return {
					...customer.customer,
					countTrips: customer.countTrips,
					files: customer.files,
				};
			});
			setCustomers(customers);
			setIsLoading(false);
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	const fetchFriends = async () => {
		setIsLoading(true);
		const getCustomers = await APIGet(`jointour/customers/${mainCustomerId}/friends`);
		if (APIHelper.handleAPIResponse(this, getCustomers)) {
			console.log('fetchFriends: ', getCustomers.data);
			let customers = getCustomers.data.map(customer => {
				return {
					...customer.customer,
					countTrips: customer.countTrips,
					files: customer.files,
				};
			});
			setCustomers(customers);
			setIsLoading(false);
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	const getAndSetCustomerDetail = async customer => {
		setIsLoading(true);
		const getCustomer = await APIGet(`jointour/customers/${customer.id}/programs/countrips`);
		if (APIHelper.handleAPIResponse(this, getCustomer)) {
			console.log('getAndSetCustomerDetail: ', getCustomer.data);
			customer.countTrips = getCustomer.data && getCustomer.data.countTrips ? getCustomer.data.countTrips : 0;
			customer.files = getCustomer.data && getCustomer.data.files ? getCustomer.data.files : [];
			setCustomers([...customers, customer]);
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}

		setIsLoading(false);
	};

	const toggleModal = () => {
		setIsShowModal(!isShowModal);
	};

	const onCustomerAdded = customer => {
		setIsShowModal(false);
		if (customers.map(c => c.id).indexOf(customer.id) === -1) {
			getAndSetCustomerDetail(customer);
		}
	};

	const onRemoveCustomer = customerId => {
		setCustomers(customers.filter(customer => customer.id !== customerId));
	};

	const onCustomerChange = customer => {
		let newCustomers = [...customers];
		newCustomers.forEach((c, index) => {
			if (c.id === customer.id) {
				newCustomers[index] = customer;
			}
		});

		console.log(newCustomers);
		setCustomers(newCustomers);
	};

	return (
		<>
			<div className="row">
				{isLoading ? (
					<LoadingCenter />
				) : (
					<CustomerList customers={customers} onRemove={onRemoveCustomer} allowUpdate={allowUpdate} onCustomerChange={onCustomerChange} />
				)}
			</div>
			{allowUpdate ? (
				<>
					<div className="row">
						<AddExistingCustomer onSelectCustomer={onCustomerAdded} />
					</div>
					<div className="row">
						<button className="button is-text" onClick={toggleModal}>
							เพิ่มลูกค้าใหม่
						</button>
						<AddNewCustomerModal isShown={isShowModal} toggleModal={toggleModal} onCustomerAdded={onCustomerAdded} />
					</div>
				</>
			) : null}
		</>
	);
};

export default JointourProgramAdditionalCustomer;
