import DEFAULT_ALL from './DefaultAll';

export const ALLFITTYPES = [
	DEFAULT_ALL,
	{
		value: 'fitVisa',
		label: 'วีซ่า',
	},
	{
		value: 'fitTicket',
		label: 'ตั๋วเครื่องบิน',
	},
	{
		value: 'fitHotel',
		label: 'โรงแรมที่พัก',
	},
	{
		value: 'fitCarRental',
		label: 'รถเช่า/รถรับส่ง',
	},
	{
		value: 'fitInsurrance',
		label: 'ค่าประกันเดินทาง',
	},
	{
		value: 'fitTravelTicket',
		label: 'ตั๋วรถไฟ/บัตรท่องเที่ยว',
	},
	{
		value: 'fitPocketWifiSim',
		label: 'พ็อกเก็ตไวไฟ/ซิม',
	},
	{
		value: 'fitTranslation',
		label: 'แปลเอกสาร',
	},
];

const FITTYPES = [...ALLFITTYPES];
FITTYPES.shift();
export default FITTYPES;
