import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';

import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

export default class IncomeCustomer extends PureComponent {
	render() {
		const { incomes, program } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">
							รายรับ/การเก็บเงินจากลูกค้า{' '}
							{/* {program.total - program.toCollectFromCustomer > 0 ? <label className="tag is-warning">ยังลงยอดที่ต้องเก็บไม่ครบ</label> : null} */}
						</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						{AdminLockRender(
							program.status,
							<Link className="button is-success" to={AppConfig.appPath + '/programs/incentive/' + this.props.programId + '/newincome'}>
								เพิ่มรายการ
							</Link>,
							<button className="button is-success" disabled>
								เพิ่มรายการ
							</button>
						)}

						<span> </span>
						{/* <button className="button is-info">PDF</button> */}
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="30%">รายการ</td>
								<td width="15%">วันวางบิล</td>
								<td width="15%">ชำระภายใน</td>
								<td width="20%">ยอดเรียกเก็บทั้งหมด (บาท)</td>
								<td width="20%">รับชำระมาแล้ว (บาท)</td>
							</tr>
						</thead>
						<tbody>
							{incomes.map(income => (
								<tr key={'income' + income.id}>
									<td>
										<Link to={AppConfig.appPath + '/programs/incentive/' + this.props.programId + '/incomes/' + income.id}>
											{income.toCollectFromCustomer > income.incomeFromCustomer ? (
												<label className="tag is-warning">ยังเก็บไม่ครบ</label>
											) : (
												<label className="tag is-success">เก็บเงินแล้ว</label>
											)}{' '}
											{income.title}
										</Link>
									</td>
									<td>
										<DateLabel date={income.billedDate} />
									</td>
									<td>
										<DateLabel date={income.expectedPaymentDate} />
									</td>
									<td className="number-cell">
										<NumberColor number={income.toCollectFromCustomer * income.currencyRate} />
									</td>
									<td className="number-cell">
										<NumberColor number={income.incomeFromCustomer * income.currencyRate} />
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan="3">Summary</td>
								<td className="number-cell">
									<NumberColor number={program.toCollectFromCustomer * program.currencyRate} />
								</td>
								<td className="number-cell">
									<NumberColor number={program.incomeFromCustomer * program.currencyRate} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
