import React from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';

import InputRow from '../../../Components/InputRow';
import LoadingCenter from '../../../Components/LoadingCenter';

export default class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			countries: [],
		};
	}
	async componentDidMount() {
		let requestCountries = APIGet('countries');
		requestCountries = await requestCountries;
		if (APIHelper.handleAPIResponse(this, requestCountries)) {
			const countries = ThingsToSelect(requestCountries.data, true);
			console.log('countries: ', countries);
			this.setState({
				isLoading: false,
				countries,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { country, city, name } = this.props.keywords;

		return (
			<div className="columns">
				<div className="column is-4">
					<label className="label">ประเทศ</label>
					<Select
						name="country"
						value={country}
						clearable={false}
						onChange={value => this.props.onSearchChange('country', value)}
						options={this.state.countries}
					/>
				</div>
				<div className="column is-3">
					<InputRow
						title="เมือง"
						labelSize="s"
						placeholder="เมือง"
						keyProps="city"
						value={city}
						onInputChange={this.props.onSearchChange}
						disabled={false}
						onKeyPress={this.handleKeyPress}
					/>
				</div>
				<div className="column is-4">
					<InputRow
						title="ชื่อร้าน"
						labelSize="s"
						placeholder="ชื่อร้าน"
						keyProps="name"
						value={name}
						onInputChange={this.props.onSearchChange}
						disabled={false}
						onKeyPress={this.handleKeyPress}
					/>
				</div>
				<div className="column is-1" style={{ paddingTop: 44 }}>
					<button className="button is-info" onClick={this.props.onClick}>
						Filter
					</button>
				</div>
			</div>
		);
	}
}
