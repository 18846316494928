import React, { useMemo } from 'react';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { DEFAULT_SUB_PLAN_TEMPLATE } from '../../../../StaticData/SubPlanTemplate';

export const SubPlanTable = ({ state, onChange, onChangeDisplaySubPlan }) => {
	const { isEditing, displaySubPlanIndex, incentiveSubPlans } = state;

	const removeExpenseId = records => {
		for (let i = 0; i < records.length; i++) {
			// console.log(records[i].expenses);
			records[i].id = null;
			for (let j = 0; j < records[i].expenses.length; j++) {
				records[i].expenses[j].id = null;
			}
		}

		return records;
	};

	const prepareDuplicateSubPlan = subPlan => {
		const copy = JSON.parse(JSON.stringify(subPlan));
		// console.log('subplan', subPlan);
		// console.log('copied', copy);
		copy.id = null;
		copy.name = '';
		copy.isSelected = false;
		copy.tickets = removeExpenseId(copy.tickets);
		copy.landAssessments = removeExpenseId(copy.landAssessments);
		copy.visas = removeExpenseId(copy.visas);
		copy.tourLeaders = removeExpenseId(copy.tourLeaders);
		copy.others = removeExpenseId(copy.others);
		copy.commissions = removeExpenseId(copy.commissions);

		return copy;
	};

	const changeSelectedSubPlan = index => {
		const copiedIncentiveSubPlans = JSON.parse(JSON.stringify(incentiveSubPlans));
		for (let i = 0; i < copiedIncentiveSubPlans.length; i++) {
			if (i === index) {
				copiedIncentiveSubPlans[i].isSelected = !copiedIncentiveSubPlans[i].isSelected;
			} else {
				copiedIncentiveSubPlans[i].isSelected = false;
			}
		}

		onChange(copiedIncentiveSubPlans);
	};

	return (
		<>
			<table className="table is-hoverable is-fullwidth is-striped is-bordered">
				<thead style={{ backgroundColor: '#209CEE' }}>
					<tr>
						<td width="5%" />
						<td width="50%" style={styles.tableHeaderStyle}>
							ชื่อแพลน
						</td>
						<td width="10%" style={styles.tableHeaderStyle}>
							ผู้ใหญ่
						</td>
						<td width="10%" style={styles.tableHeaderStyle}>
							เด็ก
						</td>
						<td width="10%" style={styles.tableHeaderStyle}>
							หัวหน้าทัวร์
						</td>
						<td width="10%" style={styles.tableHeaderStyle}>
							ตั๋วฟรี
						</td>
						<td width="5%" style={styles.tableHeaderStyle} />
					</tr>
				</thead>
				<tbody>
					{incentiveSubPlans && incentiveSubPlans.length > 0 ? (
						incentiveSubPlans.map((subPlan, index) => {
							const rowStyle = {
								backgroundColor: 'white',
								cursor: 'inherit',
							};

							if (isEditing) {
								if (subPlan.isSelected) {
									rowStyle.backgroundColor = '#C7DCEA';
								}
							} else {
								rowStyle.cursor = 'pointer';
								if (displaySubPlanIndex === index) {
									rowStyle.backgroundColor = '#C7DCEA';
								}
							}

							return (
								<tr
									key={`subPlan-${index}`}
									style={rowStyle}
									onClick={() => {
										if (!isEditing) {
											onChangeDisplaySubPlan(index);
										}
									}}
								>
									<td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
										<input
											type="radio"
											style={{ width: '15px', height: '15px' }}
											disabled={!isEditing}
											checked={subPlan.isSelected}
											onChange={() => changeSelectedSubPlan(index)}
										/>
									</td>
									<td>
										<EditableTextSwitch
											isEditing={isEditing}
											value={subPlan.name}
											onChange={text => {
												incentiveSubPlans[index] = { ...subPlan, name: text };
												onChange(incentiveSubPlans);
											}}
										>
											<p>{subPlan.name}</p>
										</EditableTextSwitch>
									</td>
									<td style={styles.tableRowDataStyle}>
										<EditableTextSwitch
											isEditing={isEditing}
											value={subPlan.adult}
											type={'number'}
											onChange={text => {
												incentiveSubPlans[index] = { ...subPlan, adult: text };
												onChange(incentiveSubPlans);
											}}
										>
											<p>{subPlan.adult}</p>
										</EditableTextSwitch>
									</td>
									<td style={styles.tableRowDataStyle}>
										<EditableTextSwitch
											isEditing={isEditing}
											value={subPlan.child}
											type={'number'}
											onChange={text => {
												incentiveSubPlans[index] = { ...subPlan, child: text };
												onChange(incentiveSubPlans);
											}}
										>
											<p>{subPlan.child}</p>
										</EditableTextSwitch>
									</td>
									<td style={styles.tableRowDataStyle}>
										<EditableTextSwitch
											isEditing={isEditing}
											value={subPlan.tourLeader}
											type={'number'}
											onChange={text => {
												incentiveSubPlans[index] = { ...subPlan, tourLeader: text };
												onChange(incentiveSubPlans);
											}}
										>
											<p>{subPlan.tourLeader}</p>
										</EditableTextSwitch>
									</td>
									<td style={styles.tableRowDataStyle}>
										{' '}
										<EditableTextSwitch
											isEditing={isEditing}
											value={subPlan.freeTicket}
											type={'number'}
											onChange={text => {
												incentiveSubPlans[index] = { ...subPlan, freeTicket: text };
												onChange(incentiveSubPlans);
											}}
										>
											<p>{subPlan.freeTicket}</p>
										</EditableTextSwitch>
									</td>
									<td
										style={{
											...styles.tableRowDataStyle,
											...{ verticalAlign: 'middle' },
										}}
									>
										<FontAwesomeIcon
											icon={faTrashCan}
											style={{ color: isEditing ? 'red' : 'black', cursor: isEditing ? 'pointer' : 'auto' }}
											onClick={e => {
												e.preventDefault();
												if (isEditing) {
													incentiveSubPlans.splice(index, 1);
													onChange(incentiveSubPlans);
												}
											}}
										/>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td />
							<td>ไม่ระบุ</td>
							<td />
							<td />
							<td />
							<td />
							<td />
						</tr>
					)}
				</tbody>
			</table>
			{isEditing ? (
				<button
					className="button is-success is-small"
					onClick={e => {
						e.preventDefault();
						if (incentiveSubPlans.length === 0) {
							onChange([{ ...DEFAULT_SUB_PLAN_TEMPLATE, isSelected: true }]);
						} else {
							console.log([...incentiveSubPlans, prepareDuplicateSubPlan(incentiveSubPlans[0])]);
							onChange([...incentiveSubPlans, prepareDuplicateSubPlan(incentiveSubPlans[0])]);
						}
					}}
				>
					+เพิ่ม
				</button>
			) : null}{' '}
		</>
	);
};

const styles = {
	tableHeaderStyle: {
		color: '#ffffff',
		textAlign: 'center',
	},
	tableRowDataStyle: {
		textAlign: 'center',
	},
};
