import AppConfig from '../AppConfig';

const Store = (function() {
	const get = key => {
		let storeParams = {};
		try {
			if (localStorage[AppConfig.localStorageKey]) {
				storeParams = JSON.parse(localStorage[AppConfig.localStorageKey]);
			}
		} catch (ex) {
			console.log('something wrong with local storage. All data here will be reset...');
			initLocalStorageIfRequired();
		}

		if (key) {
			if (storeParams[key] && storeParams[key].value) {
				const currentTime = new Date().getTime();
				if (currentTime - storeParams[key].__updatedAt > AppConfig.timeToInvalidateCacheInMilliSecond) {
					invalidate(key);
					return undefined;
				} else {
					return storeParams[key].value;
				}
			} else {
				return undefined;
			}
		} else {
			return storeParams;
		}
	};

	const set = (key, value) => {
		let storeParams = {};
		try {
			initLocalStorageIfRequired();
			storeParams = JSON.parse(localStorage[AppConfig.localStorageKey]);
			storeParams[key] = {
				value: value,
				__updatedAt: new Date().getTime(),
			};

			localStorage[AppConfig.localStorageKey] = JSON.stringify(storeParams);
		} catch (ex) {
			console.log('something wrong with local storage. All data here will be reset...');
			initLocalStorageIfRequired();
			return false;
		}

		return true;
	};

	const invalidate = key => {
		console.log('invalidate Cache: ', key);
		let storeParams = {};
		try {
			initLocalStorageIfRequired();
			storeParams = JSON.parse(localStorage[AppConfig.localStorageKey]);
			delete storeParams[key];

			localStorage[AppConfig.localStorageKey] = JSON.stringify(storeParams);
		} catch (ex) {
			console.log('something wrong with local storage. All data here will be reset...');
			localStorage[AppConfig.localStorageKey] = {};
		}
	};

	const initLocalStorageIfRequired = () => {
		if (!localStorage[AppConfig.localStorageKey]) {
			localStorage[AppConfig.localStorageKey] = JSON.stringify({});
		}
	};

	const clearaAll = () => {
		localStorage[AppConfig.localStorageKey] = JSON.stringify({});
	};

	return {
		get,
		set,
		invalidate,
		clearaAll,
	};
})();

export default Store;
