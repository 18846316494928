import React from 'react';

export default ({ title, placeholder, value, onChange, disabled, row }) => {
	const onChangeText = event => {
		onChange(event.target.value);
	};
	return (
		<div>
			{title ? (
				<label className="label" style={{ color: 'black' }}>
					{title}
				</label>
			) : null}
			<div className="control">
				<textarea className={'textarea'} type="text" placeholder={placeholder} value={value} onChange={onChangeText} disabled={disabled} rows={row} />
			</div>
		</div>
	);
};
