import React, { PureComponent } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import ThingsToSelect from '../../../Helper/ThingsToSelect';

import AppConfig from '../../../AppConfig';

export default class AddEditCompanyUser extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			restaurant: {
				name: '',
				country: null,
				city: '',
				address: '',
				type: '',
				contact: '',
				course: '',
				website: '',
				pricePerHead: '',
				remark: '',
				totalVisits: 0,
				star: 0,
			},
			select: {
				country: null,
			},
			data: {
				countries: [],
			},
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initCreate = async () => {
		let requestCountries = APIGet('countries');
		requestCountries = await requestCountries;
		if (APIHelper.handleAPIResponse(this, requestCountries)) {
			const countries = ThingsToSelect(requestCountries.data);
			console.log('countries: ', countries);
			this.setState({
				data: { countries },
			});

			return true;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
			return false;
		}
	};

	initUpdate = async () => {
		const { id } = this.props.match.params;
		let requestRestaurant = APIGet(`restaurants/${id}`);
		requestRestaurant = await requestRestaurant;
		if (APIHelper.handleAPIResponse(this, requestRestaurant)) {
			const restaurant = requestRestaurant.data;
			let select = { ...this.state.select };
			select.country = this.state.data.countries.filter(c => c.value === restaurant.countryId)[0];
			console.log('restaurant: ', restaurant);
			console.log(select, this.state.data.countries);
			this.setState({
				restaurant,
				select,
			});
			return true;
		} else if (requestRestaurant.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { id } = this.props.match.params;
		if (id) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		const { id } = this.props.match.params;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				...this.state.restaurant,
				countryId: this.state.select.country.value,
			};
			console.log(data);
			let request = null;
			if (this.isUpdate()) {
				request = await APIPut(`restaurants/${id}`, data);
			} else {
				request = await APIPost(`restaurants`, data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขเรียบร้อยแล้ว' : 'เพิ่มเรียบร้อยแล้ว');
				const result = request.data;

				this.props.history.goBack();
			} else {
				toast.error('เกิดปัญหาในการเพิ่ม/แก้ไข');
			}

			this.setState({
				isLoading: false,
			});
		}
	};

	validateSaveState = () => {
		const name = this.validateString('name', 'ชื่อ');
		const city = this.validateString('city', 'เมือง');
		const type = this.validateString('type', 'ประเภทร้านอาหาร');
		const contact = this.validateString('contact', 'ผู้ติดต่อ');
		const country = this.validateSelect('country', 'ประเทศ');
		// console.log(name && city && type && contact && country);
		return name && city && type && contact && country;
	};

	validateString = (stateName, warningMessage) => {
		const checker = this.state.restaurant[stateName].trim() !== '';
		if (!checker) {
			toast.warn('กรุณาระบุ' + warningMessage);
			return false;
		}

		return true;
	};

	validateSelect = (stateName, warningMessage) => {
		const checker = this.state.select[stateName];
		if (!checker) {
			toast.warn('กรุณาระบุ' + warningMessage);
		}

		return checker;
	};

	onTextChange = (type, value) => {
		let restaurant = { ...this.state.restaurant };
		restaurant[type] = value;
		this.setState({
			restaurant,
		});
	};

	handleSelectChange = (type, value) => {
		let select = { ...this.state.select };
		select[type] = value;
		this.setState({
			select,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { restaurant } = this.state;

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-2">{this.isUpdate() ? 'แก้ไขร้านอาหาร' : 'เพิ่มร้านอาหาร'}</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<InputRow
								title="ชื่อ"
								labelSize="s"
								placeholder="ชื่อ"
								keyProps="name"
								onInputChange={this.onTextChange}
								value={restaurant.name}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<label className="label">ประเทศ</label>
							<Select
								name="country"
								value={this.state.select.country}
								clearable={false}
								onChange={value => this.handleSelectChange('country', value)}
								options={this.state.data.countries}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="เมือง"
								labelSize="s"
								placeholder="city"
								keyProps="city"
								onInputChange={this.onTextChange}
								value={restaurant.city}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-2">
							<InputRow
								title="ประเภท"
								labelSize="s"
								placeholder="type"
								keyProps="type"
								onInputChange={this.onTextChange}
								value={restaurant.type}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="ติดต่อ"
								labelSize="s"
								placeholder="contact"
								keyProps="contact"
								onInputChange={this.onTextChange}
								value={restaurant.contact}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-3">
							<InputRow
								title="Website"
								labelSize="s"
								placeholder="website"
								keyProps="website"
								onInputChange={this.onTextChange}
								value={restaurant.website}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-3">
							<InputRow
								title="ราคาต่อคน"
								labelSize="s"
								placeholder="pricePerHead"
								keyProps="pricePerHead"
								onInputChange={this.onTextChange}
								value={restaurant.pricePerHead}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-3">
							<TextArea
								row={8}
								title="Address"
								labelSize="s"
								keyProps="address"
								value={restaurant.address}
								onInputChange={this.onTextChange}
								isMandatory={false}
							/>
						</div>
						<div className="column is-3">
							<TextArea
								row={8}
								title="Remark"
								labelSize="s"
								keyProps="remark"
								value={restaurant.remark}
								onInputChange={this.onTextChange}
								isMandatory={false}
							/>
						</div>
						<div className="column is-6">
							<TextArea
								row={8}
								title="Course"
								labelSize="s"
								keyProps="course"
								value={restaurant.course}
								onInputChange={this.onTextChange}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-6" />
						<div className="column is-6" style={{ textAlign: 'right', marginTop: 20 }}>
							<button className="button is-success" onClick={this.save}>
								บันทึก
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
