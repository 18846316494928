import React from 'react';

import NumberColor from '../../../Components/NumberColor';
import FitItemPricing from './FitItemPricing';

export default class FITItemTicket extends React.Component {
	render() {
		const { item, fitItemHelper } = this.props;
		const { fitTicket } = item;
		const pricing = fitItemHelper.getItemPrice(item);
		return (
			<div className="box" key={'item' + item.id}>
				<div className="columns">
					<div className="column is-6">
						<h4 className="title is-4">ตั๋วเครื่องบิน</h4>
					</div>
					<div className="column is-6">
						<FitItemPricing
							pricing={pricing}
							creditCard={item.creditCard}
							creditCardRecord={item.creditCardRecord}
							creditCardCurrency={item.creditCardCurrency}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-3">
						<label className="label">Airline</label>
						{fitTicket.airline ? fitTicket.airline.name : ''}
					</div>
					<div className="column is-3">
						<label className="label">Route</label>
						{fitTicket.route}
					</div>
					<div className="column is-3">
						<label className="label">Flight Number</label>
						{fitTicket.flightNumber}
					</div>
					<div className="column is-3">
						<label className="label">PNR</label>
						{fitTicket.pnr}
					</div>
				</div>
				<table className="table is-fullwidth is-bordered is-hoverable">
					<thead>
						<tr className="table-header-green">
							<td width="40%">ชื่อผู้โดยสาย</td>
							<td>หมายเลขตั๋ว</td>
							<td>ราคาขาย</td>
							<td>ต้นทุน</td>
							<td>Credit Charge (%)</td>
						</tr>
					</thead>
					<tbody>
						{fitTicket.passengers.map(passenger => (
							<tr key={'passenger' + passenger.id}>
								<td>{passenger.name}</td>
								<td style={{ textAlign: 'center' }}>{passenger.ticketNumber}</td>
								<td style={{ textAlign: 'right' }}>
									<NumberColor number={passenger.salePrice + (passenger.salePrice * passenger.creditCharge) / 100} />
								</td>
								<td style={{ textAlign: 'right' }}>
									<NumberColor number={passenger.price} isExpense={true} />
								</td>
								<td style={{ textAlign: 'right' }}>{passenger.creditCharge} %</td>
							</tr>
						))}
						<tr className="table-summary-row">
							<td />
							<td />
							<td style={{ textAlign: 'right' }}>
								<NumberColor number={pricing.salePrice + pricing.creditCharge} />
							</td>
							<td style={{ textAlign: 'right' }}>
								<NumberColor number={pricing.price} isExpense={true} />
							</td>
							<td />
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}
