import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';

import AppConfig from '../../../AppConfig';

export default class Expense extends PureComponent {
	summary = () => {
		const { expenses } = this.props;
		let total = 0;
		expenses.forEach(expense => {
			total += expense.total;
		});

		return total;
	};

	render() {
		const { expenses } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">รายจ่าย Whole Sale</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }} />
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped">
						<thead>
							<tr>
								<td width="50%">รายการ</td>
								<td width="20%">วันกำหนดชำระ</td>
								<td width="30%" style={{ textAlign: 'right' }}>
									ยอด (THB)
								</td>
							</tr>
						</thead>
						<tbody>
							{expenses.map(expense => (
								<tr key={'expense' + expense.currency + expense.jointourExpenseCategoryId}>
									<td>
										<Link to={AppConfig.appPath + '/programs/jointour/' + this.props.programId + '/expenses/' + expense.id}>
											{expense.title}
											<span> </span>
											{!expense.approver ? (
												<label className="tag is-warning">
													{expense.title.indexOf('มัดจำ') !== -1 ? 'ได้รับชำระเงินมัดจำแล้ว' : 'ได้รับชำระเงินเต็มจำนวนแล้ว '}
												</label>
											) : null}
											<span> </span>
											{!expense.financeStaff ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}
											{expense.financeStaff && expense.filePayInToWholeSales && expense.filePayInToWholeSales.length > 0 ? (
												<span className="tag is-success">ทำจ่ายเรียบร้อยแล้ว</span>
											) : null}
										</Link>
									</td>
									<td>
										<DateLabel date={expense.dueDatePayment} />
									</td>
									<td className="number-cell">
										<NumberColor number={expense.total} isExpense={true} />
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan={2}>Summary</td>
								<td className="number-cell">
									<NumberColor number={this.summary()} isExpense={true} /> บาท
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
