import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import ProgramHeader from '../../../Components/ProgramHeader';
import PaymentDetail from '../../../Components/PaymentDetail';
import DeleteButton from '../../../Components/DeleteButton';

import CURRENCIES from '../../../StaticData/Currencies';
import { PAYMENT_METHODS_REQUIRED } from '../../../StaticData/PaymentMethods';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import ValidatePaymentDetail from '../../../Helper/ValidatePaymentDetail';
import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

import Store from '../../../Helper/Store';
import StoreKey from '../../../StoreKey';
import OwnerLockRender from '../../../Helper/OwnerLockRender';

export default class AddEditIncomeTransaction extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			paymentDate: moment(new Date()),
			paymentTime: '',
			note: '',
			paymentMethod: PAYMENT_METHODS_REQUIRED[0],
			currency: CURRENCIES[0],
			currencyRate: 1,
			title: Store.get(StoreKey.incomeTransaction.title) ? Store.get(StoreKey.incomeTransaction.title) : '',
			total: Store.get(StoreKey.incomeTransaction.total) ? Store.get(StoreKey.incomeTransaction.total) : 0,
			bank: null,
			checkNumber: '',
			bankAccount: null,
			creditCard: null,
		};

		Store.invalidate(StoreKey.incomeTransaction.title);
		Store.invalidate(StoreKey.incomeTransaction.total);
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	isUpdate = () => {
		const { transactionId } = this.props.match.params;
		if (transactionId) {
			return true;
		} else {
			return false;
		}
	};

	initCreate = async () => {
		const { programId } = this.props.match.params;

		let requestProgram = APIGet('incentive/programs/' + programId);
		requestProgram = await requestProgram;
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const program = requestProgram.data;
			console.log('program: ', program);
			this.setState({
				program,
			});

			return true;
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { programId, incomeId, transactionId } = this.props.match.params;
		let requestTransaction = await APIGet('incentive/programs/' + programId + '/incomes/' + incomeId + '/transactions/' + transactionId);
		if (APIHelper.handleAPIResponse(this, requestTransaction)) {
			const transaction = requestTransaction.data;
			console.log('transaction: ', transaction);
			this.setState({
				paymentDate: moment(new Date(transaction.paymentDate)),
				paymentTime: transaction.paymentTime,
				note: transaction.note,
				paymentMethod: PAYMENT_METHODS_REQUIRED.filter(p => p.value === transaction.paymentMethod)[0],
				currency: CURRENCIES.filter(c => c.value === transaction.currency)[0],
				currencyRate: transaction.currencyRate,
				title: transaction.title,
				total: transaction.total,
				bank: transaction.bank
					? {
							value: transaction.bankId,
							label: transaction.bank.name,
					  }
					: null,
				checkNumber: transaction.checkNumber,
				bankAccount: transaction.bankAccount
					? {
							value: transaction.bankAccountId,
							label: transaction.bankAccount.name,
					  }
					: null,
				paymentTime: transaction.paymentTime,
				creditCard: transaction.creditCard
					? {
							value: transaction.creditCardId,
							label: transaction.creditCard.name,
					  }
					: null,
			});

			return true;
		} else if (requestTransaction.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	save = async () => {
		const { programId, incomeId, transactionId } = this.props.match.params;
		if (this.validateSaveState()) {
			let data = {
				incentiveIncomeId: incomeId,
				title: this.state.title,
				paymentMethod: this.state.paymentMethod.value,
				paymentDate: this.state.paymentDate.format('YYYY-MM-DD'),
				total: this.state.total,
				currencyRate: this.state.currencyRate,
				currency: this.state.currency.value,
				note: this.state.note,
			};

			if (this.state.paymentMethod.value === 'Transfer') {
				data.bankAccountId = this.state.bankAccount.value;
				data.paymentTime = this.state.paymentTime;
			} else if (this.state.paymentMethod.value === 'Check') {
				data.bankId = this.state.bank.value;
				data.checkNumber = this.state.checkNumber;
			} else if (this.state.paymentMethod.value === 'Credit Card') {
				data.creditCardId = this.state.creditCard.value;
			}

			let request = null;
			if (this.isUpdate()) {
				request = await APIPut('incentive/programs/' + programId + '/incomes/' + incomeId + '/transactions/' + transactionId, data);
			} else {
				request = await APIPost('incentive/programs/' + programId + '/incomes/' + incomeId + '/transactions', data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายการเรียบร้อยแล้ว' : 'เพิ่มรายการรายรับเรียบร้อยแล้ว');
				const result = request.data;
				this.props.history.push(AppConfig.appPath + '/programs/incentive/' + programId + '/incomes/' + incomeId);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มรายรับ');
			}
		} else {
			toast.warn('โปรดตรวจสอบความถูกต้องของข้อมูล');
		}
	};

	delete = async () => {
		const { programId, incomeId, transactionId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete('incentive/programs/' + programId + '/incomes/' + incomeId + '/transactions/' + transactionId);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/programs/incentive/' + programId + '/incomes/' + incomeId);
		} else if (requestDelete.status === 400) {
			toast.warn('คุณไม่มีสิทธิ์ลบรายการนี้');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	validateSaveState = () => {
		const title = this.state.title.trim() !== '';
		if (!title) {
			toast.warn('กรุณาระบุรายการหลัก');
		}

		const currencyRate = !isNaN(this.state.currencyRate);
		if (!currencyRate) {
			toast.warn('กรุณาระบุอัตราแลกเปลี่ยนเงินบาท โดยกรอกเป็นตัวเลขและจุดทศนิยมเท่านั้น (ไม่ต้องมี ,)');
		}

		const total = !isNaN(this.state.total);
		if (!total) {
			toast.warn('กรุณาระบุยอด โดยกรอกเป็นตัวเลขและจุดทศนิยมเท่านั้น (ไม่ต้องมี ,)');
		}

		const paymentDetail = ValidatePaymentDetail(this.state, toast);

		return title && currencyRate && total && paymentDetail;
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	toggleBilledDate = () => {
		this.setState({
			isBilledDateRequired: !this.state.isBilledDateRequired,
		});
	};

	onBilledDateChange = newDate => {
		this.setState({
			billedDate: newDate,
		});
	};

	onPaymentDateChange = newDate => {
		this.setState({
			paymentDate: newDate,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program } = this.state;
		const { programId, incomeId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/incentive/' + programId + '/incomes/' + incomeId} />
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">{this.isUpdate() ? 'แก้ไขรายการเก็บเงิน' : 'เพิ่มรายการเก็บเงิน'}</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<PaymentDetail
								paymentMethod={this.state.paymentMethod}
								paymentTime={this.state.paymentTime}
								bank={this.state.bank}
								bankAccount={this.state.bankAccount}
								creditCard={this.state.creditCard}
								checkNumber={this.state.checkNumber}
								currency={this.state.currency}
								currencyRate={this.state.currencyRate}
								paymentDate={this.state.paymentDate}
								handleSelectChange={this.handleSelectChange}
								onPaymentDateChange={this.onPaymentDateChange}
								onTextChange={this.onTextChange}
							/>
							<div className="columns">
								<div className="column is-8">
									<InputRow
										title="รายการ"
										labelSize="s"
										placeholder="รายการ"
										keyProps="title"
										onInputChange={this.onTextChange}
										value={this.state.title}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-4" style={{ textAlign: 'right' }}>
									<InputRow
										title={'รายรับ (' + this.state.currency.value + ')'}
										labelSize="s"
										placeholder="รายรับ"
										keyProps="total"
										onInputChange={this.onTextChange}
										value={this.state.total}
										disabled={false}
										isMandatory={false}
									/>
								</div>
							</div>
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onTextChange}
									isMandatory={false}
								/>
							</div>
							{AuthHelper.hasRight('Finance', 'Admin') ? (
								<div className="columns rows" style={{ marginTop: 20 }}>
									<div className="column is-8">
										{this.isUpdate()
											? OwnerLockRender(<DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />, null)
											: null}
									</div>
									<div className="column is-4" style={{ textAlign: 'right' }}>
										{AdminLockRender(
											program.status,
											<button className="button is-success" onClick={this.save}>
												บันทึก
											</button>,
											<button className="button is-success" disabled>
												บันทึก
											</button>
										)}
									</div>
								</div>
							) : (
								<span className="tag is-danger">คุณไม่สามารถเพิ่ม/แก้ไขรายการเก็บเงินได้</span>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
