import React from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import TextArea from '../../../Components/TextArea';
import NumberColor from '../../../Components/NumberColor';
import LoadingCenter from '../../../Components/LoadingCenter';
import InputRow from '../../../Components/InputRow';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import InitSelect from '../../../Helper/InitSelect';

import AppConfig from '../../../AppConfig';

import createFITItemHelper from '../Helpers/FITItemHelper';
import CustomerDetail from './CustomerDetail';
import FITItem from './FITItem';
import FITExpense from './FITExpense';

import CUSTOMER_CHANNELS from '../../../StaticData/CustomerChannels';
import FITTypes from '../../../StaticData/FITTypes';
import CURRENCIES from '../../../StaticData/Currencies';

export default class AddEditFIT extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			selectedCustomer: null,
			selectedCustomerContact: null,
			selectedChannel: null,
			fitItems: [],
			title: '',
			telephone: '',
			email: '',
			lineId: '',
			note: '',
			startDate: moment(new Date()),
			endDate: moment(new Date()),
			dueDatePayment: moment(new Date()).add(5, 'd'),
			customers: [],
			customerContacts: [],
			customerContactsRaw: [],
			creditCards: [],
			expenses: [],
			airlines: [],
			fitSuppliers: [],
			fitSuppliersRaw: [],
		};

		this.fitItemHelper = null;
	}

	componentDidMount = async () => {
		let newState = {};
		this.fitItemHelper = createFITItemHelper();
		newState = await this.initCreate();
		console.log('state create: ', newState);
		if (this.isUpdate()) {
			const updateState = await this.initUpdate(newState);
			console.log('updateState: ', updateState);
			newState = {
				...newState,
				...updateState,
			};
		}
		console.log('state update: ', newState);
		this.setState({
			...newState,
			isLoading: false,
		});
	};

	initCreate = async () => {
		let requestCreditCards = APIGet('creditCards');
		let requestCustomers = APIGet('fit/customers');
		let requestSuppliers = APIGet('fit/suppliers');
		let requestAirlines = APIGet('airlines');
		requestCreditCards = await requestCreditCards;
		requestCustomers = await requestCustomers;
		requestSuppliers = await requestSuppliers;
		requestAirlines = await requestAirlines;
		if (APIHelper.handleAPIResponse(this, requestCreditCards, requestCustomers, requestSuppliers, requestAirlines)) {
			const creditCards = ThingsToSelect(requestCreditCards.data);
			const customers = ThingsToSelect(requestCustomers.data);
			const fitSuppliersRaw = requestSuppliers.data;
			const airlines = ThingsToSelect(requestAirlines.data);
			// console.log('fitSuppliersRaw: ', fitSuppliersRaw);
			const expenses = [];

			return {
				fitItems: this.fitItemHelper.getState(),
				creditCards,
				expenses,
				customers,
				fitSuppliersRaw,
				airlines,
			};
		}
	};

	initUpdate = async createState => {
		let updateState = {};
		const { programId } = this.props.match.params;
		let requestProgram = await APIGet(`fit/programs/${programId}`);
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const fit = requestProgram.data;
			this.fitItemHelper.resetState(fit.fitItems);
			let fitItems = this.fitItemHelper.getState();
			// console.log('fit: ', fit);
			// console.log('fitItems: ', fitItems);
			for (let i = 0; i < fitItems.length; i++) {
				let fitItem = fitItems[i];
				fitItem.selectedFitType = InitSelect(FITTypes, fitItem.fitType);
				fitItem.fitSuppliers = ThingsToSelect(createState.fitSuppliersRaw.filter(supplier => supplier.fitType === fitItem.fitType));
				fitItem.selectedSupplier = InitSelect(ThingsToSelect(createState.fitSuppliersRaw), fitItem.supplierId);
				fitItem.selectedCreditCard = InitSelect(createState.creditCards, fitItem.creditCardId);
				fitItem.selectedCreditCardCurrency = InitSelect(CURRENCIES, fitItem.creditCardCurrency);
				if (this.fitItemHelper.isTicket(fitItem)) {
					fitItem.fitTicket.selectedAirline = InitSelect(createState.airlines, fitItem.fitTicket.airlineId);
				}
			}
			updateState = {
				...fit,
				startDate: new moment(fit.startDate),
				endDate: new moment(fit.endDate),
				dueDatePayment: fit.dueDatePayment ? new moment(fit.dueDatePayment) : new moment(new Date()),
				selectedCustomer: InitSelect(createState.customers, fit.fitCustomerId),
				telephone: fit.customerContactTelephone,
				email: fit.customerContactEmail,
				lineId: fit.customerContactLineId,
				selectedChannel: InitSelect(CUSTOMER_CHANNELS, fit.customerContactChannel),
				fitItems,
			};

			await this.onCustomerChange(updateState.selectedCustomer);
			updateState.selectedCustomerContact = InitSelect(this.state.customerContacts, updateState.fitCustomerContactId);
			// updateState;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}

		return updateState;
	};

	validateCreateOrUpdateInput = (state, fitItemHelper, warningToast) => {
		let result = true;
		if (!state.title) {
			warningToast('กรุณาระบุชื่อรายการ');
			result = false;
		}

		if (!state.startDate || !state.endDate || state.startDate > state.endDate) {
			warningToast('โปรดตรวจสอบวันที่');
			result = false;
		}

		if (state.expenses.filter(expense => expense.quantity === 0 || expense.pricePerPiece === 0).length > 0) {
			warningToast('โปรดจรวจสอบรายจ่าย');
			result = false;
		}

		if (!state.selectedCustomer || !state.selectedCustomerContact || !state.telephone || !state.selectedChannel) {
			warningToast('โปรดตรวจสอบข้อมูลลูกค้า');
			result = false;
		}

		state.fitItems.forEach((item, index) => {
			let fitItemResult = true;
			if (!fitItemHelper.validateItem(item)) {
				warningToast(`โปรดตรวจสอบข้อมูลรายการที่ ${index + 1}`);
				fitItemResult = false;
			}

			result &= fitItemResult;
		});

		return result;
	};

	getDataForCreateOrUpdateAPI = (state, fitItemHelper) => {
		const totalPrice = fitItemHelper.getTotalPrice();
		const data = {
			title: state.title,
			startDate: state.startDate.format('YYYY-MM-DD'),
			endDate: state.endDate.format('YYYY-MM-DD'),
			dueDatePayment: state.dueDatePayment.format('YYYY-MM-DD'),
			expenses: state.expenses,
			fitCustomerId: state.selectedCustomer.value,
			fitCustomerContactId: state.selectedCustomerContact.value,
			customerContactEmail: state.email,
			customerContactTelephone: state.telephone,
			customerContactLineId: state.lineId,
			customerContactChannel: state.selectedChannel.value,
			note: state.note,
			price: totalPrice.price,
			salePrice: totalPrice.salePrice,
			creditCharge: totalPrice.creditCharge,
			fitItems: fitItemHelper.getDataForCreateOrUpdateAPI(),
		};

		return data;
	};

	save = async () => {
		if (this.validateCreateOrUpdateInput(this.state, this.fitItemHelper, toast.warn)) {
			this.setState({
				isLoading: true,
			});
			const data = this.getDataForCreateOrUpdateAPI(this.state, this.fitItemHelper);
			console.log(data);
			let request;
			if (this.isUpdate()) {
				const { programId } = this.props.match.params;
				request = await APIPut(`fit/programs/${programId}`, data);
			} else {
				request = await APIPost(`fit/programs`, data);
			}
			console.log(request);
			this.setState({
				isLoading: false,
			});
			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายการเรียบร้อยแล้ว' : 'เพิ่มรายการเรียบร้อยแล้ว');
				const result = request.data;
				this.props.history.push(AppConfig.appPath + '/programs/fit/' + result.id);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มรายการ');
			}
		}
	};

	isUpdate = () => {
		const { programId } = this.props.match.params;
		if (programId) {
			return true;
		} else {
			return false;
		}
	};

	addFitItem = () => {
		this.setState({
			fitItems: this.fitItemHelper.addRow(),
		});
	};

	onStateChange = (key, value) => {
		let additionalChanges = {};
		if (key === 'startDate') {
			if (value > this.state.endDate) {
				additionalChanges = {
					endDate: value,
				};
			}
		} else if (key === 'endDate') {
			if (value < this.state.endDate) {
				additionalChanges = {
					startDate: value,
				};
			}
		}

		this.setState({
			...additionalChanges,
			[key]: value,
		});
	};

	onCustomerChange = async selectedCustomer => {
		const selectedCustomerId = selectedCustomer.value;
		let additionalChanges = {
			customerContacts: [],
			customerContactsRaw: [],
			telephone: '',
			lineId: '',
			email: '',
		};
		const requestCustomerContacts = await APIGet(`fit/customers/${selectedCustomerId}/contacts`);
		if (APIHelper.handleAPIResponse(this, requestCustomerContacts)) {
			const customerContacts = ThingsToSelect(requestCustomerContacts.data);
			// console.log('customerContacts: ', customerContacts);
			if (customerContacts.length > 0) {
				additionalChanges = {
					...additionalChanges,
					customerContacts,
					customerContactsRaw: requestCustomerContacts.data,
				};
			} else {
				toast.warn('ไม่พบผู้ติดต่อในลูกค้ารายนี้ กรุญาเพิ่มก่อน');
			}
		} else {
			toast.error('เกิดปัญหาในการดึงข้อมูล');
		}

		additionalChanges.selectedCustomerContact = null;

		this.setState({
			...additionalChanges,
			selectedCustomer,
		});
	};

	onCustomerContactChange = selectedCustomerContact => {
		const selectedCustomerContactId = selectedCustomerContact.value;
		let customerContact = this.state.customerContactsRaw.filter(contact => contact.id === selectedCustomerContactId);
		if (customerContact.length === 1) {
			customerContact = customerContact[0];
			const additionalChanges = {
				telephone: customerContact.telephone,
				email: customerContact.email,
				lineId: customerContact.lineId,
			};

			this.setState({
				...additionalChanges,
				selectedCustomerContact,
			});
		}
	};

	onFitChange = (index, key, value) => {
		this.setState({
			fitItems: this.fitItemHelper.onChange(index, key, value),
		});
	};

	onDetailChange = (parentIndex, key, value) => {
		this.setState({
			fitItems: this.fitItemHelper.onDetailChange(parentIndex, key, value),
		});
	};

	onDetailArrayChange = (parentIndex, index, key, value) => {
		this.setState({
			fitItems: this.fitItemHelper.onDetailArrayChange(parentIndex, index, key, value),
		});
	};

	addDetail = parentIndex => {
		this.setState({
			fitItems: this.fitItemHelper.addDetail(parentIndex),
		});
	};

	removeDetail = (parentIndex, index) => {
		this.setState({
			fitItems: this.fitItemHelper.removeDetail(parentIndex, index),
		});
	};

	onRemoveFit = index => {
		this.setState({
			fitItems: this.fitItemHelper.removeRow(index),
		});
	};

	onExpenseChanged = (index, expense) => {
		let expenses = [...this.state.expenses];
		expenses[index] = expense;
		this.setState({
			expenses,
		});
	};

	onExpenseAdded = () => {
		let expenses = [...this.state.expenses];
		expenses.push({
			title: '',
			pricePerPiece: 0,
			quantity: 0,
			total: 0,
		});

		this.setState({
			expenses,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}
		const { programId } = this.props.match.params;
		const {
			title,
			telephone,
			email,
			lineId,
			selectedChannel,
			selectedCustomer,
			selectedCustomerContact,
			fitItems,
			note,
			startDate,
			endDate,
			dueDatePayment,
			expenses,
			customers,
			customerContacts,
			fitSuppliersRaw,
			airlines,
			creditCards,
		} = this.state;

		const totalPrice = this.fitItemHelper.getTotalPrice();
		return (
			<div className="container">
				<BackButtonRouter link={this.isUpdate() ? AppConfig.appPath + '/programs/fit/' + programId : AppConfig.appPath + '/programs/fit'} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">{this.isUpdate() ? 'แก้ไข' : 'เพิ่ม'}รายการ FIT</h3>
						</div>
					</div>
					<div className="row">
						<InputRow
							title="ชื่อรายการ"
							labelSize="s"
							placeholder="ชื่อรายการ"
							keyProps="title"
							value={title}
							onInputChange={this.onStateChange}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="row">
						<h3 className="subtitle is-4">
							<strong>
								<u>ลูกค้า</u>
							</strong>
						</h3>
					</div>
					<div className="row columns">
						<div className="column is-6">
							<CustomerDetail
								onStateChange={this.onStateChange}
								onCustomerChange={this.onCustomerChange}
								onCustomerContactChange={this.onCustomerContactChange}
								telephone={telephone}
								email={email}
								lineId={lineId}
								customers={customers}
								customerContacts={customerContacts}
								selectedChannel={selectedChannel}
								selectedCustomer={selectedCustomer}
								selectedCustomerContact={selectedCustomerContact}
							/>
						</div>
						<div className="column is-6">
							<div className="columns">
								<div className="column is-6">
									<label className="label">วันที่ (จาก)</label>
									<DatePicker
										selected={startDate}
										onChange={newDate => this.onStateChange('startDate', newDate)}
										dateFormat="DD MMM YYYY"
										className="input float-left"
									/>
								</div>
								<div className="column is-6">
									<label className="label">วันที่ (ถึง)</label>
									<DatePicker
										selected={endDate}
										onChange={newDate => this.onStateChange('endDate', newDate)}
										dateFormat="DD MMM YYYY"
										className="input float-left"
									/>
								</div>
							</div>
							<div className="row">
								<TextArea title="Note" labelSize="s" keyProps="note" value={note} onInputChange={this.onStateChange} row={4} />
							</div>
							<div className="row">
								<label className="label">วันกำหนดชำระเงิน</label>
								<DatePicker
									selected={dueDatePayment}
									onChange={newDate => this.onStateChange('dueDatePayment', newDate)}
									dateFormat="DD MMM YYYY"
									className="input float-left"
								/>
							</div>
						</div>
					</div>
					<div className="row columns">
						<div className="column is-6">
							<h3 className="subtitle is-4">
								<strong>
									<u>บริการ</u>
								</strong>
							</h3>
						</div>
					</div>
					<div className="row">
						{fitItems &&
							fitItems.map((fit, index) => (
								<FITItem
									//data
									index={index}
									key={'fit' + fit.id}
									{...fit}
									creditCards={creditCards}
									fitSuppliersRaw={fitSuppliersRaw}
									airlines={airlines}
									totalPrice={this.fitItemHelper.getItemPrice(fit)}
									//event
									onFitChange={this.onFitChange}
									onDetailChange={this.onDetailChange}
									addDetail={this.addDetail}
									removeDetail={this.removeDetail}
									onDetailArrayChange={this.onDetailArrayChange}
									onRemove={this.onRemoveFit}
								/>
							))}
					</div>
					<div className="row box clickable" style={{ backgroundColor: '#eeeeee', textAlign: 'center' }} onClick={this.addFitItem}>
						<b>เพิ่มรายการ</b>
					</div>
				</div>
				<div className="row" style={{ textAlign: 'right' }}>
					<button className="button is-success" onClick={this.save}>
						บันทึก
					</button>
				</div>
				<div
					style={{
						textAlign: 'right',
						position: 'fixed',
						top: 70,
						right: 10,
						backgroundColor: 'rgba(255, 255, 255, 0.8)',
						zIndex: 1000,
						padding: 30,
						borderStyle: 'solid',
						borderWidht: 1,
						borderColor: '#cccccc',
						borderRadius: 20,
					}}
				>
					ราคาขายรวม
					<h3 className="title is-3" style={{ marginBottom: 5 }}>
						<NumberColor number={totalPrice.salePrice + totalPrice.creditCharge} /> THB
					</h3>
					ต้นทุน: <NumberColor number={totalPrice.price} isExpense={true} /> THB
					<br />
					Credit Charge: <NumberColor number={totalPrice.creditCharge} isExpense={true} /> THB
					<br />
					กำไร: <NumberColor number={totalPrice.salePrice - totalPrice.price} /> THB
				</div>
			</div>
		);
	}
}
