import React, { Component } from 'react';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import FITTypeHelper from '../../FIT/Helpers/FITTypeHelper';

export default class FitGroupType extends Component {
	getSummary = list => {
		let summary = {
			customer: 0,
			price: 0,
			salePrice: 0,
			profit: 0,
		};

		list.forEach(row => {
			summary.customer += parseInt(row.customer);
			summary.price += row.price;
			summary.salePrice += row.salePrice;
			summary.profit += row.salePrice - row.price;
		});

		return summary;
	};
	render() {
		const { list, isLoading } = this.props;
		if (isLoading) {
			return <LoadingCenter />;
		}

		if (!list || list.length === 0) {
			return null;
		}
		const summary = this.getSummary(list);
		return (
			<div className="columns">
				<div className="column is-12">
					<h4 className="title is-4">FIT (Group By Types)</h4>
					<div className="row">
						<table className="table is-hoverable is-fullwidth is-bordered">
							<thead>
								<tr className="table-header">
									<td>Types</td>
									<td>จำนวนรายการ</td>
									<td>ราคาขาย</td>
									<td>ต้นทุน</td>
									<td>กำไร</td>
								</tr>
							</thead>
							<tbody>
								{list.map(row => (
									<tr key={'fitGroupType' + row.fitType}>
										<td>{FITTypeHelper.mapLabel(row.fitType)}</td>
										<td className="number-cell">
											<NumberColor number={row.customer} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.salePrice} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.price} isExpense={true} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.salePrice - row.price} />
										</td>
									</tr>
								))}
								<tr className="table-summary-row">
									<td>Summary</td>
									<td className="number-cell">
										<NumberColor number={summary.customer} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.salePrice} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.price} isExpense={true} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.profit} />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
