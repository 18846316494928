import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment-timezone';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendarDays } from '@fortawesome/free-solid-svg-icons';

import LoadingCenter from '../../../Components/LoadingCenter';
import AuthHelper from '../../../Helper/AuthHelper';
import AppConfig from '../../../AppConfig';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import DateLabel from '../../../Components/DateLabel';
import InputRow from '../../../Components/InputRow';
import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import APIPost from '../../../API/APIPost';
export default class Planning extends Component {
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		const filter = this.buildFilterFromQuery(query);
		this.state = {
			isLoading: false,
			filter,
			plans: [],
		};
	}

	buildFilterFromQuery = query => {
		const filter = {
			name: query.name ? query.name : '',
			startDate: query.startDate ? moment(new Date(query.startDate)) : moment(),
			returnDate: query.returnDate ? moment(new Date(query.returnDate)) : moment().add(30, 'days'),
		};

		return filter;
	};

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.props.history.listen(async (location, action) => {
				const query = queryString.parse(location.search);
				const filter = this.buildFilterFromQuery(query);

				this.setState({
					filter,
				});

				await this.searchFromFilter(filter);
			});

			await this.searchFromFilter(this.state.filter);
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	onFilterChange = (key, value) => {
		this.setState({
			filter: {
				...this.state.filter,
				[key]: value,
			},
		});
	};

	searchFromFilter = async filter => {
		const body = {
			departureDate: filter.startDate.format('YYYY-MM-DD'),
			returnDate: filter.returnDate.format('YYYY-MM-DD'),
		};

		if (filter.name) body.name = filter.name;
		// console.log('search request', body);

		let response = await APIGet('incentivePlan', body);

		if (APIHelper.handleAPIResponse(this, response)) {
			// console.log('search response', response.data);

			this.setState({
				isLoading: false,
				plans: [...response.data],
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	getSearchUrl = () => {
		let startDate = '';
		if (this.state.filter.startDate) {
			startDate = this.state.filter.startDate.format('YYYY-MM-DD');
		}

		let returnDate = '';
		if (this.state.filter.returnDate) {
			returnDate = this.state.filter.returnDate.format('YYYY-MM-DD');
		}
		const params = `?name=${this.state.filter.name}&&startDate=${startDate}&&returnDate=${returnDate}`;
		return params;
	};

	createNewPlan = async () => {
		const template = {
			id: null,
			name: 'New plan',
			departureDate: moment().format('YYYY-MM-DD'),
			returnDate: moment()
				.add(1, 'days')
				.format('YYYY-MM-DD'),
			staff: {
				id: AuthHelper.getUserDetail().id,
			},
		};

		let response = await APIPost('incentivePlan', template);

		if (APIHelper.handleAPIResponse(this, response)) {
			window.location.href = `planning-incentive/${response.data.id}`;
		} else {
			toast.error('ไม่สามารถสร้าง plan ใหม่ได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/'} title="ดูทั้งหมด" />
				<div className="column is-12">
					<h2 className="title is-12" style={{ fontSize: '2.5rem' }}>
						INCENTIVE PLANNING
					</h2>
				</div>

				<div className="column is-2" />
				<div className="columns">
					<div className="column is-3">
						<div style={{ marginTop: -8 }}>
							<InputRow
								placeholder="ค้นหาจากชื่อแพลน"
								keyProps="name"
								value={this.state.filter.name}
								onInputChange={this.onFilterChange}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="column is-3 control">
						<div className="control has-icons-right">
							<DatePicker
								selected={this.state.filter.startDate}
								onChange={newDate => this.onFilterChange('startDate', newDate)}
								dateFormat="DD MMM YYYY"
								className="input float-left"
							/>
							<span className="icon is-small is-right">
								<FontAwesomeIcon icon={faCalendarDays} />
							</span>
						</div>
					</div>
					<div className="column is-3">
						<div className="control has-icons-right">
							<DatePicker
								selected={this.state.filter.returnDate}
								onChange={newDate => this.onFilterChange('returnDate', newDate)}
								dateFormat="DD MMM YYYY"
								className="input float-left"
							/>
							<span className="icon is-small is-right">
								<FontAwesomeIcon icon={faCalendarDays} />
							</span>
						</div>
					</div>
					<div className="column is-1">
						<Link to={`${AppConfig.appPath}/programs/planning-incentive${this.getSearchUrl()}`}>
							<button className="button is-link">ค้นหา</button>
						</Link>
					</div>
					<div className="column is-2" style={{ textAlign: 'right' }}>
						<button
							className="button is-success"
							onClick={async e => {
								e.preventDefault();
								await this.createNewPlan();
							}}
						>
							+ เพิ่ม
						</button>

						{/* <Link className="button is-success" to={AppConfig.appPath + '/programs/newincentive'}>
							+ เพิ่ม
						</Link> */}
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<th width="45%">ชื่อแพลน</th>
								<th width="30%">ไป - กลับ</th>
								<th width="10%">สร้างโดย</th>
								<th width="15%">สร้างเมื่อ</th>
							</tr>
						</thead>
						<tbody>
							{this.state.plans.map(plan => (
								<tr key={`plan-${plan.id}`}>
									<td width="45%">
										<Link to={AppConfig.appPath + '/programs/planning-incentive/' + plan.id}>{plan.name}</Link>
									</td>
									<td width="15%" style={{ textAlign: 'center' }}>
										<DateRangeLabel startDate={plan.departureDate} endDate={plan.returnDate} />
									</td>
									<td width="10%" style={{ textAlign: 'center' }}>
										{plan.staff ? plan.staff.name : null}
									</td>
									<td width="15%" style={{ textAlign: 'center' }}>
										<DateLabel date={plan.created_at} />
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<br />
			</div>
		);
	}
}

const styles = {
	tableHeaderStyle: {
		color: '#ffffff',
		textAlign: 'center',
	},
	tableRowDataStyle: {
		textAlign: 'center',
	},
};
