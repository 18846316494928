import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';

import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

export default class Income extends PureComponent {
	getSummary = incomes => {
		let result = 0;
		incomes.forEach(income => {
			result += income.total;
		});

		return result;
	};

	render() {
		const { fit, incomes, isFullyPaid } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">
							รายรับ {isFullyPaid ? <span className="tag is-success">ชำระครบแล้ว</span> : <span className="tag is-warning">ชำระยังไม่ครบ</span>}
						</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						{fit.paid < fit.salePrice ? (
							<Link className="button is-success" to={AppConfig.appPath + '/programs/fit/' + this.props.fitId + '/newincome'}>
								เพิ่มรายการ
							</Link>
						) : null}
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="30%">รายการ</td>
								<td width="15%">เอกสาร</td>
								<td width="15%">Payment Date</td>
								<td>ราคา (บาท)</td>
							</tr>
						</thead>
						<tbody>
							{incomes.map(income => (
								<tr key={'income' + income.id}>
									<td>
										<Link to={AppConfig.appPath + '/programs/fit/' + this.props.fitId + '/incomes/' + income.id}>{income.title}</Link>
										{!income.financeStaffId ? (
											<span className="tag is-warning">พนักงานบัญชียังไม่ตรวจสอบ</span>
										) : (
											<span className="tag is-success">พนักงานบัญชีตรวจสอบแล้ว</span>
										)}
									</td>
									<td style={{ textAlign: 'center' }}>
										<a onClick={() => OpenLink.fitReceipt(income.receipt.id)} target="_blank">
											{income.receipt ? income.receipt.documentNumber : ''}
										</a>
									</td>
									<td style={{ textAlign: 'center' }}>
										<DateLabel date={income.paymentDate} />
									</td>
									<td className="number-cell">
										<NumberColor number={income.total} />
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan="3">Summary</td>
								<td className="number-cell">
									<NumberColor number={this.getSummary(incomes)} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
