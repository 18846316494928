import moment from 'moment-timezone';

import AppConfig from '../AppConfig';

export default () => {
	const currentYear = parseInt(moment(new Date()).format('YYYY'));
	const startYear = AppConfig.startFilterYear;
	let result = [];
	for (var i = startYear; i <= currentYear + 1; i++) {
		result.push({
			value: i,
			label: i,
		});
	}

	return result;
};
