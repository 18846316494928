import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import ProgramHeader from '../../../Components/ProgramHeader';
import PaymentDetail from '../../../Components/PaymentDetail';
import AuthRender from '../../../Components/AuthRender';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import ValidatePaymentDetail from '../../../Helper/ValidatePaymentDetail';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

import PAYMENT_METHODS from '../../../StaticData/PaymentMethods';

export default class AddEditExpense extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			dueDatePayment: moment(new Date()),
			paymentDate: moment(new Date()),
			paymentTime: '',
			note: '',
			total: 0,
			paymentMethod: PAYMENT_METHODS[0],
			title: '',
			type: '',
			bank: null,
			checkNumber: '',
			bankAccount: null,
			creditCard: null,
			supplier: null,
			isFinanceReviewed: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			await this.initUpdate();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initUpdate = async () => {
		const { programId, expenseId } = this.props.match.params;
		let requestExpense = await APIGet('fit/programs/' + programId + '/expensetosuppliers/' + expenseId);
		if (APIHelper.handleAPIResponse(this, requestExpense)) {
			const expense = requestExpense.data;
			let isFinanceReviewed = null;
			if (AuthHelper.getUserDetail().authRole === 'Finance') {
				if (expense.financeStaff) {
					isFinanceReviewed = true;
				} else {
					isFinanceReviewed = false;
				}
			}

			console.log('expense: ', expense);
			let paymentMethod = PAYMENT_METHODS.filter(p => p.value === expense.paymentMethod)[0];
			if (!paymentMethod) {
				paymentMethod = PAYMENT_METHODS[0];
			}

			this.setState({
				isLoading: false,
				...expense,
				paymentMethod,
				bank: expense.bank
					? {
							value: expense.bankId,
							label: expense.bank.name,
					  }
					: null,
				bankAccount: expense.bankAccount
					? {
							value: expense.bankAccountId,
							label: expense.bankAccount.name,
					  }
					: null,
				dueDatePayment: expense.dueDatePayment ? moment(new Date(expense.dueDatePayment)) : null,
				paymentDate: expense.paymentDate ? moment(new Date(expense.paymentDate)) : null,
				creditCard: expense.creditCard
					? {
							value: expense.creditCardId,
							label: expense.creditCard.name,
					  }
					: null,
				isFinanceReviewed,
			});

			return true;
		} else if (requestExpense.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	save = async () => {
		const { programId, expenseId } = this.props.match.params;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				...this.state,
				fitProgramId: programId,
				paymentMethod: this.state.paymentMethod ? this.state.paymentMethod.value : PAYMENT_METHODS[0],
				paymentDate: this.state.paymentDate ? this.state.paymentDate.format('YYYY-MM-DD') : null,
				dueDatePayment: this.state.dueDatePayment ? this.state.dueDatePayment.format('YYYY-MM-DD') : null,
				isFinanceReviewed: this.state.isFinanceReviewed,
			};

			if (this.state.paymentMethod) {
				if (this.state.paymentMethod.value === 'Transfer') {
					data.bankAccountId = this.state.bankAccount.value;
					data.paymentTime = this.state.paymentTime;
				} else if (this.state.paymentMethod.value === 'Check') {
					data.bankId = this.state.bank.value;
					data.checkNumber = this.state.checkNumber;
				} else if (this.state.paymentMethod.value === 'Credit Card') {
					data.creditCardId = this.state.creditCard.value;
				}
			}

			const request = await APIPut('fit/programs/' + programId + '/expensetosuppliers/' + expenseId, data);

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success('แก้ไขรายจ่ายเรียบร้อยแล้ว');
				const result = request.data;
				this.props.history.push(AppConfig.appPath + '/programs/fit/' + programId + '/expensetosuppliers/' + result.id);
			} else {
				toast.error('เกิดปัญหาในการแก้ไขรายจ่าย');
				this.setState({
					isLoading: false,
				});
			}
		}
	};

	validateSaveState = () => {
		const paymentDetail = ValidatePaymentDetail(this.state, toast);
		return paymentDetail;
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onPaymentDateChange = newDate => {
		this.setState({
			paymentDate: newDate,
		});
	};

	onDueDatePaymentChange = newDate => {
		this.setState({
			dueDatePayment: newDate,
		});
	};

	toggleFinanceReviewed = () => {
		this.setState({
			isFinanceReviewed: !this.state.isFinanceReviewed,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { programId, expenseId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/fit/' + programId + '/expensetosuppliers/' + expenseId} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">แก้ไขการชำระเงิน: {this.state.title}</h3>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-4">
							<label className="label">วันครบกำหนดชำระ</label>
							<DatePicker
								selected={this.state.dueDatePayment}
								onChange={this.onDueDatePaymentChange}
								dateFormat="DD MMM YYYY"
								className="input float-left"
							/>
						</div>
						<div className="column is-4">
							<label className="label">Supplier</label>
							<strong>{this.state.supplier ? this.state.supplier.name : ''}</strong>
						</div>
						{this.state.suppliers && this.state.suppliers.length > 0 ? (
							<div className="column is-4">
								<label className="label">Supplier</label>
								<Select
									name="supplier"
									value={this.state.supplier}
									clearable={false}
									onChange={value => this.handleSelectChange('supplier', value)}
									options={this.state.suppliers}
								/>
							</div>
						) : null}
					</div>
					<div className="columns row">
						<div className="column is-12">
							{!this.state.paymentMethod || this.state.paymentMethod === PAYMENT_METHODS[0] ? (
								<div>
									<label className="label">วิธีการชำระเงิน</label>
									<Select
										name="paymentMethod"
										value={this.state.paymentMethod}
										clearable={false}
										onChange={value => this.handleSelectChange('paymentMethod', value)}
										options={PAYMENT_METHODS}
									/>
								</div>
							) : (
								<PaymentDetail
									paymentMethod={this.state.paymentMethod}
									paymentTime={this.state.paymentTime}
									bank={this.state.bank}
									bankAccount={this.state.bankAccount}
									creditCard={this.state.creditCard}
									checkNumber={this.state.checkNumber}
									currency={this.state.currency}
									currencyRate={this.state.currencyRate}
									paymentDate={this.state.paymentDate}
									handleSelectChange={this.handleSelectChange}
									onPaymentDateChange={this.onPaymentDateChange}
									onTextChange={this.onTextChange}
									disableCurrency={true}
								/>
							)}
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onTextChange}
									isMandatory={false}
								/>
							</div>
							<div style={{ textAlign: 'right', marginTop: 20 }}>
								<AuthRender roles={['Finance']}>
									<label className="checkbox">
										<input
											type="checkbox"
											value={this.state.isFinanceReviewed}
											onClick={this.toggleFinanceReviewed}
											checked={this.state.isFinanceReviewed ? 'checked' : ''}
											onChange={() => {}}
										/>
										&nbsp;ตรวจสอบโดยพนักงานบัญชีแล้ว
									</label>
								</AuthRender>
								<span> </span>
								<button className="button is-success" onClick={this.save}>
									บันทึก
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
