import AppConfig from '../AppConfig';
import APIHelper from '../Helper/APIHelper';

export default async function APIGet(service, params = {}) {
	var data = null;
	var status = null;
	var statusText = null;
	try {
		const queryString = buildQueryString(params);
		const url = AppConfig.apiUrl + service + queryString;
		if (!data) {
			const requestHeaders = APIHelper.getHttpHeader();
			const response = await fetch(url, {
				method: 'GET',
				headers: requestHeaders,
			});

			status = response.status;
			statusText = response.statusText;
			try {
				data = await response.json();
			} catch (error) {}
		}
	} catch (error) {
		console.log(error);
	}

	return { status, statusText, data };
}

function buildQueryString(params) {
	let paramString = '';
	if (params) {
		paramString = '?';
		for (var key in params) {
			if (params.hasOwnProperty(key)) {
				paramString = paramString + key + '=' + params[key] + '&';
			}
		}

		paramString = paramString.slice(0, -1);
	}

	return paramString;
}
