import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'react-loading';

import APIGet from '../../API/APIGet';
import APIHelper from '../../Helper/APIHelper';
import AuthHelper from '../../Helper/AuthHelper';

import AppConfig from '../../AppConfig';

class MobileNotification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			notifications: [],
		};
	}

	componentDidMount = () => {
		this.fetchNotification();
		this.props.history.listen((location, action) => {
			console.log(location);
			this.fetchNotification();
		});
	};

	fetchNotification = async () => {
		if (AuthHelper.isLogin()) {
			const request = await APIGet('notifications/unread');
			if (APIHelper.handleAPIResponse(this, request)) {
				const notifications = request.data;
				// console.log('notifications: ', notifications);
				this.setState({
					isLoading: false,
					notifications,
				});
			} else {
				toast.warn('ไม่สามารถดึงข้อมูล Notification ได้');
			}
		}
	};

	render() {
		if (this.state.isLoading) {
			return (
				<div className="navbar-item">
					<Loading type="spin" color="#eeeeee" delay={0} height={35} width={35} />
				</div>
			);
		}

		const { notifications } = this.state;

		return (
			<div className="navbar-item">
				<NavLink to={AppConfig.appPath + '/notifications/mobile'} className="navbar-item clickable" activeClassName="is-active">
					<span className="tag is-warning">{notifications.length}</span>
				</NavLink>
			</div>
		);
	}
}

export default withRouter(MobileNotification);
