import React, { Component } from 'react';
import InputRows from './InputRow';

export const EditableTextareaSwitch = props => {
	return (
		<textarea
			key={props.keyProps}
			className="textarea"
			placeholder="note"
			onChange={e => {
				props.onChange(e.target.value);
			}}
			disabled={!props.isEditing}
			value={props.value}
		/>
	);
};
