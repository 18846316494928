import React, { useState } from 'react';
import { toast } from 'react-toastify';

import LoadingCenter from '../LoadingCenter';
import InputWithLabel from '../InputWithLabel';
import TextAreaWithLabel from '../TextAreaWithLabel';

import APIPost from '../../API/APIPost';
import APIHelper from '../../Helper/APIHelper';

export default ({ isShown, toggleModal, onCustomerAdded }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState('');
	const [nickname, setNickname] = useState('');
	const [lineId, setLineId] = useState('');
	const [email, setEmail] = useState('');
	const [telephone, setTelephone] = useState('');
	const [note, setNote] = useState('');
	if (!isShown) {
		return null;
	}

	const save = async () => {
		setIsLoading(true);
		if (validateSaveState()) {
			let data = {
				name,
				nickname,
				telephone,
				lineId,
				email,
				note,
			};

			const request = await APIPost('jointour/customers', data);
			setIsLoading(false);
			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success('เพิ่มเรียบร้อยแล้ว');
				const result = request.data;
				onCustomerAdded(result);
			} else if (request.status === 409) {
				toast.warn('มีลูกค้าชื่อนี้อยู่ในระบบอยู่แล้ว');
			} else {
				toast.error('เกิดปัญหาในการเพิ่มลูกค้า');
			}
		}
	};

	const validateSaveState = () => {
		if (!name.trim()) {
			toast.warn('กรุณาระบุชื่อ');
		}

		if (!telephone.trim()) {
			toast.warn('กรุณาระบุเบอร์โทรศัพท์');
		}

		return name && telephone;
	};

	if (isLoading) {
		return (
			<div className="modal is-active">
				<div className="modal-background" onClick={toggleModal} />
				<div className="modal-card" style={{ minHeight: 500, minWidth: '90%' }}>
					<header className="modal-card-head">
						<p className="modal-card-title">เพิ่มลูกค้า Jointour</p>
						<button className="delete" onClick={toggleModal} />
					</header>
					<section className="modal-card-body">
						<LoadingCenter />
					</section>
				</div>
			</div>
		);
	}

	return (
		<div className="modal is-active">
			<div className="modal-background" onClick={toggleModal} />
			<div className="modal-card" style={{ minHeight: 500, minWidth: '75%' }}>
				<header className="modal-card-head">
					<p className="modal-card-title">เพิ่มลูกค้า Jointour</p>
					<button className="delete" onClick={toggleModal} />
				</header>
				<section className="modal-card-body">
					<div className="columns">
						<div className="column is-4">
							<InputWithLabel title="ชื่อลูกค้า" placeholder="ชื่อลูกค้า" keyProps="name" onChange={setName} value={name} disabled={false} />
						</div>
						<div className="column is-4">
							<InputWithLabel
								title="ชื่อเล่น"
								placeholder="ชื่อเล่น"
								keyProps="nickname"
								onChange={setNickname}
								value={nickname}
								disabled={false}
							/>
						</div>
						<div className="column is-4">
							<InputWithLabel title="Line Id" placeholder="Line Id" keyProps="lineId" onChange={setLineId} value={lineId} disabled={false} />
						</div>
					</div>
					<div className="columns row">
						<div className="column is-6">
							<InputWithLabel title="email" placeholder="email" keyProps="email" onChange={setEmail} value={email} disabled={false} />
						</div>
						<div className="column is-6">
							<InputWithLabel
								title="telephone"
								placeholder="telephone"
								keyProps="telephone"
								onChange={setTelephone}
								value={telephone}
								disabled={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-12">
							<TextAreaWithLabel title="Note" labelSize="s" keyProps="note" value={note} onChange={setNote} isMandatory={false} />
						</div>
					</div>
				</section>
				<footer className="modal-card-foot level">
					<div className="level-item level-left" />
					<div className="level-item level-right">
						<button className="button is-success" onClick={save}>
							Save
						</button>
						<button className="button" onClick={toggleModal}>
							Cancel
						</button>
					</div>
				</footer>
			</div>
		</div>
	);
};
