import React, { useMemo } from 'react';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import { SumTable } from './SumTable';

import ADULT_CHILD from '../../../../StaticData/AdultChild';
import { DEFAULT_VISA } from '../../../../StaticData/SubPlanTemplate';
import { Remark } from './Remark';

export const VisaTabs = ({ selectedSubPlan, state, onChange, onRemarkChange }) => {
	const { isEditing, selectedTab, exchangeRates, selectAdultChidOption } = state;

	if (selectedTab !== 'visa') {
		return <></>;
	}

	if (!selectedSubPlan) return <></>;

	const visas = selectedSubPlan.visas;

	return (
		<div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 50, display: selectedTab === 'visa' ? 'block' : 'none' }}>
			<Remark state={state} onChange={onRemarkChange} />
			<p className="" style={{ backgroundColor: '#209CEE', fontSize: 25, fontWeight: 'bold', color: 'white', padding: 5, paddingLeft: 10 }}>
				VISA
			</p>
			<div style={{ height: '30px' }} />
			{visas && visas.length > 0
				? visas.map((visa, index) => (
						<VisaRow
							key={`ticket-${index}`}
							visa={visa}
							exchangeRates={exchangeRates}
							index={index}
							isEditing={isEditing}
							onChange={(index, newVisa) => {
								onChange([...visas.slice(0, index), newVisa, ...visas.slice(index + 1, visas.length)]);
							}}
							onRemove={index => {
								onChange([...visas.slice(0, index), ...visas.slice(index + 1, visas.length)]);
							}}
						/>
				  ))
				: null}

			<div style={{ height: '50px' }} />
			{isEditing ? (
				<button
					className="button is-info"
					onClick={e => {
						e.preventDefault();
						console.log('on add called');
						onChange([...(visas ? visas : []), DEFAULT_VISA]);
					}}
				>
					+เพิ่ม visa
				</button>
			) : null}

			<div />
		</div>
	);
};

const VisaRow = ({ index, isEditing, visa, exchangeRates, onChange, onRemove }) => {
	const _onChange = (key, value) => {
		const updatedData = JSON.parse(JSON.stringify(visa));
		updatedData[key] = value;
		onChange(index, updatedData);
	};

	const selectedCurrency = useMemo(() => {
		if (visa.currency && !visa.currency.label) {
			return {
				value: visa.currency.id,
				label: visa.currency.name,
			};
		}

		return visa.currency;
	}, [visa.currency]);

	const selectedCustomerType = useMemo(() => {
		if (visa.customerType && !visa.customerType.label) {
			if (visa.customerType === 'adult') {
				return ADULT_CHILD[0];
			} else if (visa.customerType === 'child') {
				return ADULT_CHILD[1];
			}
		}

		return visa.customerType;
	}, [visa.customerType]);
	return (
		<div style={{ marginBottom: 30 }}>
			<div className="columns" style={{ justifyContent: 'center' }}>
				<div className="column is-4">
					<div className="level level-item">
						<p style={{ paddingRight: 20 }}>{index + 1}.</p>
						<div className="" style={{ width: '100%' }}>
							<EditableTextSwitch
								isEditing={isEditing}
								type={'text'}
								value={visa.name}
								onChange={text => {
									_onChange('name', text);
								}}
							>
								<p style={{ textAlign: 'left' }}>{visa.name}</p>
							</EditableTextSwitch>
						</div>
					</div>
				</div>
				<div className="column is-2" />
				<div className="column is-2">
					<Select
						placeholder="สกุลเงิน"
						value={selectedCurrency}
						onChange={currency => {
							_onChange('currency', {
								id: currency.value,
								name: currency.label,
							});
						}}
						options={exchangeRates.map(currency => ({ value: currency.id, label: currency.name }))}
						isDisabled={!isEditing}
					/>
				</div>
				<div className="column is-2">
					<Select
						placeholder="ผู้ใหญ่/เด็ก"
						value={selectedCustomerType}
						onChange={customerType => {
							_onChange('customerType', customerType.value);
						}}
						options={ADULT_CHILD}
						isDisabled={!isEditing}
					/>
				</div>
				<div className="column is-1" style={{ display: 'flex', alignItems: 'center' }}>
					<FontAwesomeIcon
						icon={faTrashCan}
						style={{ color: isEditing ? 'red' : 'black', cursor: isEditing ? 'pointer' : 'auto' }}
						onClick={e => {
							e.preventDefault();
							if (isEditing) {
								onRemove(index);
							}
						}}
					/>
				</div>
			</div>
			<div className="" style={{ paddingLeft: 70, paddingRight: 50 }}>
				<table className="" style={{ width: '100%' }}>
					<tbody>
						{visa.expenses && visa.expenses.length > 0
							? visa.expenses.map((expense, expenseIndex) => (
									<tr key={`visa-${index}-expense-${expenseIndex}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
										<td style={{ width: '3%' }}>•</td>
										<td style={{ width: '35%' }}>{expense.name}</td>
										<td style={{ width: '20%' }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'number'}
												value={expense.price}
												onChange={text => {
													const updatedData = JSON.parse(JSON.stringify(visa));
													updatedData.expenses[expenseIndex] = {
														...expense,
														price: text,
													};

													onChange(index, updatedData);
												}}
											>
												<p style={{ textAlign: 'center' }}>{expense.price}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '1%', paddingLeft: 10, paddingRight: 15 }}>x</td>
										<td style={{ width: '12%' }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'number'}
												value={expense.quantity}
												onChange={text => {
													const updatedData = JSON.parse(JSON.stringify(visa));
													updatedData.expenses[expenseIndex] = {
														...expense,
														quantity: text,
													};

													onChange(index, updatedData);
												}}
											>
												<p style={{ textAlign: 'center' }}>{expense.quantity}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '15%', display: 'flex', paddingLeft: 10 }}>
											<p style={{ paddingRight: 10 }}>=</p>
											<p>{(expense.price * expense.quantity).toLocaleString()}</p>
										</td>
									</tr>
							  ))
							: null}
						<tr style={{ display: 'flex', alignItems: 'center', marginTop: isEditing ? 20 : 0, marginBottom: 15 }}>
							<td style={{ width: '3%' }}>•</td>
							<td style={{ width: '35%' }}>รวม</td>
							<td style={{ width: '20%' }} />
							<td style={{ width: '1%', paddingLeft: 10, paddingRight: 15 }} />
							<td style={{ width: '12%' }} />
							<td style={{ width: '15%', display: 'flex', paddingLeft: 10 }}>
								<p style={{ paddingRight: 10 }}>=</p>
								<p>
									{visa.expenses && visa.expenses.length > 0
										? visa.expenses.reduce((acc, expense) => acc + expense.price * expense.quantity, 0).toLocaleString()
										: 0}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
