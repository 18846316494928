import FITTypes from '../../../StaticData/FITTypes';
const FITTypeHelper = (() => {
	const mapLabel = fitType => {
		const fit = FITTypes.filter(f => f.value === fitType);
		if (fit.length === 1) {
			return fit[0].label;
		} else {
			return '';
		}
	};

	return {
		mapLabel,
	};
})();

export default FITTypeHelper;
