import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import AppConfig from '../../../AppConfig';

export default class Staffs extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			staffs: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Admin')) {
			const request = await APIGet('staffs');
			if (APIHelper.handleAPIResponse(this, request)) {
				const staffs = request.data.filter(
					staff => staff.role !== 'Admin'
				);
				console.log('staffs: ', staffs);
				this.setState({
					isLoading: false,
					staffs,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Staffs</h1>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link
							className="button is-info"
							to={AppConfig.appPath + '/data/newstaff'}
						>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.staffs.map(staff => (
								<tr key={staff.id}>
									<td width="70%">
										<Link
											to={
												AppConfig.appPath +
												'/data/staffs/' +
												staff.id
											}
										>
											{staff.name}
										</Link>
									</td>
									<td
										width="30%"
										style={{ textAlign: 'right' }}
									>
										{staff.role}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
