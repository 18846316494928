import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import EditableText from '../../../Components/EditableText';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

export default class Countries extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			countries: [],
			isEditLoading: '',
			newCountry: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('countries');
			if (APIHelper.handleAPIResponse(this, request)) {
				const countries = request.data;
				console.log(countries);
				this.setState({
					isLoading: false,
					countries,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	saveChange = async (countryId, text) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPut('countries/' + countryId, {
			name: text,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const country = request.data;
			let countries = JSON.parse(JSON.stringify(this.state.countries));
			for (let i = 0; i < countries.length; i++) {
				if (countries[i].id === countryId) {
					countries[i] = country;
				}
			}

			// console.log(countries);
			toast.success('แก้ไขข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				countries,
			});
		} else {
			toast.error('ไม่สามารถแก้ไขข้อมูลได้');
		}
	};

	addNew = async text => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPost('countries', {
			name: text,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const country = request.data;
			let countries = JSON.parse(JSON.stringify(this.state.countries));
			countries.push(country);

			// console.log(countries);
			toast.success('เพิ่มข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				countries,
			});
		} else {
			toast.error('ไม่สามารถเพิ่มข้อมูลได้');
		}
	};

	delete = async countryId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete('countries/' + countryId);
		if (APIHelper.handleAPIResponse(this, request)) {
			const countries = this.state.countries.filter(country => country.id !== countryId);

			// console.log(countries);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				countries,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">ประเทศ</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.countries.map(country => (
								<tr key={country.id}>
									<td width="100%">
										<EditableText
											id={country.id}
											text={country.name}
											confirmChange={text => this.saveChange(country.id, text)}
											confirmDelete={this.delete}
										>
											{country.name}
										</EditableText>
									</td>
								</tr>
							))}
							<tr>
								<td width="100%">
									<EditableText text={this.state.newCountry} confirmChange={text => this.addNew(text)} confirmText="เพิ่ม">
										เพิ่มประเทศ
									</EditableText>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
