import React from 'react';
import Select from 'react-select';

import NumberColor from '../../../Components/NumberColor';
import DeleteButtom from '../../../Components/DeleteButton';
import TextArea from '../../../Components/TextArea';
import InputRow from '../../../Components/InputRow';

import FITTypes from '../../../StaticData/FITTypes';
import CURRENCIES from '../../../StaticData/Currencies';

import ThingsToSelect from '../../../Helper/ThingsToSelect';

import FITItemTicket from './FITItemTicket';
import FITItemVisa from './FITItemVisa';

export default class FITItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fitSuppliers: props.fitSuppliers ? props.fitSuppliers : [],
		};
	}

	onDetailChange = (key, value) => {
		this.props.onDetailChange(this.props.index, key, value);
	};

	addDetail = () => {
		this.props.addDetail(this.props.index);
	};

	removeDetail = index => {
		this.props.removeDetail(this.props.index, index);
	};

	onDetailArrayChange = (index, key, value) => {
		this.props.onDetailArrayChange(this.props.index, index, key, value);
	};

	renderFitItem = () => {
		const {
			selectedFitType,
			fitTicket,
			fitVisa,
			fitHotel,
			fitCarRental,
			fitInsurrance,
			fitTravelTicket,
			fitPocketWifiSim,
			fitTranslation,
			airlines,
		} = this.props;
		if (!selectedFitType) {
			return null;
		}
		if (selectedFitType.value === 'fitTicket') {
			return (
				<FITItemTicket
					{...fitTicket}
					airlines={airlines}
					onChange={this.onDetailChange}
					onAdd={this.addDetail}
					onRemove={this.removeDetail}
					onPassengerChange={this.onDetailArrayChange}
				/>
			);
		} else {
			let fitDetails = [];
			if (selectedFitType.value === 'fitVisa') {
				fitDetails = fitVisa.details;
			} else if (selectedFitType.value === 'fitHotel') {
				fitDetails = fitHotel.details;
			} else if (selectedFitType.value === 'fitCarRental') {
				fitDetails = fitCarRental.details;
			} else if (selectedFitType.value === 'fitInsurrance') {
				fitDetails = fitInsurrance.details;
			} else if (selectedFitType.value === 'fitTravelTicket') {
				fitDetails = fitTravelTicket.details;
			} else if (selectedFitType.value === 'fitPocketWifiSim') {
				fitDetails = fitPocketWifiSim.details;
			} else if (selectedFitType.value === 'fitTranslation') {
				fitDetails = fitTranslation.details;
			}

			return <FITItemVisa fitDetails={fitDetails} onChange={this.onDetailArrayChange} onAdd={this.addDetail} onRemove={this.removeDetail} />;
		}
	};

	onFitTypeChange = value => {
		const fitSuppliers = ThingsToSelect(this.props.fitSuppliersRaw.filter(supplier => supplier.fitType === value.value));
		this.props.onFitChange(this.props.index, 'selectedFitType', value);
		this.setState({
			fitSuppliers,
		});
	};

	onSupplierChange = value => {
		this.props.onFitChange(this.props.index, 'selectedSupplier', value);
	};

	onCreditCardCurrencyChange = value => {
		this.props.onFitChange(this.props.index, 'selectedCreditCardCurrency', value);
	};

	onCreditCardChange = value => {
		this.props.onFitChange(this.props.index, 'selectedCreditCard', value);
	};

	onTextChange = (key, value) => {
		this.props.onFitChange(this.props.index, key, value);
	};

	remove = () => {
		this.props.onRemove(this.props.index);
	};

	render() {
		const {
			totalPrice,
			selectedFitType,
			selectedSupplier,
			note,
			creditCardRecord,
			selectedCreditCardCurrency,
			selectedCreditCard,
			creditCharge,
		} = this.props;
		const { fitSuppliers } = this.state;
		return (
			<div className="box">
				<div className="columns">
					<div className="column is-3" style={{ textAlign: 'left' }}>
						ราคาขาย
						<h4 className="title is-4">
							<NumberColor number={totalPrice.salePrice + totalPrice.creditCharge} /> THB
						</h4>
						{totalPrice.creditCharge > 0 ? (
							<span>
								(รวม credit charge: <NumberColor number={totalPrice.creditCharge} /> THB)
							</span>
						) : null}
					</div>
					<div className="column is-3" style={{ textAlign: 'left' }}>
						ต้นทุน
						<h4 className="title is-4">
							<NumberColor number={totalPrice.price} /> THB
						</h4>
					</div>
					<div className="column is-3" style={{ textAlign: 'left' }}>
						กำไร
						<h4 className="title is-4">
							<NumberColor number={totalPrice.salePrice - totalPrice.price} /> THB
						</h4>
					</div>
					<div className="column is-3" style={{ textAlign: 'right' }}>
						<DeleteButtom label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.remove} />
					</div>
				</div>
				<div className="columns">
					<div className="column is-2">
						<label className="label">Service (บริการ)</label>
						<Select name="fitType" value={selectedFitType} clearable={false} onChange={this.onFitTypeChange} options={FITTypes} />
					</div>
					<div className="column is-2">
						<label className="label">Supplier</label>
						<Select name="supplier" value={selectedSupplier} clearable={false} onChange={this.onSupplierChange} options={fitSuppliers} />
					</div>

					<div className="column is-2">
						<InputRow
							title="Credit Charge (%)"
							labelSize="s"
							placeholder="Credit Charge (%)"
							keyProps="creditCharge"
							value={creditCharge}
							onInputChange={this.onTextChange}
							disabled={false}
						/>
					</div>
					<div className="column is-2">
						<InputRow
							title="ยอดที่ตัดบัตร"
							labelSize="s"
							placeholder="ยอดที่ตัดบัตร"
							keyProps="creditCardRecord"
							value={creditCardRecord}
							onInputChange={this.onTextChange}
							disabled={false}
						/>
					</div>
					<div className="column is-2">
						<label className="label">สกุลเงิน</label>
						<Select
							name="fitType"
							value={selectedCreditCardCurrency}
							clearable={false}
							onChange={this.onCreditCardCurrencyChange}
							options={CURRENCIES}
						/>
					</div>
					<div className="column is-2">
						<label className="label">Credit Card</label>
						<Select
							name="selectedCreditCard"
							value={selectedCreditCard}
							clearable={false}
							onChange={this.onCreditCardChange}
							options={this.props.creditCards}
						/>
					</div>
				</div>
				<div className="row">{this.renderFitItem()}</div>
				<div className="row">
					<TextArea title="Remark" labelSize="s" keyProps="note" value={note} onInputChange={this.onTextChange} row={4} />
				</div>
			</div>
		);
	}
}
