import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WholePageLoading from '../../../Components/WholePageLoading';
import NumberColor from '../../../Components/NumberColor';
import InputRow from '../../../Components/InputRow';
import BackButtonRouter from '../../../Components/BackButtonRouter';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';

import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import MonthFilter from '../../../Helper/MonthFilter';
import YearFilter from '../../../Helper/YearFilter';
import OpenLink from '../../../Helper/OpenLink';

const MONTHS = MonthFilter();
const YEARS = YearFilter();
const DEFAULT_NEWEXPENSE = {
	title: '',
	unit: 0,
	combaht: 0,
	combahtPercentage: 0,
	total: 0,
};

export default class CommissionDetail extends Component {
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		const date = query.month && query.year ? moment(new Date(query.month + ' ' + query.year)) : moment(new Date());
		this.state = {
			isLoading: true,
			month: {
				label: date.format('MMMM'),
				value: date.format('MMMM'),
			},
			year: {
				label: date.format('YYYY'),
				value: date.format('YYYY'),
			},
			expenses: [],
			staff: null,
		};
	}

	componentDidMount = async () => {
		if (AuthHelper.hasRight('Admin')) {
			await this.getData();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	getData = async () => {
		this.setState({
			isLoading: true,
		});
		let { month, year } = this.state;
		const { staffId } = this.props.match.params;
		let request = APIGet(`finance/commissions/${year.value}/${month.value}/${staffId}`);
		let requestStaff = APIGet('staffs/' + staffId);
		request = await request;
		requestStaff = await requestStaff;
		if (APIHelper.handleAPIResponse(this, request, requestStaff)) {
			const expenses = request.data;
			const staff = requestStaff.data;
			console.log('data: ', expenses);
			console.log('staff: ', staff);

			this.setState({
				expenses,
				staff,
				isLoading: false,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	save = async () => {
		this.setState({
			isLoading: true,
		});
		const { month, year, expenses } = this.state;
		const { staffId } = this.props.match.params;
		let data = {
			expenses,
		};

		const request = await APIPost(`finance/commissions/${year.value}/${month.value}/${staffId}`, data);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('แก้ไขรายการเรียบร้อยแล้ว');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('เกิดปัญหาในการเพิ่มข้อมูล');
			this.setState({
				isLoading: false,
			});
		}
	};

	updateExpense = (index, key, value) => {
		let expenses = [...this.state.expenses];
		expenses[index][key] = value;
		if (key === 'combaht') {
			expenses[index].total = value * expenses[index].unit;
			expenses[index].combahtPercentage = 0;
		} else if (key === 'combahtPercentage') {
			expenses[index].total = (value * expenses[index].unit) / 100;
			expenses[index].combaht = 0;
		}

		// console.log(index, key, value);

		this.setState({
			expenses,
		});
	};

	addNewExpense = () => {
		let expenses = [
			...this.state.expenses,
			{
				...DEFAULT_NEWEXPENSE,
			},
		];
		this.setState({
			expenses,
		});
	};

	getTotal = () => {
		const { expenses } = this.state;
		let total = 0;
		expenses.forEach(expense => {
			let record = parseFloat(expense.total);
			if (isNaN(record)) {
				record = 0;
			}
			total += record;
		});

		return total;
	};

	print = async () => {
		await this.save();
		const { month, year } = this.state;
		const { staffId } = this.props.match.params;
		OpenLink.printCommission(year.value, month.value, staffId);
	};

	removeRow = index => {
		const expenses = this.state.expenses.slice(0, index).concat(this.state.expenses.slice(index + 1, this.state.expenses.length));
		this.setState({
			expenses,
		});
	};

	render() {
		let { month, year, expenses, staff } = this.state;
		const total = this.getTotal();
		return (
			<div className="container">
				<div className="section">
					<BackButtonRouter />
					<div className="columns">
						<div className="column is-6">
							<span>Commission </span>
							<h3 className="title is-3">{staff && staff.name ? `(${staff.name} - ${staff.nickname})` : ''}</h3>
						</div>
						<div className="column is-2" style={{}}>
							<Select name="year" value={year} clearable={false} onChange={value => this.setState({ year: value })} options={YEARS} />
						</div>
						<div className="column is-2" style={{}}>
							<Select name="month" value={month} clearable={false} onChange={value => this.setState({ month: value })} options={MONTHS} />
						</div>
						<div className="column is-2">
							<button className="button is-info" onClick={this.getData}>
								Go
							</button>
						</div>
					</div>
				</div>
				<div className="section">
					<div style={{ textAlign: 'right', marginBottom: 5 }}>
						<button className="button is-primary" onClick={this.print}>
							Print
						</button>
					</div>
					<table className="table is-hoverable is-fullwidth is-bordered">
						<thead>
							<tr className="table-header">
								<td width="5%" />
								<td width="30%">Product/Sale</td>
								<td width="20%">Unit</td>
								<td width="15%">Com/Baht</td>
								<td width="15%">Com/Baht (%)</td>
								<td width="15%">Summary/Baht</td>
							</tr>
						</thead>
						<tbody>
							{expenses.map((expense, index) => (
								<tr key={'expense' + index}>
									<td align="center" onClick={() => this.removeRow(index)} className="clickable">
										<FontAwesomeIcon icon="minus-circle" />
									</td>
									<td>
										<InputRow
											hideLabel={true}
											placeholder="รายการ"
											keyProps="title"
											value={expense.title}
											onInputChange={(key, value) => this.updateExpense(index, key, value)}
											disabled={false}
										/>
									</td>
									<td>
										<InputRow
											hideLabel={true}
											placeholder="Unit"
											keyProps="unit"
											value={expense.unit}
											onInputChange={(key, value) => this.updateExpense(index, key, value)}
											disabled={false}
										/>
									</td>
									<td>
										<InputRow
											hideLabel={true}
											placeholder="Com/Baht"
											keyProps="combaht"
											value={expense.combaht}
											onInputChange={(key, value) => this.updateExpense(index, key, value)}
											disabled={false}
										/>
									</td>
									<td>
										<div className="columns">
											<div className="column is-10">
												<InputRow
													hideLabel={true}
													placeholder="Com/Baht"
													keyProps="combahtPercentage"
													value={expense.combahtPercentage}
													onInputChange={(key, value) => this.updateExpense(index, key, value)}
													disabled={false}
												/>
											</div>
											<div className="column is-2" style={{ textAlign: 'left', marginTop: 5 }}>
												%
											</div>
										</div>
									</td>
									<td>
										<InputRow
											hideLabel={true}
											placeholder="Summary/Baht"
											keyProps="total"
											value={expense.total}
											onInputChange={(key, value) => this.updateExpense(index, key, value)}
											disabled={false}
										/>
									</td>
								</tr>
							))}
							<tr>
								<td colSpan={6} style={{ textAlign: 'center', backgroundColor: '#eee' }} className="clickable" onClick={this.addNewExpense}>
									Add
								</td>
							</tr>
							<tr>
								<td colSpan={5} style={{ textAlign: 'center' }}>
									Total
								</td>
								<td className="number-cell">
									<NumberColor number={total} />
								</td>
							</tr>
						</tbody>
					</table>
					<div>
						<button className="button is-success" onClick={this.save}>
							Save
						</button>
					</div>
				</div>
				{this.state.isLoading ? <WholePageLoading /> : null}
			</div>
		);
	}
}
