import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';

import InputRow from '../../../Components/InputRow';

export default class PaymentTerms extends PureComponent {
	manageWhenRemoveAllNumberFromTextField = (value, previousValue) => {
		if (value.indexOf(',') !== -1) value = value.replace(',', '');
		if (value === '') value = '0';
		if (previousValue === '0' && value !== '0') value = value.substr(1, value.length);

		return value;
	};

	onDepositChange = (type, value) => {
		const { totalPrice, deposit, deposit2 } = this.props;
		value = this.manageWhenRemoveAllNumberFromTextField(value, deposit);
		const full = totalPrice - parseFloat(value) - deposit2;
		this.props.onStateChange('deposit', value);
		this.props.onStateChange('full', full);
	};

	onDeposit2Change = (type, value) => {
		const { totalPrice, deposit, deposit2 } = this.props;
		value = this.manageWhenRemoveAllNumberFromTextField(value, deposit2);
		const full = totalPrice - parseFloat(value) - deposit;
		this.props.onStateChange('deposit2', value);
		this.props.onStateChange('full', full);
	};

	render() {
		return (
			<div className="row">
				<strong>กำหนดการชำระเงิน</strong>
				<div className="row columns">
					<div className="column is-2">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={this.props.hasDeposit}
								onChange={() => this.props.onStateChange('hasDeposit', !this.props.hasDeposit)}
							/>
							&nbsp;มัดจำ
						</label>
					</div>
					<div className="column is-5">
						<label className="label">วันที่</label>
						<DatePicker
							selected={this.props.dueDateDeposit}
							dateFormat="DD MMM YYYY"
							className="input float-left"
							onChange={newDate => this.props.onStateChange('dueDateDeposit', newDate)}
							disabled={!this.props.hasDeposit}
						/>
					</div>
					<div className="column is-5">
						<InputRow
							title="มัดจำ"
							labelSize="s"
							placeholder="มัดจำ"
							keyProps="deposit"
							value={this.props.deposit}
							onInputChange={this.onDepositChange}
							isMandatory={false}
							disabled={!this.props.hasDeposit}
						/>
					</div>
				</div>
				<div className="row columns">
					<div className="column is-2">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={this.props.hasDeposit2}
								onChange={() => this.props.onStateChange('hasDeposit2', !this.props.hasDeposit2)}
							/>
							&nbsp;มัดจำ (งวดที่ 2)
						</label>
					</div>
					<div className="column is-5">
						<label className="label">วันที่</label>
						<DatePicker
							selected={this.props.dueDateDeposit2}
							dateFormat="DD MMM YYYY"
							className="input float-left"
							onChange={newDate => this.props.onStateChange('dueDateDeposit2', newDate)}
							disabled={!this.props.hasDeposit2}
						/>
					</div>
					<div className="column is-5">
						<InputRow
							title="มัดจำ"
							labelSize="s"
							placeholder="มัดจำ"
							keyProps="deposit"
							value={this.props.deposit2}
							onInputChange={this.onDeposit2Change}
							isMandatory={false}
							disabled={!this.props.hasDeposit2}
						/>
					</div>
				</div>
				<div className="row columns">
					<div className="column is-2">
						<label>ชำระที่เหลือ</label>
					</div>
					<div className="column is-5">
						<label className="label">วันที่</label>
						<DatePicker
							selected={this.props.dueDateFull}
							dateFormat="DD MMM YYYY"
							className="input float-left"
							onChange={newDate => this.props.onStateChange('dueDateFull', newDate)}
						/>
					</div>
					<div className="column is-5">
						<InputRow
							title="ชำระที่เหลือ"
							labelSize="s"
							placeholder="ชำระที่เหลือ"
							keyProps="full"
							value={this.props.full}
							onInputChange={this.props.onStateChange}
							disabled={true}
							isMandatory={false}
						/>
					</div>
				</div>
			</div>
		);
	}
}
