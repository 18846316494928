import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import ThingsToSelect from '../../../Helper/ThingsToSelect';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import Row from './Row';

export default class Airlines extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			airlines: [],
			isEditLoading: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let requestAirlines = APIGet('airlines');
			requestAirlines = await requestAirlines;
			if (APIHelper.handleAPIResponse(this, requestAirlines)) {
				const airlines = requestAirlines.data;
				console.log('airlines: ', airlines);
				this.setState({
					isLoading: false,
					airlines,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	saveChange = async (airlineId, name, shortname) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPut('airlines/' + airlineId, {
			name: name,
			shortname: shortname,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const airline = request.data;
			let airlines = JSON.parse(JSON.stringify(this.state.airlines));
			for (let i = 0; i < airlines.length; i++) {
				if (airlines[i].id === airlineId) {
					airlines[i] = airline;
				}
			}

			// console.log(airlines);
			toast.success('แก้ไขข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				airlines,
			});
		} else {
			toast.error('ไม่สามารถแก้ไขข้อมูลได้');
		}
	};

	addNew = async (name, shortname) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPost('airlines', {
			name: name,
			shortname: shortname,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const airline = request.data;
			let airlines = JSON.parse(JSON.stringify(this.state.airlines));
			airlines.push(airline);

			// console.log(airlines);
			toast.success('เพิ่มข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				airlines,
			});
		} else {
			toast.error('ไม่สามารถเพิ่มข้อมูลได้');
		}
	};

	delete = async airlineId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete('airlines/' + airlineId);
		if (APIHelper.handleAPIResponse(this, request)) {
			const airlines = this.state.airlines.filter(airline => airline.id !== airlineId);

			// console.log(airlines);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				airlines,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Airlines</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.airlines.map(airline => (
								<tr key={airline.id}>
									<td width="100%">
										<Row
											id={airline.id}
											name={airline.name}
											shortname={airline.shortname}
											confirmChange={(name, shortname) => this.saveChange(airline.id, name, shortname)}
											confirmDelete={this.delete}
										>
											{airline.name} ({airline.shortname})
										</Row>
									</td>
								</tr>
							))}
							<tr>
								<td width="100%">
									<Row name="" shortname="" confirmChange={this.addNew} confirmText="เพิ่ม">
										เพิ่มสายการบิน
									</Row>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
