import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'react-loading';

import APIGet from '../../API/APIGet';
import APIPut from '../../API/APIPut';
import APIHelper from '../../Helper/APIHelper';
import AuthHelper from '../../Helper/AuthHelper';

import AppConfig from '../../AppConfig';

class NavNotification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			notifications: [],
		};
	}

	componentDidMount = () => {
		this.fetchNotification();
		this.props.history.listen((location, action) => {
			// console.log(location);
			this.fetchNotification();
		});
	};

	fetchNotification = async () => {
		if (AuthHelper.isLogin()) {
			const request = await APIGet('notifications/unread');
			if (APIHelper.handleAPIResponse(this, request)) {
				const notifications = request.data;
				// console.log('notifications: ', notifications);
				this.setState({
					isLoading: false,
					notifications,
				});
			} else {
				toast.warn('ไม่สามารถดึงข้อมูล Notification ได้');
			}
		}
	};

	goto = async notification => {
		// mark notification as read
		const request = await APIPut('notifications/' + notification.id);
		if (!APIHelper.handleAPIResponse(this, request)) {
			toast.warn('เกิดปัญหาในการอัพเดทสถานะ Notification');
		}

		const url = AppConfig.appPath + notification.data.url;
		// console.log('url: ', url);
		window.location.href = url;
	};

	render() {
		if (this.state.isLoading) {
			return (
				<div className="navbar-item">
					<Loading type="spin" color="#eeeeee" delay={0} height={35} width={35} />
				</div>
			);
		}

		const { notifications } = this.state;
		if (!notifications || notifications.length === 0) {
			return (
				<div className="navbar-item has-dropdown is-hoverable">
					<a className="navbar-link">
						<FontAwesomeIcon icon="bell" />
					</a>
					<div className="navbar-dropdown is-boxed is-right">
						<NavLink to={AppConfig.appPath + '/notifications'} className="navbar-item clickable" activeClassName="is-active">
							ดูทั้งหมด
						</NavLink>
					</div>
				</div>
			);
		}

		return (
			<div className="navbar-item has-dropdown is-hoverable">
				<a className="navbar-link">
					<span className="tag is-warning">{notifications.length}</span>
				</a>
				<div className="navbar-dropdown is-boxed is-right">
					{notifications.map(notification => (
						<a key={notification.id} className="navbar-item clickable" onClick={() => this.goto(notification)}>
							{notification.data.message.length > 100 ? notification.data.message.substring(0, 100) + '...' : notification.data.message}
						</a>
					))}
					<NavLink to={AppConfig.appPath + '/notifications'} className="navbar-item clickable" activeClassName="is-active">
						ดูทั้งหมด
					</NavLink>
				</div>
			</div>
		);
	}
}

export default withRouter(NavNotification);
