export const PLANNING_REDUCER_ACTION = {
	UPDATE_JSON: 'UPDATE_JSON',
	UPDATE_ACTIVE_SUB_PLAN: 'UPDATE_ACTIVE_SUB_PLAN',
	UPDATE_ACTIVE_SUB_PLAN_TAB: 'UPDATE_ACTIVE_SUB_PLAN_TAB',
};

export const averagePrice = (totalPrice, totalPerson) => {
	if (totalPerson === 0) return 0;
	// console.log('avg price', totalPrice, totalPerson, totalPrice / totalPerson, Math.ceil(totalPrice / totalPerson));
	return Math.ceil(totalPrice / totalPerson);
};

export const summaryPice = (records, totalAdult, totalChild, currencies, isBoth, isTourLeader, totalTourLeader) => {
	const planSummary = { adult: 0, child: 0 };
	for (const record of records) {
		let totalPrice = 0;
		totalAdult = totalAdult ? parseInt(totalAdult) : 0;
		totalChild = totalChild ? parseInt(totalChild) : 0;
		// console.log('record', record.expenses);
		if (record.expenses) {
			for (const expense of record.expenses) totalPrice += expense.price * expense.quantity;
		}

		// console.log('currency', record.currency, totalPrice);
		if (record.currency && currencies && currencies.length > 0) {
			const currency = currencies.filter(c => c.name === record.currency.name);
			if (currency.length > 0) totalPrice = totalPrice * currency[0].calculateRate;
		}

		// console.log('total price after', totalPrice);

		if (isTourLeader || record.name === 'T/L expense') {
			let totalPriceAdult = totalPrice;
			let totalPriceChild = totalPrice;
			if (record.expenseType === 'avg') {
				// console.log('total price ', totalPrice, totalTourLeader, totalAdult, totalChild);
				totalPriceAdult = averagePrice(totalPrice * totalTourLeader, totalAdult + totalChild);
				totalPriceChild = averagePrice(totalPrice * totalTourLeader, totalAdult + totalChild);
				// console.log('total price avg', totalPriceAdult, totalPriceChild);
			}

			planSummary.adult += totalPriceAdult;
			planSummary.child += totalPriceChild;
		} else if (isBoth) {
			let totalPriceAdult = totalPrice;
			let totalPriceChild = totalPrice;
			if (record.expenseType === 'avg') {
				totalPriceAdult = averagePrice(totalPrice, totalAdult);
				totalPriceChild = averagePrice(totalPrice, totalChild);
			}

			planSummary.adult += totalPriceAdult;
			planSummary.child += totalPriceChild;
		} else {
			if (record.customerType === 'adult') {
				if (record.expenseType === 'avg') totalPrice = averagePrice(totalPrice, totalAdult);
				planSummary.adult += totalPrice;
			} else {
				if (record.expenseType === 'avg') totalPrice = averagePrice(totalPrice, totalChild);
				planSummary.child += totalPrice;
			}
		}
	}

	return planSummary;
};

export const singleTravelerToTHB = (singleTraveler, currencies) => {
	if (!singleTraveler) return 0;
	if (singleTraveler && singleTraveler.currency && currencies && currencies.length > 0) {
		const currency = currencies.filter(c => c.name === singleTraveler.currency.name);
		if (currency.length > 0) return singleTraveler.price * currency[0].calculateRate;
	} else return singleTraveler.price;
};

export const calculateSubPlanSummary = (incentiveSubPlans, currencies, singleTraveler) => {
	const tickets = [];
	const landAssessments = [];
	const visas = [];
	const tourLeaders = [];
	const others = [];
	const commissions = [];
	const totals = [];
	const childDiscounts = [];
	const expectProfits = [];
	const grandTotals = [];
	const salePrices = [];
	const totalIncomes = [];
	const totalCosts = [];
	const totalMargins = [];
	const singleTravelerNetPrices = [];
	const singleTravelerSalePrices = [];
	for (const plan of incentiveSubPlans) {
		const ticket = summaryPice(plan.tickets, plan.adult, plan.child, currencies);
		const landAssessment = summaryPice(plan.landAssessments, plan.adult, plan.child, currencies, false, false, plan.tourLeader);
		// console.log('landAssessment', landAssessment);
		const visa = summaryPice(plan.visas, plan.adult, plan.child, currencies);
		const tourLeader = summaryPice(plan.tourLeaders, plan.adult, plan.child, currencies, false, true, plan.tourLeader);
		const other = summaryPice(plan.others, plan.adult, plan.child, currencies, true);
		const commission = summaryPice(plan.commissions, plan.adult, plan.child, currencies, true);
		const total = {
			adult: ticket.adult + landAssessment.adult + visa.adult + tourLeader.adult + other.adult + commission.adult,
			child: ticket.child + landAssessment.child + visa.child + tourLeader.child + other.child + commission.child,
		};

		const discountChildInPercent = plan.discountChildPercentage;
		const discountChildInPrice = (discountChildInPercent * total.child) / 100;
		const expectProfitAdultInPrice = (plan.profitAdult * total.adult) / 100;
		const expectProfitChildInPrice = (plan.profitChild * (total.child - discountChildInPrice)) / 100;

		const expectProfit = {
			adult: expectProfitAdultInPrice,
			child: expectProfitChildInPrice,
		};

		const granTotal = {
			adult: Math.ceil(total.adult + parseFloat(expectProfit.adult ? expectProfit.adult : 0)),
			child: Math.ceil(total.child - discountChildInPrice + parseFloat(expectProfit.child ? expectProfit.child : 0)),
		};
		const salePrice = {
			adult: plan.salePriceAdult,
			child: plan.salePriceChild,
		};

		let singleTravelerTotalPrice = 0;
		let singleTravelerIncludedProfit = 0;
		let singleTravelerNetPrice = 0;
		let singleTravelerPerson = 0;

		if (singleTraveler) {
			singleTravelerTotalPrice = singleTravelerToTHB(singleTraveler, currencies);
			singleTravelerIncludedProfit = singleTraveler.profit ? singleTravelerTotalPrice + parseFloat(singleTraveler.profit) : singleTravelerTotalPrice;
			singleTravelerNetPrice = singleTraveler.netPrice ? parseFloat(singleTraveler.netPrice) : 0;
			singleTravelerPerson = singleTraveler.person ? parseInt(singleTraveler.person) : 0;
		}

		let singleTravelerSalePrice = singleTravelerNetPrice + salePrice.adult;
		let singleTravelerForIncome = singleTravelerSalePrice * singleTravelerPerson;
		//TODO: is this correct to plus totalPrice
		let singleTravelerForCost = (total.adult + singleTravelerTotalPrice) * singleTravelerPerson;

		const totalIncome = {
			adult: plan.salePriceAdult * plan.adult + singleTravelerForIncome,
			child: plan.salePriceChild * plan.child,
		};
		const totalCost = {
			adult: total.adult * plan.adult + singleTravelerForCost,
			child: total.child * plan.child,
		};
		const totalMargin = {
			adult: totalIncome.adult - totalCost.adult,
			child: totalIncome.child - totalCost.child,
		};

		tickets.push(ticket);
		landAssessments.push(landAssessment);
		visas.push(visa);
		tourLeaders.push(tourLeader);
		others.push(other);
		commissions.push(commission);
		totals.push(total);
		childDiscounts.push({
			discountChildInPercent,
			discountChildInPrice,
		});
		expectProfits.push(expectProfit);
		grandTotals.push(granTotal);
		salePrices.push(salePrice);
		totalIncomes.push(totalIncome);
		totalCosts.push(totalCost);
		totalMargins.push(totalMargin);
		singleTravelerNetPrices.push(singleTravelerNetPrice);
		singleTravelerSalePrices.push(singleTravelerSalePrice);
	}

	return {
		tickets,
		landAssessments,
		visas,
		tourLeaders,
		others,
		commissions,
		totals,
		childDiscounts,
		expectProfits,
		grandTotals,
		salePrices,
		totalIncomes,
		totalCosts,
		totalMargins,
		singleTravelerNetPrices,
		singleTravelerSalePrices,
	};
};

export const planningReducer = (state, action) => {
	const { type, newState } = action;
	if (type === PLANNING_REDUCER_ACTION.UPDATE_JSON) {
		// console.log('new state', newState);

		const _state = {
			...state,
			...newState,
		};

		return {
			..._state,
			summary: calculateSubPlanSummary(_state.incentiveSubPlans, _state.exchangeRates, _state.singleTraveler),
		};
	}

	if (type === PLANNING_REDUCER_ACTION.UPDATE_ACTIVE_SUB_PLAN) {
		const { index } = action;
		const selectedSubPlan = state.incentiveSubPlans[index];
		if (!selectedSubPlan) {
			return {
				...state,
			};
		}

		const newIntensiveSubPlans = [
			...state.incentiveSubPlans.slice(0, index),
			{
				...state.incentiveSubPlans[index],
				...newState,
			},
			...state.incentiveSubPlans.slice(index + 1, state.incentiveSubPlans.length),
		];

		return {
			...state,
			incentiveSubPlans: newIntensiveSubPlans,
			summary: calculateSubPlanSummary(newIntensiveSubPlans, state.exchangeRates, state.singleTraveler),
		};
	}

	if (type === PLANNING_REDUCER_ACTION.UPDATE_ACTIVE_SUB_PLAN_TAB) {
		const { index, key } = action;
		const selectedSubPlan = state.incentiveSubPlans[index];
		if (!selectedSubPlan) {
			return {
				...state,
			};
		}

		const newIntensiveSubPlans = [
			...state.incentiveSubPlans.slice(0, index),
			{
				...state.incentiveSubPlans[index],
				[key]: newState,
			},
			...state.incentiveSubPlans.slice(index + 1, state.incentiveSubPlans.length),
		];

		return {
			...state,
			incentiveSubPlans: newIntensiveSubPlans,
			summary: calculateSubPlanSummary(newIntensiveSubPlans, state.exchangeRates, state.singleTraveler),
		};
	}

	return {
		...state,
	};
};
