import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import StatusIcon from '../../../Components/StatusIcon';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

import SearchFilterContext from '../../../Context/SearchFilterContext';

export default class IncentiveList extends Component {
	static contextType = SearchFilterContext;
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			filter: {
				name: '',
				startDate: null,
				endDate: null,
			},
			code: '',
			programs: [],
			summary: {},
		};
	}

	async componentDidMount() {
		this.setState(
			{
				filter: {
					...this.context,
				},
			},
			async () => {
				await this.getPrograms();
			}
		);
	}

	getPrograms = async () => {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.context.updateSearchFilter(this.state.filter);
			this.setState({
				isLoading: true,
			});

			const startDate = this.state.filter.startDate.format('YYYY-MM-DD');
			const endDate = this.state.filter.endDate.format('YYYY-MM-DD');

			let filter = {
				startDate: startDate,
				endDate: endDate,
				searchBy: 'TravelingDate',
			};

			const { companyId } = this.props.match.params;
			if (companyId) {
				filter.incentiveCustomerId = companyId;
			}

			let requestPrograms = APIGet('incentive/programs', filter);
			let requestSummary = APIGet('incentive', filter);

			requestPrograms = await requestPrograms;
			requestSummary = await requestSummary;
			if (APIHelper.handleAPIResponse(this, requestPrograms, requestSummary)) {
				const programs = requestPrograms.data;
				const summary = requestSummary.data;
				console.log(programs);
				this.setState({
					isLoading: false,
					programs,
					summary,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	onFilterChange = (name, value) => {
		let filter = { ...this.state.filter };
		filter[name] = value;
		console.log(filter);
		this.setState({
			filter,
		});
	};

	handleInputChange = (key, value) => {
		this.setState({
			[key]: value,
		});
	};

	openByCode = async () => {
		let requestPrograms = await APIGet('incentive/programs?code=' + this.state.code);
		console.log(requestPrograms);
		if (APIHelper.handleAPIResponse(this, requestPrograms)) {
			const programs = requestPrograms.data;
			if (programs.length > 0) {
				const program = programs[0];
				this.props.history.push(AppConfig.appPath + '/programs/incentive/' + program.id);
			} else {
				toast.warning('ไม่พบโปรแกรมจาก code ที่คุณกรอก');
			}
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { companyId } = this.props.match.params;
		const { summary } = this.state;
		return (
			<div className="container" style={{ minWidth: 1600 }}>
				{!companyId ? (
					<BackButtonRouter link={AppConfig.appPath + '/'} title="ดูทั้งหมด" />
				) : (
					<BackButtonRouter link={`${AppConfig.appPath}/data/companies`} title="ลูกค้า" />
				)}
				<div className="columns">
					<div className="column is-4">
						<h2 className="title is-2">INCENTIVE</h2>
					</div>
					<div className="column is-2" />
					<div className="column is-2">
						<DatePicker
							selected={this.state.filter.startDate}
							onChange={newDate => this.onFilterChange('startDate', newDate)}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
					<div className="column is-2">
						<DatePicker
							selected={this.state.filter.endDate}
							onChange={newDate => this.onFilterChange('endDate', newDate)}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
					<div className="column is-2">
						<button className="button is-link" onClick={this.getPrograms}>
							ค้นหา
						</button>
					</div>
				</div>
				<div className="columns">
					<div className="column is-4">
						<InputRow
							placeholder="ดูจากรหัสโปรแกรม"
							keyProps="code"
							value={this.state.code}
							onInputChange={this.handleInputChange}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="column is-2" style={{ paddingTop: 18 }}>
						<button className="button is-link" onClick={this.openByCode}>
							เปิด
						</button>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-success" to={AppConfig.appPath + '/programs/newincentive'}>
							เพิ่มรายการ
						</Link>
						<span> </span>
						<button className="button is-info">PDF</button>
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="13%">วันเดินทาง</td>
								<td width="8%">สถานะ</td>
								<td width="20">โปรแกรม (รหัสทัวร์)</td>
								<td width="7%">ยอดเรียกเก็บทั้งหมด</td>
								<td width="7%">รับชําระมาเเล้ว</td>
								<td width="7%">ค้างรับ</td>
								<td width="6%">ค้างจ่าย</td>
								<td width="10%">รายจ่าย</td>
								<td width="10%">กำไร</td>
								<td width="13%">เงินในเซฟ</td>
							</tr>
						</thead>
						<tbody>
							{this.state.programs.map(program => (
								<tr key={program.id}>
									<td>
										<DateRangeLabel startDate={program.startDate} endDate={program.endDate} />
									</td>
									<td style={{ textAlign: 'center' }}>
										<StatusIcon status={program.status} deletedAt={program.deleted_at} />{' '}
										<span style={{ color: 'red', fontSize: 10 }}>{program.deleted_by_user_name ? program.deleted_by_user_name : null}</span>
									</td>
									<td>
										<Link to={AppConfig.appPath + '/programs/incentive/' + program.id}>
											{program.title} <b>({program.code})</b>
										</Link>
									</td>
									<td className="number-cell">
										<NumberColor number={(program.toCollectFromCustomer + program.incomeEtc) * program.currencyRate} />
									</td>
									<td className="number-cell">
										<NumberColor number={(program.incomeFromCustomer + program.incomeEtc) * program.currencyRate} />
									</td>
									<td className="number-cell">
										<NumberColor
											number={(program.toCollectFromCustomer - program.incomeFromCustomer) * program.currencyRate}
											isExpense={true}
										/>
									</td>
									<td className="number-cell">
										<NumberColor number={program.expenseNotPaid} isExpense={true} />
									</td>
									<td className="number-cell">
										<NumberColor number={program.expense * program.currencyRate} isExpense={true} />
									</td>
									<td className="number-cell">
										<NumberColor number={(program.incomeFromCustomer + program.incomeEtc - program.expense) * program.currencyRate} />
									</td>
									<td>
										{program.incentiveBudgets.map(budget => (
											<div key={'budget' + budget.id}>
												<NumberColor number={budget.total} /> {budget.currency}
											</div>
										))}
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan="3" style={{ textAlign: 'center' }}>
									Summary
								</td>
								<td className="number-cell">
									<NumberColor number={summary.toCollectFromCustomer + summary.incomeEtc} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.incomeFromCustomer + summary.incomeEtc} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.toCollectFromCustomer - summary.incomeFromCustomer} isExpense={true} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.expenseNotPaid} isExpense={true} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.expense} isExpense={true} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.incomeFromCustomer + summary.incomeEtc - summary.expense} />
								</td>
								<td />
							</tr>
						</tbody>
					</table>
				</div>
				<br />
			</div>
		);
	}
}
