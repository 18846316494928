import React, { Component } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import InputRow from '../../../Components/InputRow';

export default class Row extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditing: false,
			name: props.name,
			number: props.number,
			selectedBank: props.selectedBank,
		};
	}

	componentWillReceiveProps = nextProps => {
		this.setState({
			name: nextProps.name,
			number: nextProps.number,
			banks: nextProps.banks,
			selectedBank: nextProps.selectedBank,
		});
	};

	validateInput = () => {
		const { name, number, selectedBank } = this.state;
		if (
			name.trim() !== '' &&
			number.trim() !== '' &&
			selectedBank &&
			selectedBank.value
		) {
			return true;
		}

		return false;
	};

	handleInputChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	toggleMode = () => {
		this.setState({
			isEditing: !this.state.isEditing,
		});
	};

	confirmChange = () => {
		if (this.validateInput()) {
			this.setState({
				isEditing: false,
			});

			this.props.confirmChange(
				this.state.name,
				this.state.number,
				this.state.selectedBank
			);
		} else {
			toast.warn('กรุณากรอกข้อมูลในครบ');
		}
	};

	cancelChange = () => {
		this.setState({
			name: this.props.name,
			number: this.props.number,
			selectedBank: this.props.selectedBank,
			isEditing: false,
		});
	};

	delete = () => {
		this.props.confirmDelete(this.props.id);
	};

	render() {
		if (this.state.isEditing) {
			return (
				<div className="columns">
					<div className="column is-3">
						<InputRow
							labelSize="s"
							title="ชื่อบัญชี"
							placeholder="ชื่อบัญชี"
							keyProps="name"
							value={this.state.name}
							onInputChange={this.handleInputChange}
							disabled={false}
						/>
					</div>
					<div className="column is-3">
						<InputRow
							labelSize="s"
							title="เลขที่บัญชี"
							placeholder="เลขที่บัญชี"
							keyProps="number"
							value={this.state.number}
							onInputChange={this.handleInputChange}
							disabled={false}
						/>
					</div>
					<div className="column is-3">
						<label className="label">Bank</label>
						<Select
							name="selectedBank"
							value={this.state.selectedBank}
							clearable={false}
							onChange={value =>
								this.handleInputChange('selectedBank', value)
							}
							options={this.props.banks}
						/>
					</div>
					<div
						className="column is-3 buttons has-addons is-centered"
						style={{ paddingTop: 45 }}
					>
						<button
							type="button"
							className="button is-success"
							onClick={this.confirmChange}
						>
							{this.props.confirmText
								? this.props.confirmText
								: 'แก้ไข'}
						</button>
						{this.props.confirmDelete ? (
							<button
								type="button"
								className="button is-danger"
								onClick={this.delete}
							>
								{this.props.deleteText
									? this.props.deleteText
									: 'ลบ'}
							</button>
						) : null}
						<button
							type="button"
							className="button"
							onClick={this.cancelChange}
						>
							ยกเลิก
						</button>
					</div>
				</div>
			);
		} else {
			return (
				<div onClick={this.toggleMode} className="clickable">
					{this.props.children}
				</div>
			);
		}
	}
}
