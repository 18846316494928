const CURRENCIES = [
	{
		value: 'THB',
		label: 'ไทย (บาท)',
	},
	{
		value: 'JPY',
		label: 'ญี่ปุ่น (เยน)',
	},
	{
		value: 'KRW',
		label: 'เกาหลี (วอน)',
	},
	{
		value: 'USD',
		label: 'สหรัฐอเมริกา (ดอลลาร์)',
	},
	{
		value: 'HKD',
		label: 'ฮ่องกง (ดอลลาร์ฮ่องกง)',
	},
	{
		value: 'GBP',
		label: 'อังกฤษ (ปอนด์)',
	},
	{
		value: 'EUR',
		label: 'ยูโร',
	},
	{
		value: 'CHF',
		label: 'สวิสเซอร์แลนด์​ (ฟรังก์สวิส)',
	},
	{
		value: 'SGD',
		label: 'สิงคโปร์ (สิงคโปร์ดอลลาร์)',
	},
	{
		value: 'TWD',
		label: 'ไต้หวัน (ดอลลาร์ไต้หวัน)',
	},
	{
		value: 'CNY',
		label: 'จีน (หยวน)',
	},
	{
		value: 'VND',
		label: 'เวียดนาม (ดง)',
	},
	{
		value: 'NOK',
		label: 'นอร์เวย์ (โครน)',
	},
	{
		value: 'SEK',
		label: 'สวีเดน (โครนา)',
	},
	{
		value: 'DKK',
		label: 'เดนมาร์ก (โครน)',
	},
	{
		value: 'NLG',
		label: 'เนเธอร์แลนด์ (กิลเดอร์)',
	},
	{
		value: 'RUB',
		label: 'รัสเซีย (รูเบิล)',
	},
	{
		value: 'MYR',
		label: 'มาเลเซีย (ริงกิต)',
	},
	{
		value: 'TRY',
		label: 'ตุรกี (ลีรา)',
	},
	{
		value: 'NZD',
		label: 'นิวซีแลนด์ (ดอลลาร์นิวซีเเลนด์)',
	},
	{
		value: 'HUF',
		label: 'ฮังการี (ฟอรินต์)',
	},
];

export default CURRENCIES;
