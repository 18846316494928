import React, { PureComponent } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import InputRow from '../../../Components/InputRow';

export default class TourDetail extends PureComponent {
	render() {
		return (
			<div>
				<div className="row">
					<h3 className="subtitle is-4">
						<strong>
							<u>รายละเอียดทัวร์</u>
						</strong>
					</h3>
				</div>
				<div className="row columns">
					<div className="column is-6">
						<label className="label">Whole Sale</label>
						<Select
							name="wholeSale"
							value={this.props.wholeSale}
							clearable={false}
							onChange={value => this.props.onStateChange('wholeSale', value)}
							options={this.props.selectWholeSales}
						/>
					</div>
					<div className="column is-6">
						<label className="label">Whole Sale Contact</label>
						<Select
							name="wholeSaleContact"
							value={this.props.wholeSaleContact}
							clearable={false}
							onChange={value => this.props.onStateChange('wholeSaleContact', value)}
							options={this.props.selectWholeSaleContacts}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12">
						<InputRow
							title="ชื่อโปรแกรม"
							labelSize="s"
							placeholder="ชื่อโปรแกรม"
							keyProps="title"
							value={this.props.title}
							onInputChange={this.props.onStateChange}
							disabled={false}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<label className="label">ประเทศ</label>
						<Select
							name="country"
							value={this.props.country}
							clearable={false}
							onChange={value => this.props.onStateChange('country', value)}
							options={this.props.selectCountries}
						/>
					</div>
					<div className="column is-6">
						<label className="label">สายการบิน</label>
						<Select
							name="airline"
							value={this.props.airline}
							clearable={false}
							onChange={value => this.props.onStateChange('airline', value)}
							options={this.props.selectAirlines}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<label className="label">วันที่ (จาก)</label>
						<DatePicker
							selected={this.props.startDate}
							onChange={newDate => this.props.onStateChange('startDate', newDate)}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
					<div className="column is-6">
						<label className="label">วันที่ (ถึง)</label>
						<DatePicker
							selected={this.props.endDate}
							onChange={newDate => this.props.onStateChange('endDate', newDate)}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
				</div>
			</div>
		);
	}
}
