import React, { PureComponent } from 'react';

import Pricing from './Pricing';
import PaymentTerms from './PaymentTerms';

export default class PricingPaymentTerms extends PureComponent {
	zeroIfNan = value => (isNaN(value) ? 0 : value);

	getPricing = props => {
		const {
			quantityNormal,
			quantityChildNoBed,
			quantityChildWithBed,
			quantityInfant,
			quantitySingle,
			quantityCommission,
			priceNormal,
			priceChildNoBed,
			priceChildWithBed,
			priceInfant,
			priceSingle,
			commission,
			discounts,
			incomeEtcs,
			expenseEtcs,
		} = props;

		const totalPriceNormal = this.zeroIfNan(quantityNormal * priceNormal);
		const totalPriceChildNoBed = this.zeroIfNan(quantityChildNoBed * priceChildNoBed);
		const totalPriceChildWithBed = this.zeroIfNan(quantityChildWithBed * priceChildWithBed);
		const totalPriceInfant = this.zeroIfNan(quantityInfant * priceInfant);
		const totalPriceSingle = this.zeroIfNan(quantitySingle * priceSingle);
		const totalPriceCommission = this.zeroIfNan(quantityCommission * commission);
		let totalDiscount = discounts.reduce((a, b) => {
			return a + this.zeroIfNan(b.quantity * b.pricePerPiece);
		}, 0);
		let totalIncomeEtc = incomeEtcs.reduce((a, b) => {
			return a + this.zeroIfNan(b.quantity * b.pricePerPiece);
		}, 0);
		let totalExpenseEtc = expenseEtcs.reduce((a, b) => {
			return a + this.zeroIfNan(b.quantity * b.pricePerPiece);
		}, 0);

		const totalPrice =
			totalPriceNormal + totalPriceChildNoBed + totalPriceChildWithBed + totalPriceInfant + totalPriceSingle + totalIncomeEtc - totalDiscount;

		return {
			totalPriceNormal,
			totalPriceChildNoBed,
			totalPriceChildWithBed,
			totalPriceInfant,
			totalPriceSingle,
			totalPriceCommission,
			totalDiscount,
			totalIncomeEtc,
			totalExpenseEtc,
			totalPrice,
		};
	};

	onStateChange = (type, value) => {
		let data = { ...this.props };
		data[type] = value;
		const { totalPrice } = this.getPricing(data);

		const full = totalPrice - this.props.deposit - this.props.deposit2;
		this.props.onStateChange(type, value);
		this.props.onStateChange('full', full);
	};

	onStateArrayChange = (type, index, key, value) => {
		let data = { ...this.props };
		data[type][index][key] = value;

		const { totalPrice } = this.getPricing(data);

		const full = totalPrice - this.props.deposit - this.props.deposit2;
		this.props.onStateArrayChange(type, index, key, value);
		this.props.onStateChange('full', full);
	};

	render() {
		const {
			totalPriceNormal,
			totalPriceChildNoBed,
			totalPriceChildWithBed,
			totalPriceInfant,
			totalPriceSingle,
			totalPriceCommission,
			totalDiscount,
			totalIncomeEtc,
			totalExpenseEtc,
			totalPrice,
		} = this.getPricing(this.props);

		return (
			<div>
				<Pricing
					quantityNormal={this.props.quantityNormal}
					quantityChildNoBed={this.props.quantityChildNoBed}
					quantityChildWithBed={this.props.quantityChildWithBed}
					quantityInfant={this.props.quantityInfant}
					quantitySingle={this.props.quantitySingle}
					quantityCommission={this.props.quantityCommission}
					priceNormal={this.props.priceNormal}
					priceChildNoBed={this.props.priceChildNoBed}
					priceChildWithBed={this.props.priceChildWithBed}
					priceInfant={this.props.priceInfant}
					priceSingle={this.props.priceSingle}
					commission={this.props.commission}
					discounts={this.props.discounts}
					incomeEtcs={this.props.incomeEtcs}
					expenseEtcs={this.props.expenseEtcs}
					totalPriceNormal={totalPriceNormal}
					totalPriceChildNoBed={totalPriceChildNoBed}
					totalPriceChildWithBed={totalPriceChildWithBed}
					totalPriceInfant={totalPriceInfant}
					totalPriceSingle={totalPriceSingle}
					totalPriceCommission={totalPriceCommission}
					totalDiscount={totalDiscount}
					totalIncomeEtc={totalIncomeEtc}
					totalExpenseEtc={totalExpenseEtc}
					totalPrice={totalPrice}
					addDiscount={this.props.addDiscount}
					addIncomeEtc={this.props.addIncomeEtc}
					addExpenseEtc={this.props.addExpenseEtc}
					onStateChange={this.onStateChange}
					onStateArrayChange={this.onStateArrayChange}
				/>
				<div style={{ height: 10 }} />
				<PaymentTerms
					full={this.props.full}
					deposit={this.props.deposit}
					deposit2={this.props.deposit2}
					dueDateDeposit={this.props.dueDateDeposit}
					dueDateDeposit2={this.props.dueDateDeposit2}
					dueDateFull={this.props.dueDateFull}
					hasDeposit={this.props.hasDeposit}
					hasDeposit2={this.props.hasDeposit2}
					onStateChange={this.props.onStateChange}
					totalPrice={totalPrice}
				/>
			</div>
		);
	}
}
