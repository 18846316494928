import React, { Component } from 'react';

export const Tab = ({ selectedTab, onTabClick }) => {
	return (
		<div className="tabs is-boxed">
			<ul>
				<li
					className={`${selectedTab === 'summary' ? 'is-active' : ''}`}
					onClick={e => {
						e.preventDefault();
						onTabClick('summary');
					}}
				>
					<a>สรุป</a>
				</li>
				<li
					className={`${selectedTab === 'ticket' ? 'is-active' : ''}`}
					onClick={e => {
						e.preventDefault();
						onTabClick('ticket');
					}}
				>
					<a>ตั๋ว</a>
				</li>
				<li
					className={`${selectedTab === 'landAssessment' ? 'is-active' : ''}`}
					onClick={e => {
						e.preventDefault();
						onTabClick('landAssessment');
					}}
				>
					<a>Land Arrangement</a>
				</li>
				<li
					className={`${selectedTab === 'visa' ? 'is-active' : ''}`}
					onClick={e => {
						e.preventDefault();
						onTabClick('visa');
					}}
				>
					<a>Visa</a>
				</li>
				<li
					className={`${selectedTab === 'tourLeader' ? 'is-active' : ''}`}
					onClick={e => {
						e.preventDefault();
						onTabClick('tourLeader');
					}}
				>
					<a>หัวหน้าทัวร์</a>
				</li>
				<li
					className={`${selectedTab === 'etc' ? 'is-active' : ''}`}
					onClick={e => {
						e.preventDefault();
						onTabClick('etc');
					}}
				>
					<a>อื่นๆ</a>
				</li>
				<li
					className={`${selectedTab === 'commission' ? 'is-active' : ''}`}
					onClick={e => {
						e.preventDefault();
						onTabClick('commission');
					}}
				>
					<a>Commission</a>
				</li>
			</ul>
		</div>
	);
};
