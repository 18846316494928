export default (state, toast) => {
	let result = true;
	result = result & validateInput(state.wholeSale, ' Whole Sale', toast);
	result = result && validateInput(state.wholeSaleContact, ' Whole Sale (ติดต่อ)', toast);
	result = result & validateInput(state.title, 'ชื่อโปรแกรม', toast);
	result = result & validateInput(state.telephone, 'เบอร์โทรศัพท์', toast);
	result = result & validateInput(state.email, ' email', toast);
	result = result & validateInput(state.country, 'ประเทศ', toast);
	result = result & validateInput(state.airline, 'สายการบิน', toast);
	result = result & validateInput(state.startDate, 'วันเดินทาง', toast);
	result = result & validateInput(state.endDate, 'วันกลับ', toast);
	result = result & validateInput(state.customer, 'ลูกค้า', toast);
	result = result & validateInput(state.customerContact, 'ลูกค้า (ติดต่อ)', toast);
	result = result & validateInput(state.channel, 'ช่องทางติดต่อ', toast);
	result = result && validateTravellers(state, toast);
	result = result && validatePricing(state, toast);

	return result;
};

const validateInput = (data, aka, toast) => {
	if (!data) {
		toast.warn('กรุณาระบุ' + aka);
		return false;
	}

	return true;
};

const validateTravellers = (state, toast) => {
	const cost =
		parseFloat(state.quantityNormal) * parseFloat(state.priceNormal) +
		parseFloat(state.quantityChildNoBed) * parseFloat(state.priceChildNoBed) +
		parseFloat(state.quantityChildWithBed) * parseFloat(state.priceChildWithBed) +
		parseFloat(state.quantityInfant) * parseFloat(state.priceInfant);

	if (cost > 0) {
		return true;
	} else {
		toast.warn('กรุณาระบุจำนวนและราคา');
		return false;
	}
};

const validatePricing = (state, toast) => {
	const cost =
		parseFloat(state.quantityNormal) * parseFloat(state.priceNormal) +
		parseFloat(state.quantityChildNoBed) * parseFloat(state.priceChildNoBed) +
		parseFloat(state.quantityChildWithBed) * parseFloat(state.priceChildWithBed) +
		parseFloat(state.quantityInfant) * parseFloat(state.priceInfant) +
		parseFloat(state.quantitySingle) * parseFloat(state.priceSingle);
	const commission = parseFloat(state.quantityCommission) * parseFloat(state.commission);
	let totalDiscount = 0;
	state.discounts.forEach(discount => {
		totalDiscount += parseFloat(discount.pricePerPiece) * parseFloat(discount.quantity);
	});

	let totalIncomeEtc = 0;
	state.incomeEtcs.forEach(incomeEtc => {
		totalIncomeEtc += parseFloat(incomeEtc.pricePerPiece) * parseFloat(incomeEtc.quantity);
	});

	let totalExpenseEtc = 0;
	state.expenseEtcs.forEach(expenseEtc => {
		totalExpenseEtc += parseFloat(expenseEtc.pricePerPiece) * parseFloat(expenseEtc.quantity);
	});

	const deposit = parseFloat(state.deposit);
	const deposit2 = parseFloat(state.deposit2);
	const full = parseFloat(state.full);

	if (deposit + deposit2 + full === cost + totalIncomeEtc - totalDiscount) {
		return true;
	} else {
		toast.warn(`กรุณาตรวจสอบราคา (ยอดเรียกเก็บ = ${deposit + deposit2 + full}, ยอดจริง = ${cost + totalIncomeEtc - totalDiscount}`);
		return false;
	}
};
