import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import APIGet from '../../API/APIGet';
import APIHelper from '../../Helper/APIHelper';
import ThingsToSelect from '../../Helper/ThingsToSelect';

import LoadingCenter from '../LoadingCenter';

export default ({ onSelectCustomer }) => {
	const [selectCustomers, setSelectCustomers] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [customers, setCustomers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		// Hooks: similar to componentDidMount
		console.log('test useEffect');
		fetchData();
	}, []);

	const fetchData = async () => {
		const getCustomers = await APIGet('jointour/customers');
		console.log(getCustomers);
		if (APIHelper.handleAPIResponse(this, getCustomers)) {
			const selectCustomers = ThingsToSelect(getCustomers.data);
			setSelectCustomers(selectCustomers);
			setCustomers(getCustomers.data);
			setIsLoading(false);
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	const onAdd = () => {
		const customer = customers.filter(customer => customer.id == selectedCustomer.value)[0];
		if (customer) {
			onSelectCustomer(customer);
		}
	};

	const onCustomerChange = customer => {
		setSelectedCustomer(customer);
	};

	if (isLoading) {
		return <LoadingCenter />;
	}
	return (
		<>
			<div className="columns">
				<div className="column is-10">
					<label className="label">ลูกค้า</label>
					<Select name="selectedCustomer" value={selectedCustomer} clearable={false} onChange={onCustomerChange} options={selectCustomers} />
				</div>
				<div className="column is-2 button-padding-label">
					<button className="button" onClick={onAdd} disabled={selectedCustomer ? false : true}>
						เพิ่ม
					</button>
				</div>
			</div>
		</>
	);
};
