import React, { Component } from 'react';
import Select from 'react-select';
export const EditableSelectSwitch = props => {
	return (
		<>
			{props.title ? (
				<label className="label" style={{ color: 'black' }}>
					{props.title}
					{props.isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
				</label>
			) : null}
			{props.isEditing ? (
				<div className="">
					<Select
						name={props.selectName}
						value={props.value}
						clearable={props.isClearable}
						onChange={props.onChange}
						options={props.options}
						isDisabled={props.isDisabled}
					/>
				</div>
			) : (
				<div>{props.children}</div>
			)}
		</>
	);
};
