import React, { PureComponent } from 'react';
import Select from 'react-select';

import InputRow from '../../../Components/InputRow';

import CUSTOMER_CHANNELS from '../../../StaticData/CustomerChannels';

export default class CustomerDetail extends PureComponent {
	render() {
		const { selectedCustomer, customers, customerContacts, selectedCustomerContact, telephone, email, lineId, selectedChannel } = this.props;
		// console.log('inside customerContacts: ', customerContacts);
		if (!customerContacts || customerContacts.length === 0) {
			return (
				<div className="row columns">
					<div className="column is-6">
						<label className="label">ลูกค้า</label>
						<Select name="selectedCustomer" value={selectedCustomer} clearable={false} onChange={this.props.onCustomerChange} options={customers} />
					</div>
				</div>
			);
		}

		return (
			<div>
				<div className="row columns">
					<div className="column is-6">
						<label className="label">ลูกค้า</label>
						<Select name="selectedCustomer" value={selectedCustomer} clearable={false} onChange={this.props.onCustomerChange} options={customers} />
					</div>
					<div className="column is-6">
						<label className="label">ติดต่อ</label>
						<Select
							name="selectedCustomerContact"
							value={selectedCustomerContact}
							clearable={false}
							onChange={this.props.onCustomerContactChange}
							options={customerContacts}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<InputRow
							title="เบอร์โทรศัพท์"
							labelSize="s"
							placeholder="เบอร์โทรศัพท์"
							keyProps="telephone"
							value={telephone}
							onInputChange={this.props.onStateChange}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="column is-6">
						<InputRow
							title="Email"
							labelSize="s"
							placeholder="Email"
							keyProps="email"
							value={email}
							onInputChange={this.props.onStateChange}
							disabled={false}
							isMandatory={false}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<InputRow
							title="Line ID"
							labelSize="s"
							placeholder="Line ID"
							keyProps="lineId"
							value={lineId}
							onInputChange={this.props.onStateChange}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="column is-6">
						<label className="label">ช่องทางติดต่อ</label>
						<Select
							name="channel"
							value={selectedChannel}
							clearable={false}
							onChange={value => this.props.onStateChange('selectedChannel', value)}
							options={CUSTOMER_CHANNELS}
						/>
					</div>
				</div>
			</div>
		);
	}
}
