import React, { useMemo } from 'react';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import { SumTable } from './SumTable';

import PLANNING_EXPENSE_TYPES from '../../../../StaticData/PlanningExpenseTypes';
import { Remark } from './Remark';

export const GeneralTab = ({ tabName, displayName, defaultRecord, records, state, onChange, canRemove, onRemarkChange }) => {
	const { isEditing, selectedTab } = state;

	if (selectedTab !== tabName) {
		return <></>;
	}

	if (!records) return <></>;

	return (
		<div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 50, display: selectedTab === tabName ? 'block' : 'none' }}>
			<Remark state={state} onChange={onRemarkChange} />
			<p className="" style={{ backgroundColor: '#209CEE', fontSize: 25, fontWeight: 'bold', color: 'white', padding: 5, paddingLeft: 10 }}>
				{displayName}
			</p>
			<div style={{ height: '30px' }} />
			{records && records.length > 0
				? records.map((record, index) => (
						<RecordRow
							key={`record-${index}`}
							record={record}
							index={index}
							isEditing={isEditing}
							onChange={(index, newAssessment) => {
								onChange([...records.slice(0, index), newAssessment, ...records.slice(index + 1, records.length)]);
							}}
							onRemove={index => {
								onChange([...records.slice(0, index), ...records.slice(index + 1, records.length)]);
							}}
							canRemove={canRemove}
						/>
				  ))
				: null}

			<div style={{ height: '50px' }} />
			{/* คิดว่าตรงนี้ยังไม่จำเป็นเพราะจาก design จะมีได้แค่ 1 expense แล้วค่อยไปใส่รายละเอียดด้านใน */}
			{/* {isEditing ? (
				<button
					className="button is-info"
					onClick={e => {
						e.preventDefault();
						console.log('on add called');
						onChange([...(records ? records : []), defaultRecord]);
					}}
				>
					+เพิ่มรายการ
				</button>
			) : null} */}
			<div />
		</div>
	);
};

const RecordRow = ({ index, isEditing, record, exchangeRates, onChange, onRemove, canRemove }) => {
	const _onChange = (key, value) => {
		const updatedData = JSON.parse(JSON.stringify(record));
		updatedData[key] = value;
		onChange(index, updatedData);
	};

	const _onRemoveRecord = expenseIndex => {
		const updatedData = JSON.parse(JSON.stringify(record));
		updatedData.expenses = [...updatedData.expenses.slice(0, expenseIndex), ...updatedData.expenses.slice(expenseIndex + 1, updatedData.expenses.length)];
		onChange(index, updatedData);
	};

	const selectedExpenseType = useMemo(() => {
		if (record.expenseType && !record.expenseType.label) {
			if (record.expenseType === 'pax') {
				return PLANNING_EXPENSE_TYPES[0];
			} else if (record.expenseType === 'avg') {
				return PLANNING_EXPENSE_TYPES[1];
			}
		}

		return record.expenseType;
	}, [record.expenseType]);

	return (
		<div style={{ marginBottom: 30 }}>
			<div className="columns" style={{ justifyContent: 'center' }}>
				<div className="column is-4">
					<div className="level level-item">
						<p style={{ paddingRight: 20 }}>{index + 1}.</p>
						<div className="" style={{ width: '100%' }}>
							<EditableTextSwitch
								isEditing={isEditing}
								type={'text'}
								value={record.name}
								onChange={text => {
									_onChange('name', text);
								}}
							>
								<p style={{ textAlign: 'left' }}>{record.name}</p>
							</EditableTextSwitch>
						</div>
					</div>
				</div>
				<div className="column is-2" />
				<div className="column is-2" />
				<div className="column is-2">
					<Select
						placeholder="วิธีคิด"
						value={selectedExpenseType}
						onChange={expenseType => {
							_onChange('expenseType', expenseType.value);
						}}
						options={PLANNING_EXPENSE_TYPES}
						// ตรงนี้ต้องไม่สามารถให้แก้ได้ หัวข้อไหนเลือกแบบไหนก็ต้องได้แบบนั้น
						isDisabled={true}
					/>
				</div>
				{canRemove ? (
					<div className="column is-1" style={{ display: 'flex', alignItems: 'center' }}>
						<FontAwesomeIcon
							icon={faTrashCan}
							style={{ color: isEditing ? 'red' : 'black', cursor: isEditing ? 'pointer' : 'auto' }}
							onClick={e => {
								e.preventDefault();
								if (isEditing) {
									onRemove(index);
								}
							}}
						/>
					</div>
				) : (
					<div className="column is-1" />
				)}
			</div>
			<div className="" style={{ paddingLeft: 70, paddingRight: 50 }}>
				<table className="" style={{ width: '100%' }}>
					<tbody>
						{record.expenses && record.expenses.length > 0
							? record.expenses.map((expense, expenseIndex) => (
									<tr key={`record-${index}-expense-${expenseIndex}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
										<td style={{ width: '3%' }}>•</td>
										<td style={{ width: '35%', paddingRight: 80 }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'text'}
												value={expense.name}
												onChange={text => {
													const updatedData = JSON.parse(JSON.stringify(record));
													updatedData.expenses[expenseIndex] = {
														...expense,
														name: text,
													};

													onChange(index, updatedData);
												}}
											>
												<p style={{ textAlign: 'left' }}>{expense.name}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '20%' }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'number'}
												value={expense.price}
												onChange={text => {
													const updatedData = JSON.parse(JSON.stringify(record));
													updatedData.expenses[expenseIndex] = {
														...expense,
														price: text,
													};

													onChange(index, updatedData);
												}}
											>
												<p style={{ textAlign: 'center' }}>{expense.price ? expense.price.toLocaleString() : 0}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '1%', paddingLeft: 10, paddingRight: 15 }}>x</td>
										<td style={{ width: '12%' }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'number'}
												value={expense.quantity}
												onChange={text => {
													const updatedData = JSON.parse(JSON.stringify(record));
													updatedData.expenses[expenseIndex] = {
														...expense,
														quantity: text,
													};

													onChange(index, updatedData);
												}}
											>
												<p style={{ textAlign: 'center' }}>{expense.quantity}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '15%', display: 'flex', paddingLeft: 10 }}>
											<p style={{ paddingRight: 10 }}>=</p>
											<p>{(expense.price * expense.quantity).toLocaleString()}</p>
										</td>
										<td style={{ width: '5%', display: 'flex', paddingLeft: 10 }}>
											<FontAwesomeIcon
												icon={faTrashCan}
												style={{ color: isEditing ? 'red' : 'black', cursor: isEditing ? 'pointer' : 'auto' }}
												onClick={e => {
													e.preventDefault();
													if (isEditing) {
														_onRemoveRecord(expenseIndex);
													}
												}}
											/>
										</td>
									</tr>
							  ))
							: null}
						<tr style={{ display: 'flex', alignItems: 'center', marginTop: isEditing ? 20 : 0, marginBottom: 15 }}>
							<td style={{ width: '3%' }}>•</td>
							<td style={{ width: '35%' }}>รวม</td>
							<td style={{ width: '20%' }} />
							<td style={{ width: '1%', paddingLeft: 10, paddingRight: 15 }} />
							<td style={{ width: '12%' }} />
							<td style={{ width: '15%', display: 'flex', paddingLeft: 10 }}>
								<p style={{ paddingRight: 10 }}>=</p>
								<p>
									{record.expenses && record.expenses.length > 0
										? record.expenses.reduce((acc, expense) => acc + expense.price * expense.quantity, 0).toLocaleString()
										: 0}
								</p>
							</td>
						</tr>
						<tr>
							<td>
								{isEditing ? (
									<button
										className="button is-success is-small"
										onClick={e => {
											e.preventDefault();
											const expenses = [
												...(record.expenses ? record.expenses : []),
												{
													id: null,
													name: '',
													price: 0,
													quantity: 0,
												},
											];

											_onChange('expenses', expenses);
										}}
									>
										+เพิ่ม
									</button>
								) : null}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
