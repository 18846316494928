import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DeleteButton from '../../../Components/DeleteButton';
import DateLabel from '../../../Components/DateLabel';
import Stars from '../../../Components/Restaurant/Stars';

import APIGet from '../../../API/APIGet';
import APIDelete from '../../../API/APIDelete';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

import AddModal from './AddModal';

export default class ScheduledRestaurants extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isOpenModal: false,
			restaurants: [],
			selectedId: 0,
		};
	}
	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.getRestaurants();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	componentDidUpdate = async prevProps => {
		if (this.props.triggerForceUpdate !== prevProps.triggerForceUpdate) {
			await this.getRestaurants();
		}
	};

	getRestaurants = async () => {
		const { programId } = this.props;
		this.setState({
			isLoading: true,
		});
		let request = APIGet(`incentive/programs/${programId}/restaurants`);
		request = await request;
		if (APIHelper.handleAPIResponse(this, request)) {
			const restaurants = request.data;
			console.log('restaurants: ', restaurants);
			this.setState({
				isLoading: false,
				restaurants,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	toEdit = id => {
		console.log(id);
		this.setState({
			selectedId: parseInt(id),
			isOpenModal: true,
		});
	};

	toggleAddModal = () => {
		this.setState({
			isOpenModal: !this.state.isOpenModal,
		});
	};

	remove = async (programId, restaurantId, bookingId) => {
		const request = await APIDelete(`incentive/programs/${programId}/restaurants/${restaurantId}/${bookingId}`);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('นำออกเรียบร้อยแล้ว');
			await this.getRestaurants();
		} else {
			toast.error('ไม่สามารถทำรายการได้');
		}
	};

	edit = async (selectedRestaurantId, visitDate, visitTime, pricePerHead) => {
		if (!visitDate || !visitTime || !pricePerHead) {
			toast.warn('โปรดตรวจสอบการกรอกข้อมูล');
			return;
		}

		const { programId } = this.props;
		this.setState({
			isLoading: true,
		});

		let data = {
			visitDate: visitDate.format('YYYY-MM-DD'),
			visitTime,
			pricePerHead,
		};
		console.log(data);
		let request = await APIPut(`incentive/programs/${programId}/restaurants/${selectedRestaurantId}/${this.state.selectedId}`, data);

		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('เพิ่มเรียบร้อยแล้ว');
			await this.getRestaurants();
		} else {
			toast.error('เกิดปัญหาในการเพิ่ม/แก้ไข');
		}

		this.setState({
			isLoading: false,
			isOpenModal: false,
		});
	};

	getVisitDetailById = id => {
		let selectedRestaurant = this.state.restaurants.filter(visit => visit.id === id);
		if (selectedRestaurant.length === 1) return selectedRestaurant[0];

		return {};
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}
		const { restaurants, isOpenModal, selectedId } = this.state;
		const { programId } = this.props;
		const selectedVisitDetail = this.getVisitDetailById(selectedId);
		console.log(selectedVisitDetail);
		return (
			<>
				{restaurants && restaurants.length > 0 ? (
					<>
						<div className="table-container">
							<strong>ร้านในโปรแกรม</strong>
							<table className="table is-hoverable is-fullwidth is-striped is-bordered">
								<tbody>
									{restaurants.map(restaurantVisitInfo => {
										const { restaurant } = restaurantVisitInfo;
										if (!restaurant) {
											return null;
										}

										return (
											<tr key={'restaurant.' + restaurantVisitInfo.id}>
												<td>
													<div className="columns">
														<div className="column is-10">
															<DateLabel date={restaurantVisitInfo.visitDate} /> {restaurantVisitInfo.visitTime}{' '}
															<Stars active={restaurant.star} />
															<br />
															<Link
																to={
																	AppConfig.appPath +
																	'/programs/incentive/' +
																	programId +
																	'/restaurants/' +
																	restaurant.id +
																	'/' +
																	restaurantVisitInfo.id
																}
															>
																{restaurant.name}
															</Link>
															<br />
															<span>{restaurantVisitInfo.pricePerHead}</span>
															<br />
															<a href="#" onClick={() => this.toEdit(restaurantVisitInfo.id)}>
																แก้ไข
															</a>
														</div>
														<div className="column is-2">
															<button
																className="delete"
																onClick={() =>
																	this.remove(
																		restaurantVisitInfo.incentiveProgramId,
																		restaurantVisitInfo.restaurantId,
																		restaurantVisitInfo.id
																	)
																}
															/>
														</div>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</>
				) : (
					<div style={{ textAlign: 'center' }}>ยังไม่มีร้าน</div>
				)}
				{isOpenModal ? (
					<AddModal
						isShown={isOpenModal}
						save={this.edit}
						toggleModal={this.toggleAddModal}
						selectedRestaurantId={selectedVisitDetail.restaurantId}
						{...selectedVisitDetail}
					/>
				) : null}
			</>
		);
	}
}
