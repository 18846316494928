import React, { Component } from 'react';

import NumberColor from './NumberColor';
import InputRow from './InputRow';

export default class PricingRowWithTitle extends Component {
	shouldComponentUpdate = (nextProps, nextState) => {
		return (
			this.props.title !== nextProps.title ||
			this.props.quantity !== nextProps.quantity ||
			this.props.pricePerPiece !== nextProps.price ||
			this.props.total !== nextProps.total
		);
	};

	render() {
		const { quantity, pricePerPiece, total, title, isExpense } = this.props;
		return (
			<tr>
				<td>
					<InputRow
						placeholder=""
						keyProps="title"
						value={title}
						onInputChange={(key, value) => this.props.onTitleChange(value)}
						disabled={false}
						isMandatory={false}
					/>
				</td>
				<td>
					<InputRow
						placeholder="จำนวน"
						keyProps="quantity"
						value={quantity}
						onInputChange={(key, value) => this.props.onQuantityChange(value)}
						disabled={false}
						isMandatory={false}
					/>
				</td>
				<td>
					<InputRow
						placeholder="ราคา"
						keyProps="pricePerPiece"
						value={pricePerPiece}
						onInputChange={(key, value) => this.props.onPriceChange(value)}
						disabled={false}
						isMandatory={false}
					/>
				</td>
				<td style={{ paddingTop: 18 }} className="number-cell">
					<NumberColor number={total} isExpense={isExpense} />
				</td>
			</tr>
		);
	}
}
