import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment-timezone';
import queryString from 'query-string';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import MonthFilter from '../../../Helper/MonthFilter';
import YearFilter from '../../../Helper/YearFilter';
import OpenLink from '../../../Helper/OpenLink';

import LoadingCenter from '../../../Components/LoadingCenter';
import InputRow from '../../../Components/InputRow';

const MONTHS = MonthFilter();
const YEARS = YearFilter();

export default class Salary extends PureComponent {
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		const date = query.month && query.year ? moment(new Date(query.month + ' ' + query.year)) : moment(new Date());
		this.state = {
			isLoading: true,
			staffs: [],
			salaries: [],
			month: {
				label: date.format('MMMM'),
				value: date.format('MMMM'),
			},
			year: {
				label: date.format('YYYY'),
				value: date.format('YYYY'),
			},
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Admin')) {
			this.getData();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	getData = async () => {
		this.setState({
			isLoading: true,
		});
		let { month, year } = this.state;
		let requestUser = APIGet('staffs');
		let requestSalary = APIGet(`finance/salaries/${year.value}/${month.value}`);
		requestUser = await requestUser;
		requestSalary = await requestSalary;
		if (APIHelper.handleAPIResponse(this, requestUser, requestSalary)) {
			const staffs = requestUser.data;
			const salaries = requestSalary.data;
			console.log('staffs: ', staffs);
			console.log('salaries: ', salaries);
			this.setState({
				isLoading: false,
				staffs,
				salaries,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	getSalary = staffId => {
		const { salaries } = this.state;
		let userSalary = salaries.filter(row => row.staffId === staffId);
		if (userSalary.length > 0) {
			return userSalary[0].total;
		} else {
			return 0;
		}
	};

	onMonthChange = value => {
		this.setState({ month: value }, () => {
			this.getData();
		});
	};

	onYearChange = value => {
		this.setState({ year: value }, () => {
			this.getData();
		});
	};

	updateSalary = (staffId, value) => {
		let salaries = [...this.state.salaries];
		let hasUpdated = false;
		for (let i = 0; i < salaries.length; i++) {
			if (salaries[i].staffId === staffId) {
				hasUpdated = true;
				salaries[i].total = value;
			}
		}

		if (!hasUpdated) {
			salaries.push({
				staffId: staffId,
				total: value,
			});
		}

		this.setState({
			salaries,
		});
	};

	save = async () => {
		this.setState({
			isLoading: true,
		});

		const { month, year, staffs } = this.state;
		let expenses = [];
		for (let i = 0; i < staffs.length; i++) {
			const staffId = this.state.staffs[i].id;
			expenses[i] = {
				total: this.getSalary(staffId),
				staffId,
			};
		}

		let data = {
			expenses,
		};

		const request = await APIPost(`finance/salaries/${year.value}/${month.value}`, data);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('แก้ไขรายการเรียบร้อยแล้ว');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('เกิดปัญหาในการเพิ่มข้อมูล');
			this.setState({
				isLoading: false,
			});
		}
	};

	print = async () => {
		await this.save();
		const { month, year } = this.state;
		OpenLink.printSalary(year.value, month.value);
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-3">เงินเดือน</h1>
					</div>
					<div className="column is-2" />
					<div className="column is-2" style={{}}>
						<Select name="year" value={this.state.year} clearable={false} onChange={this.onYearChange} options={YEARS} />
					</div>
					<div className="column is-2" style={{}}>
						<Select name="month" value={this.state.month} clearable={false} onChange={this.onMonthChange} options={MONTHS} />
					</div>
				</div>
				<div className="section table-container">
					<div style={{ textAlign: 'right', marginBottom: 5 }}>
						<button className="button is-primary" onClick={this.print}>
							Print
						</button>
					</div>
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.staffs.map(staff => (
								<tr key={staff.id}>
									<td width="50%">{staff.name}</td>
									<td width="50%" style={{ textAlign: 'right' }}>
										<InputRow
											hideLabel={true}
											placeholder="Unit"
											keyProps="unit"
											value={this.getSalary(staff.id)}
											onInputChange={(key, value) => this.updateSalary(staff.id, value)}
											disabled={false}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div>
						<button className="button is-success" onClick={this.save}>
							Save
						</button>
					</div>
				</div>
			</div>
		);
	}
}
