import DEFAULT_ALL from './DefaultAll';

export const PROGRAM_STATUS = [
	DEFAULT_ALL,
	{
		value: 'In Progress',
		label: 'In Progress',
	},
	{
		value: 'Done',
		label: 'Done',
	},
	{
		value: 'Cancelled',
		label: 'Cancelled',
	},
];

export const PROGRAM_STATUS_REQUIRED = [
	{
		value: 'In Progress',
		label: 'In Progress',
	},
	{
		value: 'Done',
		label: 'Done',
	},
	{
		value: 'Cancelled',
		label: 'Cancelled',
	},
];

export default PROGRAM_STATUS_REQUIRED;
