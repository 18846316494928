import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import DeleteButton from '../../../Components/DeleteButton';
import FileManager from '../../../Components/FileManager';

import APIGet from '../../../API/APIGet';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

import Store from '../../../Helper/Store';
import StoreKey from '../../../StoreKey';

export default class IncomeInfo extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			income: null,
		};
	}

	async componentDidMount() {
		const { programId, incomeId } = this.props;
		let requestIncome = APIGet('incentive/programs/' + programId + '/incomes/' + incomeId);
		requestIncome = await requestIncome;
		if (APIHelper.handleAPIResponse(this, requestIncome)) {
			const income = requestIncome.data;
			console.log('income: ', income);
			this.setState({
				isLoading: false,
				income,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
		}
	}

	delete = async () => {
		const { programId, incomeId } = this.props;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete('incentive/programs/' + programId + '/incomes/' + incomeId);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.goBack();
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	onUploadSuccess = income => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.setState({
			income,
		});
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = income => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.setState({
			income,
		});
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	gotoAddTransaction = row => {
		const { programId, incomeId } = this.props;
		const title = row.title;
		const total = row.pricePerPiece * row.quantity;
		const url = AppConfig.appPath + '/programs/incentive/' + programId + '/incomes/' + incomeId + '/newtransaction';
		Store.set(StoreKey.incomeTransaction.title, title);
		Store.set(StoreKey.incomeTransaction.total, total);
		this.props.goto(url);
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { programId, incomeId, program } = this.props;
		const { income } = this.state;

		return (
			<div className="section">
				<div className="columns">
					<div className="column is-8">
						<h3 className="subtitle is-5">รายรับ</h3>
						<h3 className="title is-3">
							{income.title}{' '}
							{AdminLockRender(
								program.status,
								<Link className="button is-info" to={AppConfig.appPath + '/programs/incentive/' + programId + '/incomes/' + incomeId + '/edit'}>
									แก้ไข
								</Link>,
								<button className="button is-info" disabled>
									แก้ไข
								</button>
							)}
						</h3>
					</div>
					<div className="column is-4" style={{ textAlign: 'right' }}>
						{AdminLockRender(program.status, <DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />, null)}
					</div>
				</div>
				<div className="columns">
					<div className="column is-4">
						<div>
							ยอดเรียกเก็บทั้งหมด:{' '}
							<strong>
								<NumberColor number={income.toCollectFromCustomer} />
							</strong>
						</div>
						<div>
							รับชําระมาเเล้ว:{' '}
							<strong>
								<NumberColor number={income.incomeFromCustomer} />
							</strong>
						</div>
						<div>
							ค้างจ่าย:{' '}
							<strong>
								<NumberColor number={income.incomeFromCustomer - income.toCollectFromCustomer} />
							</strong>
						</div>
						<div>
							สถานะ:{' '}
							<strong>
								{income.toCollectFromCustomer > income.incomeFromCustomer ? (
									<label className="tag is-warning">ยังเก็บไม่ครบ</label>
								) : (
									<label className="tag is-success">เก็บครบแล้ว</label>
								)}
							</strong>
						</div>
						<div>
							ต้องชำระภายใน:{' '}
							<strong>
								<DateLabel date={income.expectedPaymentDate} />
							</strong>
						</div>
						<div>
							{income.billedDate ? (
								<span>
									วันวางบิล:{' '}
									<strong>
										<DateLabel date={income.billedDate} />
									</strong>
								</span>
							) : null}
						</div>
						<div>
							<strong>Note:</strong>
							<p>{income.note}</p>
						</div>
					</div>
					<div className="column is-4">
						{AdminLockRender(
							program.status,
							<FileManager
								title="Files"
								files={income.incentiveIncomeFiles}
								api={'incentive/programs/' + programId + '/incomes/' + incomeId + '/files'}
								onUploadSuccess={this.onUploadSuccess}
								onUploadFail={this.onUploadFail}
								onDeleteSuccess={this.onDeleteSuccess}
								onDeleteFail={this.onDeleteFail}
							/>,
							<FileManager
								title="Files"
								disableUpload={true}
								disableDelete={true}
								files={income.incentiveIncomeFiles}
								api={'incentive/programs/' + programId + '/incomes/' + incomeId + '/files'}
								onUploadSuccess={this.onUploadSuccess}
								onUploadFail={this.onUploadFail}
								onDeleteSuccess={this.onDeleteSuccess}
								onDeleteFail={this.onDeleteFail}
							/>
						)}
					</div>
					<div className="column is-4">
						<div className="content">
							<strong>Invoices</strong>
							<ul>
								{income.pdfIncentiveIncomeInvoiceReceiptsWithDeleted &&
									income.pdfIncentiveIncomeInvoiceReceiptsWithDeleted.map(doc => (
										<li key={'paymentVouchers' + doc.id}>
											<a onClick={() => OpenLink.incentiveInvoiceReceipt(doc.id)} target="_blank">
												{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
											</a>
											{doc.deleted_at && (
												<label className="tag is-warning">
													Inactive - <DateLabel date={doc.deleted_at} />
													{}
												</label>
											)}
										</li>
									))}
							</ul>
						</div>
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="40%">รายการ</td>
								<td width="20%">ราคาต่อหน่วย</td>
								<td width="20%">จำนวน</td>
								<td width="20%">เพิ่มรายการเก็บเงิน</td>
							</tr>
						</thead>
						<tbody>
							{income.incentiveIncomeList.map(row => (
								<tr key={'incentiveList' + row.id}>
									<td>{row.title}</td>
									<td className="number-cell">
										<NumberColor number={row.pricePerPiece} />
									</td>
									<td className="number-cell">
										<NumberColor number={row.quantity} />
									</td>
									<td style={{ textAlign: 'center' }}>
										{AuthHelper.hasRight('Finance', 'Admin') ? (
											<button className="button is-info" onClick={() => this.gotoAddTransaction(row)}>
												เพิ่ม
											</button>
										) : (
											<span className="tag is-warning">เฉพาะ Finance</span>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
