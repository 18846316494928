import React, { PureComponent } from 'react';
import AppConfig from '../AppConfig';

export default class Footer extends PureComponent {
	render() {
		return (
			<footer className="footer" style={{ marginTop: 20 }}>
				<div className="content has-text-centered">
					<strong>2022 ESKY</strong>
					<div>
						{AppConfig.appVersion}
						{AppConfig.targetEnvironment !== 'production' ? <span> | {AppConfig.targetEnvironment}</span> : null}
					</div>
				</div>
			</footer>
		);
	}
}
