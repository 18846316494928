export default (state, toast) => {
	let result = true;
	result = result & validateInput(state.paymentDate, 'วันที่ชำระ', toast);
	result = result & validateInput(state.title, 'รายการ', toast);
	result = result & validateNumber(state.total, 'รายรับ', toast);

	return result;
};

const validateInput = (data, aka, toast) => {
	if (!data) {
		toast.warn('กรุณาระบุ' + aka);
		return false;
	}

	return true;
};

const validateNumber = (data, aka, toast) => {
	if (isNaN(data)) {
		toast.warn('กรุณาระบุ' + aka + ' โดยกรอกเป็นตัวเลขและจุดทศนิยมเท่านั้น (ไม่ต้องมี ,)');
		return false;
	}

	return true;
};
