import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import NumberColor from '../../../Components/NumberColor';

import AppConfig from '../../../AppConfig';

export default class Expense extends PureComponent {
	summary = () => {
		const { expenses } = this.props;
		let total = 0;
		expenses.forEach(expense => {
			total += expense.total;
		});

		return total;
	};

	render() {
		const { expenses } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">รายจ่าย</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-warning" to={AppConfig.appPath + '/programs/fit/' + this.props.fitId + '/newexpense'}>
							เพิ่มรายการ
						</Link>
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped">
						<thead>
							<tr className="table-header">
								<td width="50%">รายการ</td>
								<td width="30%" style={{ textAlign: 'right' }}>
									ยอด (THB)
								</td>
							</tr>
						</thead>
						<tbody>
							{expenses.map(expense => (
								<tr key={'expense' + expense.id}>
									<td>
										<Link to={AppConfig.appPath + '/programs/fit/' + this.props.fitId + '/expenses/' + expense.id}>{expense.title}</Link>
										{!expense.financeStaffId ? (
											<span className="tag is-warning">พนักงานบัญชียังไม่ตรวจสอบ</span>
										) : (
											<span className="tag is-success">พนักงานบัญชีตรวจสอบแล้ว</span>
										)}
									</td>
									<td className="number-cell">
										<NumberColor number={expense.total} isExpense={true} />
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan={1}>Summary</td>
								<td className="number-cell">
									<NumberColor number={this.summary()} isExpense={true} /> บาท
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
