import React from 'react';

export default ({ title, placeholder, value, onChange, disabled }) => {
	const onChangeText = event => {
		onChange(event.target.value);
	};
	return (
		<div>
			{title ? (
				<label className="label" style={{ color: 'black' }}>
					{title}
				</label>
			) : null}
			<div className="control">
				<input className={'input'} type={'text'} placeholder={placeholder} value={value ? value : ''} onChange={onChangeText} disabled={disabled} />
			</div>
		</div>
	);
};
