import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import EditableText from '../../../Components/EditableText';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';
import AppConfig from '../../../AppConfig';

export default class ExpenseCategories extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			expenseCategories: [],
			isEditLoading: '',
			newexpenseCategory: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			const request = await APIGet('finance/companyExpenseCategories');
			if (APIHelper.handleAPIResponse(this, request)) {
				const expenseCategories = request.data;
				console.log(expenseCategories);
				this.setState({
					isLoading: false,
					expenseCategories,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	saveChange = async (expenseCategoryId, text) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPut(
			'finance/companyExpenseCategories/' + expenseCategoryId,
			{
				name: text,
			}
		);

		if (APIHelper.handleAPIResponse(this, request)) {
			const expenseCategory = request.data;
			let expenseCategories = JSON.parse(
				JSON.stringify(this.state.expenseCategories)
			);
			for (let i = 0; i < expenseCategories.length; i++) {
				if (expenseCategories[i].id === expenseCategoryId) {
					expenseCategories[i] = expenseCategory;
				}
			}

			// console.log(expenseCategories);
			toast.success('แก้ไขข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				expenseCategories,
			});
		} else {
			toast.error('ไม่สามารถแก้ไขข้อมูลได้');
		}
	};

	addNew = async text => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPost('finance/companyExpenseCategories', {
			name: text,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const expenseCategory = request.data;
			let expenseCategories = JSON.parse(
				JSON.stringify(this.state.expenseCategories)
			);
			expenseCategories.push(expenseCategory);

			// console.log(expenseCategories);
			toast.success('เพิ่มข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				expenseCategories,
			});
		} else {
			toast.error('ไม่สามารถเพิ่มข้อมูลได้');
		}
	};

	delete = async expenseCategoryId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete(
			'finance/companyExpenseCategories/' + expenseCategoryId
		);
		if (APIHelper.handleAPIResponse(this, request)) {
			const expenseCategories = this.state.expenseCategories.filter(
				expenseCategory => expenseCategory.id !== expenseCategoryId
			);

			// console.log(expenseCategories);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				expenseCategories,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">ประเภทรายจ่ายของบริษัท</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.expenseCategories.map(
								expenseCategory => (
									<tr key={expenseCategory.id}>
										<td width="80%">
											<EditableText
												id={expenseCategory.id}
												text={expenseCategory.name}
												confirmChange={text =>
													this.saveChange(
														expenseCategory.id,
														text
													)
												}
												confirmDelete={this.delete}
											>
												{expenseCategory.name}
											</EditableText>
										</td>
										<td
											width="20%"
											style={{ textAlign: 'right' }}
										>
											<NavLink
												to={`${
													AppConfig.appPath
												}/finance/companyexpensecategories/${
													expenseCategory.id
												}/suppliers`}
											>
												Suppliers
											</NavLink>
										</td>
									</tr>
								)
							)}
							<tr>
								<td width="100%" colSpan={2}>
									<EditableText
										text={this.state.newexpenseCategory}
										confirmChange={text =>
											this.addNew(text)
										}
										confirmText="เพิ่ม"
									>
										เพิ่มประเภทรายจ่าย
									</EditableText>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
