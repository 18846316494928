import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIHelper from '../../../Helper/APIHelper';

import LoadingCenter from '../../../Components/LoadingCenter';
import Stars from '../../../Components/Restaurant/Stars';

import Search from './Search';
import AddModal from './AddModal';

import AppConfig from '../../../AppConfig';

export default class FindRestaurants extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			restaurants: [],
			search: {
				name: '',
				city: '',
				country: null,
			},
			isShownAddModal: false,
			selectedRestaurantId: null,
		};
	}

	onSearchChange = (key, value) => {
		let search = { ...this.state.search };
		search[key] = value;
		this.setState({
			search,
		});
	};

	getData = async () => {
		this.setState({
			isLoading: true,
		});

		const request = await APIGet('restaurants', {
			city: this.state.search.city,
			name: this.state.search.name,
			countryId: this.state.search.country ? this.state.search.country.value : 0,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const restaurants = request.data;
			console.log('restaurants: ', restaurants);
			this.setState({
				isLoading: false,
				restaurants,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	toggleAddModal = selectedRestaurantId => {
		this.setState({
			isShownAddModal: !this.state.isShownAddModal,
			selectedRestaurantId,
		});
	};

	add = async (selectedRestaurantId, visitDate, visitTime, pricePerHead) => {
		if (!visitDate || !visitTime || !pricePerHead) {
			toast.warn('โปรดตรวจสอบการกรอกข้อมูล');
			return;
		}

		const { programId } = this.props;
		this.setState({
			isLoading: true,
		});

		let data = {
			visitDate: visitDate.format('YYYY-MM-DD'),
			visitTime,
			pricePerHead,
		};
		console.log(data);
		let request = await APIPost(`incentive/programs/${programId}/restaurants/${selectedRestaurantId}`, data);

		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('เพิ่มเรียบร้อยแล้ว');
			this.props.forceUpdateSchedule();
		} else {
			toast.error('เกิดปัญหาในการเพิ่ม/แก้ไข');
		}

		this.setState({
			isLoading: false,
			isShownAddModal: false,
		});
	};

	renderSearch = () => {
		return <Search onSearchChange={this.onSearchChange} keywords={this.state.search} onClick={this.getData} />;
	};

	render() {
		const { isLoading, restaurants, isShownAddModal, selectedRestaurantId } = this.state;
		if (isLoading) {
			return (
				<div>
					{this.renderSearch()}
					<LoadingCenter />
				</div>
			);
		}

		if (restaurants.length === 0) {
			return (
				<div>
					{this.renderSearch()}
					<div style={{ textAlign: 'center' }}>ไม่พบร้าน</div>
				</div>
			);
		}

		return (
			<div>
				{this.renderSearch()}
				<table className="table is-hoverable is-fullwidth is-striped is-bordered">
					<tbody>
						{restaurants.map(restaurant => (
							<tr key={'restaurant' + restaurant.id}>
								<td width="75%">
									<Link target="_blank" to={AppConfig.appPath + '/data/incentive/restaurants/' + restaurant.id}>
										{restaurant.name}
									</Link>{' '}
									<Stars active={restaurant.star} />
									<div>ราคาต่อลูกค้า: {restaurant.pricePerHead}</div>
									<div style={{ fontSize: 10 }}>
										{restaurant.country ? restaurant.country.name : ''} {restaurant.city}
									</div>
								</td>
								<td style={{ textAlign: 'center' }}>
									<span className="clickable" onClick={() => this.toggleAddModal(restaurant.id)}>
										Add
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<AddModal isShown={isShownAddModal} save={this.add} toggleModal={this.toggleAddModal} selectedRestaurantId={selectedRestaurantId} />
			</div>
		);
	}
}
