import { decode } from 'jsonwebtoken';
import AppConfig from '../AppConfig';
import { toast } from 'react-toastify';

import Store from './Store';
import StoreKey from '../StoreKey';

const AuthHelper = (function() {
	// const accessTokenKey = 'ax';
	// const refreshTokenKey = 'bn';
	// const tokenTypeKey = 'ci';
	// const userDetailKey = 'pe';

	var accessToken = '';
	var refreshToken = '';
	var tokenType = '';

	var isLogin = () => {
		try {
			var accessToken = Store.get(StoreKey.auth.accessToken);
			const decodedToken = decode(accessToken, { complete: true });
			if (decodedToken && !hasTokenExpired(decodedToken.payload.exp)) {
				return true;
			} else {
				return false;
			}
		} catch (e) {
			console.log(e);
		}

		return false;
	};

	var hasTokenExpired = expireTime => {
		var dateNow = new Date();
		expireTime = expireTime * 1000; // workaround -- need to make into ms
		if (expireTime < dateNow.getTime()) {
			return true;
		} else {
			return false;
		}
	};

	var getAccessTokenWithTokenType = () => {
		var accessToken = Store.get(StoreKey.auth.accessToken);
		var tokenType = Store.get(StoreKey.auth.tokenType);
		var result = tokenType + ' ' + accessToken;

		return result;
	};

	var backtoLoginPage = component => {
		component.props.history.replace(AppConfig.appPath);
	};

	var toUnAuthorizedPage = component => {
		toast.warning('กรุณา Login ด้วย Account ที่มีสิทธิ์ก่อนเข้าใช้งานหน้านี้');
		component.props.history.replace(AppConfig.appPath + '/unauthorized');
	};

	var getUserDetail = () => {
		var userDetail = Store.get(StoreKey.auth.userDetail);
		return userDetail;
	};

	var loginSuccess = (jwtInput, userDetail) => {
		accessToken = jwtInput.access_token;
		refreshToken = jwtInput.refresh_token;
		tokenType = jwtInput.token_type;

		Store.set(StoreKey.auth.accessToken, accessToken);
		Store.set(StoreKey.auth.refreshToken, refreshToken);
		Store.set(StoreKey.auth.tokenType, tokenType);
		Store.set(StoreKey.auth.userDetail, userDetail);
	};

	var saveUserDetail = userDetail => {
		Store.set(StoreKey.auth.userDetail, userDetail);
	};

	var logout = () => {
		Store.invalidate(StoreKey.auth.accessToken);
		Store.invalidate(StoreKey.auth.refreshToken);
		Store.invalidate(StoreKey.auth.tokenType);
		Store.invalidate(StoreKey.auth.userDetail);
	};

	var hasRight = (...scopes) => {
		if (isLogin()) {
			const accessToken = Store.get(StoreKey.auth.accessToken);
			const userData = decode(accessToken);
			const userScopes = userData.scopes;
			const result = scopes.some(scope => userScopes.indexOf(scope) !== -1);

			return result;
		}

		return false;
	};

	var isOwner = () => {
		if (isLogin()) {
			const userDetail = getUserDetail();
			let result = false;
			if (userDetail) {
				result = userDetail.email === 'gie' && userDetail.role === 'Admin';
			}

			return result;
		}

		return false;
	};

	return {
		getAccessTokenWithTokenType,
		isLogin,
		backtoLoginPage,
		toUnAuthorizedPage,
		loginSuccess,
		saveUserDetail,
		logout,
		hasRight,
		isOwner,
		getUserDetail,
	};
})();

export default AuthHelper;
