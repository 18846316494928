import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import EditableText from '../../../Components/EditableText';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

export default class Banks extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			banks: [],
			isEditLoading: '',
			newBank: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('banks');
			if (APIHelper.handleAPIResponse(this, request)) {
				const banks = request.data;
				console.log(banks);
				this.setState({
					isLoading: false,
					banks,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	saveChange = async (bankId, text) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPut('banks/' + bankId, {
			name: text,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const bank = request.data;
			let banks = JSON.parse(JSON.stringify(this.state.banks));
			for (let i = 0; i < banks.length; i++) {
				if (banks[i].id === bankId) {
					banks[i] = bank;
				}
			}

			// console.log(banks);
			toast.success('แก้ไขข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				banks,
			});
		} else {
			toast.error('ไม่สามารถแก้ไขข้อมูลได้');
		}
	};

	addNew = async text => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPost('banks', {
			name: text,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const bank = request.data;
			let banks = JSON.parse(JSON.stringify(this.state.banks));
			banks.push(bank);

			// console.log(banks);
			toast.success('เพิ่มข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				banks,
			});
		} else {
			toast.error('ไม่สามารถเพิ่มข้อมูลได้');
		}
	};

	delete = async bankId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete('banks/' + bankId);
		if (APIHelper.handleAPIResponse(this, request)) {
			const banks = this.state.banks.filter(bank => bank.id !== bankId);

			// console.log(banks);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				banks,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Banks</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.banks.map(bank => (
								<tr key={bank.id}>
									<td width="100%">
										<EditableText
											id={bank.id}
											text={bank.name}
											confirmChange={text => this.saveChange(bank.id, text)}
											confirmDelete={this.delete}
										>
											{bank.name}
										</EditableText>
									</td>
								</tr>
							))}
							<tr>
								<td width="100%">
									<EditableText text={this.state.newBank} confirmChange={text => this.addNew(text)} confirmText="เพิ่ม">
										เพิ่มธนาคาร
									</EditableText>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
