import React, { Component } from 'react';

import NumberColor from '../../../Components/NumberColor';

export default class SummaryPricing extends Component {
	renderRow = (key, title, quantity, price, isExpense = false) => {
		if (quantity <= 0) {
			return null;
		}

		return (
			<tr key={'traveller-table-' + key}>
				<td>{title}</td>
				<td className="number-cell">
					<NumberColor number={quantity} isExpense={isExpense} />
				</td>
				<td className="number-cell">
					<NumberColor number={price} isExpense={isExpense} />
				</td>
				<td className="number-cell">
					<NumberColor number={price * quantity} isExpense={isExpense} />
				</td>
			</tr>
		);
	};

	render() {
		const { program } = this.props;
		return (
			<div className="row">
				<table className="table is-hoverable is-fullwidth is-striped">
					<thead>
						<tr>
							<td width="30%" style={{ textAlign: 'left' }}>
								รายการ
							</td>
							<td width="20%" style={{ textAlign: 'right' }}>
								จำนวน
							</td>
							<td width="20%" style={{ textAlign: 'right' }}>
								ราคา
							</td>
							<td width="20%" style={{ textAlign: 'right' }}>
								รวม (บาท)
							</td>
						</tr>
					</thead>
					<tbody>
						{this.renderRow('normal', 'ผู้ใหญ่', program.quantityNormal, program.priceNormal)}
						{this.renderRow('childWithBed', 'เด็ก(ต้องการเตียง)', program.quantityChildWithBed, program.priceChildWithBed)}
						{this.renderRow('childNoBed', 'เด็ก(ไม่ต้องการเตียง)', program.quantityChildNoBed, program.priceChildNoBed)}
						{this.renderRow('infant', 'ทารก', program.quantityInfant, program.priceInfant)}
						{this.renderRow('single', 'พักเดี่ยว', program.quantitySingle, program.priceSingle)}
						{program.incomeEtcs.map((incomeEtc, index) =>
							this.renderRow('incomeEtc' + index, incomeEtc.title, incomeEtc.quantity, incomeEtc.pricePerPiece, false)
						)}
						{this.renderRow('commission', 'Commission', program.quantityCommission, program.commission, true)}
						{program.expenseEtcs.map((expenseEtc, index) =>
							this.renderRow('expenseEtc' + index, expenseEtc.title, expenseEtc.quantity, expenseEtc.pricePerPiece, true)
						)}
						{program.discounts.map((discount, index) =>
							this.renderRow('discount' + index, discount.title, discount.quantity, discount.pricePerPiece, true)
						)}
						<tr className="table-summary-row">
							<td>Total</td>
							<td className="number-cell" colSpan={3}>
								<NumberColor number={program.price - program.commission * program.quantityCommission} />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}
