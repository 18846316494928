import React, { Component } from 'react';

import NumberColor from './NumberColor';
import InputRow from './InputRow';

export default class PricingRow extends Component {
	shouldComponentUpdate = (nextProps, nextState) => {
		return this.props.quantity !== nextProps.quantity || this.props.price !== nextProps.price || this.props.total !== nextProps.total;
	};

	render() {
		const { quantity, price, total } = this.props;
		return (
			<tr>
				<td style={{ paddingTop: 18 }}>{this.props.title}</td>
				<td>
					<InputRow
						placeholder="จำนวน"
						keyProps="quantity"
						value={quantity}
						onInputChange={(key, value) => this.props.onQuantityChange(value)}
						disabled={false}
						isMandatory={false}
					/>
				</td>
				<td>
					<InputRow
						placeholder="ราคา"
						keyProps="price"
						value={price}
						onInputChange={(key, value) => this.props.onPriceChange(value)}
						disabled={false}
						isMandatory={false}
					/>
				</td>
				<td style={{ paddingTop: 18 }} className="number-cell">
					<NumberColor number={total} />
				</td>
			</tr>
		);
	}
}
