import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import FitTypeHelper from '../../FIT/Helpers/FITTypeHelper';

import LoadingCenter from '../../../Components/LoadingCenter';

import AppConfig from '../../../AppConfig';
import AppColor from '../../../AppColor';

export default class FITSuppliers extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			suppliers: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('fit/suppliers');
			if (APIHelper.handleAPIResponse(this, request)) {
				const suppliers = request.data;
				console.log('suppliers: ', suppliers);
				this.setState({
					isLoading: false,
					suppliers,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">FIT Suppliers</h1>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/newfitsupplier'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<thead>
							<tr>
								<td width="70%">ชื่อ</td>
								<td />
							</tr>
						</thead>
						<tbody>
							{this.state.suppliers.map(supplier => (
								<tr key={supplier.id}>
									<td>
										<Link to={AppConfig.appPath + '/data/fit/suppliers/' + supplier.id}>{supplier.name}</Link>
									</td>
									<td style={{ textAlign: 'right' }}>
										<span className="tag" style={{ backgroundColor: AppColor.fitType[supplier.fitType] }}>
											{FitTypeHelper.mapLabel(supplier.fitType)}
										</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
