import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import ProgramHeader from '../../../Components/ProgramHeader';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import DeleteButton from '../../../Components/DeleteButton';
import DeleteTag from '../../../Components/DeleteTag';

import APIGet from '../../../API/APIGet';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';

import AppConfig from '../../../AppConfig';

import Summary from './Summary';
import IncomeCustomer from './IncomeCustomer';
import ExpenseEtc from './ExpenseEtc';
import Expense from './Expense';

export default class JoinTourProgramDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLoadingExpense: true,
			filter: {
				name: '',
			},
			program: null,
			incomes: [],
			incomeEtcs: [],
			expenses: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.getProgram();
			// this.getIncomeEtc();
			this.getExpenses();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	getProgram = async () => {
		const { programId } = this.props.match.params;

		let requestProgram = APIGet('jointour/programs/' + programId);
		let requestStaffs = APIGet('staffs');
		requestProgram = await requestProgram;
		requestStaffs = await requestStaffs;
		if (APIHelper.handleAPIResponse(this, requestProgram, requestStaffs)) {
			const program = requestProgram.data;
			const staffs = ThingsToSelect(requestStaffs.data);
			console.log('program: ', program);
			console.log('staffs: ', staffs);
			this.setState({
				isLoading: false,
				program,
				staffs,
			});
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	getExpenses = async () => {
		const { programId } = this.props.match.params;

		let requestExpenses = await APIGet('jointour/programs/' + programId + '/expenses');
		if (APIHelper.handleAPIResponse(this, requestExpenses)) {
			const expenses = requestExpenses.data;
			console.log('expenses: ', expenses);
			this.setState({
				isLoadingExpense: false,
				expenses,
			});
		} else if (requestExpenses.status === 404) {
			toast.warning('ไม่พบรายการรายจ่าย');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลรายจ่ายได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	onProgramUpdated = program => {
		this.setState({
			program,
		});
	};

	delete = async () => {
		this.setState({
			isLoading: true,
		});
		const { programId } = this.props.match.params;

		let request = await APIDelete('jointour/programs/' + programId);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/programs/jointour');
		} else if (request.status === 404) {
			toast.warning('ไม่พบรายการ');
		} else {
			toast.error('กรุณาลองใหม่อีกครั้ง');
		}
	};

	render() {
		const { programId } = this.props.match.params;
		const { isLoading, isLoadingExpense, program, incomes, expenses, staffs } = this.state;
		if (isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/jointour'} title="JOIN TOUR" />
				<ProgramHeader program={program} />
				{!program.deleted_at ? (
					<div className="columns row">
						<div className="column is-6">
							<Link className="button is-info" to={AppConfig.appPath + '/programs/jointour/' + programId + '/edit'}>
								แก้ไข
							</Link>
						</div>
						<div className="column is-6" style={{ textAlign: 'right' }}>
							<DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />
						</div>
					</div>
				) : null}

				<br />

				<Summary program={program} staffs={staffs} onProgramUpdated={this.onProgramUpdated} />

				{!program.deleted_at ? (
					<div>
						<IncomeCustomer incomes={incomes} program={program} programId={programId} />

						<ExpenseEtc program={program} programId={programId} expenseEtcs={program.expenseEtcs} />

						{isLoadingExpense ? <LoadingCenter /> : <Expense programId={programId} expenses={expenses} />}
					</div>
				) : null}
			</div>
		);
	}
}
