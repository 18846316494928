import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

import APIGet from '../../API/APIGet';
import APIPut from '../../API/APIPut';
import APIDelete from '../../API/APIDelete';
import APIHelper from '../../Helper/APIHelper';
import AuthHelper from '../../Helper/AuthHelper';

import BackButtonRouter from '../../Components/BackButtonRouter';
import LoadingCenter from '../../Components/LoadingCenter';
import ConvertDateToSimpleFormat from '../../Components/ConvertDateToSimpleFormat';

import AppConfig from '../../AppConfig';

export default class Notification extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			notifications: [],
			isEditLoading: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('notifications/unread');
			if (APIHelper.handleAPIResponse(this, request)) {
				const notifications = request.data;
				console.log(notifications);
				this.setState({
					isLoading: false,
					notifications,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	goto = async notification => {
		// mark notification as read
		if (!notification.read_at) {
			const request = await APIPut('notifications/' + notification.id);
			if (!APIHelper.handleAPIResponse(this, request)) {
				toast.warn('เกิดปัญหาในการอัพเดทสถานะ Notification');
			}
		}

		// const url = AppConfig.appPath + notification.data.url;
		// this.props.history.replace(url)
	};

	delete = async notificationId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete('notifications/' + notificationId);
		if (APIHelper.handleAPIResponse(this, request)) {
			const notifications = this.state.notifications.filter(notification => notification.id !== notificationId);

			// console.log(notifications);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				notifications,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Notifications</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.notifications.map(notification => (
								<tr key={notification.id} className={notification.read_at ? '' : 'is-selected'}>
									<td width="70%">
										<NavLink onClick={() => this.goto(notification)} to={AppConfig.appPath + notification.data.url}>
											{notification.data.message}
										</NavLink>
									</td>
									<td widht="30%" style={{ textAlign: 'right' }}>
										<span style={{ fontSize: 12 }}>
											<ConvertDateToSimpleFormat dateTime={notification.created_at} isDisplayTime={true} />
										</span>
									</td>
								</tr>
							))}
							<tr>
								<td colSpan={2} style={{ textAlign: 'center' }}>
									<NavLink to={AppConfig.appPath + '/notifications'} className="navbar-item clickable" activeClassName="is-active">
										ดูทั้งหมด
									</NavLink>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
