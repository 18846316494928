import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import ProgramHeader from '../../../Components/ProgramHeader';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

export default class IncentiveExpense extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			expenseSummary: null,
			expenses: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { programId, expenseCategoryId } = this.props.match.params;
			let requestProgram = APIGet('incentive/programs/' + programId);
			let requestExpense = APIGet('incentive/programs/' + programId + '/expenses/' + expenseCategoryId);
			let requestExpenseSummary = APIGet('incentive/programs/' + programId + '/expenses/' + expenseCategoryId + '/summary');
			requestProgram = await requestProgram;
			requestExpense = await requestExpense;
			requestExpenseSummary = await requestExpenseSummary;
			if (APIHelper.handleAPIResponse(this, requestProgram, requestExpense, requestExpenseSummary)) {
				const program = requestProgram.data;
				const expenses = requestExpense.data;
				const expenseSummary = requestExpenseSummary.data;
				console.log('program: ', program);
				console.log('expenses: ', expenses);
				console.log('expenseSummary: ', expenseSummary);

				if (expenses.length > 0) {
					this.setState({
						isLoading: false,
						program,
						expenses,
						expenseSummary,
					});
				} else {
					this.props.history.replace(AppConfig.appPath + '/programs/incentive/' + programId);
				}
			} else if (requestProgram.status === 404) {
				toast.warning('ไม่พบโปรแกรมดังกล่าว');
			} else {
				toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	renderApprovalStatus = expense => {
		if (expense.approvalStatus === 'Staff Review') {
			return <label className="tag is-info">พนักงานตรวจสอบ</label>;
		} else if (expense.approvalStatus === 'Waiting') {
			return <label className="tag is-warning">รอการอนุมัติ</label>;
		} else if (expense.approvalStatus === 'Approved') {
			return <label className="tag is-success">อนุมัติแล้ว</label>;
		} else if (expense.approvalStatus === 'Not Approved') {
			return <label className="tag is-danger">ไม่อนุมัติ</label>;
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program, expenseSummary, expenses } = this.state;
		const { programId, expenseCategoryId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/incentive/' + programId} />
				<ProgramHeader program={program} />

				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="subtitle is-5">
								รายจ่าย {expenseSummary.totalWaiting > 0 ? <label className="tag is-warning">มีรายจ่ายรอการอนุมัติ</label> : null}
							</h3>
							<h3 className="title is-3">{expenseSummary.incentiveExpenseCategory.name}</h3>
						</div>
						<div className="column is-6" style={{ textAlign: 'right' }}>
							{AdminLockRender(
								program.status,
								<Link
									to={AppConfig.appPath + '/programs/incentive/' + programId + '/newexpense/' + expenseCategoryId}
									className="button is-success"
								>
									เพิ่มรายการ
								</Link>,
								<button disabled className="button is-success">
									เพิ่มรายการ
								</button>
							)}
						</div>
					</div>
					<div className="columns">
						<div className="column is-12 table-container">
							<table className="table is-hoverable is-fullwidth is-striped is-bordered">
								<thead>
									<tr className="table-header">
										<td width="13%">วันที่</td>
										<td width="45%">รายการ</td>
										<td width="15%">สกุลเงิน</td>
										<td width="12%">Rate</td>
										<td width="15%">ราคา (THB)</td>
									</tr>
								</thead>
								<tbody>
									{expenses.map(row => (
										<tr key={'expenseTable' + row.id}>
											<td>
												<DateLabel date={row.paymentDate} />
											</td>
											<td>
												<Link
													to={
														AppConfig.appPath + '/programs/incentive/' + programId + '/expenses/' + expenseCategoryId + '/' + row.id
													}
												>
													{row.title} {this.renderApprovalStatus(row)}{' '}
													{!row.financeStaff ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}{' '}
													{!row.isPaid ? <label className="tag is-black">ค้างจ่าย</label> : <span />}{' '}
												</Link>
											</td>
											<td className="number-cell">
												<NumberColor number={row.total} isExpense={true} /> {row.currency}
											</td>
											<td className="number-cell">
												{row.currencyRate}{' '}
												{row.currency !== 'THB' && row.currencyRate === 0 ? <span className="tag is-primary">ตรวจสอบ</span> : <span />}
												{row.currency === 'THB' && row.currencyRate !== 1 ? <span className="tag is-primary">ตรวจสอบ</span> : <span />}
											</td>
											<td className="number-cell">
												<NumberColor number={row.total * row.currencyRate} isExpense={true} />
											</td>
										</tr>
									))}
									<tr className="table-expense-summary-row">
										<td colSpan="4">รวม(บาท)</td>
										<td className="number-cell">
											<NumberColor number={expenseSummary.totalApproved + expenseSummary.totalWaiting} isExpense={true} />
										</td>
									</tr>
									<tr className="table-expense-summary-row">
										<td colSpan="4">
											รวม(บาท) <label className="tag is-danger">ไม่อนุมัติ</label>
										</td>
										<td className="number-cell">
											<NumberColor number={expenseSummary.totalNotApproved} isExpense={true} />
										</td>
									</tr>
									<tr className="table-expense-summary-row">
										<td colSpan="4">
											รวม(บาท) <label className="tag is-success">อนุมัติแล้ว</label> <label className="tag is-white">จ่ายแล้ว</label>
										</td>
										<td className="number-cell">
											<NumberColor number={expenseSummary.totalApprovedAndPaid} isExpense={true} />
										</td>
									</tr>
									<tr className="table-expense-summary-row">
										<td colSpan="4">
											รวม(บาท) <label className="tag is-success">อนุมัติแล้ว</label> <label className="tag is-black">ค้างจ่าย</label>
										</td>
										<td className="number-cell">
											<NumberColor number={expenseSummary.totalNotPaid} isExpense={true} />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
