import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment-timezone';
import queryString from 'query-string';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import MonthFilter from '../../../Helper/MonthFilter';
import YearFilter from '../../../Helper/YearFilter';

import LoadingCenter from '../../../Components/LoadingCenter';

import AppConfig from '../../../AppConfig';

const MONTHS = MonthFilter();
const YEARS = YearFilter();

export default class CommissionUser extends PureComponent {
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		const date = query.month && query.year ? moment(new Date(query.month + ' ' + query.year)) : moment(new Date());
		this.state = {
			isLoading: true,
			staffs: [],
			month: {
				label: date.format('MMMM'),
				value: date.format('MMMM'),
			},
			year: {
				label: date.format('YYYY'),
				value: date.format('YYYY'),
			},
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Admin')) {
			const request = await APIGet('staffs');
			if (APIHelper.handleAPIResponse(this, request)) {
				const staffs = request.data.filter(staff => staff.username !== 'gie');
				console.log('staffs: ', staffs);
				this.setState({
					isLoading: false,
					staffs,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-3">Commission</h1>
					</div>
					<div className="column is-2" />
					<div className="column is-2" style={{}}>
						<Select name="year" value={this.state.year} clearable={false} onChange={value => this.setState({ year: value })} options={YEARS} />
					</div>
					<div className="column is-2" style={{}}>
						<Select name="month" value={this.state.month} clearable={false} onChange={value => this.setState({ month: value })} options={MONTHS} />
					</div>
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.staffs.map(staff => (
								<tr key={staff.id}>
									<td width="70%">
										<Link
											to={
												AppConfig.appPath +
												'/finance/commission/' +
												staff.id +
												'?month=' +
												this.state.month.value +
												'&&year=' +
												this.state.year.value
											}
										>
											{staff.name}
										</Link>
									</td>
									<td width="30%" style={{ textAlign: 'right' }}>
										{staff.role}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
