export default (things, value) => {
	let label = '';
	if (!value) {
		value = '';
	}
	things.forEach(thing => {
		if (thing.value === value) {
			label = thing.label;
		}
	});

	return {
		value,
		label,
	};
};
