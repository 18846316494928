const STAFF_ROLE = [
	{
		value: 'Sale',
		label: 'Sale',
	},
	{
		value: 'PD',
		label: 'PD',
	},
	{
		value: 'OP',
		label: 'OP',
	},
	{
		value: 'Marketing',
		label: 'Marketing',
	},
	{
		value: 'Visa',
		label: 'Visa',
	},
	{
		value: 'Accounting',
		label: 'Accounting',
	},
	{
		value: 'Manager',
		label: 'Manager',
	},
	{
		value: 'Admin',
		label: 'Admin',
	},
];

export default STAFF_ROLE;
