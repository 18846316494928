import React, { Component } from 'react';
import InputRow from './InputRow';

export default class EditableText extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditing: false,
			text: props.text,
		};
	}

	componentWillReceiveProps = nextProps => {
		this.setState({
			text: nextProps.text,
		});
	};

	handleInputChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	toggleMode = () => {
		this.setState({
			isEditing: !this.state.isEditing,
		});
	};

	confirmChange = () => {
		this.setState({
			isEditing: false,
		});

		this.props.confirmChange(this.state.text);
	};

	cancelChange = () => {
		this.setState({
			text: this.props.text,
			isEditing: false,
		});
	};

	delete = () => {
		this.props.confirmDelete(this.props.id);
	};

	render() {
		if (this.state.isEditing) {
			return (
				<div className="columns">
					<div className="column is-9">
						<InputRow
							labelSize="m"
							placeholder={this.props.placeholder}
							keyProps="text"
							value={this.state.text}
							onInputChange={this.handleInputChange}
							disabled={false}
						/>
					</div>
					<div className="column is-3 buttons has-addons is-centered" style={{ paddingTop: 20 }}>
						<button type="button" className="button is-success" onClick={this.confirmChange}>
							{this.props.confirmText ? this.props.confirmText : 'แก้ไข'}
						</button>
						{this.props.confirmDelete ? (
							<button type="button" className="button is-danger" onClick={this.delete}>
								{this.props.deleteText ? this.props.deleteText : 'ลบ'}
							</button>
						) : null}
						<button type="button" className="button" onClick={this.cancelChange}>
							ยกเลิก
						</button>
					</div>
				</div>
			);
		} else {
			return (
				<div onClick={this.toggleMode} className="clickable">
					{this.props.children}
				</div>
			);
		}
	}
}
