import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import DeleteButton from '../../../Components/DeleteButton';
import DeleteTag from '../../../Components/DeleteTag';

import APIGet from '../../../API/APIGet';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';

import AppConfig from '../../../AppConfig';

import createFITItemHelper from '../Helpers/FITItemHelper';

import Summary from './Summary';
import FITItem from './FITItem';
import Income from './Income';
import ExpenseFIT from './ExpenseFIT';
import Expense from './Expense';
import StatusIcon from '../../../Components/StatusIcon';

export default class FITDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			fit: {},
			staffs: [],
		};

		this.fitItemHelper = null;
	}

	componentDidMount = async () => {
		const { programId } = this.props.match.params;
		let requestProgram = APIGet(`fit/programs/${programId}`);
		let requestStaffs = APIGet('staffs');
		requestProgram = await requestProgram;
		requestStaffs = await requestStaffs;
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const fit = requestProgram.data;
			this.fitItemHelper = createFITItemHelper(fit.fitItems);
			const fitItems = this.fitItemHelper.getState();
			const staffs = ThingsToSelect(requestStaffs.data);
			console.log('fit: ', fit);
			console.log('fitItems: ', fitItems);
			this.setState({
				isLoading: false,
				fit: {
					...fit,
					fitItems,
				},
				staffs,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	delete = async () => {
		this.setState({
			isLoading: true,
		});
		const { programId } = this.props.match.params;

		let request = await APIDelete('fit/programs/' + programId);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/programs/fit');
		} else if (request.status === 404) {
			toast.warning('ไม่พบรายการ');
		} else {
			toast.error('กรุณาลองใหม่อีกครั้ง');
		}
	};

	onProgramUpdated = program => {
		const fit = program;
		this.fitItemHelper = createFITItemHelper(fit.fitItems);
		const fitItems = this.fitItemHelper.getState();
		this.setState({
			fit: {
				...fit,
				fitItems,
			},
		});
	};

	isFullyPaid(fit) {
		const totalPaid = fit.paid === fit.salePrice + fit.creditCharge;
		let isFinanceApproved = fit.incomes.length > 0;
		fit.incomes.forEach(income => {
			isFinanceApproved = isFinanceApproved && income.financeStaffId !== null;
		});

		console.log('totalPaid: ', totalPaid);
		console.log('isFinanceApproved: ', isFinanceApproved);

		return totalPaid && isFinanceApproved;
	}

	render() {
		const { fit, isLoading, staffs } = this.state;
		const { programId } = this.props.match.params;
		if (isLoading) {
			return <LoadingCenter />;
		}

		const isFullyPaid = this.isFullyPaid(fit);

		return (
			<div className="container">
				{!fit.deleted_at ? (
					<div className="columns row">
						<div className="column is-6">
							<Link className="button is-info" to={AppConfig.appPath + '/programs/fit/' + programId + '/edit'}>
								แก้ไข
							</Link>
						</div>
						<div className="column is-6" style={{ textAlign: 'right' }}>
							<DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />
						</div>
					</div>
				) : null}
				<br />
				<BackButtonRouter link={AppConfig.appPath + '/programs/fit'} title="FIT" />
				<h1 className="title is-1">{fit.title}</h1>
				<StatusIcon status={fit.status} deletedAt={fit.deleted_at} />{' '}
				<span style={{ color: 'red', fontSize: 10 }}>{fit.deleted_by_user_name ? fit.deleted_by_user_name : null}</span>
				<Summary fit={fit} programId={programId} onProgramUpdated={this.onProgramUpdated} staffs={staffs} />
				<FITItem items={fit.fitItems} fitItemHelper={this.fitItemHelper} />
				<Income fitId={programId} fit={fit} incomes={fit.incomes} isFullyPaid={isFullyPaid} />
				<ExpenseFIT fitId={programId} expenses={fit.expenseToSuppliers} isFullyPaid={isFullyPaid} />
				<Expense fitId={programId} expenses={fit.expenses} />
			</div>
		);
	}
}
