import React, { Component } from 'react';

import DateLabel from './DateLabel';

export default class DeleteTag extends Component {
	render() {
		const { deletedAt } = this.props;
		if (deletedAt) {
			return (
				<span className="tag is-danger">
					ลบเมื่อ <DateLabel date={deletedAt} />
				</span>
			);
		}

		return null;
	}
}
