const defaultItem = {
	fitType: '',
	selectedFitType: null,
	note: '',
	creditCardRecord: 0,
	selectedCreditCardCurrency: {
		value: 'THB',
		label: 'ไทย (บาท)',
	},
	selectedCreditCard: null,
	price: 0,
	creditCharge: 0,
};

const defaultFITDetail = {
	fitTicket: {
		route: '',
		airlineId: 0,
		airline: null,
		selectedAirline: null,
		flightNumber: '',
		pnr: '',
		passengers: [
			{
				name: '',
				ticketNumber: '',
				price: 0,
				salePrice: 0,
			},
		],
	},
	fitVisa: {
		details: [
			{
				title: 'ค่าธรรมเนียมสถานทูต',
				price: 0,
				salePrice: 0,
			},
		],
	},
	fitHotel: {
		details: [
			{
				title: '',
				price: 0,
				salePrice: 0,
			},
		],
	},
	fitCarRental: {
		details: [
			{
				title: '',
				price: 0,
				salePrice: 0,
			},
		],
	},
	fitInsurrance: {
		details: [
			{
				title: '',
				price: 0,
				salePrice: 0,
			},
		],
	},
	fitTravelTicket: {
		details: [
			{
				title: '',
				price: 0,
				salePrice: 0,
			},
		],
	},
	fitPocketWifiSim: {
		details: [
			{
				title: '',
				price: 0,
				salePrice: 0,
			},
		],
	},
	fitTranslation: {
		details: [
			{
				title: '',
				price: 0,
				salePrice: 0,
			},
		],
	},
};

const createFITItemHelper = function(newState) {
	if (!newState) newState = [];
	let state = [];
	if (newState.length === 0) {
		state = JSON.parse(
			JSON.stringify([
				{
					...defaultItem,
				},
			])
		);
	} else {
		state = JSON.parse(JSON.stringify(newState));
	}

	return (function() {
		const resetState = newState => {
			state = JSON.parse(JSON.stringify(newState));
		};

		const onChange = (index, key, value) => {
			// console.log(index, key, value);
			state[index][key] = value;
			if (key === 'selectedFitType' && value) {
				const fitType = value.value;
				state[index].fitType = fitType;
				if (!state[index][fitType]) {
					state[index][fitType] = JSON.parse(JSON.stringify(defaultFITDetail[fitType]));
				}

				state[index].selectedSupplier = null;
			} else if (key === 'creditCharge') {
				const itemPrice = getItemPrice(state[index]);
				state[index].creditCardRecord = itemPrice.salePrice + itemPrice.creditCharge;
			}

			return state;
		};

		const onDetailChange = (parentIndex, key, value) => {
			const fitType = state[parentIndex].fitType;
			state[parentIndex][fitType][key] = value;

			return state;
		};

		const onDetailArrayChange = (parentIndex, index, key, value) => {
			const fitType = state[parentIndex].fitType;
			if (fitType === 'fitTicket') {
				state[parentIndex][fitType].passengers[index][key] = value;
			} else {
				state[parentIndex][fitType].details[index][key] = value;
			}

			return state;
		};

		const getState = () => {
			return state;
		};

		const addRow = () => {
			state = [...state, { ...defaultItem, id: 'new' + state.length }];
			return state;
		};

		const removeRow = index => {
			state.splice(index, 1);
			return state;
		};

		const addDetail = parentIndex => {
			const fitType = state[parentIndex].fitType;
			if (fitType === 'fitTicket') {
				state[parentIndex][fitType].passengers.push({ ...defaultFITDetail[fitType].passengers[0] });
			} else if (fitType === 'fitVisa') {
				state[parentIndex][fitType].details.push({ ...defaultFITDetail[fitType][1] });
			} else {
				state[parentIndex][fitType].details.push({ ...defaultFITDetail[fitType][0] });
			}
			console.log(state);
			return state;
		};

		const removeDetail = (parentIndex, index) => {
			const fitType = state[parentIndex].fitType;
			if (fitType === 'fitTicket') {
				state[parentIndex][fitType].passengers.splice(index, 1);
			} else {
				state[parentIndex][fitType].details.splice(index, 1);
			}

			return state;
		};

		const getItemPrice = item => {
			let itemPrice = { salePrice: 0, creditCharge: 0, price: 0 };
			if (isTicket(item)) {
				item.fitTicket.passengers.forEach(passenger => {
					itemPrice.salePrice += parseFloat(passenger.salePrice);
					itemPrice.creditCharge += parseFloat((item.creditCharge * passenger.salePrice) / 100);
					itemPrice.price += parseFloat(passenger.price);
				});
			} else {
				item[item.fitType] &&
					item[item.fitType].details.forEach(detail => {
						itemPrice.salePrice += parseFloat(detail.salePrice);
						itemPrice.creditCharge += parseFloat((item.creditCharge * detail.salePrice) / 100);
						itemPrice.price += parseFloat(detail.price);
					});
			}

			return itemPrice;
		};

		const getTotalPrice = () => {
			let totalPrice = { salePrice: 0, creditCharge: 0, price: 0 };
			state.forEach(item => {
				const itemPrice = getItemPrice(item);
				totalPrice.salePrice += itemPrice.salePrice;
				totalPrice.creditCharge += itemPrice.creditCharge;
				totalPrice.price += itemPrice.price;
			});

			return totalPrice;
		};

		const isTicket = item => {
			return item.fitType === 'fitTicket' && item.fitTicket && item.fitTicket.passengers && item.fitTicket.passengers.length > 0;
		};

		const isVisa = item => {
			return item.fitType === 'fitVisa' && item.fitVisa && item.fitVisa.details.length > 0;
		};

		const validateItem = item => {
			if (item.fitType === '') {
				return false;
			}

			if (item.creditCardRecord > 0 && !item.selectedCreditCard) {
				return false;
			}

			if (item.selectedCreditCard && !item.selectedCreditCardCurrency) {
				return false;
			}

			if (isTicket(item)) {
				return validateFitTicket(item);
			} else if (isVisa(item)) {
				return validateFitVisa(item);
			}

			return true;
		};

		const validateFitTicket = item => {
			if (item.fitTicket) {
				const { fitTicket } = item;
				if (!fitTicket.selectedAirline) {
					return false;
				}

				if (fitTicket.flightNumber.trim() === '') {
					return false;
				}

				if (fitTicket.pnr.trim() === '') {
					return false;
				}

				if (fitTicket.route.trim() === '') {
					return false;
				}

				if (fitTicket.passengers.filter(passenger => passenger.name.trim() === '' || passenger.ticketNumber.trim() === '').length > 0) {
					return false;
				}
			} else {
				return false;
			}

			return true;
		};

		const validateFitVisa = item => {
			if (item.fitVisa) {
				const { details } = item.fitVisa;
				if (details.length === 0) {
					return false;
				}

				if (details.filter(visa => visa.title.trim() === '').length > 0) {
					return false;
				}
			} else {
				return false;
			}

			return true;
		};

		const getDataForCreateOrUpdateAPI = () => {
			let result = [];
			state.forEach(item => {
				const { price, salePrice } = getItemPrice(item);
				let rowItem = {
					...item,
					price,
					salePrice,
					supplierId: item.selectedSupplier ? item.selectedSupplier.value : null,
					creditCardId: item.selectedCreditCard ? item.selectedCreditCard.value : null,
					creditCardCurrency: item.selectedCreditCardCurrency ? item.selectedCreditCardCurrency.value : '',
				};

				if (isTicket(item)) {
					rowItem.fitTicket.airlineId = item.fitTicket && item.fitTicket.selectedAirline ? item.fitTicket.selectedAirline.value : null;
				}

				result.push(rowItem);
			});

			return result;
		};

		return {
			resetState,
			getState,
			onChange,
			onDetailChange,
			onDetailArrayChange,
			addRow,
			removeRow,
			addDetail,
			removeDetail,
			getItemPrice,
			getTotalPrice,
			isTicket,
			isVisa,
			validateItem,
			getDataForCreateOrUpdateAPI,
		};
	})();
};

export default createFITItemHelper;
