import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class PinRestaurantComment extends React.Component {
	togglePin = () => {
		this.props.togglePin(this.props.id, !this.props.isPinned);
	};

	render() {
		const { isPinned } = this.props;

		return (
			<>
				{isPinned ? (
					<>
						<button className="button is-success is-small">
							<FontAwesomeIcon icon="map-pin" /> <span>&nbsp;</span>Pinned{' '}
						</button>
						<span> </span>
						<button className="button is-warning is-small" onClick={this.togglePin}>
							<FontAwesomeIcon icon="map-pin" /> <span>&nbsp;</span>Unpin{' '}
						</button>
					</>
				) : (
					<button className="button is-small" onClick={this.togglePin}>
						<FontAwesomeIcon icon="map-pin" /> <span>&nbsp;</span> Pin ความเห็นนี้{' '}
					</button>
				)}
			</>
		);
	}
}
