import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import AdminLockRender from '../../../Helper/AdminLockRender';

import ConvertDateToSimpleFormat from '../../../Components/ConvertDateToSimpleFormat';
import NumberColor from '../../../Components/NumberColor';
import InDevelopmentTag from '../../../Components/InDevelopmentTag';

import AppConfig from '../../../AppConfig';

export default class Budget extends PureComponent {
	render() {
		const { budgets, program } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">Budget</h3>
					</div>
					<div className="column is-6" />
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="60%">เงินอยู่ในบัญชีกองกลาง</td>
								<td width="25%">สกุลเงิน (เงินอยู่ในเซฟ)</td>
								<td />
							</tr>
						</thead>
						<tbody>
							{budgets.map(budget => (
								<tr key={'budget' + budget.id}>
									<td className="number-cell">
										<div>
											<NumberColor number={budget.total} />
										</div>
									</td>
									<td style={{ textAlign: 'center' }}>{budget.currency}</td>
									<td style={{ textAlign: 'center' }}>
										{AdminLockRender(
											program.status,
											<Link to={AppConfig.appPath + '/programs/incentive/' + this.props.programId + '/exchangeBudget/' + budget.id}>
												Exchange
											</Link>,
											<button disabled>Exchange</button>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
