import React from 'react';

import NumberColor from '../../../Components/NumberColor';

export default class FITItemTicket extends React.Component {
	render() {
		const { pricing, creditCard, creditCardRecord, creditCardCurrency } = this.props;
		return (
			<div style={{ textAlign: 'right' }}>
				<span>
					ราคาขาย: <NumberColor number={pricing.salePrice + pricing.creditCharge} /> THB | ต้นทุน:{' '}
					<NumberColor number={pricing.price} isExpense={true} /> THB | กำไร: <NumberColor number={pricing.salePrice - pricing.price} /> THB
				</span>
				<br />
				{creditCard ? (
					<span>
						ยอดที่ตัดบัตร: <NumberColor number={creditCardRecord} isExpense={true} /> {creditCardCurrency} | Credit Card: {creditCard.name}
					</span>
				) : null}
			</div>
		);
	}
}
