import React from 'react';
import { toast } from 'react-toastify';

import FileManager from '../FileManager';

export default ({ isShown, toggleModal, onUploadSuccess, onDeleteSuccess, customer }) => {
	if (!isShown || !customer) {
		return null;
	}

	const _onUploadSuccess = customer => {
		toast.success('Upload เรียบร้อยแล้ว');
		onUploadSuccess(customer);
	};

	const _onDeleteSuccess = customer => {
		toast.success('ลบเรียบร้อยแล้ว');
		onDeleteSuccess(customer);
	};

	const _onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	const _onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	console.log('customer in modal: ', customer);

	return (
		<div className="modal is-active">
			<div className="modal-background" onClick={toggleModal} />
			<div className="modal-card" style={{ minHeight: 500, minWidth: '75%' }}>
				<header className="modal-card-head">
					<p className="modal-card-title">Passport ลูกค้า</p>
					<button className="delete" onClick={toggleModal} />
				</header>
				<section className="modal-card-body">
					<FileManager
						title="Files"
						files={customer.files}
						api={`jointour/customers/${customer.id}/files`}
						onUploadSuccess={_onUploadSuccess}
						onUploadFail={_onUploadFail}
						onDeleteSuccess={_onDeleteSuccess}
						onDeleteFail={_onDeleteFail}
					/>
				</section>
			</div>
		</div>
	);
};
