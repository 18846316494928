import React, { Component } from 'react';
import InputRows from './InputRow';

export const EditableTextSwitch = props => {
	let mandatory;
	if (props.isMandatory) {
		mandatory = <span style={{ color: 'red' }}> *</span>;
	}

	return props.isEditing ? (
		<InputRows
			title={props.title}
			hideLabel={props.title ? false : true}
			labelSize={props.titleLabelSize}
			keyProps={props.keyProps}
			value={props.value}
			onInputChange={(_, text) => props.onChange(text)}
			type={props.type}
			disabled={props.disabled}
		/>
	) : (
		<div>
			{props.title ? (
				<label className="label" style={{ color: 'black' }}>
					{props.title}
					{mandatory}
				</label>
			) : null}
			{props.children}
		</div>
	);
};
