import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIGet from '../../API/APIGet';
import APIPost from '../../API/APIPost';
import APIDelete from '../../API/APIDelete';
import APIHelper from '../../Helper/APIHelper';

import LoadingCenter from '../LoadingCenter';

export default class RestaurantPictures extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			images: [],
			selectedIndex: 0,
			lightboxIsOpen: false,
		};
	}
	async componentDidMount() {
		await this.getPhotos();
	}

	getPhotos = async () => {
		const request = await APIGet('restaurants/' + this.props.id + '/photos');
		if (APIHelper.handleAPIResponse(this, request)) {
			const images = request.data;
			console.log('comments: ', images);
			this.setState({
				isLoading: false,
				images,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	toggleLightbox = selectedIndex => {
		this.setState(state => ({
			lightboxIsOpen: !state.lightboxIsOpen,
			selectedIndex,
		}));
	};

	fileChangedHandler = event => {
		event.persist();
		let reader = new FileReader();
		let file = event.target.files[0];

		reader.onloadend = () => {
			var base64 = reader.result.substr(reader.result.indexOf(',') + 1);
			var newFile = {
				base64: base64,
				name: file.name,
				size: file.size,
			};

			this.uploadFile(newFile);
		};

		reader.onerror = error => {
			console.log('Error: ', error);
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	uploadFile = async file => {
		this.setState({
			isLoading: true,
		});

		const request = await APIPost(`restaurants/${this.props.id}/photos`, {
			uploadFile: file,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			this.setState({
				isLoading: false,
			});

			await this.getPhotos();
			toast.success('เพิ่มรูปเรียบร้อยแล้ว');
		} else {
			this.setState({
				isLoading: false,
			});

			toast.warn('เกิดปัญหาในการเพิ่มรูป');
		}
	};

	delete = async fileId => {
		this.setState({
			isLoading: true,
		});

		const request = await APIDelete(`restaurants/${this.props.id}/photos/${fileId}`);

		if (APIHelper.handleAPIResponse(this, request)) {
			this.setState({
				isLoading: false,
			});

			await this.getPhotos();
			toast.success('ลบรูปเรียบร้อยแล้ว');
		} else {
			this.setState({
				isLoading: false,
			});

			toast.warn('เกิดปัญหาในการลบรูป');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { isLoading, images, lightboxIsOpen, selectedIndex } = this.state;

		return (
			<>
				<h3 className="title is-4">บรรยากาศร้าน & รูปอาหาร</h3>
				{images && images.length > 0 ? (
					<div className="row">
						{images.map((source, j) => (
							<div style={{ float: 'left', position: 'relative' }} key={source.src}>
								<div style={{ position: 'absolute', top: -2, right: -2 }}>
									<a className="delete" onClick={() => this.delete(source.id)} />
								</div>
								<img
									alt={source.caption}
									src={source.src}
									css={{
										cursor: 'pointer',
										position: 'absolute',
										float: 'left',
									}}
									width="200"
									className="clickable"
									onClick={() => this.toggleLightbox(j)}
								/>
							</div>
						))}
					</div>
				) : (
					<div style={{ textAlign: 'center' }}>ยังไม่มีรูปภาพ</div>
				)}

				<div style={{ clear: 'both' }} />
				<hr />
				<div className="row">
					<label className="file-label">
						<input className="file-input" type="file" name="resume" onChange={this.fileChangedHandler} />
						<span className="file-cta">
							<span className="file-icon">
								<FontAwesomeIcon icon="upload" />
							</span>
							<span className="file-label">Choose a photo...</span>
						</span>
					</label>
				</div>

				<ModalGateway>
					{lightboxIsOpen && !isLoading ? (
						<Modal onClose={this.toggleLightbox}>
							<Carousel currentIndex={selectedIndex} frameProps={{ autoSize: 'height' }} views={images} />
						</Modal>
					) : null}
				</ModalGateway>
			</>
		);
	}
}
