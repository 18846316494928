/* eslint-disable radix */
import React, { Component } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import AdditionalCustomer from '../../../Components/JointourProgramAdditionalCustomer';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import AuthHelper from '../../../Helper/AuthHelper';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';

import AppConfig from '../../../AppConfig';

import CUSTOMER_CHANNELS from '../../../StaticData/CustomerChannels';

import TourDetail from './TourDetail';
import CustomerDetail from './CustomerDetail';
import PricingPaymentTerms from './PricingPaymentTerms';
import InputValidation from './InputValidation';

export default class AddEditProgramJoinTour extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			title: '',
			telephone: '',
			lineId: '',
			email: '',
			note: '',
			quantityNormal: 0,
			quantityChildNoBed: 0,
			quantityChildWithBed: 0,
			quantityInfant: 0,
			quantitySingle: 0,
			quantityCommission: 0,
			priceNormal: 0,
			priceChildNoBed: 0,
			priceChildWithBed: 0,
			priceInfant: 0,
			priceSingle: 0,
			commission: 0,
			discounts: [
				{
					title: '',
					quantity: 0,
					pricePerPiece: 0,
				},
			],
			incomeEtcs: [
				{
					title: '',
					quantity: 0,
					pricePerPiece: 0,
				},
			],
			expenseEtcs: [
				{
					title: '',
					quantity: 0,
					pricePerPiece: 0,
				},
			],
			customers: [],
			wholesales: [],
			selectCustomers: [],
			selectWholesales: [],
			selectCountries: [],
			full: 0,
			deposit: 0,
			deposit2: 0,
			dueDateFull: moment(new Date()),
			dueDateDeposit: moment(new Date()),
			dueDateDeposit2: moment(new Date()),
			hasDeposit: true,
			hasDeposit2: true,
			wholeSale: null,
			wholeSaleContact: null,
			channel: null,
			customer: null,
			customerContact: null,
			additionalCustomerIds: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	isUpdate = () => {
		const { programId } = this.props.match.params;
		if (programId) {
			return true;
		} else {
			return false;
		}
	};

	initCreate = async () => {
		let requestCountries = APIGet('countries');
		let requestAirlines = APIGet('airlines');
		let requestCustomers = APIGet('jointour/customers');
		let requestWholeSales = APIGet('jointour/wholesales');
		requestCountries = await requestCountries;
		requestAirlines = await requestAirlines;
		requestCustomers = await requestCustomers;
		requestWholeSales = await requestWholeSales;
		if (APIHelper.handleAPIResponse(this, requestCountries, requestAirlines, requestCustomers, requestWholeSales)) {
			const selectCountries = ThingsToSelect(requestCountries.data);
			const selectCustomers = ThingsToSelect(requestCustomers.data);
			const selectWholeSales = ThingsToSelect(requestWholeSales.data);
			const selectAirlines = ThingsToSelect(requestAirlines.data);
			const customers = requestCustomers.data;
			const wholeSales = requestWholeSales.data;
			this.setState({
				wholeSales,
				customers,
				selectCountries,
				selectCustomers,
				selectWholeSales,
				selectAirlines,
			});

			return true;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { programId } = this.props.match.params;
		let requestProgram = await APIGet('jointour/programs/' + programId);
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const program = requestProgram.data;
			const customer = program.customer
				? {
						value: program.customerId,
						label: program.customer.name,
				  }
				: null;
			const customerContact = program.customerContact
				? {
						value: program.customerContactId,
						label: program.customerContact.name,
				  }
				: null;
			const selectCustomerContacts = this.getContacts(customer, this.state.customers);
			let customerContactDetail = selectCustomerContacts.filter(c => c.id === program.customerContactId);
			if (customerContactDetail.length === 1) {
				customerContactDetail = customerContactDetail[0];
			} else {
				customerContactDetail = {
					telephone: '',
					email: '',
					lineId: '',
				};
			}
			const wholeSale = program.wholeSale
				? {
						value: program.wholeSaleId,
						label: program.wholeSale.name,
				  }
				: null;
			const wholeSaleContact = program.wholeSaleContact
				? {
						value: program.wholeSaleContactId,
						label: program.wholeSaleContact.name,
				  }
				: null;
			const selectWholeSaleContacts = this.getContacts(wholeSale, this.state.wholeSales);
			const airline = program.airline
				? {
						value: program.airlineId,
						label: program.airline.name,
				  }
				: null;
			const channel = program.customerChannel ? CUSTOMER_CHANNELS.filter(c => c.value === program.customerChannel)[0] : null;
			this.setState({
				...program,
				airline,
				country: program.country
					? {
							value: program.countryId,
							label: program.country.name,
					  }
					: null,
				customerContact,
				customer,
				selectCustomerContacts,
				telephone: customerContactDetail.telephone,
				email: customerContactDetail.email,
				lineId: customerContactDetail.lineId,
				wholeSale,
				wholeSaleContact,
				selectWholeSaleContacts,
				channel,
				startDate: moment(new Date(program.startDate)),
				endDate: moment(new Date(program.endDate)),
				dueDateDeposit: moment(new Date(program.dueDateDeposit)),
				dueDateDeposit2: moment(new Date(program.dueDateDeposit2)),
				dueDateFull: moment(new Date(program.dueDateFull)),
				hasDeposit: program.deposit > 0,
				hasDeposit2: program.deposit2 > 0,
			});

			return true;
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	onStateChange = (type, value) => {
		let change = {
			[type]: value,
		};
		if (type === 'wholeSale') {
			change.wholeSaleContact = null;
		} else if (type === 'customer') {
			change = {
				...change,
				...this.onCustomerSelected(),
			};
		} else if (type === 'customerContact') {
			change = {
				...change,
				...this.onCustomerContactSelected(value),
			};
		} else if (type === 'hasDeposit') {
			change = {
				...change,
				...this.onHasDepositChange(value),
			};
		} else if (type === 'hasDeposit2') {
			change = {
				...change,
				...this.onHasDeposit2Change(value),
			};
		}

		this.setState(change);
	};

	onCustomerSelected = () => {
		return {
			customerContact: null,
			telephone: '',
			email: '',
			lineId: '',
		};
	};

	onCustomerContactSelected = value => {
		const contacts = this.getContacts(this.state.customer, this.state.customers);

		if (contacts.length > 0) {
			let contact = contacts.filter(c => c.id === value.value)[0];
			console.log(contact);
			return {
				telephone: contact.telephone,
				email: contact.email,
				lineId: contact.lineId,
			};
		}

		return {};
	};

	onHasDepositChange = value => {
		if (!value) {
			return {
				deposit: 0,
				deposit2: 0,
				hasDeposit2: false,
				full: parseFloat(this.state.full) + parseFloat(this.state.deposit) + parseFloat(this.state.deposit2),
			};
		}

		return {};
	};

	onHasDeposit2Change = value => {
		if (!value) {
			return {
				deposit2: 0,
				hasDeposit2: false,
				full: parseFloat(this.state.full) + parseFloat(this.state.deposit2),
			};
		}

		return {};
	};

	onStateArrayChange = (type, index, key, value) => {
		let data = [...this.state[type]];
		data[index][key] = value;
		this.setState({
			[type]: data,
		});
	};

	addDiscount = () => {
		let discounts = [...this.state.discounts];
		discounts.push({
			title: '',
			quantity: 0,
			pricePerPiece: 0,
		});

		this.setState({
			discounts,
		});
	};

	addIncomeEtc = () => {
		let incomeEtcs = [...this.state.incomeEtcs];
		incomeEtcs.push({
			title: '',
			quantity: 0,
			pricePerPiece: 0,
		});

		this.setState({
			incomeEtcs,
		});
	};

	addExpenseEtc = () => {
		let expenseEtcs = [...this.state.expenseEtcs];
		expenseEtcs.push({
			title: '',
			quantity: 0,
			pricePerPiece: 0,
		});

		this.setState({
			expenseEtcs,
		});
	};

	getContacts = (selectedData, allData) => {
		if (!selectedData) {
			return [];
		}

		let filterData = allData.filter(record => record.id === selectedData.value);
		if (filterData.length > 0) {
			return filterData[0].contacts;
		} else {
			return [];
		}
	};

	onAdditionalCustomerChanged = additionalCustomers => {
		// console.log(additionalCustomers);
		let additionalCustomerIds = additionalCustomers.map(ac => ac.id);
		const mainCustomerId = this.state.customer ? this.state.customer.value : null;
		if (mainCustomerId && additionalCustomerIds.indexOf(mainCustomerId) === -1) {
			additionalCustomerIds.push(mainCustomerId);
		}

		// console.log('new additional customer ids: ', additionalCustomerIds);

		this.setState({
			additionalCustomerIds,
		});
	};

	onSubmit = async () => {
		if (InputValidation(this.state, toast)) {
			let request;
			const data = {
				...this.state,
				wholeSaleId: this.state.wholeSale.value,
				wholeSaleContactId: this.state.wholeSaleContact.value,
				airlineId: this.state.airline.value,
				countryId: this.state.country.value,
				customerId: this.state.customer.value,
				customerContactId: this.state.customerContact.value,
				customerChannel: this.state.channel ? this.state.channel.value : '',
				startDate: this.state.startDate.format('YYYY-MM-DD'),
				endDate: this.state.endDate.format('YYYY-MM-DD'),
				dueDateDeposit: this.state.dueDateDeposit.format('YYYY-MM-DD'),
				dueDateDeposit2: this.state.dueDateDeposit2.format('YYYY-MM-DD'),
				dueDateFull: this.state.dueDateFull.format('YYYY-MM-DD'),
				additionalCustomerIds: this.state.additionalCustomerIds,
			};

			if (!this.isUpdate()) {
				request = await APIPost('jointour/programs', data);
			} else {
				const { programId } = this.props.match.params;
				request = await APIPut('jointour/programs/' + programId, data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขโปรแกรมเรียบร้อยแล้ว' : 'เพิ่มโปรแกรมร้อยแล้ว');
				const result = request.data;
				this.props.history.push(AppConfig.appPath + '/programs/jointour/' + result.id);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มโปรแกรม');
			}
		}
	};

	render() {
		// console.log('note: ', this.state.note);
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { programId } = this.props.match.params;
		const selectWholeSaleContacts = ThingsToSelect(this.getContacts(this.state.wholeSale, this.state.wholeSales));

		const selectCustomerContacts = ThingsToSelect(this.getContacts(this.state.customer, this.state.customers));
		return (
			<div className="container">
				<BackButtonRouter link={this.isUpdate() ? AppConfig.appPath + '/programs/jointour/' + programId : AppConfig.appPath + '/programs/jointour'} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">{this.isUpdate() ? 'แก้ไข' : 'เพิ่ม'} JOIN TOUR</h3>
						</div>
					</div>
					<div className="row columns">
						<div className="column is-5">
							<TourDetail
								title={this.state.title}
								country={this.state.country}
								airline={this.state.airline}
								startDate={this.state.startDate}
								endDate={this.state.endDate}
								wholeSale={this.state.wholeSale}
								wholeSaleContact={this.state.wholeSaleContact}
								selectWholeSales={this.state.selectWholeSales}
								selectWholeSaleContacts={selectWholeSaleContacts}
								selectCountries={this.state.selectCountries}
								selectAirlines={this.state.selectAirlines}
								onStateChange={this.onStateChange}
							/>
							<hr />
							<CustomerDetail
								customer={this.state.customer}
								customerContact={this.state.customerContact}
								telephone={this.state.telephone}
								email={this.state.email}
								lineId={this.state.lineId}
								channel={this.state.channel}
								note={this.state.note}
								onStateChange={this.onStateChange}
								selectCustomers={this.state.selectCustomers}
								selectCustomerContacts={selectCustomerContacts}
							/>
							<hr />
							<AdditionalCustomer
								mainCustomerId={this.state.customer ? this.state.customer.value : null}
								programId={programId}
								onChange={this.onAdditionalCustomerChanged}
								allowUpdate={true}
							/>
						</div>
						<div className="column is-7">
							<PricingPaymentTerms
								quantityNormal={this.state.quantityNormal}
								quantityChildNoBed={this.state.quantityChildNoBed}
								quantityChildWithBed={this.state.quantityChildWithBed}
								quantityInfant={this.state.quantityInfant}
								quantitySingle={this.state.quantitySingle}
								quantityCommission={this.state.quantityCommission}
								priceNormal={this.state.priceNormal}
								priceChildNoBed={this.state.priceChildNoBed}
								priceChildWithBed={this.state.priceChildWithBed}
								priceInfant={this.state.priceInfant}
								priceSingle={this.state.priceSingle}
								commission={this.state.commission}
								discounts={this.state.discounts}
								incomeEtcs={this.state.incomeEtcs}
								expenseEtcs={this.state.expenseEtcs}
								addDiscount={this.addDiscount}
								addIncomeEtc={this.addIncomeEtc}
								addExpenseEtc={this.addExpenseEtc}
								onStateChange={this.onStateChange}
								onStateArrayChange={this.onStateArrayChange}
								full={this.state.full}
								deposit={this.state.deposit}
								deposit2={this.state.deposit2}
								dueDateDeposit={this.state.dueDateDeposit}
								dueDateDeposit2={this.state.dueDateDeposit2}
								dueDateFull={this.state.dueDateFull}
								hasDeposit={this.state.hasDeposit}
								hasDeposit2={this.state.hasDeposit2}
							/>
						</div>
					</div>
					<div className="row columns">
						<div className="column is-6">
							<button className="button is-success" onClick={this.onSubmit}>
								บันทึก
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
