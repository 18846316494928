import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';
import DeleteButton from '../../../Components/DeleteButton';

import RestaurantHeader from '../../../Components/Restaurant/Header';
import RestaurantInfo from '../../../Components/Restaurant/Info';
import RestaurantPictures from '../../../Components/Restaurant/Pictures';
import RestaurantComments from '../../../Components/Restaurant/Comments';

import AppConfig from '../../../AppConfig';

export default class RestaurantDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.setState({
				isLoading: false,
			});
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	delete = async () => {
		const { id } = this.props.match.params;
		const request = await APIDelete('restaurants/' + id);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.push(`${AppConfig.appPath}/data/incentive/restaurants`);
		} else {
			toast.error('ไม่สามารถลบข้อมูลได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { id } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<RestaurantHeader id={id} />
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<div style={{ height: 3 }} />
						<DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />
					</div>
				</div>
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<RestaurantInfo id={id} />
							<RestaurantPictures id={id} />
						</div>
						<div className="column is-6">
							<RestaurantComments id={id} allowComment={true} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
