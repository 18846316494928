import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputRow from '../../../Components/InputRow';
import AppColor from '../../../AppColor';

export default class FITItemVisa extends React.Component {
	removeRow = index => {
		this.props.onRemove(index);
	};

	renderRow = () => {
		const { fitDetails } = this.props;
		if (!fitDetails) return null;
		return fitDetails.map((detail, index) => (
			<div className="row columns" key={'fitVisa' + index}>
				<div className="column is-5">
					<InputRow
						title="รายการ"
						labelSize="s"
						placeholder="รายการ"
						keyProps="title"
						value={detail.title}
						onInputChange={(key, value) => this.props.onChange(index, key, value)}
						disabled={false}
					/>
				</div>
				<div className="column is-3">
					<InputRow
						title="ราคาขาย (THB)"
						labelSize="s"
						placeholder="ราคาขาย (THB)"
						keyProps="salePrice"
						value={detail.salePrice}
						onInputChange={(key, value) => this.props.onChange(index, key, value)}
						disabled={false}
					/>
				</div>
				<div className="column is-3">
					<InputRow
						title="ต้นทุน (THB)"
						labelSize="s"
						placeholder="ราคาขาย (THB)"
						keyProps="price"
						value={detail.price}
						onInputChange={(key, value) => this.props.onChange(index, key, value)}
						disabled={false}
					/>
				</div>
				<div className="column is-1" style={{ paddingTop: 50, textAlign: 'center' }}>
					<a onClick={() => this.removeRow(index)}>
						<h4 className="title is-4" style={{ color: 'red' }}>
							<FontAwesomeIcon icon="minus-circle" />
						</h4>
					</a>
				</div>
			</div>
		));
	};

	render() {
		return (
			<div className="row">
				{this.renderRow()}
				<div
					className="row box clickable"
					style={{ backgroundColor: AppColor.bulma.isPrimary, textAlign: 'center', padding: 8, color: 'white' }}
					onClick={this.props.onAdd}
				>
					เพิ่มรายการย่อย
				</div>
			</div>
		);
	}
}
