import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';

import LoadingCenter from '../../../Components/LoadingCenter';
import InputRow from '../../../Components/InputRow';
import AuthRender from '../../../Components/AuthRender';

import ThingsToSelect from '../../../Helper/ThingsToSelect';

import { PROGRAM_STATUS } from '../../../StaticData/ProgramStatus';
import SEARCH_BY from '../../../StaticData/SearchBy';

export default class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			countries: [],
			wholeSales: [],
		};
	}

	componentDidMount = async () => {
		let requestCountries = APIGet('countries');
		let requestWholeSales = APIGet('jointour/wholesales');
		requestCountries = await requestCountries;
		requestWholeSales = await requestWholeSales;
		if (APIHelper.handleAPIResponse(this, requestCountries, requestWholeSales)) {
			const countries = ThingsToSelect(requestCountries.data, true);
			const wholeSales = ThingsToSelect(requestWholeSales.data, true);
			this.setState({
				isLoading: false,
				countries,
				wholeSales,
			});
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="row">
				<div className="columns">
					<div className="column is-3">
						<label className="label">ประเทศ</label>
						<Select
							name="country"
							value={this.props.country}
							clearable={false}
							onChange={value => this.props.onFilterChange('country', value)}
							options={this.state.countries}
						/>
					</div>
					<div className="column is-3">
						<label className="label">Whole Sale</label>
						<Select
							name="country"
							value={this.props.wholeSale}
							clearable={false}
							onChange={value => this.props.onFilterChange('wholeSale', value)}
							options={this.state.wholeSales}
						/>
					</div>
					<div className="column is-2">
						<label className="label">สถานะ</label>
						<Select
							name="status"
							value={this.props.status}
							clearable={false}
							onChange={value => this.props.onFilterChange('status', value)}
							options={PROGRAM_STATUS}
						/>
					</div>
					<div className="column is-2">
						<InputRow
							title="Invoice"
							labelSize="s"
							placeholder="Invoice"
							keyProps="invoice"
							onInputChange={this.props.onFilterChange}
							value={this.props.invoice}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="column is-2">
						<label className="label">ประเภทวัน</label>
						<Select
							name="searchBy"
							value={this.props.searchBy}
							clearable={false}
							onChange={value => this.props.onFilterChange('searchBy', value)}
							options={SEARCH_BY}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-2">
						<label className="label">วันเริ่มต้น</label>
						<DatePicker
							selected={this.props.startDate}
							onChange={value => this.props.onFilterChange('startDate', value)}
							dateFormat="DD MMM YYYY"
							className="input"
						/>
					</div>
					<div className="column is-2">
						<label className="label">วันสิ้นสุด</label>
						<DatePicker
							selected={this.props.endDate}
							onChange={value => this.props.onFilterChange('endDate', value)}
							dateFormat="DD MMM YYYY"
							className="input"
						/>
					</div>
					<div className="column is-2">
						<InputRow
							title="ชื่อลูกค้า"
							labelSize="s"
							placeholder="ชื่อลูกค้า"
							keyProps="customerName"
							onInputChange={this.props.onFilterChange}
							value={this.props.customerName}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="column is-2">
						<InputRow
							title="เบอร์โทรลูกค้า"
							labelSize="s"
							placeholder="เบอร์โทรลูกค้า"
							keyProps="customerTelephone"
							onInputChange={this.props.onFilterChange}
							value={this.props.customerTelephone}
							disabled={false}
							isMandatory={false}
						/>
					</div>
					<div className="column is-2">
						<AuthRender roles={['Admin', 'Manager', 'Finance']}>
							<InputRow
								title="พนักงาน"
								labelSize="s"
								placeholder="พนักงาน"
								keyProps="staffName"
								onInputChange={this.props.onFilterChange}
								value={this.props.staffName}
								disabled={false}
								isMandatory={false}
							/>
						</AuthRender>
					</div>
					<div className="column is-1" style={{ paddingTop: 45 }}>
						<button className="button is-info" onClick={this.props.search}>
							Filter
						</button>
					</div>
					<div className="column is-1" style={{ paddingTop: 45 }}>
						<button className="button is-warning" onClick={this.props.clearSearch}>
							Clear
						</button>
					</div>
				</div>
			</div>
		);
	}
}
