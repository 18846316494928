import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import FileManager from '../../../Components/FileManager';
import DeleteButton from '../../../Components/DeleteButton';

import APIGet from '../../../API/APIGet';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

export default class FitExpenseDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			expense: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { programId, expenseId } = this.props.match.params;
			let requestProgram = APIGet('fit/programs/' + programId);
			let requestExpense = APIGet('fit/programs/' + programId + '/expenses/' + expenseId);
			requestProgram = await requestProgram;
			requestExpense = await requestExpense;
			if (APIHelper.handleAPIResponse(this, requestProgram, requestExpense)) {
				const program = requestProgram.data;
				const expense = requestExpense.data;
				console.log('program: ', program);
				console.log('expense: ', expense);
				this.setState({
					isLoading: false,
					program,
					expense,
				});
			} else if (requestProgram.status === 404) {
				toast.warning('ไม่พบโปรแกรมดังกล่าว');
			} else {
				toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	onUploadSuccess = expense => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.setState({
			expense,
		});
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = expense => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.setState({
			expense,
		});
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	delete = async () => {
		const { programId, expenseId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete('fit/programs/' + programId + '/expenses/' + expenseId);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/programs/fit/' + programId);
		} else if (requestDelete.status === 400) {
			toast.warn('คุณไม่มีสิทธิ์ลบรายการนี้');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program, expense } = this.state;
		const { programId, expenseId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/fit/' + programId} />
				<div className="section">
					<div className="columns">
						<div className="column is-8">
							<h3 className="subtitle is-5">
								รายจ่าย {expense.title}
								<span> </span>
								<Link to={AppConfig.appPath + '/programs/fit/' + programId + '/expenses/' + expenseId + '/edit'} className="button is-info">
									แก้ไข
								</Link>
							</h3>
						</div>
						<div className="column is-4" style={{ textAlign: 'right' }}>
							<DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />
						</div>
					</div>
					<div className="columns">
						<div className="column is-6">
							<div>
								ราคา:{' '}
								<strong>
									<NumberColor isExpense={true} number={expense.total} />
								</strong>
							</div>
							<div>
								{!expense.financeStaff ? (
									<span className="tag is-warning">พนักงานบัญชียังไม่ตรวจสอบ</span>
								) : (
									<span className="tag is-success">พนักงานบัญชีตรวจสอบแล้ว</span>
								)}
							</div>
							<div>
								พนักงานบัญชี: <strong>{expense.financeStaff && expense.financeStaff.name}</strong>
							</div>
							<hr />
							<div>
								วันที่:{' '}
								<strong>
									<DateLabel date={expense.paymentDate} />
								</strong>
							</div>
							<div>
								วิธีการ: <strong>{expense.paymentMethod}</strong>
							</div>
							{expense.paymentMethod === 'Transfer' ? (
								<div>
									บัญชี: <strong>{expense.bankAccount.name}</strong>
								</div>
							) : null}
							{expense.paymentMethod === 'Check' ? (
								<div>
									ธนาคาร: <strong>{expense.bank.name}</strong>
									<br />
									เช็คเลขที่: <strong>{expense.checkNumber}</strong>
								</div>
							) : null}
							{expense.paymentMethod === 'Credit Card' ? (
								<div>
									บัตร: <strong>{expense.creditCard.name}</strong>
								</div>
							) : null}
						</div>
						<div className="column is-6">
							<div className="content">
								<strong>เอกสาร:</strong>
								<ul>
									{expense.paymentVouchers &&
										expense.paymentVouchers.map(doc => (
											<li key={'paymentVouchers' + doc.id}>
												<a onClick={() => OpenLink.fitPaymentVoucher(doc.id)} target="_blank">
													{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
												</a>
												{doc.deleted_at && (
													<label className="tag is-warning">
														Inactive - <DateLabel date={doc.deleted_at} />
														{}
													</label>
												)}
											</li>
										))}
								</ul>
							</div>
							<hr />
							<div className="content">
								<FileManager
									title="Files"
									files={expense.files}
									api={'fit/programs/' + programId + '/expenses/' + expenseId + '/files'}
									onUploadSuccess={this.onUploadSuccess}
									onUploadFail={this.onUploadFail}
									onDeleteSuccess={this.onDeleteSuccess}
									onDeleteFail={this.onDeleteFail}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
