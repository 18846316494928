import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Summary from '../Pages/Reports/Summary';
import CreditCard from '../Pages/Reports/CreditCard';

import AppConfig from '../AppConfig';

export default class DataRoutes extends Component {
	render() {
		return (
			<span>
				<Route exact path={AppConfig.appPath + '/reports/summary'} component={Summary} />
				<Route exact path={AppConfig.appPath + '/reports/creditCard'} component={CreditCard} />
			</span>
		);
	}
}
