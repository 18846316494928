import React from 'react';

import DateLabel from '../../../Components/DateLabel';
import DateRangeLabel from '../../../Components/DateRangeLabel';

import CustomerChannels from '../../../StaticData/CustomerChannels';

export default class FITDetail extends React.Component {
	render() {
		const { fit } = this.props;
		let channel = CustomerChannels.filter(c => c.value === fit.customerContactChannel)[0];
		if (channel) {
			channel = channel.label;
		}
		return (
			<div className="row">
				ลูกค้า: <strong>{fit.customer ? fit.customer.name : ''}</strong> <br />
				เบอร์โทร: <strong>{fit.customer ? fit.customer.telephone : ''}</strong> <br />
				email: <strong>{fit.customer ? fit.customer.email : ''}</strong> <br />
				วันที่:{' '}
				<strong>
					<DateRangeLabel startDate={fit.startDate} endDate={fit.endDate} />
				</strong>
				<br />
				วันทำจอง:{' '}
				<strong>
					<DateLabel date={fit.created_at} />
				</strong>
				<hr />
				<b>ติดต่อ</b>
				<br />
				ชื่อ: <strong>{fit.customerContact ? fit.customerContact.name : ''}</strong> <br />
				เบอร์โทร: <strong>{fit.customerContactTelephone}</strong> <br />
				email: <strong>{fit.customerContactEmail}</strong> <br />
				Line: <strong>{fit.customerContactLineId}</strong> <br />
				<hr />
				ช่องทางติดต่อ: <strong>{channel}</strong> <br />
				<hr />
				กำหนดชำระเงิน:{' '}
				<strong>
					<DateLabel date={fit.dueDatePayment} />
				</strong>
			</div>
		);
	}
}
