import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import DeleteButton from '../../../Components/DeleteButton';
import LoadingCenter from '../../../Components/LoadingCenter';

import RestaurantHeader from '../../../Components/Restaurant/Header';
import IncentiveRestaurantDetail from '../../../Components/Restaurant/IncentiveRestaurantDetail';
import RestaurantInfo from '../../../Components/Restaurant/Info';
import RestaurantPictures from '../../../Components/Restaurant/Pictures';
import RestaurantComments from '../../../Components/Restaurant/Comments';

import AppConfig from '../../../AppConfig';

export default class RestaurantDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}
	componentDidMount = () => {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.setState({
				isLoading: false,
			});
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	delete = async () => {
		const { id, programId, bookingId } = this.props.match.params;
		const request = await APIDelete(`incentive/programs/${programId}/restaurants/${id}/${bookingId}`);
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success('นำออกเรียบร้อยแล้ว');
			this.props.history.push(`${AppConfig.appPath}/programs/incentive/${programId}`);
		} else {
			toast.error('ไม่สามารถทำรายการได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}
		const { id, programId, bookingId } = this.props.match.params;
		return (
			<div className="container">
				<BackButtonRouter id={id} programId={programId} />
				<div className="columns">
					<div className="column is-6">
						<RestaurantHeader id={id} programId={programId} />
						<IncentiveRestaurantDetail id={bookingId} programId={programId} />
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<DeleteButton label="นำออกจากโปรแกรม" warningMessage="ยืนยันการนำออกจากโปรแกรม" onClick={this.delete} />
					</div>
				</div>
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<RestaurantInfo id={id} programId={programId} />
							<RestaurantPictures id={id} programId={programId} />
						</div>
						<div className="column is-6">
							<RestaurantComments allowComment={true} id={id} programId={programId} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
