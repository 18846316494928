import React from 'react';

export default function(props) {
	try {
		if (!isNaN(props.number)) {
			const number = parseInt(props.number);
			return <span>{number.toLocaleString()}</span>;
		}
	} catch (ex) {
		console.log(ex);
	}

	if (props.default) {
		return <span>{props.default}</span>;
	}

	return <span />;
}
