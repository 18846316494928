import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import Stars from '../../../Components/Restaurant/Stars';

import AppConfig from '../../../AppConfig';

import Search from './Search';

export default class Restaurants extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			restaurants: [],
			search: {
				country: null,
				city: '',
				name: '',
			},
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			await this.getData();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	getData = async () => {
		this.setState({
			isLoading: true,
		});

		const request = await APIGet('restaurants', {
			city: this.state.search.city,
			name: this.state.search.name,
			countryId: this.state.search.country ? this.state.search.country.value : 0,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const restaurants = request.data;
			console.log('restaurants: ', restaurants);
			this.setState({
				isLoading: false,
				restaurants,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	onSearchChange = (key, value) => {
		let search = { ...this.state.search };
		search[key] = value;
		this.setState({
			search,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Incentive Restaurants</h1>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/incentive/newrestaurant'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<Search onSearchChange={this.onSearchChange} keywords={this.state.search} onClick={this.getData} />
				<div className="section table-container">
					{this.state.restaurants.length > 0 ? (
						<table className="table is-hoverable is-fullwidth is-striped is-bordered">
							<thead>
								<tr className="table-header">
									<td width="14%">ประเทศ</td>
									<td width="14%">เมือง</td>
									<td>ร้าน</td>
									<td width="14%">ดาว</td>
									<td width="10%">จำนวนครั้งที่ไป</td>
									<td width="14%">ราคาต่อคน</td>
								</tr>
							</thead>
							<tbody>
								{this.state.restaurants.map(restaurant => (
									<tr key={'restaurant' + restaurant.id}>
										<td style={{ textAlign: 'center' }}>{restaurant.country ? restaurant.country.name : ''}</td>
										<td style={{ textAlign: 'center' }}>{restaurant.city}</td>
										<td>
											<Link to={AppConfig.appPath + '/data/incentive/restaurants/' + restaurant.id}>{restaurant.name}</Link>
										</td>
										<td style={{ textAlign: 'center' }}>
											<Stars active={restaurant.star} />
										</td>
										<td className="number-cell">{restaurant.totalVisits ? restaurant.totalVisits : 0}</td>
										<td className="number-cell">{restaurant.pricePerHead}</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<div style={{ textAlign: 'center' }}>ไม่พบร้าน</div>
					)}
				</div>
			</div>
		);
	}
}
