import React, { useState, useEffect } from 'react';
import Loading from 'react-loading';
import APIGet from '../../../API/APIGet';
import NumberColor from '../../../Components/NumberColor';
import APIHelper from '../../../Helper/APIHelper';

const ExpenseRowDetail = ({ programId, categoryId }) => {
	const [expenses, setExpenses] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getExpenseByCatId(programId, categoryId);
	}, []);

	const getExpenseByCatId = async () => {
		setIsLoading(true);
		let request = await APIGet('incentive/programs/' + programId + '/expenses/' + categoryId);
		setIsLoading(false);
		if (APIHelper.handleAPIResponse(this, request)) {
			const expenses = request.data;
			if (expenses.length > 0) {
				const result = summarizeExpense(expenses);
				setExpenses(result);
			}
		}
	};

	const summarizeExpense = expenses => {
		let summarize = {};
		for (const expense of expenses) {
			if (!summarize[expense.currency]) {
				summarize[expense.currency] = {
					currencyRate: expense.currencyRate,
					total: 0,
					rateNotEqual: false,
				};
			}

			summarize[expense.currency].total += expense.total;
			if (!isDecimalEqual(summarize[expense.currency].currencyRate, expense.currencyRate)) {
				summarize[expense.currency].rateNotEqual = true;
			}
		}

		let result = [];
		for (const key in summarize) {
			result.push({
				currency: key,
				...summarize[key],
			});
		}

		return result;
	};

	const isDecimalEqual = (a, b) => {
		return Math.round(a * 10000) === Math.round(b * 10000);
	};

	if (isLoading) {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Loading type="balls" color="#999999" height={'20%'} width={'20%'} />
			</div>
		);
	}

	return (
		<div>
			{expenses.map(expense => {
				return (
					<div style={textStyle}>
						{expense.currency} {expense.rateNotEqual ? ' 🚨' : ''} <NumberColor number={expense.total} isExpense={true} /> x {expense.currencyRate}
					</div>
				);
			})}
		</div>
	);
};

const textStyle = {
	fontSize: 12,
	color: 'red',
	textAlign: 'right',
};

export default ExpenseRowDetail;
