import React, { PureComponent } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import DeleteButton from '../../../Components/DeleteButton';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

export default class SupplierDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			name: '',
			email: '',
			tax: '',
			telephone: '',
			address: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			let initUpdate = true;
			if (this.isUpdate()) {
				initUpdate = await this.initUpdate();
			}

			if (initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initUpdate = async () => {
		const { categoryId, supplierId } = this.props.match.params;
		let requestSupplier = APIGet(
			`finance/companyExpenseCategories/${categoryId}/suppliers/${supplierId}`
		);
		requestSupplier = await requestSupplier;
		if (APIHelper.handleAPIResponse(this, requestSupplier)) {
			const supplier = requestSupplier.data;
			console.log('supplier: ', supplier);
			this.setState({
				name: supplier.name,
				email: supplier.email,
				telephone: supplier.telephone,
				tax: supplier.tax,
				address: supplier.address,
			});

			return true;
		} else if (requestSupplier.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { supplierId } = this.props.match.params;
		if (supplierId) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		const { categoryId, supplierId } = this.props.match.params;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				name: this.state.name,
				address: this.state.address,
				email: this.state.email,
				telephone: this.state.telephone,
				tax: this.state.tax,
			};

			let request = null;
			if (this.isUpdate()) {
				request = await APIPut(
					`finance/companyExpenseCategories/${categoryId}/suppliers/${supplierId}`,
					data
				);
			} else {
				request = await APIPost(
					`finance/companyExpenseCategories/${categoryId}/suppliers`,
					data
				);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(
					this.isUpdate()
						? 'แก้ไขเรียบร้อยแล้ว'
						: 'เพิ่มเรียบร้อยแล้ว'
				);
				const result = request.data;
				this.props.history.replace(
					AppConfig.appPath +
						'/finance/companyExpenseCategories/' +
						categoryId +
						'/suppliers'
				);
			} else {
				toast.error('เกิดปัญหาในการเพิ่ม Supplier');
			}

			this.setState({
				isLoading: false,
			});
		}
	};

	validateSaveState = () => {
		const name = this.state.name.trim() !== '';
		if (!name) {
			toast.warn('กรุณาระบุชื่อ');
		}

		const telephone = this.state.telephone.trim() !== '';
		if (!telephone) {
			toast.warn('กรุณาระบุเบอร์โทรศัพท์');
		}

		const email = this.state.email.trim() !== '';
		if (!email) {
			toast.warn('กรุณาระบุ email');
		}

		const address = this.state.address.trim() !== '';
		if (!address) {
			toast.warn('กรุณาระบุที่อยู่');
		}

		return name && telephone && email && address;
	};

	delete = async () => {
		const { categoryId, supplierId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete(
			`finance/companyExpenseCategories/${categoryId}/suppliers/${supplierId}`
		);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(
				AppConfig.appPath +
					'/finance/companyExpenseCategories/' +
					categoryId +
					'/suppliers'
			);
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">
								{this.isUpdate()
									? 'แก้ไข Expense Supplier'
									: 'เพิ่ม Expense Supplier'}
							</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<InputRow
								title="ชื่อ"
								labelSize="s"
								placeholder="ชื่อ"
								keyProps="name"
								onInputChange={this.onTextChange}
								value={this.state.name}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="email"
								labelSize="s"
								placeholder="email"
								keyProps="email"
								onInputChange={this.onTextChange}
								value={this.state.email}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="Tax"
								labelSize="s"
								placeholder="Tax"
								keyProps="tax"
								onInputChange={this.onTextChange}
								value={this.state.tax}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-4">
							<InputRow
								title="telephone"
								labelSize="s"
								placeholder="telephone"
								keyProps="telephone"
								onInputChange={this.onTextChange}
								value={this.state.telephone}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-12">
							<TextArea
								title="Address"
								labelSize="s"
								keyProps="address"
								value={this.state.address}
								onInputChange={this.onTextChange}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-6">
							{this.isUpdate() ? (
								<DeleteButton
									label="ลบ"
									warningMessage="ยืนยันการลบ"
									onClick={this.delete}
								/>
							) : null}
						</div>
						<div
							className="column is-6"
							style={{ textAlign: 'right', marginTop: 20 }}
						>
							<button
								className="button is-success"
								onClick={this.save}
							>
								บันทึก
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
