import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Programs from '../Pages/Programs';
import Clear from '../Pages/Clear';
import Notification from '../Pages/Notification';
import NotificationMobile from '../Pages/NotificationMobile';
import Login from '../Pages/Login';
import Logout from '../Pages/Logout';
import Unauthorized from '../Pages/Unauthorized';

import AppConfig from '../AppConfig';

export default class GeneralRoutes extends Component {
	render() {
		return (
			<span>
				<Route exact path={AppConfig.appPath + '/'} component={Login} />
				<Route exact path={AppConfig.appPath + '/logout'} component={Logout} />
				<Route exact path={AppConfig.appPath + '/dashboard'} component={Programs} />
				<Route exact path={AppConfig.appPath + '/unauthorized'} component={Unauthorized} />
				<Route exact path={AppConfig.appPath + '/clear'} component={Clear} />
				<Route exact path={AppConfig.appPath + '/notifications'} component={Notification} />
				<Route exact path={AppConfig.appPath + '/notifications/mobile'} component={NotificationMobile} />
			</span>
		);
	}
}
