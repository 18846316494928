import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../LoadingCenter';
import DateLabel from '../DateLabel';
import Stars from './Stars';
import Pin from './Pin';

import DeleteButton from '../DeleteButton';

import APIGet from '../../API/APIGet';
import APIPost from '../../API/APIPost';
import APIPut from '../../API/APIPut';
import APIDelete from '../../API/APIDelete';
import APIHelper from '../../Helper/APIHelper';
import AuthHelper from '../../Helper/AuthHelper';

import AppConfig from '../../AppConfig';

import NewComment from './NewComment';
import EditComment from './EditComment';
import CommentPhotos from './CommentPhotos';

export default class RestaurantComments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			comments: [],
			comment: '',
			course: '',
			star: 0,
			editingComments: [],
		};
	}
	async componentDidMount() {
		await this.getComment();
	}

	getComment = async () => {
		const request = await APIGet('restaurants/' + this.props.id + '/comments');
		if (APIHelper.handleAPIResponse(this, request)) {
			const comments = request.data;
			console.log('comments: ', comments);
			this.setState({
				isLoading: false,
				comment: '',
				star: 0,
				course: '',
				comments,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onCommentStarClick = index => {
		this.setState({
			star: index + 1,
		});
	};

	onAddComment = async () => {
		if (!this.state.course) {
			toast.warn('โปรดตรวจสอบการกรอกข้อมูล');
			return;
		}

		const { id, programId } = this.props;
		this.setState({
			isLoading: true,
		});

		let data = {
			incentiveProgramId: programId,
			comment: this.state.comment ? this.state.comment : '',
			course: this.state.course,
			star: !this.state.star ? 0 : this.state.star,
		};

		let request = await APIPost(`restaurants/${id}/comments`, data);
		await this.handleAPIResponse(request, 'เพิ่มเรียบร้อยแล้ว');
	};

	delete = async commentId => {
		const { id } = this.props;
		this.setState({
			isLoading: true,
		});

		let request = await APIDelete(`restaurants/${id}/comments/${commentId}`);
		await this.handleAPIResponse(request, 'ลบเรียบร้อยแล้ว');
	};

	onEditComment = async (commentId, data) => {
		const { id } = this.props;
		this.setState({
			isLoading: true,
		});

		let request = await APIPut(`restaurants/${id}/comments/${commentId}`, {
			...data,
		});

		let result = await this.handleAPIResponse(request, 'แก้ไขเรียบร้อยแล้ว');
		if (result) {
			this.onCancelEditComment(commentId);
		}
	};

	togglePin = async (commentId, isPinned) => {
		const { id } = this.props;
		this.setState({
			isLoading: true,
		});

		let request = await APIPut(`restaurants/${id}/comments/${commentId}/pin`, {
			isPinned,
		});
		await this.handleAPIResponse(request, 'แก้ไขเรียบร้อยแล้ว');
	};

	handleAPIResponse = async (request, message) => {
		let isSuccess = true;
		if (APIHelper.handleAPIResponse(this, request)) {
			toast.success(message);
			await this.getComment();
		} else {
			toast.error('เกิดปัญหาในการแก้ไข');
			isSuccess = false;
		}

		this.setState({
			isLoading: false,
		});

		return isSuccess;
	};

	checkAllowMorePin = comments => {
		const pinnedComment = comments.filter(filter => filter.isPinned);
		if (pinnedComment.length >= AppConfig.restaurantCommentPin) {
			return false;
		}

		return true;
	};

	isAllowEdit = comment => {
		const userDetail = AuthHelper.getUserDetail();
		const result = userDetail && userDetail.id === comment.staffId;
		return result;
	};

	isSelectedForEdit = comment => {
		return this.state.editingComments.indexOf(comment.id) !== -1;
	};

	onCancelEditComment = id => {
		let editingComments = JSON.parse(JSON.stringify(this.state.editingComments));
		const index = editingComments.indexOf(id);
		if (index !== -1) {
			editingComments.splice(index);
			this.setState({
				editingComments,
			});
		}
	};

	onEnableEditComment = id => {
		let editingComments = JSON.parse(JSON.stringify(this.state.editingComments));
		editingComments.push(id);
		this.setState({
			editingComments,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { allowComment } = this.props;
		const { comments, comment, course, star } = this.state;
		const isAllowMorePin = this.checkAllowMorePin(comments);
		return (
			<>
				{allowComment ? (
					<NewComment
						comment={comment}
						course={course}
						star={star}
						onTextChange={this.onTextChange}
						onCommentStarClick={this.onCommentStarClick}
						onAddComment={this.onAddComment}
					/>
				) : null}
				<h3 className="title is-4">รีวิวจาก Group ก่อนหน้า</h3>
				{comments && comments.length > 0 ? (
					<>
						{comments.map(comment => (
							<div className="box" key={comment.id}>
								{this.isAllowEdit(comment) && this.isSelectedForEdit(comment) ? (
									<EditComment {...comment} onEditComment={this.onEditComment} onCancelEditComment={this.onCancelEditComment} />
								) : (
									<BaseComment comment={comment} isAllowEdit={this.isAllowEdit(comment)} onEnableEditComment={this.onEnableEditComment} />
								)}
								<CommentPhotos
									restaurantId={comment.restaurantId}
									commentId={comment.id}
									images={comment.photos}
									isAllowEdit={this.isAllowEdit(comment)}
								/>
								<PinAndDeleteComment
									comment={comment}
									togglePin={this.togglePin}
									delete={this.delete}
									isAllowMorePin={isAllowMorePin}
									isAllowEdit={this.isAllowEdit(comment)}
								/>
							</div>
						))}
					</>
				) : (
					<div style={{ textAlign: 'center' }}>ยังไม่มี Comment</div>
				)}
			</>
		);
	}
}

class BaseComment extends React.Component {
	onEditComment = () => {
		const { comment } = this.props;
		this.props.onEnableEditComment(comment.id);
	};
	render() {
		const { comment, isAllowEdit } = this.props;
		return (
			<>
				<div className="columns">
					<div className="column is-8">
						<b>
							{comment.incentiveProgram ? (
								<Link to={AppConfig.appPath + '/programs/incentive/' + comment.incentiveProgram.id}>{comment.incentiveProgram.title}</Link>
							) : null}
						</b>{' '}
						(<DateLabel date={comment.created_at} />)
					</div>
					<div className="column is-4" style={{ textAlign: 'right' }}>
						<span>{comment.staff ? comment.staff.name : ''}</span>
						<br />
						<Stars active={comment.star} />
					</div>
				</div>
				<div className="row">{comment.comment}</div>
				<div className="row">
					<strong>Course</strong>
				</div>
				<div className="row">{comment.course}</div>
				{isAllowEdit ? (
					<div className="row" style={{ textAlign: 'right' }}>
						<button className="button is-success" onClick={this.onEditComment}>
							Edit
						</button>
					</div>
				) : null}
			</>
		);
	}
}

class PinAndDeleteComment extends React.Component {
	delete = () => {
		this.props.delete(this.props.comment.id);
	};

	togglePin = (id, isPinned) => {
		const { isAllowMorePin } = this.props;
		if (!isPinned || isAllowMorePin) {
			this.props.togglePin(id, isPinned);
		} else {
			toast.warn('มี Pin ครบ ' + AppConfig.restaurantCommentPin + ' แล้ว กรุณาเลือก unpin บาง comment ก่อน');
		}
	};
	render() {
		const { comment, isAllowEdit } = this.props;
		return (
			<div className="columns row">
				<div className="column is-6" style={{ textAlign: 'left', paddingTop: 30 }}>
					<Pin isPinned={comment.isPinned} togglePin={this.togglePin} id={comment.id} />
				</div>
				<div className="column is-6" style={{ textAlign: 'right' }}>
					{isAllowEdit ? <DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} /> : null}
				</div>
			</div>
		);
	}
}
