import React, { Component } from 'react';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';

export default class IncentiveGroupStaff extends Component {
	render() {
		const { list, isLoading } = this.props;
		if (isLoading) {
			return <LoadingCenter />;
		}

		if (!list || list.length === 0) {
			return null;
		}

		return (
			<div className="columns">
				<div className="column is-12">
					<h4 className="title is-4">Incentive (Group By Staffs)</h4>
					<div className="row">
						<table className="table is-hoverable is-fullwidth is-bordered">
							<thead>
								<tr className="table-header">
									<td>Staff</td>
									<td>ลูกค้าเดินทาง</td>
									<td>ราคาขาย</td>
									<td>ต้นทุน</td>
									<td>กำไร</td>
								</tr>
							</thead>
							<tbody>
								{list.map(row => (
									<tr key={'incentiveGroupStaff' + row.id}>
										<td>
											{row.name} ({row.nickname})
										</td>
										<td className="number-cell">
											<NumberColor number={row.customer} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.salePrice} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.price} isExpense={true} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.salePrice - row.price} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
