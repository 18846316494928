import React from 'react';

import NumberColor from '../../../Components/NumberColor';
import FITTypes from '../../../StaticData/FITTypes';
import FITTypeHelper from '../Helpers/FITTypeHelper';

import FitItemPricing from './FitItemPricing';

export default class FITItemVisa extends React.Component {
	render() {
		const { item, fitItemHelper } = this.props;
		// console.log('item: ', item);
		const type = FITTypeHelper.mapLabel(item.fitType);
		const fitDetail = item[item.fitType] && item[item.fitType].details ? item[item.fitType].details : [];
		const pricing = fitItemHelper.getItemPrice(item);
		return (
			<div className="box" key={'item' + item.id}>
				<div className="columns">
					<div className="column is-6">
						<h4 className="title is-4">{type}</h4>
					</div>
					<div className="column is-6">
						<FitItemPricing
							pricing={pricing}
							creditCard={item.creditCard}
							creditCardRecord={item.creditCardRecord}
							creditCardCurrency={item.creditCardCurrency}
						/>
					</div>
				</div>
				<table className="table is-fullwidth is-bordered is-hoverable">
					<thead>
						<tr className="table-header-green">
							<td width="40%">รายการ</td>
							<td>ราคาขาย</td>
							<td>ต้นทุน</td>
						</tr>
					</thead>
					<tbody>
						{fitDetail &&
							fitDetail.map(detail => (
								<tr key={'detail' + item.fitType + detail.id}>
									<td>{detail.title}</td>
									<td style={{ textAlign: 'right' }}>
										<NumberColor number={detail.salePrice} />
									</td>
									<td style={{ textAlign: 'right' }}>
										<NumberColor number={detail.price} isExpense={true} />
									</td>
								</tr>
							))}
						<tr className="table-summary-row">
							<td />
							<td style={{ textAlign: 'right' }}>
								<NumberColor number={pricing.salePrice} />
							</td>
							<td style={{ textAlign: 'right' }}>
								<NumberColor number={pricing.price} isExpense={true} />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}
