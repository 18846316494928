import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import AppConfig from '../../../AppConfig';

export default class Suppliers extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			suppliers: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const request = await APIGet('suppliers');
			if (APIHelper.handleAPIResponse(this, request)) {
				const suppliers = request.data;
				console.log('suppliers: ', suppliers);
				this.setState({
					isLoading: false,
					suppliers,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Suppliers</h1>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/data/newsupplier'}>
							เพิ่ม
						</Link>
					</div>
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.suppliers.map(supplier => (
								<tr key={supplier.id}>
									<td width="70%">
										<Link to={AppConfig.appPath + '/data/suppliers/' + supplier.id}>{supplier.name}</Link>
									</td>
									<td width="30%" style={{ textAlign: 'right' }} />
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
