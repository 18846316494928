import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Banks from '../Pages/Data/Banks';
import BankAccounts from '../Pages/Data/BankAccounts';
import CreditCards from '../Pages/Data/CreditCards';
import Countries from '../Pages/Data/Countries';
import ExpenseCategories from '../Pages/Data/ExpenseCategories';
import ExpenseSuppliers from '../Pages/Data/ExpenseSuppliers';
import ExpenseSupplierDetail from '../Pages/Data/ExpenseSupplierDetail';
import IncomeEtcCategories from '../Pages/Data/IncomeEtcCategories';
import Companies from '../Pages/Data/Companies';
import CompanyDetail from '../Pages/Data/CompanyDetail';
import AddEditCompanyUsers from '../Pages/Data/AddEditCompanyUsers';
import Staffs from '../Pages/Data/Staffs';
import StaffDetail from '../Pages/Data/StaffDetail';
import Suppliers from '../Pages/Data/Suppliers';
import SupplierDetail from '../Pages/Data/SupplierDetail';
import Customers from '../Pages/Data/Customers';
import CustomerDetail from '../Pages/Data/CustomerDetail';
import CompanyContacts from '../Pages/Data/CompanyContacts';
import EditProfile from '../Pages/Data/EditProfile';
import ChangePassword from '../Pages/Data/ChangePassword';
import IncentiveRestaurants from '../Pages/Data/IncentiveRestaurants';
import IncentiveRestaurantDetail from '../Pages/Data/IncentiveRestaurantDetail';
import AddEditIncentiveRestaurant from '../Pages/Data/AddEditIncentiveRestaurant';

import JointourWholesales from '../Pages/Data/JointourWholesales';
import JointourWholesaleDetail from '../Pages/Data/JointourWholesaleDetail';
import JointourWholesaleContacts from '../Pages/Data/JointourWholesaleContacts';
import JointourWholesaleContactDetail from '../Pages/Data/JointourWholesaleContactDetail';

import JointourCustomers from '../Pages/Data/JointourCustomers';
import JointourCustomerDetail from '../Pages/Data/JointourCustomerDetail';
import JointourCustomerContacts from '../Pages/Data/JointourCustomerContacts';
import JointourCustomerContactDetail from '../Pages/Data/JointourCustomerContactDetail';

import FITSuppliers from '../Pages/Data/FITSuppliers';
import FITSupplierDetail from '../Pages/Data/FITSupplierDetail';
import FITCustomers from '../Pages/Data/FITCustomers';
import FITCustomerDetail from '../Pages/Data/FITCustomerDetail';
import FITCustomerContacts from '../Pages/Data/FITCustomerContacts';
import FITCustomerContactDetail from '../Pages/Data/FITCustomerContactDetail';

import Airlines from '../Pages/Data/Airlines';

import AppConfig from '../AppConfig';

export default class DataRoutes extends Component {
	render() {
		return (
			<span>
				<Route exact path={AppConfig.appPath + '/data/airlines'} component={Airlines} />
				<Route exact path={AppConfig.appPath + '/data/banks'} component={Banks} />
				<Route exact path={AppConfig.appPath + '/data/bankAccounts'} component={BankAccounts} />
				<Route exact path={AppConfig.appPath + '/data/creditCards'} component={CreditCards} />
				<Route exact path={AppConfig.appPath + '/data/countries'} component={Countries} />
				<Route exact path={AppConfig.appPath + '/data/expenseCategories'} component={ExpenseCategories} />
				<Route exact path={AppConfig.appPath + '/data/expenseCategories/:categoryId/suppliers'} component={ExpenseSuppliers} />
				<Route exact path={AppConfig.appPath + '/data/expenseCategories/:categoryId/newsupplier'} component={ExpenseSupplierDetail} />
				<Route exact path={AppConfig.appPath + '/data/expenseCategories/:categoryId/suppliers/:supplierId'} component={ExpenseSupplierDetail} />
				<Route exact path={AppConfig.appPath + '/data/incomeEtcCategories'} component={IncomeEtcCategories} />
				<Route exact path={AppConfig.appPath + '/data/newcompany'} component={CompanyDetail} />
				<Route exact path={AppConfig.appPath + '/data/companies'} component={Companies} />
				<Route exact path={AppConfig.appPath + '/data/companies/:companyId'} component={CompanyDetail} />
				<Route exact path={AppConfig.appPath + '/data/companies/:companyId/contacts'} component={CompanyContacts} />
				<Route exact path={AppConfig.appPath + '/data/companies/:companyId/newuser'} component={AddEditCompanyUsers} />
				<Route exact path={AppConfig.appPath + '/data/companies/:companyId/users/:customerId'} component={AddEditCompanyUsers} />
				<Route exact path={AppConfig.appPath + '/data/newcustomer'} component={CustomerDetail} />
				<Route exact path={AppConfig.appPath + '/data/customers'} component={Customers} />
				<Route exact path={AppConfig.appPath + '/data/customers/:customerId'} component={CustomerDetail} />
				<Route exact path={AppConfig.appPath + '/data/newstaff'} component={StaffDetail} />
				<Route exact path={AppConfig.appPath + '/data/staffs'} component={Staffs} />
				<Route exact path={AppConfig.appPath + '/data/staffs/:staffId'} component={StaffDetail} />
				<Route exact path={AppConfig.appPath + '/data/profile'} component={EditProfile} />
				<Route exact path={AppConfig.appPath + '/data/password'} component={ChangePassword} />
				<Route exact path={AppConfig.appPath + '/data/newsupplier'} component={SupplierDetail} />
				<Route exact path={AppConfig.appPath + '/data/suppliers'} component={Suppliers} />
				<Route exact path={AppConfig.appPath + '/data/suppliers/:supplierId'} component={SupplierDetail} />
				<Route exact path={AppConfig.appPath + '/data/newjointourWholesale'} component={JointourWholesaleDetail} />
				<Route exact path={AppConfig.appPath + '/data/jointour/wholesales'} component={JointourWholesales} />
				<Route exact path={AppConfig.appPath + '/data/jointour/wholesales/:wholesaleId'} component={JointourWholesaleDetail} />
				<Route exact path={AppConfig.appPath + '/data/jointour/wholesales/:wholesaleId/contacts'} component={JointourWholesaleContacts} />
				<Route exact path={AppConfig.appPath + '/data/jointour/wholesales/:wholesaleId/newcontact'} component={JointourWholesaleContactDetail} />
				<Route
					exact
					path={AppConfig.appPath + '/data/jointour/wholesales/:wholesaleId/contacts/:contactId'}
					component={JointourWholesaleContactDetail}
				/>

				<Route exact path={AppConfig.appPath + '/data/newJointourCustomer'} component={JointourCustomerDetail} />
				<Route exact path={AppConfig.appPath + '/data/jointour/customers'} component={JointourCustomers} />
				<Route exact path={AppConfig.appPath + '/data/jointour/customers/:customerId'} component={JointourCustomerDetail} />
				<Route exact path={AppConfig.appPath + '/data/jointour/customers/:customerId/contacts'} component={JointourCustomerContacts} />
				<Route exact path={AppConfig.appPath + '/data/jointour/customers/:customerId/newcontact'} component={JointourCustomerContactDetail} />
				<Route exact path={AppConfig.appPath + '/data/jointour/customers/:customerId/contacts/:contactId'} component={JointourCustomerContactDetail} />

				<Route exact path={AppConfig.appPath + '/data/fit/suppliers'} component={FITSuppliers} />
				<Route exact path={AppConfig.appPath + '/data/newfitsupplier'} component={FITSupplierDetail} />
				<Route exact path={AppConfig.appPath + '/data/fit/suppliers/:supplierId'} component={FITSupplierDetail} />

				<Route exact path={AppConfig.appPath + '/data/fit/customers'} component={FITCustomers} />
				<Route exact path={AppConfig.appPath + '/data/newfitcustomer'} component={FITCustomerDetail} />
				<Route exact path={AppConfig.appPath + '/data/fit/customers/:customerId'} component={FITCustomerDetail} />
				<Route exact path={AppConfig.appPath + '/data/fit/customers/:customerId/contacts'} component={FITCustomerContacts} />
				<Route exact path={AppConfig.appPath + '/data/fit/customers/:customerId/newcontact'} component={FITCustomerContactDetail} />
				<Route exact path={AppConfig.appPath + '/data/fit/customers/:customerId/contacts/:contactId'} component={FITCustomerContactDetail} />

				<Route exact path={AppConfig.appPath + '/data/incentive/restaurants'} component={IncentiveRestaurants} />
				<Route exact path={AppConfig.appPath + '/data/incentive/restaurants/:id'} component={IncentiveRestaurantDetail} />
				<Route exact path={AppConfig.appPath + '/data/incentive/restaurants/:id/edit'} component={AddEditIncentiveRestaurant} />
				<Route exact path={AppConfig.appPath + '/data/incentive/newrestaurant'} component={AddEditIncentiveRestaurant} />
			</span>
		);
	}
}
