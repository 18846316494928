import React, { Component } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import Search from './Search';
import IncentiveList from './IncentiveList';
import IncentiveGroupCustomer from './IncentiveGroupCustomer';
import IncentiveGroupStaff from './IncentiveGroupStaff';
import JointourList from './JointourList';
import JointourGroupWholeSale from './JointourGroupWholeSale';
import JointourGroupStaff from './JointourGroupStaff';
import FitList from './FitList';
import FitGroupStaff from './FitGroupStaff';
import FitGroupSupplier from './FitGroupSupplier';
import FitGroupType from './FitGroupType';

export default class Programs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingIncentiveList: false,
			incentiveList: [],
			isLoadingIncentiveGroupCustomer: false,
			incentiveGroupCustomer: [],
			isLoadingIncentiveGroupStaff: false,
			incentiveGroupStaff: [],
			isLoadingJointourList: false,
			jointourList: [],
			isLoadingJointourGroupWholeSale: false,
			jointourGroupWholeSale: [],
			isLoadingJointourGroupStaff: false,
			jointourGroupStaff: [],
			isLoadingFitList: false,
			fitList: [],
			isLoadingFitGroupSupplier: false,
			fitGroupSupplier: [],
			isLoadingFitGroupType: false,
			fitGroupType: [],
			isLoadingFitGroupStaff: false,
			fitGroupStaff: [],
		};
	}

	getSummary = async filter => {
		console.log(filter);
		this.getList('INCENTIVE', 'incentiveList', 'isLoadingIncentiveList', 'incentive/programs', filter);
		this.getList('INCENTIVE', 'incentiveGroupCustomer', 'isLoadingIncentiveGroupCustomer', 'incentive/programs/companies', filter);
		this.getList('INCENTIVE', 'incentiveGroupStaff', 'isLoadingIncentiveGroupStaff', 'incentive/programs/staffs', filter);
		this.getList('JOINTOUR', 'jointourList', 'isLoadingJointourList', 'jointour/programs', filter);
		this.getList('JOINTOUR', 'jointourGroupWholeSale', 'isLoadingJointourGroupWholeSale', 'jointour/programs/wholesales', filter);
		this.getList('JOINTOUR', 'jointourGroupStaff', 'isLoadingJointourGroupStaff', 'jointour/programs/staffs', filter);
		this.getList('FIT', 'fitList', 'isLoadingFitList', 'fit/programs', filter);
		this.getList('FIT', 'fitGroupSupplier', 'isLoadingFitGroupSupplier', 'fit/programs/suppliers', filter);
		this.getList('FIT', 'fitGroupType', 'isLoadingFitGroupType', 'fit/programs/types', filter);
		this.getList('FIT', 'fitGroupStaff', 'isLoadingFitGroupStaff', 'fit/programs/staffs', filter);
	};

	getList = async (type, key, isLoadingKey, api, filter) => {
		if (!this.isType(filter, type)) {
			this.setState({
				[key]: [],
			});

			return;
		}

		this.setState({
			[isLoadingKey]: true,
		});

		if (api.indexOf('incentive/') !== -1) {
			filter.status = 'Done';
		}

		const result = await APIGet(api, filter);
		if (APIHelper.handleAPIResponse(this, result)) {
			console.log(key, result.data);
			this.setState({
				[isLoadingKey]: false,
				[key]: result.data,
			});
		} else {
			toast.error(`ไม่สามารถดึงข้อมูล ${api} ได้ กรุณาลองใหม่อีกครั้ง`);
		}
	};

	isType = (filter, type) => {
		return filter.type === '' || filter.type === type;
	};

	render() {
		if (!AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
			return null;
		}

		return (
			<div className="container">
				<div className="columns">
					<div className="column is-8">
						<h1 className="title is-2">ESKY Summary Report</h1>
					</div>
				</div>
				<Search onSubmit={this.getSummary} />
				<IncentiveList isLoading={this.state.isLoadingIncentiveList} list={this.state.incentiveList} />
				<div className="columns">
					<div className="column is-6">
						<IncentiveGroupCustomer isLoading={this.state.isLoadingIncentiveGroupCustomer} list={this.state.incentiveGroupCustomer} />
					</div>
					<div className="column is-6">
						<IncentiveGroupStaff isLoading={this.state.isLoadingIncentiveGroupStaff} list={this.state.incentiveGroupStaff} />
					</div>
				</div>
				<JointourList isLoading={this.state.isLoadingJointourList} list={this.state.jointourList} />
				<div className="columns">
					<div className="column is-6">
						<JointourGroupWholeSale isLoading={this.state.isLoadingJointourGroupWholeSale} list={this.state.jointourGroupWholeSale} />
					</div>
					<div className="column is-6">
						<JointourGroupStaff isLoading={this.state.isLoadingJointourGroupStaff} list={this.state.jointourGroupStaff} />
					</div>
				</div>
				<FitList isLoading={this.state.isLoadingFitList} list={this.state.fitList} />
				<div className="columns">
					<div className="column is-6">
						<FitGroupSupplier isLoading={this.state.isLoadingFitGroupSupplier} list={this.state.fitGroupSupplier} />
					</div>
					<div className="column is-6">
						<FitGroupStaff isLoading={this.state.isLoadingFitGroupStaff} list={this.state.fitGroupStaff} />
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<FitGroupType isLoading={this.state.isLoadingFitGroupType} list={this.state.fitGroupType} />
					</div>
					<div className="column is-6" />
				</div>
			</div>
		);
	}
}
