import React, { useMemo, useState } from 'react';
import Select from 'react-select';

import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import ThingsToSelect from '../../../../Helper/ThingsToSelect';

export const SingleSupplementTable = ({ state, onChange }) => {
	const { isEditing, exchangeRates, singleTraveler } = state;

	const total = () => {
		let currencies = [];
		let total = 0;
		if (exchangeRates && exchangeRates.length > 0 && singleTraveler.currency) {
			currencies = exchangeRates.filter(e => e.name === singleTraveler.currency.name);
		}

		const profit = singleTraveler.profit ? parseFloat(singleTraveler.profit) : 0;
		const price = singleTraveler.price ? parseFloat(singleTraveler.price) : 0;

		if (currencies && currencies.length > 0) total = Math.ceil(price * currencies[0].calculateRate + profit);
		else total = Math.ceil(price + profit);

		return isNaN(total) ? 0 : total;
	};

	const selectedCurrency = useMemo(() => {
		if (singleTraveler.currency && !singleTraveler.currency.label) {
			return {
				value: singleTraveler.currency.id,
				label: singleTraveler.currency.name,
			};
		}

		return singleTraveler.currency;
	}, [singleTraveler.currency]);

	return (
		<>
			<table className="table is-fullwidth is-striped is-bordered">
				<thead style={{ backgroundColor: '#209CEE' }}>
					<tr>
						<td width="15%" style={styles.tableHeaderStyle}>
							SGL Supplement
						</td>
						<td width="15%" style={styles.tableHeaderStyle}>
							สกุลเงิน
						</td>
						<td width="20%" style={styles.tableHeaderStyle}>
							กำไร (บาท)
						</td>
						<td width="20%" style={styles.tableHeaderStyle}>
							รวม (บาท)
						</td>
						<td width="20%" style={styles.tableHeaderStyle}>
							บวกเพิ่มจริง (บาท)
						</td>
						<td width="10%" style={styles.tableHeaderStyle}>
							จำนวนคน
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td style={styles.tableRowDataStyle}>
							<EditableTextSwitch
								isEditing={isEditing}
								value={singleTraveler.price}
								type={'number'}
								onChange={text => {
									onChange({ ...singleTraveler, price: text });
								}}
							>
								<p>{singleTraveler.price ? parseFloat(singleTraveler.price).toLocaleString() : 0}</p>
							</EditableTextSwitch>
						</td>
						<td>
							<Select
								placeholder="สกุลเงิน"
								value={selectedCurrency}
								onChange={currency => {
									onChange({
										...singleTraveler,
										currency: {
											id: currency.value,
											name: currency.label,
										},
									});
								}}
								options={ThingsToSelect(exchangeRates)}
								isDisabled={!isEditing}
							/>
						</td>
						<td style={styles.tableRowDataStyle}>
							<EditableTextSwitch
								isEditing={isEditing}
								value={singleTraveler.profit}
								type={'number'}
								onChange={text => {
									onChange({ ...singleTraveler, profit: text });
								}}
							>
								<p>{singleTraveler.profit ? parseFloat(singleTraveler.profit).toLocaleString() : 0}</p>
							</EditableTextSwitch>
						</td>
						<td style={styles.tableRowDataStyle}>{total().toLocaleString()}</td>
						<td style={styles.tableRowDataStyle}>
							<EditableTextSwitch
								isEditing={isEditing}
								value={singleTraveler.netPrice}
								type={'number'}
								onChange={text => {
									onChange({ ...singleTraveler, netPrice: text });
								}}
							>
								<p>{singleTraveler.netPrice ? parseFloat(singleTraveler.netPrice).toLocaleString() : 0}</p>
							</EditableTextSwitch>
						</td>
						<td style={styles.tableRowDataStyle}>
							<EditableTextSwitch
								isEditing={isEditing}
								value={singleTraveler.person}
								type={'number'}
								onChange={text => {
									onChange({ ...singleTraveler, person: text });
								}}
							>
								<p>{singleTraveler.person}</p>
							</EditableTextSwitch>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

const styles = {
	tableHeaderStyle: {
		color: '#ffffff',
		textAlign: 'center',
	},
	tableRowDataStyle: {
		textAlign: 'center',
		verticalAlign: 'middle',
	},
};
