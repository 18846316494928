import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const EditableDateSwitch = props => {
	return (
		<>
			{props.title ? (
				<label className="label" style={{ color: 'black' }}>
					{props.title}
					{props.isMandatory ? <span style={{ color: 'red' }}> *</span> : null}
				</label>
			) : null}

			{props.isEditing ? (
				<div className="control has-icons-right">
					<DatePicker
						selected={props.value}
						onChange={date => props.onChange(date)}
						dateFormat={props.dateFormat ? props.DateFormat : 'DD MMM YYYY'}
						className={props.customClassName ? props.customClassName : 'input float-left'}
						disabled={!props.isEditing}
					/>
					{props.showIcon ? (
						<span className="icon is-small is-right">
							<FontAwesomeIcon icon={faCalendarDays} />
						</span>
					) : null}
				</div>
			) : (
				<div>{props.children}</div>
			)}
		</>
	);
};
