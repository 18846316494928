import React, { Component } from 'react';

import NumberColor from '../../../Components/NumberColor';

export default class SummaryPricing extends Component {
	render() {
		const { program } = this.props;
		return (
			<div className="row">
				<div className="columns">
					<div className="column is-4">
						<div className="box" style={{ textAlign: 'right' }}>
							<span style={{ fontSize: 14 }}>ยอดเรียกเก็บทั้งหมด (บาท)</span>
							<p className="title is-4">
								<NumberColor number={program.price} isExpense={false} />
							</p>
						</div>
					</div>

					<div className="column is-4">
						<div className="box" style={{ textAlign: 'right' }}>
							รับชำระมาแล้ว (บาท)
							<p className="title is-4">
								<NumberColor number={program.paid} isExpense={false} />
							</p>
						</div>
					</div>

					<div className="column is-4">
						<div className="box" style={{ textAlign: 'right' }}>
							ค้างจ่าย (บาท)
							<p className="title is-4">
								<NumberColor number={program.price - program.paid} isExpense={true} />
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
