import React, { Component } from 'react';

export default class TextArea extends Component {
	constructor(props) {
		super(props);

		var value = props.value;
		if (value === null) {
			value = '';
		}
		this.state = {
			value: value,
			isInitialize: true,
		};
	}

	componentWillReceiveProps = nextProps => {
		var value = nextProps.value;
		if (value === null) {
			value = '';
		}
		this.setState({ value: value });
	};

	render() {
		var mandatory;
		var inputAlert;
		if (this.props.isMandatory) {
			mandatory = <span style={{ color: 'red' }}> *</span>;
		}

		if ((!this.state.isInitialize && !this.state.value && this.props.isMandatory) || this.props.error) {
			inputAlert = 'is-danger';
		}

		var disabled;
		if (this.props.disabled) {
			disabled = 'disabled';
		}

		var label = (
			<h5 className="subtitle is-3" style={{ color: 'black', marginBottom: '5px' }}>
				{this.props.title}
				{mandatory}
			</h5>
		);
		if (this.props.labelSize && this.props.labelSize.toLowerCase() === 's') {
			label = (
				<label className="label" style={{ color: 'black', marginBottom: '5px' }}>
					{this.props.title}
					{mandatory}
				</label>
			);
		}

		var row = '5';
		if (this.props.row) {
			row = this.props.row;
		}

		return (
			<div>
				{label}
				<div className="control" style={{ marginBottom: '10px' }}>
					<textarea
						className={'textarea ' + inputAlert}
						type="text"
						placeholder={this.props.placeholder}
						value={this.state.value}
						onChange={event => {
							this.setState(
								{
									value: event.target.value,
									isInitialize: false,
								},
								this.props.onInputChange(this.props.keyProps, event.target.value)
							);
						}}
						disabled={disabled}
						rows={row}
					/>
				</div>
			</div>
		);
	}
}
