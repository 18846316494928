import React, { Component } from 'react';

import AuthHelper from '../Helper/AuthHelper';

export default class AuthRender extends Component {
	render() {
		if (AuthHelper.hasRight(...this.props.roles)) {
			return this.props.children;
		} else {
			return null;
		}
	}
}
