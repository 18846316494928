import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Select from 'react-select';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';
import FileManager from '../../../Components/FileManager';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';

import OpenLink from '../../../Helper/OpenLink';

export default class CompanyExpenseDaily extends Component {
	constructor(props) {
		const today = moment(new Date());
		super(props);
		this.state = {
			isLoading: true,
			category: {
				label: 'All - ทั้งหมด',
				value: 0,
			},
			categories: [],
			expenses: [],
			summary: [],
			date: new moment(new Date()),
		};

		console.log(this.state);
	}

	componentDidMount = async () => {
		if (AuthHelper.hasRight('Finance', 'Admin')) {
			let requestCategories = await APIGet(`finance/companyExpenseCategories`);

			requestCategories = await requestCategories;
			if (APIHelper.handleAPIResponse(this, requestCategories)) {
				let categories = [
					{
						label: 'All - ทั้งหมด',
						value: 0,
					},
					{
						label: 'Incentive',
						value: -1,
					},
					{
						label: 'Jointour',
						value: -2,
					},
				];
				categories = [...categories, ...ThingsToSelect(requestCategories.data)];
				console.log('category: ', categories);
				this.setState({
					categories,
				});

				await this.getExpenses();
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	getExpenses = async () => {
		this.setState({
			isLoading: false,
		});

		const { category } = this.state;
		const today = this.state.date.format('YYYY-MMM-DD');
		let url = `finance/companyExpenses/daily/${today}`;
		if (category && category.value !== 0) {
			url = `${url}/${category.value}`;
		}

		let requestExpenses = await APIGet(url);

		requestExpenses = await requestExpenses;
		if (APIHelper.handleAPIResponse(this, requestExpenses)) {
			const expenses = this.jsonToArray(requestExpenses.data);
			console.log('expenses: ', expenses);
			this.setState({
				isLoading: false,
				expenses,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	jsonToArray = json => {
		var result = json;
		if (json && isNaN(json.length)) {
			result = [];
			for (var x in json) {
				result.push(json[x]);
			}
		}

		return result;
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onDateChange = newDate => {
		this.setState({
			date: newDate,
		});
	};

	getTotalExpense = () => {
		let total = 0;
		if (this.state.expenses && this.state.expenses.length > 0) {
			this.state.expenses.forEach(expense => {
				total += this.getPrice(expense);
			});
		}

		return total;
	};

	isCompanyExpense = expense => {
		return expense.pdfCompanyExpensePaymentVouchers !== undefined;
	};

	isIncentiveExpense = expense => {
		return expense.pdfIncentiveExpensePaymentVouchers !== undefined;
	};

	isJointourExpense = expense => {
		return expense.paymentVoucher && expense.paymentVoucher.documentNumber.indexOf('JEP') !== -1;
	};

	isJointourExpenseEtc = expense => {
		return expense.paymentVoucher && expense.paymentVoucher.documentNumber.indexOf('JETC') !== -1;
	};

	isFitExpense = expense => {
		return expense.fitProgramId && !expense.supplierId;
	};

	isFitExpenseToSupplier = expense => {
		return expense.fitProgramId && expense.supplierId;
	};

	getPrice = expense => {
		return this.isJointourExpenseEtc(expense) ? expense.pricePerPiece * expense.quantity * expense.currencyRate : expense.total * expense.currencyRate;
	};

	renderPaymentMethod = expense => {
		if (expense.paymentMethod === 'Cash') {
			return 'เงินสด';
		} else if (expense.paymentMethod === 'Transfer') {
			return this.renderPaymentTransfer(expense);
		} else if (expense.paymentMethod === 'Check') {
			return 'เช็ค / ' + (expense.bank ? expense.bank.name : '') + ' / ' + expense.checkNumber;
		} else if (expense.paymentMethod === 'Credit Card') {
			return 'Credit / ' + (expense.creditCard ? expense.creditCard.name : '');
		}

		return null;
	};

	renderPaymentTransfer = expense => {
		return (
			<>
				<div>โอนเงิน</div>
				{expense.bankAccount ? <div>จากบัญชี: {expense.bankAccount.name}</div> : null}
				{expense.supplier ? <div>ไปที่: {expense.supplier.name}</div> : null}
			</>
		);
	};

	getFiles = expense => {
		if (this.isCompanyExpense(expense)) {
			return expense.files;
		} else if (this.isIncentiveExpense(expense)) {
			return expense.incentiveExpenseFiles;
		} else if (this.isJointourExpense(expense)) {
			return expense.files;
		} else if (this.isJointourExpenseEtc(expense)) {
			return expense.files;
		} else if (this.isFitExpense(expense)) {
			return expense.files;
		} else if (this.isFitExpenseToSupplier(expense)) {
			return expense.files;
		}

		return [];
	};

	printExpenseTitle = expense => {
		if (expense) {
			return expense.title;
		} else {
			return '';
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const total = this.getTotalExpense();

		return (
			<div className="container">
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h1 className="title is-2">รายจ่ายของบริษัท (สรุปรายวัน)</h1>
						</div>
						<div className="column is-6" />
					</div>
					<div className="columns row">
						<div className="column is-3">
							<label className="label">วันที่</label>
							<DatePicker selected={this.state.date} onChange={this.onDateChange} dateFormat="DD MMM YYYY" className="input float-left" />
						</div>
						<div className="column is-4">
							<label className="label">Category</label>
							<Select
								name="category"
								value={this.state.category}
								clearable={false}
								onChange={value => this.handleSelectChange('category', value)}
								options={this.state.categories}
							/>
						</div>
						<div className="column is-2" style={{ paddingTop: 44 }}>
							<button className="button is-info" onClick={this.getExpenses}>
								Filter
							</button>
						</div>
					</div>
					<div className="table-container">
						<table className="table is-hoverable is-fullwidth is-bordered">
							<thead>
								<tr className="table-header">
									<td width="10%">เวลา</td>
									<td width="10%">เอกสาร</td>
									<td width="10%">Category</td>
									<td width="25%">รายละเอียดค่าใช้จ่าย</td>
									<td width="15%">วิธีการ</td>
									<td width="20%">Files</td>
									<td width="10%">Total (THB)</td>
								</tr>
							</thead>
							<tbody>
								{this.state.expenses.map(expense => (
									<tr key={expense.id}>
										<td>
											<DateLabel date={expense.paymentDate} />
										</td>
										<td>
											{this.isCompanyExpense(expense) ? (
												<a onClick={() => OpenLink.companyPaymentVoucher(expense.pdfCompanyExpensePaymentVouchers[0].id)}>
													{expense.pdfCompanyExpensePaymentVouchers[0].documentNumber}
												</a>
											) : null}
											{this.isIncentiveExpense(expense) ? (
												<a onClick={() => OpenLink.incentivePaymentVoucher(expense.pdfIncentiveExpensePaymentVouchers[0].id)}>
													{expense.pdfIncentiveExpensePaymentVouchers[0].documentNumber}
												</a>
											) : null}
											{this.isJointourExpense(expense) ? (
												<a onClick={() => OpenLink.jointourPaymentVoucher(expense.paymentVoucher.id)}>
													{expense.paymentVoucher.documentNumber}
												</a>
											) : null}
											{this.isJointourExpenseEtc(expense) ? (
												<a onClick={() => OpenLink.jointourPaymentVoucher(expense.paymentVoucher.id)}>
													{expense.paymentVoucher.documentNumber}
												</a>
											) : null}
											{this.isFitExpense(expense) ? (
												<a onClick={() => OpenLink.fitPaymentVoucher(expense.paymentVoucher.id)}>
													{expense.paymentVoucher.documentNumber}
												</a>
											) : null}
											{this.isFitExpenseToSupplier(expense) ? (
												<a onClick={() => OpenLink.fitExpenseToSupplierPaymentVoucher(expense.paymentVoucher.id)}>
													{expense.paymentVoucher.documentNumber}
												</a>
											) : null}
										</td>
										<td>
											{this.isCompanyExpense(expense) ? expense.companyExpenseCategory.name : null}
											{this.isIncentiveExpense(expense) ? expense.incentiveExpenseCategory.name : null}
											{this.isJointourExpense(expense) ? `Jointour` : null}
											{this.isJointourExpenseEtc(expense) ? `Jointour (ETC)` : null}
											{this.isFitExpense(expense) ? `FIT` : null}
											{this.isFitExpenseToSupplier(expense) ? `FIT (รายจ่ายคู่ค้า)` : null}
										</td>
										<td>
											{this.isCompanyExpense(expense) ? this.printExpenseTitle(expense) : null}
											{this.isIncentiveExpense(expense)
												? `${this.printExpenseTitle(expense)} (${this.printExpenseTitle(expense.incentiveProgram)})`
												: null}
											{this.isJointourExpense(expense)
												? `${this.printExpenseTitle(expense)} (${this.printExpenseTitle(expense.program)})`
												: null}
											{this.isJointourExpenseEtc(expense)
												? `${this.printExpenseTitle(expense)} (${this.printExpenseTitle(expense.program)}) `
												: null}
											{this.isJointourExpenseEtc(expense) && expense.companyExpenseSupplier
												? ` / ${expense.companyExpenseSupplier.name}`
												: null}
											{this.isFitExpense(expense)
												? `${this.printExpenseTitle(expense)} (${this.printExpenseTitle(expense.program)})`
												: null}
											{this.isFitExpenseToSupplier(expense)
												? `${this.printExpenseTitle(expense)} (${this.printExpenseTitle(expense.program)})`
												: null}
										</td>
										<td>{this.renderPaymentMethod(expense)}</td>
										<td>
											<div className="content">
												<ul>
													{this.getFiles(expense).map(file => (
														<li key={'file' + file.id} style={{ wordBreak: 'break-all' }}>
															<a target="_blank" href={file.url}>
																{file.filename}
															</a>{' '}
														</li>
													))}
												</ul>
											</div>
										</td>
										<td className="number-cell">
											<NumberColor number={this.getPrice(expense)} isExpense={true} />
										</td>
									</tr>
								))}
								<tr className="table-summary-row">
									<td colSpan={6}>Summary</td>
									<td className="number-cell">
										<NumberColor number={total} isExpense={true} />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
