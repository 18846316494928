import React, { Component } from 'react';

export default class DeleteButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasClicked: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			hasClicked: false,
		});
	}

	onButtonClicked(toConfirm) {
		this.setState({
			hasClicked: toConfirm,
		});
	}

	render() {
		var button = (
			<a
				className="button is-danger is-outlined"
				onClick={() => {
					this.onButtonClicked(true);
				}}
			>
				{this.props.label}
			</a>
		);

		if (this.state.hasClicked) {
			button = (
				<div>
					<span className="tag is-warning">{this.props.warningMessage}</span>
					<span> </span>
					<a className="button is-danger" onClick={this.props.onClick}>
						ยืนยัน
					</a>
					<span> </span>
					<a
						className="button is-primary is-outlined"
						onClick={() => {
							this.onButtonClicked(false);
						}}
					>
						{' '}
						ยกเลิก
					</a>
				</div>
			);
		}

		return <div>{button}</div>;
	}
}
