import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

import InputRow from '../../../Components/InputRow';

export default class AddModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visitDate: props.visitDate ? moment(new Date(props.visitDate)) : null,
			visitTime: props.visitTime ? props.visitTime : '',
			pricePerHead: props.pricePerHead ? props.pricePerHead : '',
		};
	}

	onChange = (key, value) => {
		this.setState({
			[key]: value,
		});
	};

	save = () => {
		this.props.save(this.props.selectedRestaurantId, this.state.visitDate, this.state.visitTime, this.state.pricePerHead);
	};

	render() {
		const { isShown } = this.props;
		if (!isShown) {
			return null;
		}
		return (
			<div className="modal is-active">
				<div className="modal-background" onClick={this.props.toggleModal} />
				<div className="modal-card" style={{ minHeight: 500 }}>
					<header className="modal-card-head">
						<p className="modal-card-title">Visit Information</p>
						<button className="delete" onClick={this.props.toggleModal} />
					</header>
					<section className="modal-card-body">
						<div className="row">
							<label className="label">วัน</label>
							<DatePicker
								selected={this.state.visitDate}
								onChange={date => this.onChange('visitDate', date)}
								dateFormat="DD MMM YYYY"
								className="input float-left"
							/>
						</div>
						<div className="row">
							<InputRow
								title="เวลา"
								labelSize="s"
								keyProps="visitTime"
								value={this.state.visitTime}
								onInputChange={this.onChange}
								isMandatory={true}
							/>
						</div>
						<div className="row">
							<InputRow
								title="ราคาต่อหัว"
								labelSize="s"
								keyProps="pricePerHead"
								value={this.state.pricePerHead}
								onInputChange={this.onChange}
								isMandatory={true}
							/>
						</div>
					</section>
					<footer className="modal-card-foot level">
						<div className="level-item level-left" />
						<div className="level-item level-right">
							<button className="button is-success" onClick={this.save}>
								Save
							</button>
							<button className="button" onClick={this.props.toggleModal}>
								Cancel
							</button>
						</div>
					</footer>
				</div>
			</div>
		);
	}
}
