import React, { Component } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';

import WholePageLoading from '../../../Components/WholePageLoading';
import NumberColor from '../../../Components/NumberColor';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import DateLabel from '../../../Components/DateLabel';
import OpenLink from '../../../Helper/OpenLink';

export default class CreditCardReport extends Component {
	constructor(props) {
		super(props);
		const query = queryString.parse(props.location.search);
		const startDate = query.startDate ? moment(new Date(query.startDate)) : moment(new Date()).subtract(14, 'd');
		const endDate = query.endDate ? moment(new Date(query.endDate)) : moment(new Date());

		this.state = {
			isLoading: true,
			startDate,
			endDate,
			summary: [],
		};
	}

	componentDidMount = async () => {
		await this.getSummary();
	};

	handleStartDateChange = newDate => {
		if (newDate <= this.state.endDate) {
			this.setState({ startDate: newDate });
		}
	};

	handleEndDateChange = newDate => {
		if (newDate >= this.state.startDate) {
			this.setState({ endDate: newDate });
		}
	};

	getSummary = async () => {
		let { startDate, endDate } = this.state;
		startDate = startDate.format('YYYY-MM-DD');
		endDate = endDate.format('YYYY-MM-DD');
		this.setState({
			isLoading: true,
		});

		const filter = {
			startDate: startDate,
			endDate: endDate,
		};

		let requestIncentive = APIGet('finance/creditCards/incentive', filter);
		let requestJointour = APIGet('finance/creditCards/jointour', filter);
		let requestFit = APIGet('finance/creditCards/fit', filter);
		requestIncentive = await requestIncentive;
		requestJointour = await requestJointour;
		requestFit = await requestFit;
		if (APIHelper.handleAPIResponse(this, requestIncentive, requestJointour, requestFit)) {
			const incentiveExpenses = requestIncentive.data;
			const jointourExpenses = requestJointour.data ? requestJointour.data.expenses : [];
			const jointourExpenseEtcs = requestJointour.data ? requestJointour.data.expenseEtcs : [];
			const fitExpenses = requestFit.data ? requestFit.data.expenses : [];
			const fitExpenseToSuppliers = requestFit.data ? requestFit.data.expenseToSuppliers : [];
			console.log('incentiveExpenses: ', incentiveExpenses);
			console.log('jointourExpenses: ', jointourExpenses);
			console.log('jointourExpenseEtcs: ', jointourExpenseEtcs);
			console.log('fitExpenses: ', fitExpenses);
			console.log('fitExpenseToSuppliers: ', fitExpenseToSuppliers);
			const summary = this.buildSummary(incentiveExpenses, jointourExpenses, jointourExpenseEtcs, fitExpenses, fitExpenseToSuppliers);

			console.log('summary: ', summary);
			this.setState({
				isLoading: false,
				summary,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}

		if (window.history.pushState) {
			const newQueryString = queryString.stringify({
				startDate,
				endDate,
			});
			var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + newQueryString;
			window.history.pushState({ path: newurl }, '', newurl);
		}
	};

	mapExpenseToArray = (expenses, type) => {
		let programKey = 'program';
		let typeName = '';
		if (type === 'incentive') {
			programKey = 'incentiveProgram';
			typeName = 'Incentive';
		} else if (type === 'jointour') {
			typeName = 'Join Tour';
		} else if (type === 'jointourEtc') {
			typeName = 'Join Tour';
		} else if (type === 'fit') {
			typeName = 'Fit';
		} else if (type === 'fitToSupplier') {
			typeName = 'Fit';
		}

		return expenses.map(expense => {
			let total = expense.total;
			if (isNaN(total)) {
				total = expense.pricePerPiece * expense.quantity;
			}
			return {
				date: expense.paymentDate,
				id: expense.id,
				key: type + expense.id,
				currency: expense.currency,
				currencyRate: expense.currencyRate,
				title: expense.title,
				program: expense[programKey].title,
				creditCard: expense.creditCard,
				total,
				type,
				typeName,
				totalInThb: total * expense.currencyRate,
				paymentVoucher: expense.paymentVoucher,
			};
		});
	};

	buildSummary = (incentiveExpenses, jointourExpenses, jointourExpenseEtcs, fitExpenses, fitExpenseToSuppliers) => {
		let result = [
			...this.mapExpenseToArray(incentiveExpenses, 'incentive'),
			...this.mapExpenseToArray(jointourExpenses, 'jointour'),
			...this.mapExpenseToArray(jointourExpenseEtcs, 'jointourEtc'),
			...this.mapExpenseToArray(fitExpenses, 'fit'),
			...this.mapExpenseToArray(fitExpenseToSuppliers, 'fitToSupplier'),
		];

		result = result.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));

		return result;
	};

	getTotal = summary => {
		let result = 0;
		summary.forEach(row => {
			result += row.totalInThb;
		});

		return result;
	};

	onClickDocument = (type, id) => {
		if (type === 'incentive') {
			OpenLink.incentivePaymentVoucher(id);
		} else if (type === 'jointour') {
			OpenLink.jointourPaymentVoucher(id);
		} else if (type === 'jointourEtc') {
			OpenLink.jointourPaymentVoucherEtc(id);
		} else if (type === 'fit') {
			OpenLink.fitPaymentVoucher(id);
		} else if (type === 'fitToSupplier') {
			OpenLink.fitExpenseToSupplierPaymentVoucher(id);
		}
	};

	render() {
		if (!AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
			return null;
		}

		const totalInThb = this.getTotal(this.state.summary);

		return (
			<div className="container">
				<div className="columns">
					<div className="column is-8">
						<h1 className="title is-2">Credit Card Report</h1>
					</div>
				</div>
				<div className="row columns">
					<div className="column is-2">
						<label className="label">Start Date</label>
						<DatePicker
							selected={this.state.startDate}
							onChange={this.handleStartDateChange}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
					<div className="column is-2">
						<label className="label">End Date</label>
						<DatePicker selected={this.state.endDate} onChange={this.handleEndDateChange} dateFormat="DD MMM YYYY" className="input float-left" />
					</div>
					<div className="column is-2 button-padding-label">
						<button className="button is-info" onClick={this.getSummary}>
							Submit
						</button>
					</div>
				</div>
				<table className="table is-hoverable is-fullwidth is-bordered">
					<thead>
						<tr className="table-header">
							<td>Payment Date</td>
							<td>Card</td>
							<td>Type</td>
							<td>Title</td>
							<td>Program</td>
							<td>Document</td>
							<td>Price</td>
						</tr>
					</thead>
					<tbody>
						{this.state.summary.map(row => (
							<tr key={row.key}>
								<td>
									<DateLabel date={row.date} />
								</td>
								<td>{row.creditCard ? row.creditCard.name : ''}</td>
								<td>{row.typeName}</td>
								<td>{row.title}</td>
								<td>{row.program}</td>
								<td>
									{row.paymentVoucher ? (
										<a onClick={() => this.onClickDocument(row.type, row.paymentVoucher.id)}>{row.paymentVoucher.documentNumber}</a>
									) : (
										''
									)}
								</td>
								<td className="number-cell">
									<NumberColor isExpense={true} number={row.totalInThb} />
								</td>
							</tr>
						))}
						<tr className="table-summary-row">
							<td colSpan={6}>Summary</td>
							<td className="number-cell">
								<NumberColor isExpense={true} number={totalInThb} />
							</td>
						</tr>
					</tbody>
				</table>
				{this.state.isLoading ? <WholePageLoading /> : null}
			</div>
		);
	}
}
