import React, { Component } from 'react';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import NumberColor from '../../../Components/NumberColor';

export default class JointourList extends Component {
	getRows = list => {
		let rows = [];

		list.forEach(row => {
			const price =
				row.priceNormal * row.quantityNormal +
				row.priceChildWithBed * row.quantityChildWithBed +
				row.priceChildNoBed * row.quantityChildNoBed +
				row.priceInfant * row.quantityInfant +
				row.priceSingle * row.quantitySingle -
				row.commission * row.quantityCommission +
				row.totalExpenseEtc;

			const salePrice = row.deposit + row.deposit2 + row.full;
			const profit = salePrice - price;
			const totalCustomer = row.quantityNormal + row.quantityChildNoBed + row.quantityChildWithBed + row.quantityInfant;
			rows.push({
				...row,
				id: row.id,
				price,
				salePrice,
				profit,
				totalCustomer,
			});
		});

		return rows;
	};

	getSummary = list => {
		let summary = {
			price: 0,
			salePrice: 0,
			profit: 0,
			totalCustomer: 0,
		};

		list.forEach(row => {
			summary.price += row.price;
			summary.salePrice += row.salePrice;
			summary.profit += row.salePrice - row.price;
			summary.totalCustomer += row.totalCustomer;
		});

		return summary;
	};
	render() {
		const { list, isLoading } = this.props;
		if (isLoading) {
			return <LoadingCenter />;
		}

		if (!list || list.length === 0) {
			return null;
		}

		const rows = this.getRows(list);
		const summary = this.getSummary(rows);
		return (
			<div className="columns row">
				<div className="column is-12">
					<h4 className="title is-4">Jointour</h4>
					<div className="row">
						<table className="table is-hoverable is-fullwidth is-bordered">
							<thead>
								<tr className="table-header">
									<td>Booking Date</td>
									<td>วันเดินทาง</td>
									<td>ลูกค้า</td>
									<td>Whole Sale</td>
									<td>Staffs</td>
									<td>ลูกค้าเดินทาง</td>
									<td>ราคาขาย</td>
									<td>ต้นทุน</td>
									<td>กำไร</td>
								</tr>
							</thead>
							<tbody>
								{rows.map(row => (
									<tr key={'jointourList' + row.id}>
										<td>
											<DateLabel date={row.created_at} />
										</td>
										<td>
											<DateRangeLabel startDate={row.startDate} endDate={row.endDate} />
										</td>
										<td>{row.customer ? row.customer.name : ''}</td>
										<td>{row.wholeSale ? row.wholeSale.name : ''}</td>
										<td>{row.staffs.map(({ staff }) => (staff ? staff.name : '')).join(', ')}</td>
										<td className="number-cell">
											<NumberColor number={row.totalCustomer} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.salePrice} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.price} isExpense={true} />
										</td>
										<td className="number-cell">
											<NumberColor number={row.profit} />
										</td>
									</tr>
								))}
								<tr className="table-summary-row">
									<td colSpan={5}>Summary</td>
									<td className="number-cell">
										<NumberColor number={summary.totalCustomer} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.salePrice} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.price} isExpense={true} />
									</td>
									<td className="number-cell">
										<NumberColor number={summary.profit} />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
