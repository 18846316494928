import React from 'react';

import Stars from './Stars';
import TextArea from '../TextArea';

export default class RestaurantEditComment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			comment: props.comment,
			course: props.course,
			star: props.star,
		};
	}

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onCommentStarClick = index => {
		this.setState({
			star: index + 1,
		});
	};

	onEditComment = () => {
		this.props.onEditComment(this.props.id, { ...this.props, ...this.state });
	};

	onCancelEditComment = () => {
		this.props.onCancelEditComment(this.props.id);
	};

	render() {
		const { comment, course, star } = this.state;

		return (
			<>
				<div>
					<TextArea title="รีวิว" labelSize="s" keyProps="comment" value={comment} onInputChange={this.onTextChange} isMandatory={false} />
				</div>
				<div className="row">
					<TextArea title="Course ของทริปนี้" labelSize="s" keyProps="course" value={course} onInputChange={this.onTextChange} isMandatory={false} />
				</div>
				<div className="columns row">
					<div className="column is-6">
						<Stars active={star} onClick={this.onCommentStarClick} />
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<button className="button is-success" onClick={this.onEditComment}>
							Edit
						</button>
						<span> </span>
						<button className="button" onClick={this.onCancelEditComment}>
							Cancel
						</button>
					</div>
				</div>
			</>
		);
	}
}
