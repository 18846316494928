import React from 'react';
import { toast } from 'react-toastify';

import LoadingCenter from '../LoadingCenter';
import Stars from './Stars';

import APIGet from '../../API/APIGet';
import APIHelper from '../../Helper/APIHelper';

export default class RestaurantHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			restaurant: null,
		};
	}
	async componentDidMount() {
		const request = await APIGet('restaurants/' + this.props.id);
		if (APIHelper.handleAPIResponse(this, request)) {
			const restaurant = request.data;
			// console.log('restaurant: ', restaurant);
			this.setState({
				isLoading: false,
				restaurant,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	}

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { restaurant } = this.state;

		if (!restaurant) {
			return null;
		}

		return (
			<div>
				<h2 className="sub-title is-3">Restaurants</h2>
				<h1 className="title is-2">{restaurant.name}</h1>
				<Stars active={restaurant.star} />
				<span style={{ fontSize: 12 }}> (จากทั้งหมด {restaurant.totalReviews ? restaurant.totalReviews : 0} รีวิว)</span>
			</div>
		);
	}
}
