import React from 'react';

import PricingRowWithTitle from '../../../Components/PricingRowWithTitle';

export default class FITExpense extends React.Component {
	onChange = (index, key, value) => {
		let expense = { ...this.props.expenses[index] };
		expense[key] = value;
		expense.total = parseFloat(expense.pricePerPiece) * parseFloat(expense.quantity);
		this.props.onChange(index, expense);
	};

	render() {
		const { expenses } = this.props;
		return (
			<div>
				<div className="row">
					<h3 className="subtitle is-4">
						<strong>
							<u>รายจ่าย</u>
						</strong>
					</h3>
				</div>
				<div className="row">
					<table className="table is-hoverable is-fullwidth">
						<thead>
							<tr>
								<td>รายการ</td>
								<td width="25%">จำนวน</td>
								<td width="25%">ราคา (THB)</td>
								<td
									width="25%"
									style={{
										textAlign: 'right',
									}}
								>
									รวม
								</td>
							</tr>
						</thead>
						<tbody>
							{expenses.map((expense, index) => (
								<PricingRowWithTitle
									key={'expense' + index}
									quantity={expense.quantity}
									pricePerPiece={expense.pricePerPiece}
									total={expense.total}
									title={expense.title}
									isExpense={true}
									onTitleChange={value => this.onChange(index, 'title', value)}
									onQuantityChange={value => this.onChange(index, 'quantity', value)}
									onPriceChange={value => this.onChange(index, 'pricePerPiece', value)}
								/>
							))}
							<tr className="clickable">
								<td colSpan={4} style={{ textAlign: 'center' }} onClick={this.props.addExpense}>
									เพิ่มรายจ่าย
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
