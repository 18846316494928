import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import List from '../Pages/FIT/List';
import ListByCustomer from '../Pages/FIT/ListByCustomer';
import AddEditFIT from '../Pages/FIT/AddEditFIT';
import FITDetail from '../Pages/FIT/Detail';
import FITExpenseDetail from '../Pages/FIT/ExpenseDetail';
import FITExpenseToSupplierDetail from '../Pages/FIT/ExpenseToSupplierDetail';
import EditFITExpenseToSupplier from '../Pages/FIT/EditExpenseToSupplier';
import AddEditIncome from '../Pages/FIT/AddEditIncome';
import IncomeDetail from '../Pages/FIT/IncomeDetail';
import AddEditExpense from '../Pages/FIT/AddEditExpense';

import AppConfig from '../AppConfig';

export default class JoinTourRoutes extends Component {
	render() {
		return (
			<span>
				<Route exact path={AppConfig.appPath + '/programs/fit'} component={List} />
				<Route exact path={AppConfig.appPath + '/data/fit/customers/:customerId/programs'} component={ListByCustomer} />
				<Route exact path={AppConfig.appPath + '/programs/newfit'} component={AddEditFIT} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId'} component={FITDetail} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/newincome'} component={AddEditIncome} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/newexpense'} component={AddEditExpense} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/incomes/:incomeId'} component={IncomeDetail} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/incomes/:incomeId/edit'} component={AddEditIncome} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/edit'} component={AddEditFIT} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/expenses/:expenseId'} component={FITExpenseDetail} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/expenses/:expenseId/edit'} component={AddEditExpense} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/expensetosuppliers/:expenseId'} component={FITExpenseToSupplierDetail} />
				<Route exact path={AppConfig.appPath + '/programs/fit/:programId/expensetosuppliers/:expenseId/edit'} component={EditFITExpenseToSupplier} />
			</span>
		);
	}
}
