import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputRow from '../../../Components/InputRow';

import AppColor from '../../../AppColor';

export default class FITItemTicket extends React.Component {
	onAirlineChange = value => {
		this.props.onChange('selectedAirline', value);
	};

	removeRow = index => {
		this.props.onRemove(index);
	};

	renderRow = () => {
		const { passengers } = this.props;
		if (!passengers) return null;
		return passengers.map((passenger, index) => (
			<div className="row columns" key={'fitTIcketPassenger' + index}>
				<div className="column is-4">
					<InputRow
						title={'ชื่อผู้โดยสาร ' + (index + 1)}
						labelSize="s"
						placeholder="ชื่อผู้โดยสาร"
						keyProps="name"
						value={passenger.name}
						onInputChange={(key, value) => this.props.onPassengerChange(index, key, value)}
						disabled={false}
					/>
				</div>
				<div className="column is-3">
					<InputRow
						title={'หมายเลขตั๋ว ' + (index + 1)}
						labelSize="s"
						placeholder="หมายเลขตั๋ว"
						keyProps="ticketNumber"
						value={passenger.ticketNumber}
						onInputChange={(key, value) => this.props.onPassengerChange(index, key, value)}
						disabled={false}
					/>
				</div>
				<div className="column is-2">
					<InputRow
						title="ราคาขาย (THB)"
						labelSize="s"
						placeholder="ราคาขาย (THB)"
						keyProps="salePrice"
						value={passenger.salePrice}
						onInputChange={(key, value) => this.props.onPassengerChange(index, key, value)}
						disabled={false}
					/>
				</div>
				<div className="column is-2">
					<InputRow
						title="ต้นทุน (THB)"
						labelSize="s"
						placeholder="ต้นทุน (THB)"
						keyProps="price"
						value={passenger.price}
						onInputChange={(key, value) => this.props.onPassengerChange(index, key, value)}
						disabled={false}
					/>
				</div>
				<div className="column is-1" style={{ paddingTop: 50, textAlign: 'center' }}>
					<a onClick={() => this.removeRow(index)}>
						<h4 className="title is-4" style={{ color: 'red' }}>
							<FontAwesomeIcon icon="minus-circle" />
						</h4>
					</a>
				</div>
			</div>
		));
	};

	render() {
		const { route, selectedAirline, flightNumber, pnr, airlines } = this.props;
		return (
			<div className="row">
				<div className="row columns" style={{ backgroundColor: '#eeeeee' }}>
					<div className="column is-3">
						<label className="label">Airline</label>
						<Select name="fitType" value={selectedAirline} clearable={false} onChange={this.onAirlineChange} options={airlines} />
					</div>
					<div className="column is-3">
						<InputRow
							title="Route"
							labelSize="s"
							placeholder="Route"
							keyProps="route"
							value={route}
							onInputChange={this.props.onChange}
							disabled={false}
						/>
					</div>
					<div className="column is-3">
						<InputRow
							title="Flight Number"
							labelSize="s"
							placeholder="Flight Number"
							keyProps="flightNumber"
							value={flightNumber}
							onInputChange={this.props.onChange}
							disabled={false}
						/>
					</div>
					<div className="column is-3">
						<InputRow title="PNR" labelSize="s" placeholder="PNR" keyProps="pnr" value={pnr} onInputChange={this.props.onChange} disabled={false} />
					</div>
				</div>
				{this.renderRow()}
				<div
					className="row box clickable"
					style={{ backgroundColor: AppColor.bulma.isPrimary, textAlign: 'center', padding: 8, color: 'white' }}
					onClick={this.props.onAdd}
				>
					เพิ่มรายการย่อย
				</div>
			</div>
		);
	}
}
