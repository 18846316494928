import DEFAULT_ALL from './DefaultAll';

const ALLPROGRAMTYPES = [
	DEFAULT_ALL,
	{
		value: 'JOINTOUR',
		label: 'JOINTOUR',
	},
	{
		value: 'INCENTIVE',
		label: 'INCENTIVE',
	},
	{
		value: 'FIT',
		label: 'FIT',
	},
	{
		value: 'COLLECTIVE',
		label: 'COLLECTIVE',
	},
];

const PROGRAMTYPES = [...ALLPROGRAMTYPES];
PROGRAMTYPES.shift();
export const REQUIRED_PROGRAMTYPES = [...PROGRAMTYPES];
export default ALLPROGRAMTYPES;
