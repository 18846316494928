import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import FileManager from '../../../Components/FileManager';
import DeleteButton from '../../../Components/DeleteButton';
import AuthRender from '../../../Components/AuthRender';

import APIGet from '../../../API/APIGet';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

export default class FitExpenseDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			income: null,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { programId, incomeId } = this.props.match.params;
			let requestProgram = APIGet('fit/programs/' + programId);
			let requestExpense = APIGet('fit/programs/' + programId + '/incomes/' + incomeId);
			requestProgram = await requestProgram;
			requestExpense = await requestExpense;
			if (APIHelper.handleAPIResponse(this, requestProgram, requestExpense)) {
				const program = requestProgram.data;
				const income = requestExpense.data;
				console.log('program: ', program);
				console.log('income: ', income);
				this.setState({
					isLoading: false,
					program,
					income,
				});
			} else if (requestProgram.status === 404) {
				toast.warning('ไม่พบโปรแกรมดังกล่าว');
			} else {
				toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	onUploadSuccess = income => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.setState({
			income,
		});
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = income => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.setState({
			income,
		});
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	delete = async () => {
		const { programId, incomeId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = await APIDelete('fit/programs/' + programId + '/incomes/' + incomeId);
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/programs/fit/' + programId);
		} else if (requestDelete.status === 400) {
			toast.warn('คุณไม่มีสิทธิ์ลบรายการนี้');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program, income } = this.state;
		const { programId, incomeId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/fit/' + programId} />
				<div className="section">
					<div className="columns">
						<div className="column is-8">
							<h3 className="subtitle is-5">
								รายรับ {income.title}
								<span> </span>
								<Link to={AppConfig.appPath + '/programs/fit/' + programId + '/incomes/' + incomeId + '/edit'} className="button is-info">
									แก้ไข
								</Link>
							</h3>
						</div>
						<div className="column is-4" style={{ textAlign: 'right' }}>
							<AuthRender roles={['Admin', 'Finance']}>
								<DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />
							</AuthRender>
						</div>
					</div>
					<div className="columns">
						<div className="column is-6">
							<div>
								ราคา:{' '}
								<strong>
									<NumberColor isExpense={false} number={income.total} />
								</strong>
							</div>
							<div>
								วันรับชำระ:{' '}
								<strong>
									<DateLabel date={income.paymentDate} />
								</strong>
							</div>
							<div>
								สถานะ:
								{!income.financeStaff ? (
									<span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span>
								) : (
									<span className="tag is-success">บัญชีตรวจสอบแล้ว ({income.financeStaff.name})</span>
								)}
							</div>
							<div>
								พนักงานบัญชี: <strong>{income.financeStaff && income.financeStaff.name}</strong>
							</div>
							<hr />
							<div>
								วันที่:{' '}
								<strong>
									<DateLabel date={income.paymentDate} />
								</strong>
							</div>
							<div>
								วิธีการ: <strong>{income.paymentMethod}</strong>
							</div>
							{income.paymentMethod === 'Transfer' ? (
								<div>
									บัญชี: <strong>{income.bankAccount.name}</strong>
								</div>
							) : null}
							{income.paymentMethod === 'Check' ? (
								<div>
									ธนาคาร: <strong>{income.bank.name}</strong>
									<br />
									เช็คเลขที่: <strong>{income.checkNumber}</strong>
								</div>
							) : null}
							{income.paymentMethod === 'Credit Card' ? (
								<div>
									บัตร: <strong>{income.creditCard.name}</strong>
								</div>
							) : null}
						</div>
						<div className="column is-6">
							<div className="content">
								<strong>เอกสาร:</strong>
								<ul>
									{income.receipts &&
										income.receipts.map(doc => (
											<li key={'paymentVouchers' + doc.id}>
												<a onClick={() => OpenLink.fitReceipt(doc.id)} target="_blank">
													{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
												</a>
												{doc.deleted_at && (
													<label className="tag is-warning">
														Inactive - <DateLabel date={doc.deleted_at} />
														{}
													</label>
												)}
											</li>
										))}
								</ul>
							</div>
							<hr />
							<div className="content">
								<FileManager
									title="Files"
									files={income.files}
									api={'fit/programs/' + programId + '/incomes/' + incomeId + '/files'}
									onUploadSuccess={this.onUploadSuccess}
									onUploadFail={this.onUploadFail}
									onDeleteSuccess={this.onDeleteSuccess}
									onDeleteFail={this.onDeleteFail}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
