import React, { useState, useEffect, useRef } from 'react';
import InputRows from '../../../../Components/InputRow';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import { Remark } from './Remark';

export const SummaryTab = ({ state, onChange, onRemarkChange }) => {
	const { isEditing, selectedTab, incentiveSubPlans, summary } = state;

	useEffect(() => {}, []);

	const Row = ({ isBold, color, fontSize, name, data, isInput, isDiscountPercent, keyProps, onInputChange, isSingle }) => {
		if (keyProps === 'salePrice') console.log('input data', data);
		return (
			<tr>
				<td
					style={{
						fontWeight: isBold ? 'bold' : 'normal',
						color: color ? color : '#363636',
						fontSize: fontSize ? fontSize : '1rem',
					}}
				>
					{name}
				</td>
				{data && data.length > 0
					? data.map((d, i) =>
							isInput ? (
								<td key={`${name}-${i}`}>
									<RowDataInput
										isBold={isBold}
										color={color}
										fontSize={fontSize}
										adult={d.adult}
										child={d.child}
										index={i}
										keyProps={keyProps}
										onInputChange={objAdultChild => {
											data[i] = objAdultChild;
											onInputChange(data);
										}}
									/>
								</td>
							) : isDiscountPercent ? (
								<td key={`${name}-${i}`}>
									<RowDiscountPercent discountPercent={d.discountChildInPercent} discountPrice={d.discountChildInPrice} />
								</td>
							) : isSingle ? (
								<td key={`${name}-${i}`}>
									<RowDataSingle value={d} isBold={isBold} color={color} fontSize={fontSize} />
								</td>
							) : (
								<td key={`${name}-${i}`}>
									<RowData isBold={isBold} color={color} fontSize={fontSize} adult={d.adult} child={d.child} />
								</td>
							)
					  )
					: null}
			</tr>
		);
	};

	const RowData = ({ isBold, color, fontSize, adult, child }) => {
		return (
			<div className="columns">
				<div className="column is-2" />
				<p
					className="column is-4"
					style={{
						textAlign: 'right',
						fontWeight: isBold ? 'bold' : 'normal',
						color: color ? color : '#363636',
						fontSize: fontSize ? fontSize : '1rem',
					}}
				>
					{adult ? adult.toLocaleString() : 0.0}
				</p>
				<p className="column is-1" style={{ textAlign: 'center' }}>
					|
				</p>
				<p
					className="column is-4"
					style={{
						textAlign: 'left',
						fontWeight: isBold ? 'bold' : 'normal',
						color: color ? color : '#363636',
						fontSize: fontSize ? fontSize : '1rem',
					}}
				>
					{child ? child.toLocaleString() : 0.0}
				</p>
				<div className="column is-1" />
			</div>
		);
	};

	const RowDataInput = ({ isBold, color, fontSize, adult, child, index, keyProps, onInputChange }) => {
		return (
			<td key={`${keyProps}-${index}`}>
				<div className="columns">
					<div className="column is-1" />
					{isEditing ? (
						<div className="column is-4">
							<InputRows
								// key={`${keyProps}-adult-${index}`}
								hideLabel={true}
								value={adult}
								onInputChange={(_, text) => {
									console.log(adult, text);
									onInputChange(text);
									// onInputChange({ adult: parseFloat(text), child });
									// clearTimeout(timerId.current);
									// timerId.current = setTimeout(() => {
									// 	const sub = incentiveSubPlans[index];
									// 	// console.log(keyProps, text, keyProps);
									// 	sub[`${keyProps}Adult`] = text;
									// 	onChange(sub);
									// }, 2000);
								}}
								type={'number'}
							/>
						</div>
					) : (
						<p
							className="column is-4"
							style={{
								textAlign: 'right',
								fontWeight: isBold ? 'bold' : 'normal',
								color: color ? color : '#363636',
								fontSize: fontSize ? fontSize : '1rem',
							}}
						>
							{adult ? adult.toLocaleString() : 0.0}
						</p>
					)}
					<p className="column is-1" style={{ textAlign: 'center' }}>
						|
					</p>
					{isEditing ? (
						<div className="column is-4">
							<InputRows hideLabel={true} value={child} onInputChange={(_, text) => {}} type={'number'} />
						</div>
					) : (
						<p
							className="column is-4"
							style={{
								textAlign: 'left',
								fontWeight: isBold ? 'bold' : 'normal',
								color: color ? color : '#363636',
								fontSize: fontSize ? fontSize : '1rem',
							}}
						>
							{child ? child.toLocaleString() : 0.0}
						</p>
					)}
					<div className="column is-1" />
				</div>
			</td>
		);
	};

	const RowDiscountPercent = ({ discountPercent, discountPrice, index }) => {
		return (
			<div className="columns" style={{ justifyContent: 'center', alignItems: 'center' }}>
				{isEditing ? (
					<div className="column is-2">
						<InputRows hideLabel={true} value={discountPercent} onInputChange={(_, text) => {}} type={'number'} />
					</div>
				) : (
					<p className="column is-2 " style={{ textAlign: 'right' }}>
						{discountPercent}
					</p>
				)}
				<p className="column is-1" style={{ textAlign: 'center' }}>
					%=
				</p>
				<p className="column is-5" style={{ textAlign: 'left', paddingLeft: 20 }}>
					{discountPrice ? discountPrice.toLocaleString() : 0.0}
				</p>
			</div>
		);
	};

	const RowDataSingle = ({ value, isBold, color, fontSize }) => {
		return (
			<div className="columns">
				<div className="column is-4" />
				<p
					className="column is-4"
					style={{
						textAlign: 'center',
						fontWeight: isBold ? 'bold' : 'normal',
						color: color ? color : '#363636',
						fontSize: fontSize ? fontSize : '1rem',
					}}
				>
					{value ? value.toLocaleString() : 0}
				</p>
				<div className="column is-4" />
			</div>
		);
	};

	return (
		<div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 50, display: selectedTab === 'summary' ? 'block' : 'none' }}>
			<Remark state={state} onChange={onRemarkChange} />
			<p className="" style={{ backgroundColor: 'black', fontSize: 25, fontWeight: 'bold', color: 'white', padding: 5 }}>
				สรุป
			</p>
			<div style={{ height: '30px' }} />
			<div className="table-container is-scrollable">
				<table className="table">
					<thead>
						<tr>
							<th style={{ minWidth: '200px' }} />
							{incentiveSubPlans && incentiveSubPlans.length > 0
								? incentiveSubPlans.map((plan, i) => (
										<th key={`subPlan-${i}`} style={{ minWidth: '300px', backgroundColor: '#676767' }}>
											<p>{plan.name}</p>
											<RowData color={'white'} adult={'ผู้ใหญ่'} child={'เด็ก'} />
										</th>
								  ))
								: null}
						</tr>
					</thead>
					<tbody>
						<Row name="ตั๋ว" data={summary.tickets} />
						<Row name="Land Arrangement" data={summary.landAssessments} />
						<Row name="VISA" data={summary.visas} />
						<Row name="หัวหน้าทัวร์" data={summary.tourLeaders} />
						<Row name="อื่นๆ" data={summary.others} />
						<Row name="Commission" data={summary.commissions} />
						<Row name="รวมค่าใช้จ่าย" isBold={true} fontSize={18} data={summary.totals} />
						{/* <Row name="หักส่วนลดเด็ก" isDiscountPercent={true} data={summary.childDiscounts} /> */}
						<tr>
							<td
								style={{
									color: '#363636',
								}}
							>
								หักส่วนลดเด็ก (%)
							</td>
							{incentiveSubPlans && incentiveSubPlans.length > 0
								? incentiveSubPlans.map((plan, i) => (
										// TODO: don't know why use component update data doesn't work
										// for investigate, all components are updated then it will lost focus
										// when changing data in input
										<td key={`child-discount-${i}`}>
											<div className="columns" style={{ justifyContent: 'center', alignItems: 'center' }}>
												<div className="column is-1" />
												{isEditing ? (
													<>
														<div className="column is-3">
															<InputRows
																hideLabel={true}
																value={plan.discountChildPercentage}
																onInputChange={(_, text) => {
																	plan.discountChildPercentage = text;
																	onChange(plan);
																}}
																type={'number'}
															/>
														</div>
														<div className="column is-1">%</div>
													</>
												) : (
													<p className="column is-3 " style={{ textAlign: 'right' }}>
														{plan.discountChildPercentage}%
													</p>
												)}
												<p className="column is-1" style={{ textAlign: 'center' }}>
													=
												</p>
												<p className="column is-4" style={{ textAlign: 'left', paddingLeft: 20 }}>
													{summary.childDiscounts[i].discountChildInPrice.toLocaleString()}
												</p>
											</div>
										</td>
								  ))
								: null}
						</tr>
						<tr>
							<td
								style={{
									color: '#363636',
								}}
							>
								กำไรที่คาดหวัง (%)
							</td>
							{incentiveSubPlans && incentiveSubPlans.length > 0
								? incentiveSubPlans.map((plan, i) => (
										// TODO: don't know why use component update data doesn't work
										// for investigate, all components are updated then it will lost focus
										// when changing data in input
										<td key={`profit-${i}`}>
											<div className="columns" style={{ justifyContent: 'center', alignItems: 'center' }}>
												<div className="column is-2" />
												{isEditing ? (
													<>
														<div className="column is-3">
															<InputRows
																hideLabel={true}
																value={plan.profitAdult}
																onInputChange={(_, text) => {
																	plan.profitAdult = text;
																	onChange(plan);
																}}
																type={'number'}
															/>
														</div>
														<div className="column is-1">%</div>
													</>
												) : (
													<p
														className="column is-4"
														style={{
															textAlign: 'right',
															fontWeight: 'normal',
															color: '#363636',
															fontSize: '1rem',
														}}
													>
														{plan.profitAdult ? plan.profitAdult.toLocaleString() : 0.0}%
													</p>
												)}
												<p className="column is-1" style={{ textAlign: 'center' }}>
													|
												</p>
												{isEditing ? (
													<>
														<div className="column is-3">
															<InputRows
																hideLabel={true}
																value={plan.profitChild}
																onInputChange={(_, text) => {
																	plan.profitChild = text;
																	onChange(plan);
																}}
																type={'number'}
															/>
														</div>
														<div className="column is-1">%</div>
													</>
												) : (
													<p
														className="column is-4"
														style={{
															textAlign: 'left',
															fontWeight: 'normal',
															color: '#363636',
															fontSize: '1rem',
														}}
													>
														{plan.profitChild ? plan.profitChild.toLocaleString() : 0.0}%
													</p>
												)}
												<div className="column is-1" />
											</div>
										</td>
								  ))
								: null}
						</tr>
						<Row name="กำไรที่คาดหวัง" isInput={false} data={summary.expectProfits} />
						<Row name="รวม" data={summary.grandTotals} />
						{/* <Row
							name="ราคาขาย"
							isInput={true}
							isBold={true}
							fontSize={18}
							data={salePrices}
							keyProps={'salePrice'}
							onInputChange={data => {
								console.log('setState', data);
								setSalePrices([...data]);
							}}
						/> */}
						<tr>
							<td
								style={{
									color: '#363636',
									fontWeight: 'bold',
									fontSize: 18,
								}}
							>
								ราคาขาย
							</td>
							{incentiveSubPlans && incentiveSubPlans.length > 0
								? incentiveSubPlans.map((plan, i) => (
										// TODO: don't know why use component update data doesn't work
										// for investigate, all components are updated then it will lost focus
										// when changing data in input
										<td key={`salePrice-${i}`}>
											<div className="columns">
												<div className="column is-2" />
												{isEditing ? (
													<div className="column is-4">
														<InputRows
															key={`sale-price-adult-${i}`}
															hideLabel={true}
															value={plan.salePriceAdult}
															onInputChange={(_, text) => {
																// console.log(text);
																// onInputChange(text);
																// onInputChange({ adult: parseFloat(text), child });
																// clearTimeout(timerId.current);
																// timerId.current = setTimeout(() => {
																// 	const sub = incentiveSubPlans[index];
																// 	// console.log(keyProps, text, keyProps);
																plan.salePriceAdult = text ? parseFloat(text) : 0;
																onChange(plan);
																// }, 2000);
															}}
															type={'number'}
														/>
													</div>
												) : (
													<p
														className="column is-4"
														style={{
															textAlign: 'right',
															fontWeight: 'bold',
															color: '#363636',
															fontSize: 18,
														}}
													>
														{plan.salePriceAdult ? plan.salePriceAdult.toLocaleString() : 0.0}
													</p>
												)}
												<p className="column is-1" style={{ textAlign: 'center' }}>
													|
												</p>
												{isEditing ? (
													<div className="column is-4">
														<InputRows
															hideLabel={true}
															value={plan.salePriceChild}
															onInputChange={(_, text) => {
																plan.salePriceChild = text ? parseFloat(text) : text;
																onChange(plan);
															}}
															type={'number'}
														/>
													</div>
												) : (
													<p
														className="column is-4"
														style={{
															textAlign: 'left',
															fontWeight: 'bold',
															color: '#363636',
															fontSize: 18,
														}}
													>
														{plan.salePriceChild ? plan.salePriceChild.toLocaleString() : 0.0}
													</p>
												)}
												<div className="column is-1" />
											</div>
										</td>
								  ))
								: null}
						</tr>
						<Row name={'SGL Supplement'} isSingle={true} data={summary.singleTravelerNetPrices} />
						<Row name={'ราคาขาย SGL Supplement'} isSingle={true} data={summary.singleTravelerSalePrices} isBold={true} fontSize={18} />
						<Row name="เงินเข้าทั้งหมด" isBold={true} fontSize={18} color="#209CEE" data={summary.totalIncomes} />
						<Row name="ค่าใช้จ่ายทั้งกรุ๊ป" isBold={true} fontSize={18} color="#C20000" data={summary.totalCosts} />
						<Row name="ผลต่างกำไร" isBold={true} fontSize={18} color="#00AD13" data={summary.totalMargins} />
					</tbody>
				</table>
			</div>
		</div>
	);
};
