import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import LoginForm from '../../Components/LoginForm';
import AuthHelper from '../../Helper/AuthHelper';
import AppConfig from '../../AppConfig';

export default class Login extends PureComponent {
	componentDidMount = () => {
		if (AuthHelper.isLogin()) {
			this.props.history.replace(AppConfig.appPath + '/dashboard');
		}
	};

	loginSuccess = () => {
		this.props.history.replace(AppConfig.appPath + '/dashboard');
	};

	render() {
		return (
			<div>
				<section className="section">
					<div className="container">
						<div className="columns">
							<div className="column is-3" />
							<div className="column is-6">
								<LoginForm onSuccess={this.loginSuccess} />
							</div>
							<div className="column is-3" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
