import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import { getDateRangeString } from '../../../Components/DateRangeLabel';
import AppConfig from '../../../AppConfig';

export const SelectPlan = ({ program, onUpdatePlan }) => {
	const [selectedOption, setSelectedOption] = useState(null);
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [delayTimer, setDelayTimer] = useState(null);
	const [displayPlanningSelect, setDisplayPlanningSelect] = useState(false);

	const handleInputChange = inputValue => {
		if (inputValue === '') {
			return;
		}
		// Clear any existing timer
		if (delayTimer) {
			clearTimeout(delayTimer);
		}

		// Set a new timer for 500ms
		setDelayTimer(
			setTimeout(() => {
				loadOptions(inputValue);
			}, 500)
		);
	};

	const loadOptions = async inputValue => {
		setIsLoading(true);

		// make an API request
		const body = {
			departureDate: moment(new Date()).format('YYYY-MM-DD'),
			returnDate: moment(new Date('31 Dec 3000')).format('YYYY-MM-DD'),
			name: inputValue,
		};

		let response = await APIGet('incentivePlan', body);

		if (APIHelper.handleAPIResponse(this, response)) {
			console.log('search request: ', body);
			console.log('search response', response.data);
			setOptions(
				response.data.map(plan => ({
					value: plan.id,
					label: `${plan.name} (${getDateRangeString(plan.departureDate, plan.returnDate)})`,
				}))
			);
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
		}

		setIsLoading(false);
	};

	const togglePlanningSelect = () => {
		setDisplayPlanningSelect(!displayPlanningSelect);
	};

	const savePlanning = async () => {
		if (selectedOption) {
			console.log(selectedOption);
			await onUpdatePlan(selectedOption.value);
			setDisplayPlanningSelect(false);
		}
	};

	if (!displayPlanningSelect) {
		return (
			<div className="columns">
				<div className="column is-9">
					{program.incentivePlan !== null ? (
						<h3 className="title is-4">
							{program.incentivePlan !== null && program.incentivePlan.deleted_at !== null ? (
								<>
									<span>{program.incentivePlan.name}</span>
									<span> </span>
									<span className="tag is-warning">ถูกลบออกแล้ว</span>
								</>
							) : (
								<Link to={AppConfig.appPath + '/programs/planning-incentive/' + program.incentivePlan.id} target="_blank">
									{program.incentivePlan.name}
								</Link>
							)}
						</h3>
					) : null}
				</div>
				<div className="column is-3">
					<button className="button is-info" onClick={togglePlanningSelect}>
						แก้ไข Planning
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="columns">
			<div className="column is-8">
				<Select
					name="selectPlanning"
					value={selectedOption}
					clearable={false}
					onChange={setSelectedOption}
					onInputChange={handleInputChange}
					options={options}
					isLoading={isLoading}
				/>
			</div>
			<div className="column is-4" style={{ textAlign: 'left' }}>
				<div>
					<button className="button is-info" onClick={savePlanning} disabled={isLoading || !selectedOption}>
						บันทึก
					</button>
					<span> </span>
					<button className="button" onClick={togglePlanningSelect}>
						ยกเลิก
					</button>
				</div>
			</div>
		</div>
	);
};
