import AppConfig from '../AppConfig';
import AuthHelper from './AuthHelper';

const APIHelper = (function() {
	const successStatus = 'success';
	const unauthorizedStatus = 'unauthorized';
	const errorStatus = 'error';
	const conflictStatus = 'conflict';

	const getHttpHeader = () => {
		var requestHeaders = new Headers();
		const accessToken = AuthHelper.getAccessTokenWithTokenType();
		requestHeaders.append('Authorization', accessToken);
		requestHeaders.append('Content-Type', 'application/json');
		requestHeaders.append('X-Requested-With', 'XMLHttpRequest');

		return requestHeaders;
	};

	const handleAPIResponse = (component, ...responses) => {
		const responseStatus = checkResponse(responses);
		if (isSuccess(responseStatus)) {
			return true;
		} else if (isUnauthorized(responseStatus)) {
			AuthHelper.toUnAuthorizedPage(component);
		} else {
			return false;
		}
	};

	const checkResponse = responses => {
		var hasRight = true;
		var hasSuccess = true;
		responses.forEach(data => {
			hasSuccess = hasSuccess && (data.status >= 200 && data.status < 300);
			hasRight = hasRight && data.status !== 401;
		});

		if (hasSuccess) {
			return successStatus;
		}

		if (!hasRight) {
			return unauthorizedStatus;
		}

		return errorStatus;
	};

	const isSuccess = status => {
		return status === successStatus;
	};

	const isUnauthorized = status => {
		return status === unauthorizedStatus;
	};

	const isError = status => {
		return status === errorStatus;
	};

	const isConflict = status => {
		return status === conflictStatus;
	};

	var toErrorPage = component => {
		component.props.history.push(AppConfig.appPath + '/error');
	};

	return {
		getHttpHeader,
		toErrorPage,
		handleAPIResponse,
		isConflict,
	};
})();

export default APIHelper;
