import React, { Component } from 'react';

export default class InDevelopmentTag extends Component {
	render() {
		return (
			<span className="tag is-warning">
				{this.props.text ? this.props.text : 'In Development < />'}
			</span>
		);
	}
}
