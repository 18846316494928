import React from 'react';
import moment from 'moment-timezone';

const SearchFilterContext = React.createContext(defaultSearchDate);

export const defaultSearchDate = {
	name: '',
	startDate: moment(new Date()).subtract(14, 'd'),
	endDate: moment(new Date()),
	updateSearchFilter: filter => {},
};

export default SearchFilterContext;
