import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import DeleteButton from '../../../Components/DeleteButton';
import FileManager from '../../../Components/FileManager';
import CustomerList from '../../../Components/JointourProgramAdditionalCustomer/CustomerList';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

export default class JointourCustomerDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			name: '',
			nickname: '',
			email: '',
			telephone: '',
			lineId: '',
			note: '',
			files: [],
			friends: [],
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initUpdate = true;
			if (this.isUpdate()) {
				initUpdate = await this.initUpdate();
			}

			if (initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initUpdate = async () => {
		const { customerId } = this.props.match.params;
		let requestCustomer = APIGet('jointour/customers/' + customerId);
		let requestFriends = APIGet('jointour/customers/' + customerId + '/friends');
		requestCustomer = await requestCustomer;
		requestFriends = await requestFriends;
		if (APIHelper.handleAPIResponse(this, requestCustomer, requestFriends)) {
			const customer = requestCustomer.data;
			const friends = requestFriends.data.map(customer => {
				return {
					...customer.customer,
					countTrips: customer.countTrips,
					files: customer.files,
				};
			});
			console.log('customer: ', customer);
			this.setState({
				...customer,
				friends,
			});

			return true;
		} else if (requestCustomer.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { customerId } = this.props.match.params;
		if (customerId) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		this.setState({
			isLoading: true,
		});

		const { customerId } = this.props.match.params;
		if (this.validateSaveState()) {
			let data = {
				...this.state,
			};

			let request = null;
			if (this.isUpdate()) {
				request = await APIPut('jointour/customers/' + customerId, data);
			} else {
				request = await APIPost('jointour/customers', data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขเรียบร้อยแล้ว' : 'เพิ่มเรียบร้อยแล้ว');
				const result = request.data;
				if (!this.isUpdate()) {
					this.props.history.replace(AppConfig.appPath + '/data/jointour/customers');
				}
			} else if (request.status === 409) {
				toast.warn('มีลูกค้าชื่อนี้อยู่ในระบบอยู่แล้ว');
			} else {
				toast.error('เกิดปัญหาในการเพิ่มลูกค้า');
			}
		}

		this.setState({
			isLoading: false,
		});
	};

	validateSaveState = () => {
		const name = this.state.name.trim() !== '';
		if (!name) {
			toast.warn('กรุณาระบุชื่อ');
		}

		const telephone = this.state.telephone.trim() !== '';
		if (!telephone) {
			toast.warn('กรุณาระบุเบอร์โทรศัพท์');
		}

		return name && telephone;
	};

	delete = async () => {
		const { customerId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete('jointour/customers/' + customerId);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			this.props.history.replace(AppConfig.appPath + '/data/jointour/customers');
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onUploadSuccess = customer => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.setState({
			files: customer.files,
		});
	};

	onDeleteSuccess = customer => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.setState({
			files: customer.files,
		});
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { customerId } = this.props.match.params;
		return (
			<div className="container">
				<BackButtonRouter link={`${AppConfig.appPath}/data/jointour/customers`} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">{this.isUpdate() ? 'แก้ไขลูกค้า' : 'เพิ่มลูกค้า'}</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-4">
							<InputRow
								title="ชื่อลูกค้า"
								labelSize="s"
								placeholder="ชื่อลูกค้า"
								keyProps="name"
								onInputChange={this.onTextChange}
								value={this.state.name}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="ชื่อเล่น"
								labelSize="s"
								placeholder="ชื่อเล่น"
								keyProps="nickname"
								onInputChange={this.onTextChange}
								value={this.state.nickname}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-4">
							<InputRow
								title="Line Id"
								labelSize="s"
								placeholder="Line Id"
								keyProps="lineId"
								onInputChange={this.onTextChange}
								value={this.state.lineId}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-6">
							<InputRow
								title="email"
								labelSize="s"
								placeholder="email"
								keyProps="email"
								onInputChange={this.onTextChange}
								value={this.state.email}
								disabled={false}
								isMandatory={false}
							/>
						</div>
						<div className="column is-6">
							<InputRow
								title="telephone"
								labelSize="s"
								placeholder="telephone"
								keyProps="telephone"
								onInputChange={this.onTextChange}
								value={this.state.telephone}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					</div>
					<div className="columns row">
						<div className="column is-6">
							<TextArea
								title="Note"
								labelSize="s"
								keyProps="note"
								value={this.state.note}
								onInputChange={this.onTextChange}
								isMandatory={false}
							/>
							<div className="row">
								<CustomerList customers={this.state.friends} allowUpdate={false} />
							</div>
						</div>
						<div className="column is-6">
							{this.isUpdate() ? (
								<FileManager
									title="Passport"
									files={this.state.files}
									api={`jointour/customers/${customerId}/files`}
									onUploadSuccess={this.onUploadSuccess}
									onUploadFail={this.onUploadFail}
									onDeleteSuccess={this.onDeleteSuccess}
									onDeleteFail={this.onDeleteFail}
								/>
							) : null}
						</div>
					</div>
					<div className="columns row">
						<div className="column is-6">
							{this.isUpdate() ? <DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} /> : null}
						</div>
						<div className="column is-6" style={{ textAlign: 'right', marginTop: 20 }}>
							<button className="button is-success" onClick={this.save}>
								บันทึก
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
