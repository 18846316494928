import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Select from 'react-select';
import { toast } from 'react-toastify';

import LoadingCenter from './LoadingCenter';
import InputRow from './InputRow';

import APIGet from '../API/APIGet';
import APIHelper from '../Helper/APIHelper';
import ThingsToSelect from '../Helper/ThingsToSelect';
import AuthHelper from '../Helper/AuthHelper';

import CURRENCIES from '../StaticData/Currencies';
import { PAYMENT_METHODS_REQUIRED } from '../StaticData/PaymentMethods';

export default class PaymentDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			banks: [],
			bankAccounts: [],
			creditCards: [],
		};
	}

	async componentDidMount() {
		let requestBanks = APIGet('banks');
		let requestBankAccounts = APIGet('bankAccounts');
		let requestCreditCards = APIGet('creditCards');
		requestBanks = await requestBanks;
		requestBankAccounts = await requestBankAccounts;
		requestCreditCards = await requestCreditCards;
		if (APIHelper.handleAPIResponse(this, requestBanks, requestBankAccounts, requestCreditCards)) {
			const banks = ThingsToSelect(requestBanks.data);
			const bankAccounts = ThingsToSelect(requestBankAccounts.data);
			const creditCards = ThingsToSelect(requestCreditCards.data);
			this.setState({
				isLoading: false,
				banks,
				bankAccounts,
				creditCards,
			});
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}
	}

	renderTransfer = () => {
		const { paymentMethod } = this.props;
		if (!paymentMethod) {
			return null;
		}

		if (paymentMethod.value === 'Transfer') {
			return (
				<div className="columns">
					<div className="column is-3">
						<label className="label">บัญชี</label>
						<Select
							name="bankAccount"
							value={this.props.bankAccount}
							clearable={false}
							onChange={value => this.props.handleSelectChange('bankAccount', value)}
							options={this.state.bankAccounts}
						/>
					</div>
					<div className="column is-3">
						<InputRow
							title="เวลาโอน"
							labelSize="s"
							placeholder="เวลาโอน"
							keyProps="paymentTime"
							onInputChange={this.props.onTextChange}
							value={this.props.paymentTime}
							disabled={false}
							isMandatory={false}
						/>
					</div>
				</div>
			);
		}

		return null;
	};

	renderCheck = () => {
		const { paymentMethod } = this.props;
		if (!paymentMethod) {
			return null;
		}

		if (paymentMethod.value === 'Check') {
			return (
				<div className="columns">
					<div className="column is-3">
						<label className="label">ธนาคาร</label>
						<Select
							name="bank"
							value={this.props.bank}
							clearable={false}
							onChange={value => this.props.handleSelectChange('bank', value)}
							options={this.state.banks}
						/>
					</div>
					<div className="column is-9">
						<InputRow
							title="หมายเลขเช็ค"
							labelSize="s"
							placeholder="หมายเลขเช็ค"
							keyProps="checkNumber"
							onInputChange={this.props.onTextChange}
							value={this.props.checkNumber}
							disabled={false}
							isMandatory={false}
						/>
					</div>
				</div>
			);
		}

		return null;
	};

	renderCreditCard = () => {
		const { paymentMethod } = this.props;
		if (!paymentMethod) {
			return null;
		}

		if (paymentMethod.value === 'Credit Card') {
			return (
				<div className="columns">
					<div className="column is-4">
						<label className="label">Credit Cards</label>
						<Select
							name="creditCard"
							value={this.props.creditCard}
							clearable={false}
							onChange={value => this.props.handleSelectChange('creditCard', value)}
							options={this.state.creditCards}
						/>
					</div>
				</div>
			);
		}

		return null;
	};

	render() {
		const { paymentMethod } = this.props;
		if (!paymentMethod) {
			return null;
		}

		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { disableCurrency } = this.props;

		return (
			<div>
				<div className="columns row">
					<div className="column is-3">
						<label className="label">วิธีการชำระเงิน</label>
						<Select
							name="paymentMethod"
							value={this.props.paymentMethod}
							clearable={false}
							onChange={value => this.props.handleSelectChange('paymentMethod', value)}
							options={PAYMENT_METHODS_REQUIRED}
						/>
					</div>
					<div className="column is-3">
						<label className="label">วันที่ชำระ</label>
						<DatePicker
							selected={this.props.paymentDate}
							onChange={this.props.onPaymentDateChange}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
					{!disableCurrency ? (
						<div className="column is-2">
							<label className="label">สกุลเงิน</label>
							<Select
								name="currency"
								value={this.props.currency}
								clearable={false}
								onChange={value => this.props.handleSelectChange('currency', value)}
								options={CURRENCIES}
							/>
						</div>
					) : null}
					{!disableCurrency && AuthHelper.hasRight('Finance', 'Admin') ? (
						<div className="column is-4">
							<InputRow
								title={'อัตราแลกเปลี่ยนเงินบาทต่อ 1 ' + this.props.currency.value}
								labelSize="s"
								placeholder="อัตราแลกเปลี่ยนกับเงินบาท"
								keyProps="currencyRate"
								onInputChange={this.props.onTextChange}
								value={this.props.currencyRate}
								disabled={false}
								isMandatory={false}
							/>
						</div>
					) : null}
				</div>
				{this.renderTransfer()}
				{this.renderCheck()}
				{this.renderCreditCard()}
			</div>
		);
	}
}
