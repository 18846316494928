export const DEFAULT_TICKET = {
	id: null,
	name: 'inter',
	airline: null,
	currency: null,
	customerType: 'adult', //child, all
	expenses: [
		{
			id: null,
			name: 'ราคา',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'ภาษี',
			price: 0,
			quantity: 0,
		},
	],
};

export const DEFAULT_LAND_ASSESSMENT = [
	{
		id: null,
		name: 'Land expense',
		currency: null,
		customerType: 'adult', //child, all
		expenseType: 'pax', // pax avg
		expenses: [
			{
				id: null,
				name: '',
				price: 0,
				quantity: 0,
			},
		],
	},
	{
		id: null,
		name: 'Client expense',
		currency: null,
		customerType: 'adult', //child, all
		expenseType: 'pax', // pax avg
		expenses: [
			{
				id: null,
				name: '',
				price: 0,
				quantity: 0,
			},
		],
	},
	{
		id: null,
		name: 'T/L expense',
		currency: null,
		customerType: null, //child, all
		expenseType: 'avg', // pax avg
		expenses: [
			{
				id: null,
				name: '',
				price: 0,
				quantity: 0,
			},
		],
	},
	{
		id: null,
		name: 'Tip Guide & Driver',
		currency: null,
		customerType: null, //child, all
		expenseType: 'pax', // pax avg
		expenses: [
			{
				id: null,
				name: '',
				price: 0,
				quantity: 0,
			},
		],
	},
];

export const DEFAULT_VISA = {
	id: null,
	name: '',
	currency: null,
	customerType: 'adult', //child, all
	expenses: [
		{
			id: null,
			name: 'visa',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'service fee',
			price: 0,
			quantity: 0,
		},
	],
};

export const DEFAULT_TOUR_LEADER = {
	id: null,
	name: 'Expenses',
	customerType: 'adult', // only adult
	expenseType: 'avg', // pax avg
	expenses: [
		{
			id: null,
			name: 'Perdium',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'T/L Ticket',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'T/L Ticket Domestic',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'Transportation',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'Phone',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'Insurance',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'VISA',
			price: 0,
			quantity: 0,
		},
	],
};

export const DEFAULT_OTHER = {
	id: null,
	name: 'Expenses',
	customerType: 'adult', // only adult
	expenseType: 'pax', // pax avg
	expenses: [
		{
			id: null,
			name: 'Check in + ส่งกรุ๊ปเมืองนอก',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'Premium + Booklet ค่าแปล',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'ค่า SIM card',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'Insurance (Basic)',
			price: 0,
			quantity: 0,
		},
		{
			id: null,
			name: 'ค่าน้ำ',
			price: 0,
			quantity: 0,
		},
	],
};

export const DEFAULT_COMMISSION = {
	id: null,
	name: 'Expenses',
	customerType: 'adult', // only adult
	expenseType: 'pax', // pax avg
	expenses: [
		{
			id: null,
			name: '',
			price: 0,
			quantity: 0,
		},
	],
};

export const DEFAULT_SUB_PLAN_TEMPLATE = {
	id: null, // ถ้าไม่มี id ถือว่าเป็น create
	name: 'new sub plan',
	adult: 0,
	child: 0,
	tourLeader: 0,
	freeTicket: 0,
	discountChildPercentage: 0,
	profitAdult: 0,
	profitChild: 0,
	salePriceAdult: 0,
	salePriceChild: 0,
	totalProfit: 0,
	totalIncome: 0,
	totalExpense: 0,
	isSelected: false,
	tickets: [DEFAULT_TICKET], // ticket
	landAssessments: DEFAULT_LAND_ASSESSMENT, //landAssessments
	visas: [DEFAULT_VISA], //visas
	tourLeaders: [DEFAULT_TOUR_LEADER], //tourLeaders
	others: [DEFAULT_OTHER], //others
	commissions: [DEFAULT_COMMISSION], //commissions
};
