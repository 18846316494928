import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import FileManager from '../../../Components/FileManager';
import DateLabel from '../../../Components/DateLabel';
import OpenLink from '../../../Helper/OpenLink';

import CustomerDetail from './CustomerDetail';
import SummaryPricingBox from './SummaryPricingBox';
import SummaryStatus from './SummaryStatus';
import Staffs from './Staffs';

export default class FITDetail extends React.Component {
	onUploadSuccess = program => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.props.onProgramUpdated(program);
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = program => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.props.onProgramUpdated(program);
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	render() {
		const { fit, programId, staffs } = this.props;
		return (
			<div className="columns">
				<div className="column is-6">
					<CustomerDetail fit={fit} />
					<Staffs staffs={staffs} fit={fit} onProgramUpdated={this.props.onProgramUpdated} />
				</div>
				<div className="column is-6">
					<SummaryPricingBox fit={fit} />
					<SummaryStatus fit={fit} onProgramUpdated={this.props.onProgramUpdated} />
					<div className="row">
						<strong>Note: </strong>
						<p>{fit.note}</p>
					</div>
					<FileManager
						title="Files"
						files={fit.files}
						api={'fit/programs/' + programId + '/files'}
						onUploadSuccess={this.onUploadSuccess}
						onUploadFail={this.onUploadFail}
						onDeleteSuccess={this.onDeleteSuccess}
						onDeleteFail={this.onDeleteFail}
					/>
					<div className="row">
						<strong>Invoices:</strong>
						<div className="content">
							<ul>
								{fit.invoices &&
									fit.invoices.map(doc => (
										<li key={'invoice' + doc.id}>
											<a onClick={() => OpenLink.fitInvoice(doc.id)} target="_blank">
												{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
											</a>
											{doc.deleted_at && (
												<label className="tag is-warning">
													Inactive - <DateLabel date={doc.deleted_at} />
													{}
												</label>
											)}
										</li>
									))}
							</ul>
						</div>
					</div>
					<div className="row">
						<strong>Receipts:</strong>
						<div className="content">
							<ul>
								{fit.receipts &&
									fit.receipts.map(doc => (
										<li key={'receipt' + doc.id}>
											<a onClick={() => OpenLink.fitReceipt(doc.id)} target="_blank">
												{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
											</a>
											{doc.deleted_at && (
												<label className="tag is-warning">
													Inactive - <DateLabel date={doc.deleted_at} />
													{}
												</label>
											)}
										</li>
									))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
