import React, { Component } from 'react';
import moment from 'moment-timezone';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import LoadingCenter from '../../../Components/LoadingCenter';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';

import ThignsToSelect from '../../../Helper/ThingsToSelect';

import ALLPROGRAMTYPES from '../../../StaticData/ProgramTypes';
import SEARCH_BY from '../../../StaticData/SearchBy';
import DEFAULT_ALL from '../../../StaticData/DefaultAll';

export default class Programs extends Component {
	constructor(props) {
		super(props);
		const startDate = moment(new Date()).subtract(14, 'd');
		const endDate = moment(new Date());
		this.state = {
			isLoading: true,
			startDate,
			endDate,
			staff: DEFAULT_ALL,
			country: DEFAULT_ALL,
			type: DEFAULT_ALL,
			searchBy: SEARCH_BY[1],
			selectedSupplierIncentive: DEFAULT_ALL,
			selectedCustomerIncentive: DEFAULT_ALL,
			selectedSupplierJointour: DEFAULT_ALL,
			selectedCustomerJointour: DEFAULT_ALL,
			selectedSupplierFit: DEFAULT_ALL,
			selectedCustomerFit: DEFAULT_ALL,
			selectedSupplierCollective: DEFAULT_ALL,
			selectedCustomerCollective: DEFAULT_ALL,
			supplierIncentives: [],
			customerIncentives: [],
			supplierJointours: [],
			customerJointours: [],
			supplierFits: [],
			customerFits: [],
			supplierCollectives: [],
			customerCollectives: [],
			staffs: [],
			countries: [],
		};
	}

	componentDidMount = async () => {
		const suppliers = await this.getSuppliers();
		const customers = await this.getCustomers();
		const staffs = await this.getDataForSelect('staffs', true);
		const countries = await this.getDataForSelect('countries', true);

		this.setState({
			isLoading: false,
			...suppliers,
			...customers,
			staffs,
			countries,
		});
	};

	handleFilterChange = (type, value) => {
		this.setState({ [type]: value });
	};

	getSuppliers = async () => {
		let incentive = APIGet('suppliers');
		let jointour = APIGet('jointour/wholesales');
		let fit = APIGet('fit/suppliers');

		incentive = await incentive;
		jointour = await jointour;
		fit = await fit;

		if (APIHelper.handleAPIResponse(this, incentive, jointour, fit)) {
			return {
				supplierIncentives: ThignsToSelect(incentive.data, true),
				supplierJointours: ThignsToSelect(jointour.data, true),
				supplierFits: ThignsToSelect(fit.data, true),
			};
		}

		return null;
	};

	getCustomers = async () => {
		let incentive = APIGet('companies');
		let jointour = APIGet('jointour/customers');
		let fit = APIGet('fit/customers');

		incentive = await incentive;
		jointour = await jointour;
		fit = await fit;

		if (APIHelper.handleAPIResponse(this, incentive, jointour, fit)) {
			return {
				customerIncentives: ThignsToSelect(incentive.data, true),
				customerJointours: ThignsToSelect(jointour.data, true),
				customerFits: ThignsToSelect(fit.data, true),
			};
		}

		return null;
	};

	getDataForSelect = async (data, addAll) => {
		let result = await APIGet(data);
		if (APIHelper.handleAPIResponse(this, result)) {
			return ThignsToSelect(result.data, addAll);
		}

		return [];
	};

	isType = type => {
		return this.state.type.value === '' || this.state.type.value === type;
	};

	onSubmit = () => {
		let data = {
			startDate: this.state.startDate.format('YYYY-MM-DD'),
			endDate: this.state.endDate.format('YYYY-MM-DD'),
			type: this.state.type ? this.state.type.value : '',
			searchBy: this.state.searchBy ? this.state.searchBy.value : '',
			countryId: this.state.country ? this.state.country.value : null,
			staffId: this.state.staff ? this.state.staff.value : null,
			incentiveSupplierId: this.state.selectedSupplierIncentive && this.isType('INCENTIVE') ? this.state.selectedSupplierIncentive.value : null,
			incentiveCustomerId: this.state.selectedCustomerIncentive && this.isType('INCENTIVE') ? this.state.selectedCustomerIncentive.value : null,
			jointourSupplierId: this.state.selectedSupplierJointour && this.isType('JOINTOUR') ? this.state.selectedSupplierJointour.value : null,
			jointourCustomerId: this.state.selectedCustomerJointour && this.isType('JOINTOUR') ? this.state.selectedCustomerJointour.value : null,
			fitSupplierId: this.state.selectedSupplierFit && this.isType('FIT') ? this.state.selectedSupplierFit.value : null,
			fitCustomerId: this.state.selectedCustomerFit && this.isType('FIT') ? this.state.selectedCustomerFit.value : null,
			collectiveSupplierId: this.state.selectedSupplierCollective && this.isType('COLLECTIVE') ? this.state.selectedSupplierCollective.value : null,
			collectiveCustomerId: this.state.selectedCustomerCollective && this.isType('COLLECTIVE') ? this.state.selectedCustomerCollective.value : null,
		};
		this.props.onSubmit(data);
	};

	render() {
		const { isLoading } = this.state;
		if (isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<div className="columns">
					<div className="column is-2">
						<label className="label">ประเภท</label>
						<Select
							name="type"
							value={this.state.type}
							clearable={false}
							onChange={value => this.handleFilterChange('type', value)}
							options={ALLPROGRAMTYPES}
						/>
					</div>
					<div className="column is-2">
						<label className="label">Start Date</label>
						<DatePicker
							selected={this.state.startDate}
							onChange={newDate => this.handleFilterChange('startDate', newDate)}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
					<div className="column is-2">
						<label className="label">End Date</label>
						<DatePicker
							selected={this.state.endDate}
							onChange={newDate => this.handleFilterChange('endDate', newDate)}
							dateFormat="DD MMM YYYY"
							className="input float-left"
						/>
					</div>
					<div className="column is-2">
						<label className="label">ค้นหาโดย</label>
						<Select
							name="searchBy"
							value={this.state.searchBy}
							clearable={false}
							onChange={value => this.handleFilterChange('searchBy', value)}
							options={SEARCH_BY}
						/>
					</div>
					<div className="column is-2">
						<label className="label">Sales</label>
						<Select
							name="staff"
							value={this.state.staff}
							clearable={false}
							onChange={value => this.handleFilterChange('staff', value)}
							options={this.state.staffs}
						/>
					</div>
					<div className="column is-2">
						<label className="label">ประเทศ</label>
						<Select
							name="country"
							value={this.state.country}
							clearable={false}
							onChange={value => this.handleFilterChange('country', value)}
							options={this.state.countries}
						/>
					</div>
				</div>
				<div className="row columns">
					{this.isType('INCENTIVE') ? (
						<div className="column is-3">
							<label className="label">Incentive Customers</label>
							<Select
								name="selectedCustomerIncentive"
								value={this.state.selectedCustomerIncentive}
								clearable={false}
								onChange={value => this.handleFilterChange('selectedCustomerIncentive', value)}
								options={this.state.customerIncentives}
							/>
						</div>
					) : null}
					{this.isType('JOINTOUR') ? (
						<div className="column is-3">
							<label className="label">Jointour Whole Sale</label>
							<Select
								name="selectedSupplierJointour"
								value={this.state.selectedSupplierJointour}
								clearable={false}
								onChange={value => this.handleFilterChange('selectedSupplierJointour', value)}
								options={this.state.supplierJointours}
							/>
						</div>
					) : null}
					{this.isType('JOINTOUR') ? (
						<div className="column is-3">
							<label className="label">Jointour Customers</label>
							<Select
								name="selectedCustomerJointour"
								value={this.state.selectedCustomerJointour}
								clearable={false}
								onChange={value => this.handleFilterChange('selectedCustomerJointour', value)}
								options={this.state.customerJointours}
							/>
						</div>
					) : null}
				</div>
				<div className="row columns">
					{this.isType('FIT') ? (
						<div className="column is-3">
							<label className="label">FIT Supplier</label>
							<Select
								name="selectedSupplierFit"
								value={this.state.selectedSupplierFit}
								clearable={false}
								onChange={value => this.handleFilterChange('selectedSupplierFit', value)}
								options={this.state.supplierFits}
							/>
						</div>
					) : null}
					{this.isType('FIT') ? (
						<div className="column is-3">
							<label className="label">FIT Customers</label>
							<Select
								name="selectedCustomerFit"
								value={this.state.selectedCustomerFit}
								clearable={false}
								onChange={value => this.handleFilterChange('selectedCustomerFit', value)}
								options={this.state.customerFits}
							/>
						</div>
					) : null}
					{this.isType('COLLECTIVE') ? (
						<div className="column is-3">
							<label className="label">Collective</label>
							<Select
								name="selectedSupplierCollective"
								value={this.state.selectedSupplierCollective}
								clearable={false}
								onChange={value => this.handleFilterChange('selectedSupplierCollective', value)}
								options={this.state.supplierCollectives}
							/>
						</div>
					) : null}
					{this.isType('COLLECTIVE') ? (
						<div className="column is-3">
							<label className="label">Collective Customers</label>
							<Select
								name="selectedCustomerCollective"
								value={this.state.selectedCustomerCollective}
								clearable={false}
								onChange={value => this.handleFilterChange('selectedCustomerCollective', value)}
								options={this.state.customerCollectives}
							/>
						</div>
					) : null}
				</div>
				<div className="row columns">
					<div className="column is-12" style={{ textAlign: 'right' }}>
						<button className="button is-info" onClick={this.onSubmit}>
							Submit
						</button>
					</div>
				</div>
			</div>
		);
	}
}
