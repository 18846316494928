import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';

import AppConfig from '../../../AppConfig';

export default class Expense extends PureComponent {
	summary = () => {
		const { expenses } = this.props;
		let total = 0;
		expenses.forEach(expense => {
			total += expense.total;
		});

		return total;
	};

	render() {
		const { expenses, isFullyPaid } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">รายจ่ายคู่ค้า</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }} />
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped">
						<thead>
							<tr className="table-header">
								<td width="50%">รายการ</td>
								<td width="20%">วันกำหนดชำระ</td>
								<td width="15%">วันที่ชำระ</td>
								<td width="15%" style={{ textAlign: 'right' }}>
									ยอด (THB)
								</td>
							</tr>
						</thead>
						<tbody>
							{expenses.map(expense => (
								<tr key={'expenseFit' + expense.id}>
									<td>
										{isFullyPaid ? (
											<Link to={AppConfig.appPath + '/programs/fit/' + this.props.fitId + '/expensetosuppliers/' + expense.id}>
												{expense.title} ({expense.supplier ? expense.supplier.name : ''}){' '}
												{!expense.financeStaffId ? (
													<span className="tag is-warning">พนักงานบัญชียังไม่ตรวจสอบ</span>
												) : (
													<span className="tag is-success">พนักงานบัญชีตรวจสอบแล้ว</span>
												)}
											</Link>
										) : (
											<span>
												{expense.title} ({expense.supplier ? expense.supplier.name : ''})
											</span>
										)}
									</td>
									<td style={{ textAlign: 'center' }}>
										<DateLabel date={expense.dueDatePayment} />
									</td>
									<td style={{ textAlign: 'center' }}>
										<DateLabel date={expense.paymentDate} />
									</td>
									<td className="number-cell">
										<NumberColor number={expense.total} isExpense={true} />
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan={3}>Summary</td>
								<td className="number-cell">
									<NumberColor number={this.summary()} isExpense={true} /> บาท
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
