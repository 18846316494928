import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import AdminLockRender from '../../../../Helper/AdminLockRender';
import DeleteButton from '../../../../Components/DeleteButton';
import AppConfig from '../../../../AppConfig';

export const EditSave = ({ state, onEdit, onSave, onCancel, onDelete }) => {
	const { isEditing } = state;
	return (
		<div className="columns">
			<div className="column is-6">
				{isEditing ? (
					<>
						<button className="button is-success" style={{ marginRight: 10 }} onClick={onSave}>
							บันทึก
						</button>
						<button className="button is-danger is-outlined" onClick={onCancel}>
							ยกเลิก
						</button>
					</>
				) : (
					AdminLockRender(
						'',
						<button className="button is-info" onClick={onEdit}>
							แก้ไข
						</button>,
						<button className="button is-info" disabled>
							แก้ไข
						</button>
					)
				)}
				{}
			</div>
			{onDelete ? (
				<div className="column is-6" style={{ textAlign: 'right' }}>
					{AdminLockRender('', <DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={onDelete} />, null)}
				</div>
			) : (
				<div />
			)}
		</div>
	);
};
