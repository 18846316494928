import React, { Component, useMemo } from 'react';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import { EditableDateSwitch } from '../../../../Components/EditableDateSwitch';
import CURRENCIES from '../../../../StaticData/Currencies';

export const ExchangeTable = ({ state, onChange }) => {
	const { isEditing, exchangeRates } = state;

	const _onChange = (index, updatedCurrency) => {
		const copiedExchangeRates = JSON.parse(JSON.stringify(exchangeRates));
		copiedExchangeRates[index] = updatedCurrency;
		onChange(copiedExchangeRates);
	};

	const _onRemove = index => {
		const copiedExchangeRates = JSON.parse(JSON.stringify(exchangeRates));
		copiedExchangeRates.splice(index, 1);
		onChange(copiedExchangeRates);
	};

	return (
		<>
			<table className="table is-fullwidth is-striped is-bordered">
				<thead style={{ backgroundColor: '#209CEE' }}>
					<tr>
						<td width="55%" style={styles.tableHeaderStyle}>
							อัตราแลกเปลี่ยน
						</td>
						<td width="10%" style={styles.tableHeaderStyle}>
							คำนวณ
							<br />
							<span style={{ fontSize: 12 }}>บาท/สกุลเงิน</span>
						</td>
						<td width="10%" style={styles.tableHeaderStyle}>
							จริง
							<br />
							<span style={{ fontSize: 12 }}>บาท/สกุลเงิน</span>
						</td>
						<td width="20%" style={styles.tableHeaderStyle}>
							ณ วันที่
						</td>
						<td width="ถ%" style={styles.tableHeaderStyle} />
					</tr>
				</thead>
				<tbody>
					{exchangeRates && exchangeRates.length > 0 ? (
						exchangeRates.map((currency, index) => (
							<ExchangeRow
								key={`exchange-${index}`}
								index={index}
								isEditing={isEditing}
								currency={currency}
								onChange={_onChange}
								onRemove={_onRemove}
							/>
						))
					) : (
						<tr>
							<td>ไม่ระบุ</td>
							<td />
							<td />
							<td />
							<td />
						</tr>
					)}
				</tbody>
			</table>
			{isEditing ? (
				<button
					className="button is-success is-small"
					onClick={e => {
						e.preventDefault();
						onChange([...exchangeRates, ...[{ id: null, name: null, calculateRate: 1, actualRate: 1, date: moment().format('YYYY-MM-DD') }]]);
					}}
				>
					+เพิ่ม
				</button>
			) : null}
		</>
	);
};

const ExchangeRow = ({ currency, isEditing, index, onChange, onRemove }) => {
	const selectedCurrency = useMemo(() => {
		const cur = CURRENCIES.filter(c => c.value === currency.name)[0];
		return cur;
	}, [currency]);

	const _onChange = (key, value) => {
		onChange(index, {
			...currency,
			[key]: value,
		});
	};

	return (
		<tr>
			<td>
				<Select
					name="currency"
					value={selectedCurrency}
					clearable={false}
					onChange={value => {
						_onChange('name', value.value);
					}}
					options={CURRENCIES}
					isDisabled={!isEditing}
				/>
			</td>
			<td style={styles.tableRowDataStyle}>
				<EditableTextSwitch
					isEditing={isEditing}
					type={'number'}
					value={currency.calculateRate}
					onChange={text => {
						_onChange('calculateRate', text);
					}}
				>
					<p>{currency.calculateRate}</p>
				</EditableTextSwitch>
			</td>
			<td style={styles.tableRowDataStyle}>
				<EditableTextSwitch
					isEditing={isEditing}
					type={'number'}
					value={currency.actualRate}
					onChange={text => {
						_onChange('actualRate', text);
					}}
				>
					<p>{currency.actualRate}</p>
				</EditableTextSwitch>
			</td>
			<td style={styles.tableRowDataStyle}>
				<EditableDateSwitch
					isEditing={isEditing}
					value={moment(new Date(currency.date))}
					onChange={date => {
						_onChange('date', date.format('YYYY-MM-DD'));
					}}
				>
					<p> {moment(currency.date).format('DD MMM YYYY')}</p>
				</EditableDateSwitch>
			</td>
			<td style={styles.tableRowDataStyle}>
				<FontAwesomeIcon
					icon={faTrashCan}
					style={{ color: isEditing ? 'red' : 'black', cursor: isEditing ? 'pointer' : 'auto' }}
					onClick={e => {
						e.preventDefault();
						if (isEditing) {
							onRemove(index);
						}
					}}
				/>
			</td>
		</tr>
	);
};

const styles = {
	tableHeaderStyle: {
		color: '#ffffff',
		textAlign: 'center',
	},
	tableRowDataStyle: {
		textAlign: 'center',
		verticalAlign: 'middle',
	},
};
