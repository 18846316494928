import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DeleteButton from '../../../Components/DeleteButton';
import ProgramHeader from '../../../Components/ProgramHeader';
import BackButtonRouter from '../../../Components/BackButtonRouter';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import AppConfig from '../../../AppConfig';

import ScheduledRestaurants from './ScheduledRestaurants';
import FindRestaurants from './FindRestaurants';

export default class ManageRestaurant extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			triggerForceUpdate: false,
		};
	}
	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.getProgram();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	getProgram = async () => {
		const { programId } = this.props.match.params;

		let requestProgram = APIGet('incentive/programs/' + programId);
		requestProgram = await requestProgram;
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			const program = requestProgram.data;
			console.log('program: ', program);
			this.setState({
				isLoading: false,
				program,
			});
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	forceUpdateSchedule = () => {
		this.setState({
			triggerForceUpdate: !this.state.triggerForceUpdate,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}
		const { program, triggerForceUpdate } = this.state;
		const { programId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/incentive/' + programId} title="INCENTIVE" />
				<ProgramHeader program={program} />
				<hr />
				<div className="columns">
					<div className="column is-4">
						<ScheduledRestaurants programId={programId} triggerForceUpdate={triggerForceUpdate} />
					</div>
					<div className="column is-8">
						<FindRestaurants programId={programId} forceUpdateSchedule={this.forceUpdateSchedule} />
					</div>
				</div>
			</div>
		);
	}
}
