import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Planning from '../Pages/Incentive/Planning';
import AppConfig from '../AppConfig';
import PlanningDetail from '../Pages/Incentive/Planning/Detail';

export default class PlanningIncentiveRoutes extends Component {
	render() {
		return (
			<span>
				<Route exact path={AppConfig.appPath + '/programs/planning-incentive'} component={Planning} />
				<Route exact path={AppConfig.appPath + '/programs/planning-incentive/:planningId'} component={PlanningDetail} />
			</span>
		);
	}
}
