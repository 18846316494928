import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import NumberColor from '../../../Components/NumberColor';
import DateLabel from '../../../Components/DateLabel';

import AppConfig from '../../../AppConfig';

export default class ExpenseEtc extends PureComponent {
	summary = () => {
		const { expenseEtcs } = this.props;
		let total = 0;
		expenseEtcs.forEach(expense => {
			total += expense.pricePerPiece * expense.quantity;
		});

		return total;
	};

	render() {
		const { expenseEtcs } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">รายจ่ายอื่นๆ</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }} />
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped">
						<thead>
							<tr>
								<td width="50%">รายการ</td>
								<td width="20%">วันกำหนดชำระ</td>
								<td width="30%" style={{ textAlign: 'right' }}>
									ยอด (THB)
								</td>
							</tr>
						</thead>
						<tbody>
							{expenseEtcs.map(expense => (
								<tr key={'expense' + expense.currency + expense.jointourExpenseCategoryId}>
									<td>
										<Link to={AppConfig.appPath + '/programs/jointour/' + this.props.programId + '/expenseEtcs/' + expense.id}>
											{expense.title}
											<span> </span>
											{expense.approvalStatus === 'Waiting' || expense.approvalStatus === 'Staff Review' ? (
												<label className="tag is-warning">{expense.approvalStatus}</label>
											) : null}
											<span> </span>
											{!expense.financeStaffId ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}
											{expense.financeStaffId && expense.files && expense.files.length > 0 ? (
												<span className="tag is-success">ทำจ่ายเรียบร้อยแล้ว</span>
											) : null}
										</Link>
									</td>
									<td>
										<DateLabel date={expense.dueDatePayment} />
									</td>
									<td className="number-cell">
										<NumberColor number={expense.pricePerPiece * expense.quantity} isExpense={true} />
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan={2}>Summary</td>
								<td className="number-cell">
									<NumberColor number={this.summary()} isExpense={true} /> บาท
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
