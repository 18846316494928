import React, { Component } from 'react';
import moment from 'moment-timezone';

export default class DateRangeLabel extends Component {
	render() {
		const dateRange = getDateRangeString(this.props.startDate, this.props.endDate);
		return <span>{dateRange}</span>;
	}
}

export const getDateRangeString = (startDt, endDt) => {
	const startDate = moment(new Date(startDt));
	const endDate = moment(new Date(endDt));
	let dateRange = null;
	if (isSameYear(startDate, endDate) && isSameMonth(startDate, endDate) && isSameDay(startDate, endDate)) {
		dateRange = startDate.format('D') + ' ' + startDate.format('MMM') + ' ' + startDate.format('YYYY');
	} else if (isSameYear(startDate, endDate) && isSameMonth(startDate, endDate)) {
		dateRange = startDate.format('D') + ' - ' + endDate.format('D') + ' ' + startDate.format('MMM') + ' ' + startDate.format('YYYY');
	} else if (isSameYear(startDate, endDate)) {
		dateRange =
			startDate.format('D') + ' ' + startDate.format('MMM') + ' - ' + endDate.format('D') + ' ' + endDate.format('MMM') + ' ' + startDate.format('YYYY');
	} else {
		dateRange =
			startDate.format('D') +
			' ' +
			startDate.format('MMM') +
			' ' +
			startDate.format('YYYY') +
			' - ' +
			endDate.format('D') +
			' ' +
			endDate.format('MMM') +
			' ' +
			endDate.format('YYYY');
	}

	console.log(dateRange);
	return dateRange;
};

const isSameDay = (startDate, endDate) => {
	return startDate.format('D') === endDate.format('D');
};

const isSameMonth = (startDate, endDate) => {
	return startDate.format('MMM') === endDate.format('MMM');
};

const isSameYear = (startDate, endDate) => {
	return startDate.format('Y') === endDate.format('Y');
};
