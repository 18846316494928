import React, { Component } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';

import APIPost from '../../../API/APIPost';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';

export default class SummaryStaffs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			staff: null,
		};
	}

	handleInputChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	addStaff = async () => {
		if (this.state.staff) {
			const { fit } = this.props;
			this.setState({
				isLoading: true,
			});

			const request = await APIPost('fit/programs/' + fit.id + '/staffs', {
				staffId: this.state.staff.value,
			});

			if (APIHelper.handleAPIResponse(this, request)) {
				const staffs = request.data;
				console.log('staffs: ', staffs);
				this.setState({
					isLoading: false,
				});

				toast.success('เพิ่ม Staff เรียบร้อยแล้ว');
				let updatedProgram = JSON.parse(JSON.stringify(fit));
				updatedProgram.staffs = staffs;
				this.props.onProgramUpdated(updatedProgram);
			} else {
				this.setState({
					isLoading: false,
				});

				toast.error('เกิดปัญหาในการเพิ่ม Staff');
			}
		} else {
			toast.warn('กรุณาเลือก Staff');
		}
	};

	removeStaff = async staffId => {
		const { fit } = this.props;
		this.setState({
			isLoading: true,
		});

		const request = await APIDelete('fit/programs/' + fit.id + '/staffs/' + staffId);
		if (APIHelper.handleAPIResponse(this, request)) {
			this.setState({
				isLoading: false,
			});

			toast.success('ลบ Staff เรียบร้อยแล้ว');
			let updatedProgram = JSON.parse(JSON.stringify(fit));
			updatedProgram.staffs = updatedProgram.staffs.filter(staff => staff.staffId !== staffId);
			this.props.onProgramUpdated(updatedProgram);
		} else {
			this.setState({
				isLoading: false,
			});

			toast.error('เกิดปัญหาในการลบ Staff');
		}
	};

	render() {
		const { fit, staffs } = this.props;
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}
		return (
			<div className="row">
				<label className="label">พนักงาน:</label>
				<div className="content">
					<ul>
						{fit.staffs.map(record => {
							const staff = record.staff;
							if (!staff) {
								return null;
							}

							return (
								<li key={'staff_' + staff.id}>
									{staff.name} ({staff.nickname} - {staff.role}){' '}
									<span
										className="clickable"
										style={{
											color: 'red',
										}}
										onClick={() => this.removeStaff(staff.id)}
									>
										(ลบ)
									</span>
								</li>
							);
						})}
					</ul>
				</div>
				<div className="columns">
					<div className="column is-9">
						<Select
							name="staff"
							value={this.state.staff}
							clearable={false}
							onChange={value => this.handleInputChange('staff', value)}
							options={staffs}
						/>
					</div>
					<div className="column is-3">
						<button className="button" onClick={this.addStaff}>
							เพิ่ม
						</button>
					</div>
				</div>
			</div>
		);
	}
}
