import React, { Component } from 'react';
import moment from 'moment-timezone';

export default class ConvertDateToSimpleFormat extends Component {
	convertDateTimeToSimpleFormat(dateInput, isDisplayTime) {
		var result = '';
		try {
			if (!isDisplayTime) {
				result = moment(dateInput).format('Do MMMM YYYY');
			} else {
				result = moment(dateInput).format('Do MMMM YYYY H:mm');
			}
		} catch (error) {
			console.log(error);
		}

		return result;
	}

	render() {
		const resultDateTime = this.convertDateTimeToSimpleFormat(
			this.props.dateTime,
			this.props.isDisplayTime
		);
		return <span>{resultDateTime}</span>;
	}
}
