import React, { PureComponent } from 'react';

import NumberColor from '../../../Components/NumberColor';

import PricingRow from '../../../Components/PricingRow';
import PricingRowWithTitle from '../../../Components/PricingRowWithTitle';

export default class Pricing extends PureComponent {
	zeroIfNan = value => (isNaN(value) ? 0 : value);

	render() {
		const {
			quantityNormal,
			quantityChildNoBed,
			quantityChildWithBed,
			quantityInfant,
			quantitySingle,
			quantityCommission,
			priceNormal,
			priceChildNoBed,
			priceChildWithBed,
			priceInfant,
			priceSingle,
			commission,
			discounts,
			incomeEtcs,
			expenseEtcs,
			totalPriceNormal,
			totalPriceChildNoBed,
			totalPriceChildWithBed,
			totalPriceInfant,
			totalPriceSingle,
			totalPriceCommission,
			totalPrice,
		} = this.props;

		return (
			<div>
				<div className="row box" style={{ backgroundColor: '#eeeeee' }}>
					<div className="columns">
						<div className="column is-4">
							<strong>
								<u>ราคา</u>
							</strong>
						</div>
						<div className="column is-8">
							<h3 className="subtitle is-3" style={{ textAlign: 'right' }}>
								<strong>
									<NumberColor number={totalPrice} />
								</strong>
							</h3>
						</div>
					</div>
					<div className="row box">
						<table className="table is-hoverable is-fullwidth">
							<thead>
								<tr>
									<td>ประเภท</td>
									<td width="25%">จำนวน</td>
									<td width="25%">ราคา (THB)</td>
									<td
										width="25%"
										style={{
											textAlign: 'right',
										}}
									>
										รวม
									</td>
								</tr>
							</thead>
							<tbody>
								<PricingRow
									title="ผู้ใหญ่"
									quantity={quantityNormal}
									price={priceNormal}
									total={totalPriceNormal}
									onQuantityChange={value => this.props.onStateChange('quantityNormal', value)}
									onPriceChange={value => this.props.onStateChange('priceNormal', value)}
								/>
								<PricingRow
									title="เด็ก(ไม่ต้องการเตียง)"
									quantity={quantityChildNoBed}
									price={priceChildNoBed}
									total={totalPriceChildNoBed}
									onQuantityChange={value => this.props.onStateChange('quantityChildNoBed', value)}
									onPriceChange={value => this.props.onStateChange('priceChildNoBed', value)}
								/>
								<PricingRow
									title="เด็ก(ต้องการเตียง)"
									quantity={quantityChildWithBed}
									price={priceChildWithBed}
									total={totalPriceChildWithBed}
									onQuantityChange={value => this.props.onStateChange('quantityChildWithBed', value)}
									onPriceChange={value => this.props.onStateChange('priceChildWithBed', value)}
								/>
								<PricingRow
									title="ทารก"
									quantity={quantityInfant}
									price={priceInfant}
									total={totalPriceInfant}
									onQuantityChange={value => this.props.onStateChange('quantityInfant', value)}
									onPriceChange={value => this.props.onStateChange('priceInfant', value)}
								/>
								<PricingRow
									title="พักเดี่ยว"
									quantity={quantitySingle}
									price={priceSingle}
									total={totalPriceSingle}
									onQuantityChange={value => this.props.onStateChange('quantitySingle', value)}
									onPriceChange={value => this.props.onStateChange('priceSingle', value)}
								/>
								<PricingRow
									title="Commission"
									quantity={quantityCommission}
									price={commission}
									total={totalPriceCommission}
									onQuantityChange={value => this.props.onStateChange('quantityCommission', value)}
									onPriceChange={value => this.props.onStateChange('commission', value)}
								/>
							</tbody>
						</table>
					</div>
					<div className="columns">
						<div className="column is-4">
							<strong>
								<u>รายรับอื่นๆ</u>
							</strong>
						</div>
					</div>
					<div className="row box">
						<table className="table is-hoverable is-fullwidth">
							<thead>
								<tr>
									<td>รายการ</td>
									<td width="25%">จำนวน</td>
									<td width="25%">ราคา (THB)</td>
									<td
										width="25%"
										style={{
											textAlign: 'right',
										}}
									>
										รวม
									</td>
								</tr>
							</thead>
							<tbody>
								{incomeEtcs.map((incomeEtc, index) => (
									<PricingRowWithTitle
										key={'incomeEtc' + index}
										title={incomeEtc.title}
										quantity={incomeEtc.quantity}
										pricePerPiece={incomeEtc.pricePerPiece}
										total={this.zeroIfNan(incomeEtc.quantity * incomeEtc.pricePerPiece)}
										isExpense={false}
										onQuantityChange={value => this.props.onStateArrayChange('incomeEtcs', index, 'quantity', value)}
										onPriceChange={value => this.props.onStateArrayChange('incomeEtcs', index, 'pricePerPiece', value)}
										onTitleChange={value => this.props.onStateArrayChange('incomeEtcs', index, 'title', value)}
									/>
								))}
								<tr className="clickable">
									<td colSpan={4} style={{ textAlign: 'center' }} onClick={this.props.addIncomeEtc}>
										เพิ่มรายรับอื่นๆ
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="columns">
						<div className="column is-4">
							<strong>
								<u>รายจ่าย</u>
							</strong>
						</div>
					</div>
					<div className="row box">
						<table className="table is-hoverable is-fullwidth">
							<thead>
								<tr>
									<td>รายการ</td>
									<td width="25%">จำนวน</td>
									<td width="25%">ราคา (THB)</td>
									<td
										width="25%"
										style={{
											textAlign: 'right',
										}}
									>
										รวม
									</td>
								</tr>
							</thead>
							<tbody>
								{expenseEtcs.map((expenseEtc, index) => (
									<PricingRowWithTitle
										key={'incomeEtc' + index}
										title={expenseEtc.title}
										quantity={expenseEtc.quantity}
										pricePerPiece={expenseEtc.pricePerPiece}
										total={this.zeroIfNan(expenseEtc.quantity * expenseEtc.pricePerPiece)}
										isExpense={true}
										onQuantityChange={value => this.props.onStateArrayChange('expenseEtcs', index, 'quantity', value)}
										onPriceChange={value => this.props.onStateArrayChange('expenseEtcs', index, 'pricePerPiece', value)}
										onTitleChange={value => this.props.onStateArrayChange('expenseEtcs', index, 'title', value)}
									/>
								))}
								<tr className="clickable">
									<td colSpan={4} style={{ textAlign: 'center' }} onClick={this.props.addExpenseEtc}>
										เพิ่มรายจ่าย
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="columns">
						<div className="column is-4">
							<strong>
								<u>ส่วนลด</u>
							</strong>
						</div>
					</div>
					<div className="row box">
						<table className="table is-hoverable is-fullwidth">
							<thead>
								<tr>
									<td>รายการ</td>
									<td width="25%">จำนวน</td>
									<td width="25%">ราคา (THB)</td>
									<td
										width="25%"
										style={{
											textAlign: 'right',
										}}
									>
										รวม
									</td>
								</tr>
							</thead>
							<tbody>
								{discounts.map((discount, index) => (
									<PricingRowWithTitle
										key={'discount' + index}
										title={discount.title}
										quantity={discount.quantity}
										pricePerPiece={discount.pricePerPiece}
										total={this.zeroIfNan(discount.quantity * discount.pricePerPiece)}
										isExpense={true}
										onQuantityChange={value => this.props.onStateArrayChange('discounts', index, 'quantity', value)}
										onPriceChange={value => this.props.onStateArrayChange('discounts', index, 'pricePerPiece', value)}
										onTitleChange={value => this.props.onStateArrayChange('discounts', index, 'title', value)}
									/>
								))}
								<tr className="clickable">
									<td colSpan={4} style={{ textAlign: 'center' }} onClick={this.props.addDiscount}>
										เพิ่มส่วนลด
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}
