import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import APIGet from '../../API/APIGet';
import APIHelper from '../../Helper/APIHelper';
import OpenLink from '../../Helper/OpenLink';

import LoadingCenter from '../LoadingCenter';
import NumberColor from '../NumberColor';
import DateRangeLabel from '../DateRangeLabel';
import DeleteTag from '../DeleteTag';
import StatusIcon from '../StatusIcon';

import AppConfig from '../../AppConfig';

export default ({ isShown, toggleModal, customerId }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [programs, setPrograms] = useState([]);
	const [summary, setSummary] = useState({});

	useEffect(() => {
		if (isShown && customerId) {
			fetchCustomer();
		}
	}, [isShown]);

	const fetchCustomer = async () => {
		setIsLoading(true);
		let requestPrograms = APIGet(`jointour/customers/${customerId}/programs`);
		let requestSummary = APIGet(`jointour/customers/${customerId}/programs/summary`);

		requestPrograms = await requestPrograms;
		requestSummary = await requestSummary;
		if (APIHelper.handleAPIResponse(this, requestPrograms, requestSummary)) {
			console.log('programs: ', requestPrograms.data);
			console.log('summary: ', requestSummary.data);
			setPrograms(requestPrograms.data);
			setSummary(requestSummary.data);
			setIsLoading(false);
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	if (!isShown) {
		return null;
	}

	return (
		<div className="modal is-active">
			<div className="modal-background" onClick={toggleModal} />
			<div className="modal-card" style={{ minHeight: 500, minWidth: '90%' }}>
				<header className="modal-card-head">
					<p className="modal-card-title">การเดินทางที่ผ่านมา</p>
					<button className="delete" onClick={toggleModal} />
				</header>
				<section className="modal-card-body">
					{isLoading ? (
						<LoadingCenter />
					) : (
						<div className="table-container row">
							<table className="table is-hoverable is-fullwidth is-striped is-bordered">
								<thead>
									<tr className="table-header">
										<td width="12%">วันเดินทาง</td>
										<td>Invoice</td>
										<td>ประเทศ</td>
										<td width="20%">โปรแกรม</td>
										<td width="20%">ลูกค้า / พนักงาน</td>
										<td>Whole Sale</td>
										<td>Seat</td>
										<td width="5%">Commission (หักส่วนลด)</td>
										<td width="5%">ราคา</td>
										<td width="10%">รับชำระมาแล้ว</td>
									</tr>
								</thead>
								<tbody>
									{programs.map(
										program =>
											program.wholeSale &&
											program.country &&
											program.airline &&
											program.customer && (
												<tr key={'program' + program.id}>
													<td>
														<DateRangeLabel startDate={program.startDate} endDate={program.endDate} />
													</td>
													<td>
														{program.invoice ? (
															<button className="button is-text" onClick={() => OpenLink.jointourInvoice(program.invoice.id)}>
																{program.invoice.documentNumber}
															</button>
														) : (
															''
														)}
													</td>
													<td style={{ textAlign: 'center' }}>{program.country.name}</td>
													<td>
														<Link to={AppConfig.appPath + '/programs/jointour/' + program.id} target="_blank">
															{program.title}{' '}
															{program.deleted_at ? (
																<DeleteTag deletedAt={program.deleted_at} />
															) : (
																<StatusIcon status={program.status} />
															)}
														</Link>
													</td>
													<td>
														{program.customer && program.customer.name}
														{program.staffs && program.staffs.length > 0 ? (
															<div>
																<b>Staffs</b>
																{program.staffs.map(staff => {
																	if (staff && staff.staff) {
																		return <div key={'staff-' + staff.staff.id}>- {staff.staff.nickname}</div>;
																	} else {
																		return null;
																	}
																})}
															</div>
														) : null}
													</td>
													<td>{program.wholeSale.shortname ? program.wholeSale.shortname : program.wholeSale.name}</td>
													<td className="number-cell">
														<NumberColor
															number={
																program.quantityNormal +
																program.quantityChildWithBed +
																program.quantityChildNoBed +
																program.quantityInfant
															}
														/>
													</td>
													<td className="number-cell">
														<NumberColor
															number={
																program.commission * program.quantityCommission +
																program.totalIncomeEtc -
																program.totalDiscount -
																program.totalExpenseEtc
															}
														/>
													</td>
													<td className="number-cell">
														<NumberColor number={program.deposit + program.deposit2 + program.full} />
													</td>
													<td className="number-cell">
														<NumberColor number={program.paidDeposit + program.paidDeposit2 + program.paidFull} />
													</td>
												</tr>
											)
									)}
									<tr className="table-summary-row">
										<td colSpan="6" style={{ textAlign: 'center' }}>
											Summary
										</td>
										<td className="number-cell">
											<NumberColor number={summary.seat} />
										</td>
										<td className="number-cell">
											<NumberColor number={summary.commission + summary.incomeEtc - summary.totalDiscount - summary.expenseEtc} />
										</td>
										<td className="number-cell">
											<NumberColor number={summary.price} />
										</td>
										<td className="number-cell">
											<NumberColor number={summary.paid} />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
				</section>
			</div>
		</div>
	);
};
