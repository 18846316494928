import React from 'react';
import OpenLink from '../../../Helper/OpenLink';
import DateLabel from '../../../Components/DateLabel';

const SummaryInvoice = ({ program }) => {
	return (
		<div className="row">
			<strong>Invoices:</strong>
			<div className="content">
				<ul>
					{program.invoices &&
						program.invoices.map(doc => (
							<li key={'quotation' + doc.id}>
								<button className="button is-text" onClick={() => OpenLink.jointourInvoice(doc.id)} target="_blank">
									{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
								</button>
								{doc.deleted_at && (
									<label className="tag is-warning">
										Inactive - <DateLabel date={doc.deleted_at} />
										{}
									</label>
								)}
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};

export default SummaryInvoice;
