import React, { Component } from 'react';
import DateRangeLabel from './DateRangeLabel';
import StatusIcon from './StatusIcon';

export default class ProgramHeader extends Component {
	render() {
		const { program } = this.props;
		return (
			<div>
				<h2 className="title is-3">
					{program.code ? program.code + ': ' : ''}
					{program.title}
				</h2>
				<h4 className="subtitle is-4">
					{program.country.name}
					{program.airline ? <span> ({program.airline.name})</span> : null}
					<span> | </span>
					<DateRangeLabel startDate={program.startDate} endDate={program.endDate} />
					{program.joinTourWholeSale ? <span> | {program.joinTourWholeSale.name}</span> : null}{' '}
					<StatusIcon status={program.status} deletedAt={program.deleted_at} />{' '}
					<span style={{ color: 'red', fontSize: 10 }}>{program.deleted_by_user_name ? program.deleted_by_user_name : null}</span>
				</h4>
			</div>
		);
	}
}
