import React, { PureComponent } from 'react';

import BackButtonRouter from '../../Components/BackButtonRouter';

import Store from '../../Helper/Store';

export default class Clear extends PureComponent {
	componentDidMount = () => {
		Store.clearaAll();
	};

	render() {
		return (
			<div className="container">
				<BackButtonRouter />
				<div className="section">
					<strong>
						Clear Local Storage Cache{' '}
						<span className="tag is-success">Done</span>
					</strong>
				</div>
			</div>
		);
	}
}
