import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import StatusIcon from '../../../Components/StatusIcon';
import DeleteTag from '../../../Components/DeleteTag';

import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';
import AppColor from '../../../AppColor';

import FITTypeHelper from '../Helpers/FITTypeHelper';

export default class FITListByCustomer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			programs: [],
			summary: {},
			customer: null,
		};
	}

	async componentDidMount() {
		await this.getPrograms();
	}

	getPrograms = async () => {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			this.setState({
				isLoading: true,
			});

			const { customerId } = this.props.match.params;
			let requestPrograms = APIGet(`fit/customers/${customerId}/programs`);
			let requestSummary = APIGet(`fit/customers/${customerId}/programs/summary`);
			let requestCustomer = APIGet('fit/customers/' + customerId);

			requestPrograms = await requestPrograms;
			requestSummary = await requestSummary;
			requestCustomer = await requestCustomer;
			if (APIHelper.handleAPIResponse(this, requestPrograms, requestSummary, requestCustomer)) {
				const programs = requestPrograms.data;
				const summary = requestSummary.data;
				const customer = requestCustomer.data;
				console.log('programs: ', programs);
				console.log('summary: ', summary);
				console.log('customer: ', customer);
				this.setState({
					isLoading: false,
					programs,
					summary,
					customer,
				});
			} else {
				toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { summary, programs, customer } = this.state;
		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h2 className="title is-2">FIT ({customer.name})</h2>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-success" to={`${AppConfig.appPath}/programs/newfit`}>
							เพิ่มรายการ
						</Link>
					</div>
				</div>
				<br />
				<div className="table-container row">
					<table className="table is-hoverable is-fullwidth is-striped is-bordered">
						<thead>
							<tr className="table-header">
								<td width="12%">วันจอง</td>
								<td>Invoice</td>
								<td>Types</td>
								<td width="20%">รายการ</td>
								<td width="20%">พนักงาน</td>
								<td width="5%">ราคา</td>
								<td width="5%">รายจ่าย</td>
								<td width="5%">กำไร</td>
								<td width="10%">รับชำระมาแล้ว</td>
							</tr>
						</thead>
						<tbody>
							{programs.map(
								program =>
									program.customer && (
										<tr key={'program' + program.id}>
											<td>
												<DateLabel date={program.created_at} />
											</td>
											<td>
												{program.invoice ? (
													<a onClick={() => OpenLink.fitInvoice(program.invoice.id)}>{program.invoice.documentNumber}</a>
												) : (
													''
												)}
											</td>
											<td>
												{program.fitItems &&
													program.fitItems.map(item => (
														<span
															className="tag"
															key={'type-' + item.id}
															style={{ marginRight: 2, backgroundColor: AppColor.fitType[item.fitType] }}
														>
															{FITTypeHelper.mapLabel(item.fitType)}
														</span>
													))}
											</td>
											<td>
												<Link to={AppConfig.appPath + '/programs/fit/' + program.id}>
													{program.title} ({program.customer && program.customer.name}){' '}
													{program.deleted_at ? <DeleteTag deletedAt={program.deleted_at} /> : <StatusIcon status={program.status} />}
												</Link>
											</td>
											<td>
												{program.staffs && program.staffs.length > 0 ? (
													<div>
														{program.staffs.map(staff => {
															if (staff && staff.staff) {
																return <div key={'staff-' + staff.staff.id}>- {staff.staff.nickname}</div>;
															} else {
																return null;
															}
														})}
													</div>
												) : null}
											</td>
											<td className="number-cell">
												<NumberColor number={program.salePrice + program.creditCharge} />
											</td>
											<td className="number-cell">
												<NumberColor number={program.expense + program.price} isExpense={true} />
											</td>
											<td className="number-cell">
												<NumberColor number={program.salePrice - program.expense - program.price} />
											</td>
											<td className="number-cell">
												<NumberColor number={program.paid} />
											</td>
										</tr>
									)
							)}
							<tr className="table-summary-row">
								<td colSpan="5" style={{ textAlign: 'center' }}>
									Summary
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalSalePrice + summary.totalCreditCharge} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalExpense + summary.totalPrice} isExpense={true} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalSalePrice - summary.totalExpense - summary.totalPrice} />
								</td>
								<td className="number-cell">
									<NumberColor number={summary.totalPaid} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
			</div>
		);
	}
}
