import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import PaymentDetail from '../../../Components/PaymentDetail';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import AuthRender from '../../../Components/AuthRender';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';

import AuthHelper from '../../../Helper/AuthHelper';
import ValidatePaymentDetail from '../../../Helper/ValidatePaymentDetail';

import { PAYMENT_METHODS_REQUIRED } from '../../../StaticData/PaymentMethods';
import AppConfig from '../../../AppConfig';

import InputValidation from './InputValidation';

export default class AddEditIncome extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			fit: null,
			paymentDate: moment(new Date()),
			paymentTime: '',
			note: '',
			paymentMethod: PAYMENT_METHODS_REQUIRED[0],
			bank: null,
			checkNumber: '',
			bankAccount: null,
			creditCard: null,
			title: '',
			total: 0,
			isFinanceReviewed: false,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Finance', 'Admin', 'Staff')) {
			let newState = await this.initCreate();
			if (this.isUpdate() && newState) {
				const updateState = await this.initUpdate(newState);
				newState = {
					...newState,
					...updateState,
				};
			}

			if (newState) {
				this.setState({
					isLoading: false,
					...newState,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initCreate = async () => {
		const { programId } = this.props.match.params;
		let requestProgram = await APIGet('fit/programs/' + programId);
		let fit;
		if (APIHelper.handleAPIResponse(this, requestProgram)) {
			fit = requestProgram.data;
			fit.total = fit.salePrice + fit.creditCharge - fit.paid;
			console.log('fit: ', fit);
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
		}

		return fit;
	};

	initUpdate = async createState => {
		const { programId, incomeId } = this.props.match.params;
		let requestTransaction = await APIGet('fit/programs/' + programId + '/incomes/' + incomeId);
		let updateState = { ...createState };
		if (APIHelper.handleAPIResponse(this, requestTransaction)) {
			const transaction = requestTransaction.data;
			let isFinanceReviewed = false;
			if (AuthHelper.getUserDetail().authRole === 'Finance') {
				if (transaction.financeStaff) {
					isFinanceReviewed = true;
				} else {
					isFinanceReviewed = false;
				}
			}
			console.log('transaction: ', transaction);
			updateState = {
				...transaction,
				paymentDate: moment(new Date(transaction.paymentDate)),
				paymentMethod: PAYMENT_METHODS_REQUIRED.filter(p => p.value === transaction.paymentMethod)[0],
				bank: transaction.bank
					? {
							value: transaction.bankId,
							label: transaction.bank.name,
					  }
					: null,
				bankAccount: transaction.bankAccount
					? {
							value: transaction.bankAccountId,
							label: transaction.bankAccount.name,
					  }
					: null,
				creditCard: transaction.creditCard
					? {
							value: transaction.creditCardId,
							label: transaction.creditCard.name,
					  }
					: null,
				isFinanceReviewed,
			};
		} else if (requestTransaction.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
		}

		return updateState;
	};

	isUpdate = () => {
		const { incomeId } = this.props.match.params;
		if (incomeId) {
			return true;
		} else {
			return false;
		}
	};

	onTextChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	onPaymentDateChange = newDate => {
		this.setState({
			paymentDate: newDate,
		});
	};

	handleSelectChange = (type, value) => {
		this.setState({
			[type]: value,
		});
	};

	save = async () => {
		const { programId, incomeId } = this.props.match.params;
		if (InputValidation(this.state, toast) && ValidatePaymentDetail(this.state, toast)) {
			let request;
			const data = {
				...this.state,
				paymentDate: this.state.paymentDate.format('YYYY-MM-DD'),
				paymentMethod: this.state.paymentMethod.value,
				bankId: this.state.bank ? this.state.bank.value : '',
				bankAccountId: this.state.bankAccount ? this.state.bankAccount.value : '',
				creditCardId: this.state.creditCard ? this.state.creditCard.value : '',
			};

			if (!this.isUpdate()) {
				request = await APIPost(`fit/programs/${programId}/incomes`, data);
			} else {
				const { programId } = this.props.match.params;
				request = await APIPut(`fit/programs/${programId}/incomes/${incomeId}`, data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายการเรียบร้อยแล้ว' : 'เพิ่มรายการร้อยแล้ว');
				let targetLink = this.isUpdate()
					? `${AppConfig.appPath}/programs/fit/${programId}/incomes/${incomeId}`
					: `${AppConfig.appPath}/programs/fit/${programId}/incomes/${request.data.id}`;
				this.props.history.push(targetLink);
			} else {
				toast.error('เกิดปัญหาในการเพิ่ม/แก้ไขรายการ');
			}
		}
	};

	toggleFinanceReviewed = () => {
		this.setState({
			isFinanceReviewed: !this.state.isFinanceReviewed,
		});
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">{this.isUpdate() ? 'แก้ไขรายการเก็บเงิน' : 'เพิ่มรายการเก็บเงิน'}</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<PaymentDetail
								paymentMethod={this.state.paymentMethod}
								paymentTime={this.state.paymentTime}
								bank={this.state.bank}
								bankAccount={this.state.bankAccount}
								creditCard={this.state.creditCard}
								checkNumber={this.state.checkNumber}
								paymentDate={this.state.paymentDate}
								handleSelectChange={this.handleSelectChange}
								onPaymentDateChange={this.onPaymentDateChange}
								onTextChange={this.onTextChange}
								disableCurrency={true}
							/>
							<div className="columns">
								<div className="column is-8">
									<InputRow
										title="รายการ"
										labelSize="s"
										placeholder="รายการ"
										keyProps="title"
										onInputChange={this.onTextChange}
										value={this.state.title}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-4" style={{ textAlign: 'right' }}>
									<InputRow
										title={'รายรับ'}
										labelSize="s"
										placeholder="รายรับ"
										keyProps="total"
										onInputChange={this.onTextChange}
										value={this.state.total}
										disabled={false}
										isMandatory={false}
									/>
								</div>
							</div>
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onTextChange}
									isMandatory={false}
								/>
							</div>
							<div style={{ textAlign: 'right', marginTop: 20 }}>
								<AuthRender roles={['Finance']}>
									<label className="checkbox">
										<input
											type="checkbox"
											value={this.state.isFinanceReviewed}
											onClick={this.toggleFinanceReviewed}
											checked={this.state.isFinanceReviewed ? 'checked' : ''}
											onChange={() => {}}
										/>
										&nbsp;ตรวจสอบโดยพนักงานบัญชีแล้ว
									</label>
								</AuthRender>
								<span> </span>
								<button className="button is-success" onClick={this.save}>
									บันทึก
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
