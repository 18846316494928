const AppColor = {
	mainTheme: '#ff9933',
	bulma: {
		isSuccess: '#23d160',
		isWarning: '#ffdd57',
		isDanger: '#ff3860',
		isLink: '#3273dc',
		isInfo: '#209cee',
		isPrimary: '#00d1b2',
	},
	fitType: {
		fitTicket: '#00dd1b',
		fitVisa: '#ff9933',
		fitHotel: '#ff88ff',
		fitCarRental: '#ff3377',
		fitInsurrance: '#ffdd57',
		fitTravelTicket: '#E6B0AA',
		fitPocketWifiSim: '#00d1b2',
		fitTranslation: '#9999ff',
	},
};

export default AppColor;
