import DEFAULT_ALL from '../StaticData/DefaultAll';

export default (things, addAll) => {
	let prepareThings;
	if (things) {
		prepareThings = things.map(thing => {
			return {
				value: thing.id,
				label: thing.name,
			};
		});

		if (addAll) {
			prepareThings = [DEFAULT_ALL, ...prepareThings];
		}
	}

	return prepareThings;
};
