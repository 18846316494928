const targetEnv = process.env.REACT_APP_ENV;
const StoreKey = {
	auth: {
		accessToken: targetEnv + 'ax',
		refreshToken: targetEnv + 'bx',
		tokenType: targetEnv + 'tx',
		userDetail: targetEnv + 'ux',
	},
	filter: {
		startDate: 'filter.startDate',
		endDate: 'filter.endDate',
		wholeSale: 'filter.wholeSale',
		country: 'filter.country',
		status: 'filter.status',
		invoice: 'filter.invoice',
		customerName: 'filter.customerName',
		customerTelephone: 'filter.customerTelephone',
		searchBy: 'searchBy',
		staffName: 'staffName',
		fitCustomer: 'filter.fitCustomer',
		fitType: 'filter.fitType',
		date: 'filter.date',
	},
	filterContext: 'filterContext',
	incomeTransaction: {
		title: 'incomeTransaction.title',
		total: 'incomeTransaction.total',
	},
};

export default StoreKey;
