import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import LoginForm from '../../Components/LoginForm';

import AppConfig from '../../AppConfig';

export default class Unauthorized extends PureComponent {
	loginSuccess = () => {
		this.props.history.replace(AppConfig.appPath + '/dashboard');
	};

	render() {
		return (
			<div>
				<section className="hero">
					<div className="hero-body">
						<div className="container">
							<h1 className="title">
								<b>UNAUTHORIZED</b>
							</h1>
							<h2 className="subtitle">
								กรุณา Login ด้วย Account
								ที่มีสิทธิ์ก่อนเข้าใช้งานหน้านี้
							</h2>
						</div>
					</div>
				</section>

				<section className="section">
					<div className="container">
						<div className="columns">
							<div className="column is-3" />
							<div className="column is-6">
								<LoginForm onSuccess={this.loginSuccess} />
							</div>
							<div className="column is-3" />
						</div>
					</div>
				</section>
			</div>
		);
	}
}
