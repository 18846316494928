import React, { Component } from 'react';
import Loading from 'react-loading';

export default class LoadingCenter extends Component {
	render() {
		return (
			<div className="loading-whole-page">
				<Loading type="bubbles" color="#cylon" delay={0} />
			</div>
		);
	}
}
