import React, { useState } from 'react';

import CustomerTravelLogModal from './CustomerTravelLogModal';
import CustomerPassportModal from './CustomerPassportModal';

export default ({ customers, onRemove, allowUpdate, onCustomerChange }) => {
	const [customer, setCustomer] = useState(undefined);
	const [isShowModal, setIsShowModal] = useState(false);
	const [isShowPassportModal, setIsShowPassportModal] = useState(false);

	const toggleModal = () => {
		setIsShowModal(!isShowModal);
	};

	const togglePassportModal = () => {
		setIsShowPassportModal(!isShowPassportModal);
	};

	const onUploadSuccess = customer => {
		onCustomerChange(customer);
		togglePassportModal();
	};
	const onDeleteSuccess = customer => {
		onCustomerChange(customer);
		togglePassportModal();
	};

	const onClickTravel = customerId => {
		let selectedCustomer = customers.filter(c => c.id == customerId)[0];
		setCustomer(selectedCustomer);
		setIsShowModal(true);
		setIsShowPassportModal(false);
	};

	const onClickPassport = customerId => {
		let selectedCustomer = customers.filter(c => c.id == customerId)[0];
		setCustomer(selectedCustomer);
		setIsShowPassportModal(true);
		setIsShowModal(false);
	};

	return (
		<>
			<b>รายชื่อผู้เดินทาง</b>
			<table className="table is-hoverable is-fullwidth is-bordered is-narrow">
				<thead className="table-header">
					<tr>
						<td>ชื่อ</td>
						<td style={{ width: '15%' }}>เคยเดินทาง</td>
						<td style={{ width: '15%' }}>Passport</td>
						{allowUpdate ? <td style={{ width: '15%' }}>เอาออก</td> : null}
					</tr>
				</thead>
				<tbody>
					{customers.map(customer => (
						<CustomerRow
							key={'customer' + customer.id}
							id={customer.id}
							name={customer.name}
							files={customer.files}
							countTrips={customer.countTrips}
							onRemove={onRemove}
							onClickTravel={onClickTravel}
							allowUpdate={allowUpdate}
							onClickPassport={onClickPassport}
						/>
					))}
					{customers.length === 0 ? (
						<tr>
							<td colSpan={4} style={{ textAlign: 'center' }}>
								ไม่มีข้อมูล
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
			<CustomerTravelLogModal isShown={isShowModal} toggleModal={toggleModal} customerId={customer ? customer.id : null} />
			<CustomerPassportModal
				isShown={isShowPassportModal}
				toggleModal={togglePassportModal}
				customer={customer}
				onUploadSuccess={onUploadSuccess}
				onDeleteSuccess={onDeleteSuccess}
			/>
		</>
	);
};

const CustomerRow = ({ id, name, countTrips, files, onRemove, onClickTravel, allowUpdate, onClickPassport }) => {
	console.log(id, name, files);
	const onRemoveClick = () => {
		onRemove(id);
	};

	const onClick = () => {
		onClickTravel(id);
	};

	const onPassportClick = () => {
		onClickPassport(id);
	};
	return (
		<tr>
			<td>{name}</td>
			<td style={{ textAlign: 'center' }}>
				{!countTrips ? (
					<button className="button is-text is-small">ครั้งแรก</button>
				) : (
					<button className="button is-text is-small" onClick={onClick}>
						{countTrips} ครั้ง
					</button>
				)}{' '}
			</td>
			<td style={{ textAlign: 'center' }}>
				{files && files.length > 0 ? (
					<button className="button is-text is-success is-small" onClick={onPassportClick}>
						มีแล้ว
					</button>
				) : (
					<button className="button is-text is-warning is-small" onClick={onPassportClick}>
						ยังไม่มี
					</button>
				)}
			</td>
			{allowUpdate ? (
				<td style={{ textAlign: 'center' }}>
					<button className="delete is-small" onClick={onRemoveClick} />
				</td>
			) : null}
		</tr>
	);
};
