import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Stars from '../../../Components/Restaurant/Stars';
import DateLabel from '../../../Components/DateLabel';

import OpenLink from '../../../Helper/OpenLink';

import AppConfig from '../../../AppConfig';

export default class Restaurant extends PureComponent {
	onGetExcel = () => {
		const { programId } = this.props;
		OpenLink.getIncentiveRestaurantExcel(programId);
	};

	render() {
		const { restaurants, programId } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">ร้านอาหาร</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						<Link className="button is-info" to={AppConfig.appPath + '/programs/incentive/' + programId + '/managerestaurants'}>
							เพิ่ม/แก้ไขร้านในโปรแกรมนี้
						</Link>
						<span> </span>
						<button className="button is-success" onClick={this.onGetExcel}>
							Get Excel
						</button>
					</div>
				</div>
				{restaurants && restaurants.length > 0 ? (
					<>
						<div className="table-container">
							<table className="table is-hoverable is-fullwidth is-striped is-bordered">
								<thead>
									<tr className="table-header">
										<td width="15%">วันที่</td>
										<td width="30%">ร้าน</td>
										<td width="15%">ประเภท</td>
										<td>ราคา (ต่อคน)</td>
										<td>คะแนน (เฉลี่ย)</td>
									</tr>
								</thead>
								<tbody>
									{restaurants.map(restaurantVisitInfo => {
										const { restaurant } = restaurantVisitInfo;
										if (restaurant) {
											return (
												<tr key={'restaurant.' + restaurantVisitInfo.id}>
													<td style={{ textAlign: 'center' }}>
														<DateLabel date={restaurantVisitInfo.visitDate} /> {restaurantVisitInfo.visitTime}
													</td>
													<td>
														<Link
															to={
																AppConfig.appPath +
																'/programs/incentive/' +
																programId +
																'/restaurants/' +
																restaurant.id +
																'/' +
																restaurantVisitInfo.id
															}
														>
															{restaurant.name}
														</Link>
													</td>
													<td style={{ textAlign: 'center' }}>{restaurant.type}</td>
													<td style={{ textAlign: 'center' }}>{restaurant.pricePerHead}</td>
													<td style={{ textAlign: 'center' }}>
														<Stars active={restaurant.star} />
													</td>
												</tr>
											);
										} else {
											return null;
										}
									})}
								</tbody>
							</table>
						</div>
					</>
				) : (
					<div style={{ textAlign: 'center' }}>ยังไม่มีร้าน</div>
				)}
			</div>
		);
	}
}
